import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Pnl from "./Pnl/Pnl";
import Margin from "./Margin/Margin";
import Dealer from "./dealer/Dealer";

export default function RiskManagementTab() {
  const location = useLocation();

  // const getTabClass = (tabName) => {
  //     const isActive = location.pathname.includes(tabName) ||
  //         (location.pathname === '/riskmanagement' && tabName === 'pnl')
  //     return `
  //         ${isActive ? 'bg-gray-800 text-white' : 'text-gray-500 hover:text-gray-200'}
  //         border-b-2 ${isActive ? 'border-[#3b9599]' : 'hover:border-gray-300'}
  //         rounded-t-lg px-4 py-2
  //         transition duration-300 ease-in-out
  //         font-semibold text-sm`;
  // };

  const getTabClass = (tabName) => {
    const isActive =
      location.pathname.includes(tabName) ||
      (location.pathname === "/riskmanagement" && tabName === "pnl");
    return isActive ? "tab-link active" : "tab-link";
  };

  return (
    <div className="w-full mt-4 px-4 tabs tab-left-right">
      <nav className="rounded-lg navbar">
        <Link to="/riskmanagement" className={getTabClass("pnl")}>
          P&L - Admin
          <span className="nav-item-before" />
        </Link>
        <Link to="/riskmanagement/margin" className={getTabClass("margin")}>
          Margin - Admin
          <span className="nav-item-before" />
        </Link>
        <Link to="/riskmanagement/dealer" className={getTabClass("dealer")}>
          Dealer
          <span className="nav-item-before" />
        </Link>
        {/* <ul className="flex space-x-1 gap-5 ">
          <li className="">
            <Link to="/riskmanagement" className={getTabClass("pnl")}>
              P&L - Admin
            </Link>
          </li>
          <li className="whitespace-nowrap">
            <Link to="/riskmanagement/margin" className={getTabClass("margin")}>
              Margin - Admin
            </Link>
          </li>
          <li className="whitespace-nowrap">
            <Link to="/riskmanagement/dealer" className={getTabClass("dealer")}>
              Dealer
            </Link>
          </li>
        </ul> */}
        {/* <div className="float-right mb-4">
                    <ToggleButtonGroup
                        color="primary"
                        value={isAdmin}
                        exclusive
                        onChange={(e) => setIsAdmin(e.target.value)}
                        aria-label="Platform"
                    >
                        <ToggleButton value="admin" sx={{px:1.2, py:0.8, fontSize:'12px'}}>Admin</ToggleButton>
                        <ToggleButton value="dealer" sx={{px:1.2, py:0.8, fontSize:'12px'}}>Dealer</ToggleButton>
                    </ToggleButtonGroup>
                </div> */}
      </nav>
      <div>
        <Routes>
          <Route path="/" element={<Pnl />} />
          <Route path="/margin" element={<Margin />} />
          <Route path="/dealer" element={<Dealer />} />
        </Routes>
      </div>
    </div>
  );
}
