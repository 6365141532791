import React from 'react'
import HeaderTable from '../components/HeaderTable'
import MainTable from '../components/MainTable'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PortfolioUpdate from './admin/PortfolioUpdate';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AllChartsAdmin from './admin/AllChartAdmin';
import axios from 'axios';
import AdminDateSelection from "../components/admin/AdminDateSelection";
import Skeleton from '@mui/material/Skeleton';
import useFetchData from '../components/fetchData/useFetchData';
import { DatasetLinked } from '@mui/icons-material';
import DateSelected from './custom/SelectedDate';


const HeaderTableHeaders = [
  { id: 'total', label: 'Total', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, borderLeft: '1px solid #307C9D', width: '238px', fontSize: '12px' } },
  // { id: 'dealer_id', label: 'ID', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  // { id: 'dealer_name', label: 'Dealer Name', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'MarginUsed', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'AvgMargin', label: 'Avg Margin', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '150px', fontSize: '12px' } },
  { id: 'SigmaUsed', label: 'Sigma Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'SigmaCost', label: 'Margin Benefit Cost', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'ROM', label: 'Rom', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '70px', fontSize: '12px' } },
  { id: 'MarginTriggers', label: 'Trigger Hits', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'QtyTraded', label: 'Quantity Traded', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
];

const MainTableHeaders = [
  { id: 'Rank', label: 'Rank', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '38px', fontSize: '12px' } },
  { id: 'DealerID', label: 'ID', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'Name', label: 'Name', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'MarginUsed', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'AvgMargin', label: 'Avg Margin', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '150px', fontSize: '12px' } },
  { id: 'SigmaUsed', label: 'Sigma Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'SigmaCost', label: 'Margin Benefit Cost', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'ROM', label: 'Rom', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '70px', fontSize: '12px' } },
  { id: 'MarginTriggers', label: 'Trigger Hits', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'QtyTraded', label: 'Quantity Traded', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
];

const subheaders = [
  { id: 'Rank', label: 'Rank', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '38px', fontSize: '12px' } },
  { id: 'DealerID', label: 'ID', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'Time', label: 'Month', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'MarginUsed', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'AvgMargin', label: 'Avg Margin', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '150px', fontSize: '12px' } },
  { id: 'SigmaUsed', label: 'Sigma Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'SigmaCost', label: 'Margin Benefit Cost', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'ROM', label: 'Rom', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '70px', fontSize: '12px' } },
  { id: 'MarginTriggers', label: 'Trigger Hits', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'QtyTraded', label: 'Quantity Traded', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
];

const Admin = () => {
  const [isPortfolioShow, setIsPortfolioShow] = React.useState(false);
  const [isChartShow, setIsChartShow] = React.useState(false);
  const [portfolioData, setPortfolioData] = React.useState([]);
  const [headerTableData, setHeaderTableData] = React.useState();
  const [mainTableData, setMainTableData] = React.useState();
  const [dateSelected, setDateSelected] = React.useState("today");
  const [subtableData, setSubTableData] = React.useState()
  const [isLoading, setIsLoading] = React.useState(true);
  const [data] = useFetchData("admin")
  const year = data?.years?.join(",");
  // console.log(year)

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  React.useEffect(() => {
    const fetchData = () => {
      setIsLoading(true)
      // PortfolioUpdate
      axios.get(`${api_server_url}/backoffice/portfolio`, { withCredentials: true })
        .then((res) => {
          // console.log(res.data)
          setPortfolioData(res.data);
        })
        .catch((error) => {
          console.log("error", error);
        });

      if (dateSelected) {
        axios.post(`${api_server_url}/backoffice/margintable/all`, { range: dateSelected, year: year }, { withCredentials: true })
          .then((res) => {
            // months are in dealers list so need to map it here and extract the month from here
            const data = res.data;
            // console.log(data);
            // const month = data?.map((item) => (item?.Monthly || item?.Quarterly || item?.Yearly || item?.Daily));
            // let monthId = month === 'Monthly' ? 'Month' : month === 'Quarterly' ? 'Quarter' : month === 'Daily' ? 'Day' : ''; // Dynamically set based on month
            // console.log(monthId);
            setMainTableData(data.slice(1));
            setHeaderTableData(data[0]);
            const subTableData = data.map((sub) => sub.Sub).slice(1);
            console.log(data);
            setSubTableData(subTableData);
          })
          .catch((error) => {
            console.log("error", error);
          })
          .finally(() => {
            setIsLoading(false);
          })
      };
    }

    fetchData();

  }, [api_server_url, dateSelected, year]);

  const handlePortfolioOpen = () => {
    setIsPortfolioShow(true);
    setIsChartShow(false);
  }

  const handleChartOpen = () => {
    setIsPortfolioShow(false);
    setIsChartShow(true);
  }

  return (
    <>
      {!isChartShow && !isPortfolioShow ?
        <div className='flex flex-col gap-2 border-[1px]  border-[#307C9D] border-opacity-50 p-2 rounded-lg'>
          <div className="flex w-full justify-between items-center">
            <div className="flex items-center w-[50%] justify-between">
              <AdminDateSelection dateSelected={dateSelected} setDateSelected={setDateSelected} />
              <h1 className=" text-center font-semibold text-[14px]">Admin</h1>
            </div>
            <div className="flex items-center gap-4">
              <button className='bg-gray-800 p-1' onClick={handlePortfolioOpen}><InsertDriveFileOutlinedIcon fontSize='small' /></button>
              <button className='bg-gray-800 p-1' onClick={handleChartOpen}><ShowChartIcon fontSize='small' /></button>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            {isLoading ? (
              <Skeleton variant="rectangular" width="100%" height={200} />
            ) : (
              <>
                {headerTableData && <HeaderTable headers={HeaderTableHeaders} data={headerTableData} />}
                {mainTableData && <MainTable headers={MainTableHeaders} data={mainTableData} subheaders={subheaders} subtableData={subtableData} />}
              </>
            )}
          </div>

        </div>
        : isPortfolioShow ? <PortfolioUpdate setShowPortfolio={setIsPortfolioShow} data={portfolioData} /> :
          <AllChartsAdmin
            dateSelected={dateSelected} setDateSelected={setDateSelected}
            // requestData={{ range: dateSelected, year: year }}
             year={year}
            setShowGraphs={setIsChartShow} />}
    </>
  )
}

export default Admin;
