import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Pnl from './Pnl/Pnl';
import Margin from './Margin/Margin';
import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function BackofficeTab() {
    const [isPnlAdmin, setIsPnlAdmin] = useState("admin");
    const [dealerId, setDealerId] = useState("admin");
    const [dealerName, setDealerName] = useState("admin");

    const location = useLocation();

    const getTabClass = (tabName) => {
        const isActive =
          location.pathname.includes(tabName) ||
          (location.pathname === "/backoffice" && tabName === "pnl");
        return isActive ? "tab-link active" : "tab-link";
      };

    return (
        <div className="w-full mt-2 px-4 pb-0 mb-0">
            <div className="w-full mt-4 mb-0 pb-0 tabs tab-left-right flex items-center justify-between">
                <nav className="rounded-lg mb-0 navbar">
                    {/* <ul className="flex space-x-1 gap-5">
                        <li className="">
                            <Link to="/backoffice" className={getTabClass('pnl')}>
                                P&L
                            </Link>
                        </li>
                        <li className="whitespace-nowrap">
                            <Link to="/backoffice/margin" className={getTabClass('margin')}>
                                Margin
                            </Link>
                        </li>
                    </ul> */}
                    <Link to="/backoffice" className={getTabClass("pnl")}>
                        P&L
                        <span className="nav-item-before" />
                    </Link>
                    <Link to="/backoffice/margin" className={getTabClass("margin")}>
                        Margin
                        <span className="nav-item-before" />
                    </Link>
                </nav>
                <div className="flex justify-end">
                    <ToggleButtonGroup
                        color="primary"
                        value={isPnlAdmin}
                        exclusive
                        onChange={(e) => setIsPnlAdmin(e.target.value)}
                        aria-label="Platform"
                    >
                        <ToggleButton value="admin" sx={{ px: 1.2, py: 0.8, fontSize: '12px' }}>Admin</ToggleButton>
                        <ToggleButton value="dealer" sx={{ px: 1.2, py: 0.8, fontSize: '12px' }}>Dealer</ToggleButton>
                        {/* <ToggleButton value="custom" sx={{ px: 1.2, py: 0.8, fontSize: '12px' }}>Custom</ToggleButton> */}
                    </ToggleButtonGroup>
                </div>
            </div>
            <div className="p-2 border border-[#307C9D] border-opacity-50 rounded-md">
                <Routes>
                    <Route path="/" element={<Pnl isPnlAdmin={isPnlAdmin} dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName} />} />
                    {/* <Route path="/pnl" element={<Pnl dealerId={dealerId} dealerName={dealerName} isPnlAdmin={isPnlAdmin} />} /> */}
                    <Route path="/margin" element={<Margin dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName} isMarginAdmin={isPnlAdmin} />} />
                </Routes>
            </div>
        </div>
    );
}