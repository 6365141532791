import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Colors } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import seedrandom from "seedrandom";
import axios from "axios";
import { CircularProgress } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Colors);

const generateChartData = (data, label, colors) => {
  return {
    labels: Object.keys(data),
    datasets: [
      {
        label: label,
        data: Object.values(data).map((item) => {
          if (label === "Margin Usage") {
            return item;
          } else if (label === "Realised") {
            return item.realised;
          } else {
            return item.unrealised;
          }
        }),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };
};
const generateDiffColors = (count, color) => {
  const shades = [];
  for (let i = 0; i < count; i++) {
    const shade = modifyColor(color, i / (count * 1.3));
    shades.push(shade);
  }
  return shades;
};

const generateRandomColors = (count, seed = "light") => {
  const rng = seedrandom(seed);
  const colors = [];
  const baseColor =
    "#" +
    Math.floor(rng() * 16777215)
      .toString(16)
      .padStart(6, "0");
  colors.push(baseColor);

  for (let i = 1; i < count; i++) {
    let newColor = baseColor;
    while (
      newColor === baseColor ||
      parseInt(newColor.substr(1), 16) < 0x888888
    ) {
      // Ensure the new color is different from the base color and not too dark
      newColor =
        "#" +
        Math.floor(rng() * 16777215)
          .toString(16)
          .padStart(6, "0");
    }
    colors.push(newColor);
  }

  return colors;
};

const generateColors = (data) => {
  const totalDataPoints = Object.keys(data).length;
  const shades = generateShades("#00FF00", totalDataPoints);
  return shades;
};

const generateShades = (baseColor, count) => {
  const shades = [];
  for (let i = 0; i < count; i++) {
    const shade = modifyColor(baseColor, i / count);
    shades.push(shade);
  }
  return shades;
};

const modifyColor = (color, factor) => {
  const hex = (color) => ("0" + Math.floor(color).toString(16)).slice(-2);
  const rgb = color.match(/\w\w/g).map((x) => parseInt(x, 16));
  const modifiedColor = `#${hex(rgb[0] * (1 - factor))}${hex(
    rgb[1] * (1 - factor)
  )}${hex(rgb[2] * (1 - factor))}`;
  return modifiedColor;
};

const sortUnderlyingsByPnl = (underlyings) => {
  return Object.fromEntries(
    Object.entries(underlyings).sort(([, a], [, b]) => b.realised - a.realised)
  );
};

const PnlPieDealer = ({ dealerId, dealerName }) => {
  const [chartData, setChartData] = useState();
  const [dataAvailable, setDataAvailable] = useState(true);

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          api_server_url + "/dealer/piechart",
          { dealer_id: dealerId },
          { withCredentials: true }
        );
        // console.log(response.data);
        setChartData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        if (error.response.status === 500) {
          setDataAvailable(false);
        }
      }
    };

    fetchData(); // Initial call

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [api_server_url, dealerId]);

  const chartOptions = {
    rotation: 180,
    // animation: true,
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 15,
        fontColor: "black",
      },
    },
  };

  if (!dataAvailable) {
    return (
      <div className="flex items-center justify-center h-full text-sm text-gray-500">
        <p>No data available.</p>
      </div>
    );
  }

  if (!chartData) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }
  // const marginColors = ["#2f9079", "#51b59d"];
  // const marginChartData = generateChartData(
  //   chartData.margin,
  //   "Margin Usage",
  //   marginColors
  // );
  // console.log(Object.keys(chartData.margin));

  // Calculate the total number of positive P&L values
  const positivePnlCount = Object.values(chartData.underlyings ?? {}).filter(
    (item) => item.realised >= 0
  ).length;

  const pnlPositiveColors = generateDiffColors(positivePnlCount, "#13567C"); // Green shades for positive P&L
  const pnlNegativeColors = generateDiffColors(
    chartData.underlyings
      ? Object.keys(chartData.underlyings).length - positivePnlCount
      : 0,
    "#9C3F17"
  ); // Red shades for negative P&L
  const pnlColors = [
    ...pnlPositiveColors.slice().reverse(),
    ...pnlNegativeColors,
  ];
  const pnlChartData = generateChartData(
    sortUnderlyingsByPnl(chartData.underlyings),
    "Realised",
    pnlColors
  );

  // const underlyingColors = generateRandomColors(Object.keys(chartData.underlyings).length, "a");
  // console.log("Length ..............", chartData.underlyings);
  // const underlyingColors = generateDiffColors(
  //   Object.keys(chartData.underlyings).length,
  //   "#5db3d6"
  // );

  const positiveUnrealisedCount = Object.values(
    chartData.underlyings ?? {}
  ).filter((item) => item.unrealised >= 0).length;

  const unrealisedPositiveColors = generateDiffColors(
    positiveUnrealisedCount,
    "#649763"
  ); // Green shades for positive P&L
  const unrealisedNegativeColors = generateDiffColors(
    chartData.underlyings
      ? Object.keys(chartData.underlyings).length - positiveUnrealisedCount
      : 0,
    "#802626"
  ); // Red shades for negative P&L
  const unrealisedColors = [
    ...unrealisedPositiveColors.slice().reverse(),
    ...unrealisedNegativeColors,
  ];
  const unrealisedChartData = generateChartData(
    chartData.underlyings,
    "Unrealised",
    unrealisedColors
  );

  return (
    <div className="flex justify-center h-[90%] items-center gap-10">
      <div className="h-[90%]">
        <h1 className="flex justify-center text-sm font-semibold w-full mb-2">
          Unrealised P&L
        </h1>
        <div className="h-[100%]">
          <Doughnut data={unrealisedChartData} options={chartOptions} />
        </div>
      </div>

      <div className="h-[90%]">
        <h1 className="flex justify-center text-sm font-semibold w-full mb-2">
          Realised P&L
        </h1>
        <div className="h-[100%]">
          <Doughnut data={pnlChartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default PnlPieDealer;
