import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { toast } from "sonner";

const confirm_password = process.env.REACT_APP_PASSWORD;
const api_server_url = process.env.REACT_APP_API_SERVER_URL;

export default function AddDealer({ block, setBlock }) {
  const [open, setOpen] = React.useState(false);
  const [dealers, setDealers] = React.useState([]);
  const [password, setPassword] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    if (password !== confirm_password) {
      alert("Wrong password");
      return;
    }
    const newDealer = {
      dealerName: formData.get("dealerName"),
      dealerID: formData.get("dealerID"),
      tradingCredentials: {
        apiKey: formData.get("t_api_key"),
        apiSecret: formData.get("t_api_secret"),
      },
      dataCredentials: {
        apiKey: formData.get("d_api_key"),
        apiSecret: formData.get("d_api_secret"),
      },
      email_id: formData.get("email_id"),
      password: "",
      block: false,
    };

    // console.log(newDealer);

    axios
      .post(api_server_url + "/dealers", newDealer, {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        console.log("Response:", response.data);
        // setDealers([newDealer]);
        handleClose();
        setBlock(!block);
        toast.success(`${newDealer.dealerName} Added Successfully`);
      })
      .catch((error) => {
        handleClose();
        console.error("Error:", error);
        if (error.response) {
          console.error("Response Data:", error.response.data);
          console.error("Response Status:", error.response.status);
          console.error("Response Headers:", error.response.headers);
        }
      });

    setPassword("");
  };

  const handleCancel = () => {
    handleClose();
  };

  return (
    <div className="">
      <div className="" onClick={handleOpen}>
        Add Dealer
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        // onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400, // Adjust the width as needed
              bgcolor: "#021B26",
              boxShadow: 30,
              py: 2,
              px: 4,
              maxHeight: "90vh",
              overflowY: "auto",
              display: "flex",
              gap: "15px",
              flexDirection: "column",
            }}
          >
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Add New Dealer
                </Typography>
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="dealerName"
                  label="Dealer Name"
                  name="dealerName"
                  type="text"
                  autoFocus
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="dealerID"
                  label="Dealer Id"
                  name="dealerID"
                  type="text"
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="t_api_key"
                  label="Trading API Key"
                  name="t_api_key"
                  type="text"
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="t_api_secret"
                  label="Trading Secret Key"
                  name="t_api_secret"
                  type="text"
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="d_api_key"
                  label="Data API Key"
                  name="d_api_key"
                  type="text"
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="d_api_secret"
                  label="Data Secret Key"
                  name="d_api_secret"
                  type="text"
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="email_id"
                  label="Email Id"
                  name="email_id"
                  type="email"
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                />
                <TextField
                  margin="none"
                  required
                  fullWidth
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  InputProps={{
                    style: {
                      height: "45px", // Adjust the height as needed
                      padding: "10px 4px", // Adjust padding as needed
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      lineHeight: "0.9",
                      textAlign: "center",
                      fontSize: "13px",
                    },
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
                  <Button
                    onClick={handleCancel}
                    type="cancel"
                    style={{
                      py: 0.5,
                      px: 0,
                      marginBottom: "8px",
                      color: "#fff",
                      border: "1px solid #307c9d",
                      fontSize: "11px",
                      "&:hover": {
                        backgroundColor: "transparent", // Change background color on hover
                        color: "#509ebf",
                        border: "1px solid #509ebf",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    // style={{ mt: 2, mb: 1, backgroundColor: '#307c9d', color: '#fff', px:0, py:0.5 ,fontSize:"11px" }}>
                    style={{
                      py: 0.5,
                      px: 0,
                      marginBottom: "8px",
                      backgroundColor: "#307c9d",
                      color: "#fff",
                      fontSize: "11px",
                    }}
                  >
                    Add Dealer
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
