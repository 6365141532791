import React from "react";
import OptionChainHeader from "./OptionChainHeader";
import OptionChainTabs from "./OptionChainTabs";
const OptionChain = () => {
  return (
    <div className="flex flex-col h-[90vh]">
      {/* <OptionChainHeader /> */}

      <OptionChainTabs />
      
    </div>
  );
};

export default OptionChain;
