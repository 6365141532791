import React, { useEffect, useState } from "react";
import axios from "axios";
import Skeleton from "@mui/material/Skeleton";
import DealerBarChart from "../../components/dealer/DealerBarChart";
import DealerLineChart from "../../components/dealer/DealerLineChart";
import DealerMultipleBarChart from "../../components/dealer/DealerMultipleBarChart";
import DealerTable from "./DealerTable";
import Drawdown from "./Drawdown";
import ComponentZoomModal from "../../../../Hoc/ComponentZoomModal";

const DealerMain = ({
  showSingleBarChartInMiddle = false,
  dealerId,
  range,
}) => {
  const [tableData, setTableData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pnlLine, setPnlLine] = useState([]);
  const [pnlSegment, setPnlSegment] = useState([]);
  const [qtyTradedBar, setQtyTradedBar] = useState([]);
  const [drawdown, setDrawdown] = useState([]);
  const [dataKey, setDataKey] = useState([]);
  const [color, setColor] = useState([]);
  const [openDrawdown, setOpenDrawdown] = useState(false);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          api_server_url + `/backoffice/pnltable/${dealerId}`,
          {
            range: range,
            year: "2024",
          }
        );
        // console.log(response.data);
        setTableData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }

      axios
        .post(api_server_url + `/backoffice/pnlchart/${dealerId}`, {
          range: range,
          year: "2024",
        })
        .then((res) => {
          // console.log(res.data);
          const { PnlBarNLine, PnlSegment, QtyTradedBar, Drawdown } = res.data;
          setPnlLine(PnlBarNLine);
          // console.log("PNL LINE DATA HERE ------>>> ", PnlBarNLine)
          setPnlSegment(PnlSegment);
          setQtyTradedBar(QtyTradedBar?.data);
          // console.log(QtyTradedBar)
          setDrawdown(Drawdown);
          const colorArray = QtyTradedBar?.data?.map((item) => item.Color);
          setColor(colorArray);
          const dataKeyy = PnlSegment?.datakey;
          setDataKey(dataKeyy);
          console.log(dataKeyy);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    fetchData();
  }, [api_server_url, dealerId, range]);

  const dealerLineChartName = "P&L";
  const dealerBarChartName = "P&L Segment";
  const qtyTradedName = "Quantity Traded";
  const drawDownName = "Drawdown";

  const HeaderTableHeaders = [
    {
      id: "NetPnl",
      label: "Net P&L",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "TotalProfit",
      label: "Total Profit",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "TotalLoss",
      label: "Total Loss",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "ProfitDays",
      label: "Profit Days",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "LossDays",
      label: "Loss Days",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "AvgProfit",
      label: "Avg Profit",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "AvgLoss",
      label: "Avg Loss",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "ROM",
      label: "ROM",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "MaxDrawdown",
      label: "Max Dr",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "MaxIntradayDrawdown",
      label: "Max Day Dr",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "RiskRewardRatio",
      label: "Reward/Risk",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "ProfitTriggers",
      label: "Profit Hits",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "LossTriggers",
      label: "Loss Hits",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
    {
      id: "QtyTraded",
      label: "Qty Traded",
      style: {
        pl: 0.5,
        pr: 0.5,
        pt: 0.5,
        pb: 0.5,
        width: "100px",
        fontSize: "12px",
        whiteSpace: "no-wrap",
      },
    },
  ];

  return (
    <>
      <div className="mb-3">
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            width="100%"
            height={100}
            style={{ borderRadius: 5 }}
          />
        ) : (
          tableData && (
            <DealerTable
              headers={HeaderTableHeaders}
              data={tableData}
              openDrawdown={openDrawdown}
              setOpenDrawdown={setOpenDrawdown}
            />
          )
        )}
      </div>
      <div className="flex gap-3 overflow-hidden">
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-2 h-[32vh] w-[48%] relative">
          <div className="w-full flex justify-end absolute top-2 right-2">
            <ComponentZoomModal
              component={DealerLineChart}
              componentProps={{ data: pnlLine, name: dealerLineChartName }}
            />
          </div>
          {isLoading ? (
            <Skeleton
              animtion="wave"
              variant="rounded"
              width="100%"
              height="100%"
              style={{ borderRadius: 5 }}
            />
          ) : pnlLine?.length > 0 ? (
            <DealerLineChart name={dealerLineChartName} data={pnlLine} />
          ) : (
            <p className="text-center flex items-center justify-center h-full">Data not available</p>
          )}
        </div>

        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-2 h-[32vh] w-[48%] relative">
          <div className="w-full flex justify-end absolute top-2 right-2 ">
            <ComponentZoomModal
              component={
                showSingleBarChartInMiddle
                  ? DealerBarChart
                  : DealerMultipleBarChart
              }
              componentProps={{
                name: dealerBarChartName,
                data: pnlSegment,
                dataKey: dataKey,
              }}
            />
          </div>
          {isLoading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height="100%"
              style={{ borderRadius: 5 }}
            />
          ) : showSingleBarChartInMiddle ? (
            <DealerBarChart name={dealerBarChartName} data={pnlSegment} />
          ) : (
            <DealerMultipleBarChart
              name={dealerBarChartName}
              data={pnlSegment}
              dataKey={dataKey}
            />
          )}
        </div>
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-2 h-[32vh] w-[48%] relative">
          <div className="w-full flex justify-end absolute top-2 right-2 ">
            <ComponentZoomModal
              component={DealerBarChart}
              componentProps={{
                data: qtyTradedBar,
                name: qtyTradedName,
                color: color,
              }}
            />
          </div>
          {isLoading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height="100%"
              style={{ borderRadius: 5 }}
            />
          ) : (
            <DealerBarChart
              name={qtyTradedName}
              data={qtyTradedBar}
              color={color}
            />
          )}
        </div>
      </div>

      {openDrawdown && (
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg mt-3 p-2 h-[32vh] relative">
          <div className="w-full flex justify-end absolute top-2 right-2">
            <ComponentZoomModal
              component={Drawdown}
              componentProps={{
                data: drawdown,
                setOpenDrawdown: setOpenDrawdown,
              }}
            />
          </div>
          {isLoading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height="100%"
              style={{ borderRadius: 5 }}
            />
          ) : (
            <Drawdown data={drawdown} setOpenDrawdown={setOpenDrawdown} />
          )}
        </div>
      )}
    </>
  );
};

export default DealerMain;
