import React from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import io from "socket.io-client";
import { throttle } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import FunctionsIcon from '@mui/icons-material/Functions';
import { useTheme, LinearProgress } from '@mui/material';
import MarginModal from './charts/Modal';
import TextField from '@mui/material/TextField';
import { toast } from 'sonner';

const columnHelper = createMRTColumnHelper();

const MarginManager = () => {
    const [data, setData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [sorting, setSorting] = React.useState([]);
    const rowVirtualizerInstanceRef = React.useRef(null);
    const [loading, setLoading] = React.useState(true);
    const [hedgeValue, setHedgeValue] = React.useState(null);
    // const [modalLoading, setModalLoading] = React.useState(true)

    const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

    const checkHedgeValue = (event, max) => {
        if (event.target.value > max) {
            event.target.value = Math.floor(max);
        }
        if (event.target.value < 0) {
            event.target.value = 0;
        }
        const numericValue = event.target.value.replace(/[^0-9]/g, '');

        // Ensure the numeric value is within the allowed range
        const clampedValue = Math.min(Math.floor(max), Math.max(0, numericValue));

        // Format the numeric value with commas in Indian number format
        const formattedValue = new Intl.NumberFormat('en-IN').format(clampedValue);

        // Set the formatted value to state
        setHedgeValue(clampedValue);

        // Set the formatted value back to the input field
        event.target.value = formattedValue;
    }

    const columns = [
        {
            header: 'DealerId', // Main heading
            columns: [
                columnHelper.accessor('id', {
                    header: 'ID',
                    maxSize: 30,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                }),
                columnHelper.accessor('name', {
                    header: 'Name',
                    size: 25,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                }),
            ],
        },
        {
            header: 'Margin', // Main heading
            columns: [
                columnHelper.accessor('netMargin', {
                    header: 'Net Margin',
                    size: 55,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    Cell: ({ row }) => (
                        <Box>
                            {row.original?.netMargin && row.original?.netMargin?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </Box>
                    ),
                    filterVariant: 'range',
                    filterFn: 'betweenInclusive',
                }),
                columnHelper.accessor('utilizedMargin', {
                    header: 'Margin Used',
                    size: 55,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    Cell: ({ row }) => (
                        <Box sx={{ color: row.original?.utilizedMarginPercent > 70 ? '#FF6565' : '#97FF95' }}>
                            {row.original?.utilizedMargin && row.original?.utilizedMargin?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({row.original?.utilizedMarginPercent?.toFixed(2)}%)
                        </Box>
                    ),
                    filterVariant: 'range',
                    filterFn: 'betweenInclusive',
                }),
                columnHelper.accessor('leftMargin', {
                    header: 'Margin Left',
                    size: 55,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    filterVariant: 'range',
                    filterFn: 'betweenInclusive',
                    Cell: ({ row }) => (
                        <Box>
                            {row.original?.leftMargin && row.original?.leftMargin.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            {'\u00a0'}({row.original?.leftMarginPercent?.toFixed(2)}%)
                        </Box>

                    ),
                }),
            ],
        },
        {
            header: 'Sigma', // Main heading
            columns: [
                columnHelper.accessor('utilizedMarginPercent', {
                    header: 'Graph',
                    Cell: ({ row }) => {
                        const theme = useTheme();
                        const progress = row.original?.utilizedMarginPercent || 0;

                        return (
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 3 }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    sx={{
                                        height: 10,
                                        width: 100,
                                        borderRadius: 5,
                                        backgroundColor: theme.palette.mode === 'light' ? '#e0e0e0' : '#424242', // Customize background color
                                        '& .MuiLinearProgress-bar': {
                                            borderRadius: 5,
                                            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
                                        },
                                    }}
                                />
                            </div>
                        );
                    },
                    size: 25,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    enableColumnFilter: false,
                    enableSorting: false
                }),
                columnHelper.accessor('hedge', {
                    header: 'Expected Margin',
                    Cell: ({ cell, row }) => (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 3 }}>
                            <TextField
                                sx={{
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                        display: "none",
                                    },
                                    "& input[type=number]": {
                                        MozAppearance: "textfield",
                                    },
                                    width: 150,
                                }} label="Required"
                                size="small"
                                type='text'
                                onChange={(e) => checkHedgeValue(e, row.original?.utilizedMargin)}
                                InputProps={{
                                    inputProps: { min: 0, max: Math.floor(row.original?.utilizedMargin) },
                                }}
                            />
                        </div>
                    ),
                    size: 55,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    enableColumnFilter: false,
                    enableSorting: false
                }),

                columnHelper.accessor('sigma', {
                    header: 'Sigma',
                    Cell: ({ cell, row }) => (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 3, padding: 0 }}>
                            <MarginModal row={row.original} hedge={hedgeValue} />
                        </div>
                    ),
                    maxSize: 25,
                    muiTableHeadCellProps: {
                        align: 'center',
                    },
                    muiTableBodyCellProps: {
                        align: 'center',
                    },
                    enableColumnFilter: false,
                    enableSorting: false
                }),
            ],
        },

    ];

    const throttledSetData = React.useCallback(throttle((newData) => {
        setData(newData);
    }), []);

    React.useEffect(() => {
        const socket = io(socket_server_url + "/testMarginPage");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on("test-marginpage", (newData) => {
            if (newData) {
                throttledSetData(newData?.dealers);
                setLoading(false)
                // console.log(newData)
            }
            else {
                setLoading(false)
            }

        });

        return () => {
            socket.disconnect();
            throttledSetData.cancel();
        };
    }, [throttledSetData]);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setTableData(data);
        }
    }, []);

    React.useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const handleExportRows = (rows) => {
        try {
            if (!Array.isArray(rows) || rows.length === 0) {
                throw new Error("No rows to export.");
            }
            toast.success('Donwloaded Successfully')
            const doc = new jsPDF('landscape'); // Use landscape orientation

            // Process the table headers
            const tableHeaders = columns.map((c) => c.header || '');

            // Find the index of the 'dealerID' column
            const dealerIDIndex = columns.findIndex(c => c.id === 'dealerID');

            const tableData = rows.map((row) => {
                return columns.map((column) => {
                    const cellValue = row.original ? row.original[column.id] : '';
                    return cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
                });
            });

            autoTable(doc, {
                theme: 'grid',
                head: [tableHeaders],
                body: tableData,
                styles: {
                    overflow: 'linebreak',
                    cellWidth: 'wrap',
                    fontSize: 8,
                },
                columnStyles: {
                    // Set custom width for 'dealerID' column
                    [dealerIDIndex]: { cellWidth: 50 }, // Adjust the width as needed
                },
                startY: 10,
                margin: { top: 10, right: 10, bottom: 10, left: 10 },
                didDrawPage: function (data) {
                    doc.text('Page ' + doc.internal.getNumberOfPages(), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
                },
            });
            const csvContent = [tableHeaders.join(',')];
            tableData.forEach((row) => {
                csvContent.push(row.join(','));
            });
            const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);
            const csvLink = document.createElement('a');
            csvLink.href = csvUrl;
            csvLink.setAttribute('download', 'Sigma_Optimizer.csv');
            document.body.appendChild(csvLink);
            csvLink.click();
            document.body.removeChild(csvLink);

            doc.save('Sigma_Optimizer.pdf');
        } catch (error) {
            toast.error('Download Failed')
            console.error("Error while generating PDF: ", error);
            alert("An error occurred while generating the PDF: " + error.message);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        // columnFilterDisplayMode: 'popover',
        enablePagination: false,
        enableBottomToolbar: false,
        enableStickyHeader: true,
        enableDensityToggle: false,
        initialState: { density: 'compact' },
        columnResizeMode: 'onChange',
        enableRowVirtualization: true,
        onSortingChange: setSorting,
        state: { loading, sorting },
        rowVirtualizerInstanceRef,
        enableGlobalFilterModes: true,
        enableColumnActions: false,
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                fontWeight: '800',
                backgroundColor: '#021b26',
                fontSize: '15px',
                paddingY: 1.5
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                paddingY: 0
            },
        },
        muiTableContainerProps: {
            sx: { sx: { maxHeight: '70vh' } },
            className:'alarm-scrollbar'
          },
        rowVirtualizerOptions: { overscan: 5 }, renderTopToolbarCustomActions: ({ table }) => (
            <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                    handleExportRows(table.getPrePaginationRowModel().rows)
                }
                style={{ marginBottom: '10px', backgroundColor: '#1d1d1d', color: '#3b9599', border: '1px solid #3b9599' }}
                startIcon={<FileDownloadIcon />}
            >
                Download
            </Button>
        ),
    });


    const skeletonRows = [...Array(10)].map((_, index) => (
        <TableRow key={index}>
            {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));

    React.useEffect(() => {
        const rootSocket = io(socket_server_url + "/");

        rootSocket.on('connect', () => console.log('Connected to root socket server'));
        rootSocket.on("disconnect", () => console.log("Disconnected from root socket server"));

        rootSocket.emit("marginpage", "Test Update from frontend");

        return () => {
            rootSocket.disconnect();
        };
    }, []);

    return (
        <>
            {/* <h1 className='bg-[] ml-[20px] mt-[10px] px-4 py-2 w-fit'>Margin Manager</h1> */}
            <Box Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // height: '85vh',
                    overflow: 'hidden',
                    margin: '20px',
                }}
            >
                {
                    loading ? (
                        <TableContainer component={Paper} style={{ maxHeight: '450px', overflow: 'auto' }} className='alarm-scrollbar'>
                            <Table sx={{ minWidth: 600 }} aria-label="skeleton table">
                                <TableHead sx={{ backgroundColor: '#021B26' }}>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} style={{ ...column.style }}>
                                                <Skeleton animation="wave" height={30} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={{ backgroundColor: '#021B26' }}>{skeletonRows}</TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        <MaterialReactTable table={table} />
                    )}
            </Box>
        </>
    )

};

export default MarginManager;

