import React from 'react'
import DealerTable from './DealerTable';
import DealerLineChart from '../../components/dealer/DealerLineChart'
import DealerBarChart from '../../components/dealer/DealerBarChart';
import DealerPieChart from './DealerPieChart';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import ComponentZoomModal from '../../../../Hoc/ComponentZoomModal';
import useFetchData from '../../components/fetchData/useFetchData';

const api_server_url = process.env.REACT_APP_API_SERVER_URL;

const dealerLineChartName = 'Margin'
const PieChart1 = 'Margin Used-Segment'
const PieChart2 = 'Market Value-Segment'
const dealerBarChartName = 'Margin Segment'

const SegmentWisePnlColors = [
  "#0E7181",
  "#2399AB",
  "#65DEF1",
  "#ABEFFA",
  "#E5FCFF",
];

const MarketValueColors = [
  "#1D3D03",
  "#2A4A11",
  "#3B621B",
  "#76A84D",
  "#96C570",
  "#C5E6AB",
];

const DayWisePnl = {
  Monday: 64240,
  Tuesday: 30800,
  Wednesday: 73210,
  Thursday: 24432,
  Friday: 26728,
  Saturday: 14769,
};

const DealerTableHeaders = [
  { id: 'MarginUsed', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px', borderLeft: '1px solid #307C9D' } },
  { id: 'AvgMargin', label: 'Avg Margin', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '150px', fontSize: '12px' } },
  { id: 'SigmaUsed', label: 'Sigma Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'SigmaCost', label: 'Margin Benefit Cost', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'ROM', label: 'Rom', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '50px', fontSize: '12px' } },
  { id: 'MarginTriggers', label: 'Trigger Hits', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'QtyTraded', label: 'Quantity Traded', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
];

const DealerMain = ({ dealerId, range, dateSelected }) => {

  const [data] = useFetchData("admin");
  const year = data?.years?.join(",");
  // console.log(year);
  const [isLoading, setIsLoading] = React.useState(true);
  const [tableData, setTableData] = React.useState([]);
  const [subTableData, setSubTableData] = React.useState([])
  const [marginChartData, setMarginChartData] = React.useState([])
  const [dealerBarChart, setDealerBarChart] = React.useState([])
  const [marginSegmentPie, setMarginSegmentPie] = React.useState([])
  // const [marketValueSegment, setMarketValueSegment] = React.useState([])
  // const [totalMarketValue, setTotalMarketValue] = React.useState('');
  const [totalSegment, setTotalSegment] = React.useState('');
  const [dataKey, setDataKey] = React.useState('')
  React.useEffect(() => {
    const fetchData = () => {
      setIsLoading(true)
      axios.post(`${api_server_url}/backoffice/marginchart/${dealerId}`, { range: range, year: year }, { withCredentials: true })
        .then((res) => {
          const data = res.data
          // console.log(data);
          setMarginChartData(data?.MarginLine);
          setDealerBarChart(data?.MarginSegment);
          // if (range === 'today' || range === 'this_month') {
            //   setDealerBarChart(data?.MarginSegment);
            // } else {
              //   setDealerBarChart((data?.MarginSegment?.data).filter(item => item.Segment !== "total"))
              // }
              // console.log(data?.MarginSegment);
              const MarginDelaerBarChartSegmentKey = ["Equity", "IndexFutures", "IndexOptions" ,"StockFutures", "StockOptions"]
              setDataKey(MarginDelaerBarChartSegmentKey);
          // console.log(data?.MarginSegment.map(val => Object.keys(val)))
          setMarginSegmentPie(data?.MarginSegmentPie)
          // setMarketValueSegment(data?.MarketValue)
          // const totalMarketVal = Array.isArray(data?.MarketValue) ? data?.MarketValue?.reduce((total, item) => total + item.MarketValue, 0) : 0;
          const totalSeg = data?.MarginSegmentPie?.find(item => item.Segment === "Total")?.Margin || '';
          // setTotalMarketValue(totalMarketVal);
          setTotalSegment(totalSeg);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        })
      axios.post(`${api_server_url}/backoffice/margintable/${dealerId}`, { range: range, year: year },
        { withCredentials: true })
        .then((res) => {
          const data = res.data
          // console.log(data?.Sub);
          setTableData(data);
          setSubTableData(data?.Sub)
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        })

    };
    fetchData();

  }, [dealerId, range, year]);


  return (
    <div className='flex flex-col  gap-3 overflow-hidden'>
      <div className="flex w-full items-center">
        {tableData && <DealerTable headers={DealerTableHeaders} data={tableData} subTableData={subTableData} />}
      </div>
      <div className='flex gap-3'>
        <div className="border border-[#307C9D] flex items-center justify-center border-opacity-50 rounded-lg p-4 h-[32vh] w-[48%] relative">
          <div className="w-full flex justify-end absolute top-2 right-2">
            <ComponentZoomModal
              component={DealerLineChart}
              componentProps={{ data: marginChartData, name: dealerLineChartName }}
            />
          </div>
          {isLoading ? (
            <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
          ) : (marginChartData?.length > 0 ?
            <DealerLineChart data={marginChartData} name={dealerLineChartName} /> : (
              <p>Data is not available</p>
            ))}
        </div>
        <div className="border border-[#307C9D] flex items-center justify-center border-opacity-50 rounded-lg p-4 h-[32vh] w-[48%] relative">
          <div className="w-full flex justify-end absolute top-2 right-2">
            <ComponentZoomModal
              component={DealerBarChart}
              componentProps={{ data: dealerBarChart, name: dealerBarChartName, dataKey: dataKey }}
            />
          </div>
          {isLoading ? (
            <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
          ) : (dealerBarChart?.length > 0 ?
            <DealerBarChart data={dealerBarChart} dataKey={dataKey} barColor name={dealerBarChartName}/> : (
              <p>Data is not available</p>
            ))}
        </div>
        <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-2 h-[32vh] w-[48%] relative">
          <div className="w-full flex justify-end absolute top-2 right-2">
            <ComponentZoomModal
              component={() => (
                <div className='flex h-full w-full items-center justify-center'>
                  {isLoading ? (
                    <Skeleton animation="wave" variant="rounded" width="100%" height={200} />
                  ) : (
                    <>
                      {marginSegmentPie && marginSegmentPie?.length > 0 ? (
                        <div className='flex items-center w-[50%] h-full'>
                          <DealerPieChart name={PieChart1} originalData={marginSegmentPie} colors={SegmentWisePnlColors} heading="Total Margin Allotted" totalValue={totalSegment} />
                          {/* <DealerPieChart name={PieChart2} originalData={marketValueSegment} colors={MarketValueColors} heading="Total Market Value" totalValue={totalMarketValue} /> */}
                        </div>
                      ) : (
                        <p>Data is not available</p>
                      )}
                    </>
                  )}
                </div>
              )}
              componentProps={{}}
            />
          </div>
          {isLoading ? (
            <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
          ) : (
            <>
              {marginSegmentPie && marginSegmentPie?.length > 0 ? (
                <>
                  <DealerPieChart name={PieChart1} originalData={marginSegmentPie} colors={SegmentWisePnlColors} heading='Total Margin Allotted' totalValue={totalSegment} />
                  {/* <DealerPieChart name={PieChart2} originalData={marketValueSegment} colors={MarketValueColors} heading='Total Market Value' totalValue={totalMarketValue} /> */}
                </>
              ) : (
                <p>Data is not available</p>
              )}
            </>
          )}
        </div>
      </div>
    </div>)
}

export default DealerMain;
