import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {
  Area,
  AreaChart,
  Brush,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CircularProgress } from "@mui/material";

const MarginDealerLineChart = ({ dealerId, dealerName }) => {
  const [liveData, setLiveData] = useState([]);
  // const [dealerId, setDealerID] = useState("admin");
  // const [dealerName, setDealerName] = useState("admin");
  const [highest, setHighest] = useState();
  const [lowest, setLowest] = useState();
  const [current, setCurrent] = useState();
  const [dataAvailable, setDataAvailable] = useState(true);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          api_server_url + "/dealer/marginchart",
          { dealer_id: dealerId, days: 1 }
        );
        // console.log(response.data);
        setLiveData(response.data.data);
        setHighest(response.data.highest);
        setLowest(response.data.lowest);
        setCurrent(response.data.current);
        setDataAvailable(false);
      } catch (error) {
        console.error("Error fetching data for the selected dealer:", error);
        setDataAvailable(false);
      }
    };

    fetchData(); // Initial call
    const intervalId = setInterval(fetchData, 60000);

    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(intervalId);
  }, [api_server_url, dealerId]);

  if (!dataAvailable) {
    <p className="flex items-center justify-center h-full text-sm text-gray-500">
      <CircularProgress />
    </p>;
  }

  return (
    <div style={{ width: "100%", height: "90%" }} className="flex flex-col">
      <div style={{ width: "100%", height: "90%" }}>
        <ResponsiveContainer>
          {liveData.length > 0 ? (
            <AreaChart data={liveData}>
              <defs>
                <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#3b9599" stopOpacity="0.4" />
                  <stop offset="100%" stopColor="#3b9599" stopOpacity="0" />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="time"
                tick={{
                  fontSize: "10px",
                  fill: "#828282",
                  angle: -18,
                  textAnchor: "end",
                }}
              />
              <YAxis
                dataKey="usedMargin"
                tick={{ fontSize: "10px", fill: "#828282" }}
              />

              <Area
                type="monotone"
                dataKey="usedMargin"
                stroke="#3b9599"
                fill="url(#gradient)"
                strokeWidth={2}
                dot={false}
              />

              <Brush height={11} stroke="black" fill="slategray" />

              <Tooltip
                content={(dataItem) => {
                  if (dataItem.payload && dataItem.payload.length > 0) {
                    const timeValue = dataItem.payload[0].payload.time;
                    const sumValue = dataItem?.payload[0]?.payload?.usedMargin;
                    return (
                      <div
                        style={{
                          background: "rgba(1, 13, 18, 0.95)",
                          color: "white",
                          borderRadius: "5px",
                          padding: "8px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                          Value: {sumValue?.toLocaleString("en-IN")}
                        </p>
                        <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                          Time: {timeValue}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </AreaChart>
          ) : (
            <div className="flex items-center justify-center h-full">
              <p>Data not available.</p>
            </div>
          )}
        </ResponsiveContainer>
      </div>
      <div className="flex gap-10 items-center justify-center">
        <p className="text-[12px]">Current Margin : {current}</p>
        <p className="text-[12px]">Highest Margin : {highest}</p>
        <p className="text-[12px]">Lowest Margin : {lowest}</p>
      </div>
    </div>
  );
};
export default MarginDealerLineChart;
