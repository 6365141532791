import React, { useEffect } from "react";
import {
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  AreaChart,
  CartesianGrid,
  Area,
} from "recharts"; // Importing necessary components from recharts library

// Define the PnlPayoff functional component
const PnlPayoff = ({ payoffChartData }) => {
  
  // useEffect hook with an empty dependency array to mimic componentDidMount
  useEffect(() => {
    return () => { };
  }, []);

  // Function to calculate the offset for gradient color
  const gradientOffset = () => {
    const dataMax = Math.max(...payoffChartData?.map((i) => i.y));
    const dataMin = Math.min(...payoffChartData?.map((i) => i.y));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset(); // Calculate the gradient offset

  // JSX to render the component
  return (
    <ResponsiveContainer style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} width="100%" height="100%">
      <h1 className='text-[13px] font-bold'>Payoff Chart</h1>
      <AreaChart
        style={{ zIndex: 1 }}
        width="100%"
        height="100%"
        data={payoffChartData}
      >
        {/* Render Cartesian grid */}
        <CartesianGrid
          horizontal={false}
          stroke="white"
          strokeOpacity={0.3}
          strokeDasharray="2 2"
        />
        {/* Define XAxis */}
        <XAxis
          dataKey="x"
          domain={["auto", "auto"]}
          interval={1}
          tick={{ fontSize: "10px", fill: "white" }}
        />
        {/* Define YAxis */}
        <YAxis
          dataKey="y"
          tickCount={10}
          domain={["auto", "auto"]}
          tick={{ fontSize: "10px", fill: "white" }}
        />

        {/* Add ReferenceLine for x=0 */}
        <ReferenceLine x={0} stroke="white" strokeOpacity={0.6} />
        {/* Add ReferenceLine for y=0 */}
        <ReferenceLine
          y={0}
          stroke="white"
          strokeOpacity={0.4}
          strokeDasharray="none"
        />
        {/* Tooltip component */}
        <Tooltip
          content={(dataItem) => {
            if (dataItem.payload && dataItem.payload.length > 0) {
              const xValue = dataItem.payload[0].payload.x;
              const yValue = dataItem.payload[0].payload.y;

              const color =
                yValue < 0 ? "rgba(255, 0, 0, 0.4)" : "rgba(0, 255, 0, 0.4)";

              return (
                <div
                  style={{
                    background: color,
                    color: "white",
                    borderRadius: "5px",
                    padding: "6px",
                    fontSize: "12px",
                  }}
                >
                  <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    P&L: {yValue}
                  </p>
                  <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                    Percentage: {xValue}
                  </p>
                </div>
              );
            }
            return null;
          }}
        />

        {/* Define linearGradient */}
        <defs>
          <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset={off} stopColor="green" stopOpacity={0.5} />
            <stop offset={off} stopColor="red" stopOpacity={0.5} />
          </linearGradient>
        </defs>
        {/* Render Area with gradient */}
        <Area
          type="monotone"
          dataKey="y"
          stroke="transparent"
          fill="url(#splitColor)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default PnlPayoff; 
