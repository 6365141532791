import React, { useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Pnl from "./Pnl";
import Margin from "./Margin";
// import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
// import ToggleButton from "@mui/material/ToggleButton";
import Dealer from "./Dealer";

const AnalyticsTab = () => {
  const location = useLocation();

  const [isAdmin, setIsAdmin] = useState("admin");

  // const getTabClass = (tabName) => {
  //   const isActive =
  //     location.pathname.includes(tabName) ||
  //     (location.pathname === "/dashboard" && tabName === "pnlChart"); // Adjusted the condition for pieChart

  //   // console.log(tabName, isActive);
  //   return `
  //           ${
  //             isActive
  //               ? "bg-gray-800 text-white"
  //               : "text-gray-500 hover:text-gray-200"
  //           }
  //           border-b-2 ${
  //             isActive ? "border-[#3b9599]" : "hover:border-gray-300"
  //           }
  //           rounded-t-lg px-4 py-2
  //           transition duration-300 ease-in-out
  //           font-semibold text-sm`;
  // };

  const getTabClass = (tabName) => {
    const isActive =
      location.pathname.includes(tabName) ||
      (location.pathname === "/dashboard" && tabName === "pnlChart");
    return isActive ? "tab-link active" : "tab-link";
  };

  return (
    <div className="w-full mt-4 px-4 tabs tab-left-right">
      <nav className="rounded-lg navbar ">
        <Link to="/dashboard" className={getTabClass("pnlChart")}>
          P&L - Admin
          <span className="nav-item-before" />
        </Link>
        <Link
          to="/dashboard/marginChart"
          className={getTabClass("marginChart")}
        >
          Margin - Admin
          <span className="nav-item-before" />
        </Link>
        <Link to="/dashboard/dealer" className={getTabClass("dealer")}>
          Dealer
          <span className="nav-item-before" />
        </Link>
        {/* <ul className="flex space-x-1 gap-5">
          <li>
            <Link to="/dashboard" className={getTabClass("pnlChart")}>
              P&L - Admin
            </Link>
          </li>
          <li>
            <Link
              to="/dashboard/marginChart"
              className={getTabClass("marginChart")}
            >
              Margin - Admin
            </Link>
          </li>
          <li>
            <Link to="/dashboard/dealer" className={getTabClass("dealer")}>
              Dealer
            </Link>
          </li>
        </ul> */}

        {/* <div className="float-right mb-4">
        <ToggleButtonGroup
          color="primary"
          value={isAdmin}
          exclusive
          onChange={(e) => setIsAdmin(e.target.value)}
          aria-label="Platform"
        >
          <ToggleButton value="admin" sx={{px:1.2, py:0.8, fontSize:'12px'}}>Admin</ToggleButton>
          <ToggleButton value="dealer" sx={{px:1.2, py:0.8, fontSize:'12px'}}>Dealer</ToggleButton>
        </ToggleButtonGroup>
      </div> */}
      </nav>
      <div >
        <Routes>
          <Route path="/" element={<Pnl isPnlAdmin={isAdmin} />} />
          <Route
            path="/marginChart"
            element={<Margin isMarginAdmin={isAdmin} />}
          />
          <Route path="/dealer" element={<Dealer />} />
          {/* <Route path="/pnlChart" element={<Pnl />} /> */}
        </Routes>
      </div>
    </div>
  );
};

export default AnalyticsTab;
