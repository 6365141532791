import React from "react";
import {
  BarChart,
  Bar,
  YAxis,
  XAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const HorizontalBars = ({ data, name }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <h1 className='text-[12px] text-center mb-2'>{name}</h1>
      <BarChart data={data} layout="vertical" stackOffset="sign">
        <YAxis
          type="category"
          dataKey="Time"
          tick={{
            fontSize: "11px",
            fill: "white",
          }}
        />
        <XAxis
          type="number"
          tick={{
            fontSize: "11px",
            fill: "white",
          }}
        // scale="point"
        />
        <Tooltip
          contentStyle={{
            background: "rgba(0, 0, 0, 0.9)",
            color: "white",
            borderRadius: "5px",
            padding: "6px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <ReferenceLine x={0} stroke="#ccc" />
        <Bar
          dataKey="Use"
          fill="#0E7181"
          stackId="stack"
          barSize={10}
          radius={[0, 4, 4, 0]}
        />
        <Bar
          dataKey="Cost"
          fill="#03D6F8"
          stackId="stack"
          barSize={10}
          radius={[0, 4, 4, 0]}
        />

      </BarChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBars;
