import React from "react";
import { Route, Routes, Navigate, } from "react-router-dom";
import Sidebar from "./components/sidebar/Sidebar";
import { DateWisePositions, AlarmsMain, Navbar, Response, MarginManager, LivePositions, Rightbar, PayoffChart, Analytics, OptionChain, RiskManagement,DealerwiseMismatch } from "./components";
import { Margin as RiskMargin, Dealer as RiskDealer, Pnl as RiskPnl } from "./components";
import Dashboard from "./pages/Dashboard/Dashboard";
import { CustomThemeProvider } from "./components";
import "./index.css";
import Login from "./pages/login/Login";
import ls from "localstorage-slim";
import io from "socket.io-client";
import { Toaster, toast } from "sonner";
import NotFoundPage from "./NotFound";
import Backoffice from "./components/sidebarComponents/Backoffice/Backoffice";
import ControlPanel from "./pages/Dashboard/ControlPanel";
import FirewallTab from "./pages/Dashboard/firewall/FirewallTab";
import View from "./pages/Dashboard/firewall/View";
import Firewall from "./pages/Dashboard/Firewall";
import Alarms from "./components/sidebarComponents/Alarms/Alarms";
import Pnl from "./components/sidebarComponents/Analytics/Pnl";
import Margin from "./components/sidebarComponents/Analytics/Margin";
import Dealer from "./components/sidebarComponents/Analytics/Dealer";
import OptionChainHeader from "./components/sidebarComponents/optionChain/OptionChainHeader";
import OptionChainTabs from "./components/sidebarComponents/optionChain/OptionChainTabs";
import DealersLivePositions from "./components/sidebarComponents/livePositions/DealersLivePositions";
import CummulativePositions from "./components/sidebarComponents/livePositions/CummulativePositions";


const alarmSounds = {
  loss: new Audio("/alarms/Loss-limit.mp3"),
  profit: new Audio("/alarms/profit.mp3"),
  rejected: new Audio("/alarms/level1.mp3"),
  margin: new Audio("/alarms/margin-limit.mp3"),
  block: new Audio("/alarms/blocked-dealer.mp3"),
  1: new Audio("/alarms/level1.mp3"),
  2: new Audio("/alarms/level2.mp3"),
  3: new Audio("/alarms/level3.mp3"),
};
// we add level and alert mannually

function App() {
  const [collapsed, setCollapsed] = React.useState(true);
  const [isLoggedIn, setIsLoggedIn] = React.useState(ls.get("isLoggedIn", false));
  const [alertData, setAlertData] = React.useState(null);
  const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

  const showToasts = (level, message, alert) => {
    // alarmSounds.hasOwnProperty(alert) //also we can use this instead of below line
    if (Object.keys(alarmSounds).includes(alert)) {
      switch (alert) {
        case 'loss':
          return toast.error(message, {
            duration: 300000,
          });
        case 'profit':
          return toast.success(message, {
            duration: 300000,
          });
        case 'rejected':
          return toast.warning(message, {
            duration: 300000,
          });
        case 'margin':
          return toast.warning(message, {
            duration: 300000,
          });
        case 'block':
          return toast.warning(message, {
            duration: 300000,
          });
        default:
          return toast.warning(message, {
            duration: 300000,
          });
      }
    }
    else {
      if (level == 1) {
        toast.success(message, {
          duration: 300000,
        });
      } else if (level == 2) {
        toast.warning(message, {
          duration: 300000,
        });
      } else if (level == 3) {
        toast.error(message, {
          duration: 300000,
        });
      } else {
        console.log("error in toast levels");
      }
    }
  };

  React.useEffect(() => {
    const socket = io(socket_server_url + "/testAlarms");

    const handleAlarm = (newAlarm) => {
      try {
        setAlertData(newAlarm);
        console.log(newAlarm);
        // Play an alarm sound based on severity;
        showToasts(newAlarm.level, newAlarm.message, newAlarm.alert);
        // alarmSound.autoplay = true;
        playAlarmSound(newAlarm.alert, newAlarm.level);
      } catch (error) {
        console.error("Error in socket event listener:", error);
      }
    };

    socket.on("connect", () => {
      console.log("Socket Connected........");
    });

    socket.on("test-alarm", handleAlarm);

    // Cleanup function with the timeout
    const timeoutId = setTimeout(() => {
      setAlertData(null);
    }, 30000);

    // Cleanup on component unmount or new alert
    return () => {
      setAlertData(null);
      clearTimeout(timeoutId);
      socket.disconnect();
    };
  }, [setAlertData, socket_server_url, alertData]);

  const playAlarmSound = (alert, level) => {
    try {
      // Check the level and play the corresponding alarm sound
      const alarmSound = alarmSounds[alert] || alarmSounds[level];

      // Ensure the sound is loaded before attempting to play
      if (alarmSound && alarmSound instanceof Audio) {
        // Reset the playback position and play the sound
        alarmSound.autoplay = true;
        alarmSound.currentTime = 0;
        alarmSound.play();
      } else {
        console.warn(`No valid sound found for alert ${alert} or level ${level}`);
      }
    } catch (error) {
      console.error("Error in playAlarmSound:", error);
    }
  };

  React.useEffect(() => { }, [isLoggedIn]);

  return (
    // <UserProvider isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}>
    <CustomThemeProvider>
      <>
        <Toaster richColors visibleToasts={10} closeButton expand={false} position="bottom-right" />
        <div className="flex overflow-hidden h-screen">
          {ls.get("isLoggedIn") && (
            <Sidebar setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} />
          )}
          <div className="flex-grow overflow-y-auto overflow-x-hidden">
            {ls.get("isLoggedIn") && (
              <Navbar collapsed={collapsed} setCollapsed={setCollapsed} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
            )}
            <Routes>
              <Route path="/login" element={ls.get("isLoggedIn") ? (<Navigate to="/" />
              ) : (
                <Login setCollapsed={setCollapsed} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />)} />
              {ls.get("isLoggedIn") ? (
                <>
                  <Route path="/" element={<Dashboard collapsed={collapsed} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} alertData={alertData} />}>
                    <Route path="/controlPanel" element={<ControlPanel />} />
                    <Route path="/firewall" element={<FirewallTab />} >
                      <Route path="/firewall/edit" element={<Firewall />} />
                      <Route path="/firewall/view" element={<View />} />
                    </Route>
                  </Route>
                  <Route path="/alarmsPage" element={<AlarmsMain />}>
                    <Route path="Alarms" element={<Alarms />} />
                  </Route>
                  <Route path="/dashboard" element={<Analytics isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} alertData={alertData} />}>
                    <Route path="/dashboard" element={<Pnl />} />
                    <Route path="/dashboard/marginChart" element={<Margin />} />
                    <Route path="/dashboard/dealer" element={<Dealer />} />
                  </Route>
                  <Route path="/riskmanagement" element={<RiskManagement />} >\
                    <Route path='/riskmanagement' element={<RiskMargin />} />
                    <Route path='/riskmanagement/margin' element={<RiskDealer />} />
                    <Route path='/riskmanagement/dealer' element={<RiskPnl />} />
                  </Route>
                  <Route path="/optionsChain" element={<OptionChain />} >
                    <Route path="optionsChain" element={<OptionChainHeader />} />
                    <Route path="terminal" element={<OptionChainTabs />} />
                  </Route>
                  <Route path="/livePositions/*" element={<LivePositions />} >
                    <Route path="Dealers" element={<DealersLivePositions />} />
                    <Route path="Cumulative" element={<CummulativePositions />} />
                    <Route path="DealerwiseMismatch" element={<DealerwiseMismatch />} />
                  </Route>
                  <Route path="/orderBook" element={<Response />} />
                  <Route path="/payoffChart" element={<PayoffChart />} />
                  {/* <Route path="/dealerwiseMismatch/*" element={<DealerWiseMismatch />} /> */}
                  <Route path="/sigmaOptimizer" element={<MarginManager />} />
                  <Route path="/datewiseposition" element={<DateWisePositions />} />
                  <Route path="/backoffice/*" element={<Backoffice />} />

                  {/* <Route path='/logout' element={<Logout isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>} /> */}
                  <Route path="*" element={<NotFoundPage />} />
                </>
              ) : (
                <Route path="*" element={<Navigate to="/login" />} />
              )}
            </Routes>
          </div>
          {ls.get("isLoggedIn") && (
            <Rightbar collapsed={collapsed} setCollapsed={setCollapsed} />
          )}
        </div>
      </>
    </CustomThemeProvider>
  );
}

export default App;