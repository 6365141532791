import React, { useState } from "react";
import MarginAdmin from "./MarginAdmin";
import MarginDealer from "./MarginDealer";
import DealerDropdown from "./DealerDropdown";

const Margin = ({ isMarginAdmin }) => {
  // const [isMarginAdmin, setIsMarginAdmin] = useState('admin');
  const [dealerId, setDealerId] = useState("admin");
  const [dealerName, setDealerName] = useState("admin");

  return (
    <>
      <div className="flex justify-between">
        <div className="w-fit">
          {isMarginAdmin === "admin" ? null : (
            <DealerDropdown
              dealerId={dealerId}
              setDealerId={setDealerId}
              dealerName={dealerName}
              setDealerName={setDealerName}
            />
          )}
        </div>
      </div>
      {isMarginAdmin === "admin" ? (
        <MarginAdmin />
      ) : (
        <MarginDealer dealerId={dealerId} dealerName={dealerName} />
      )}
    </>
  );
};

export default Margin;
