import axios from "axios";
import React from "react";
import PnlHeaderTable from "../PnlHeaderTable";
import PnlMainTable from "../PnlMainTable";

const ViewRange = ({ endDate, setEndDate, startDate, setStartDate }) => {
  const [headerTableData, setHeaderTableData] = React.useState([]);
  const [mainTableData, setMainTableData] = React.useState([]);
  const [subTableData, setSubTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const HeaderTableHeaders = [
    { id: '', label: 'Cumulative Data', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '354px', fontSize: '12px' ,whiteSpace:'no-wrap', } },
    { id: 'NetPnl', label: 'Net P&L', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalProfit', label: 'Total Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalLoss', label: 'Total Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitDays', label: 'Profit Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossDays', label: 'Loss Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgProfit', label: 'Avg Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgLoss', label: 'Avg Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ROM', label: 'ROM', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxDrawdown', label: 'Max Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxIntradayDrawdown', label: 'Max Day Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'RiskRewardRatio', label: 'Reward/Risk', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitTriggers', label: 'Profit Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossTriggers', label: 'Loss Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'QtyTraded', label: 'Qty Traded', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
  ];

  const MainTableHeaders = [
    { id: '', label: 'Rank', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'DealerID', label: 'ID', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'Name', label: 'Name', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'NetPnl', label: 'Net P&L', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalProfit', label: 'Total Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalLoss', label: 'Total Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitDays', label: 'Profit Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'} },
    { id: 'LossDays', label: 'Loss Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgProfit', label: 'Avg Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgLoss', label: 'Avg Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ROM', label: 'ROM', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxDrawdown', label: 'Max Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxIntradayDrawdown', label: 'Max Day Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'RiskRewardRatio', label: 'Reward/Risk', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitTriggers', label: 'Profit Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossTriggers', label: 'Loss Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'QtyTraded', label: 'Qty Traded', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
  ];

  const SubTableHeaders = [
    { id: '', label: 'Rank', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: '', label: 'ID', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: "Time", label: 'Name', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'NetPnl', label: 'Net P&L', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalProfit', label: 'Total Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalLoss', label: 'Total Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitDays', label: 'Profit Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'} },
    { id: 'LossDays', label: 'Loss Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgProfit', label: 'Avg Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgLoss', label: 'Avg Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ROM', label: 'ROM', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'} },
    { id: 'MaxDrawdown', label: 'Max Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxIntradayDrawdown', label: 'Max Day Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'RiskRewardRatio', label: 'Reward/Risk', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitTriggers', label: 'Profit Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossTriggers', label: 'Loss Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'QtyTraded', label: 'Qty Traded', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
  ];

  const fetchData = () => {
    console.log("cja sdjbvahwbeiuf");
    setIsLoading(true);
    if (startDate && endDate) {
      axios
        .post(
          `${api_server_url}/backoffice/pnltable/all`,
          {
            range: "custom",
            custom_type: "range",
            range_type: "week_day",
            start_day: startDate,
            end_day: endDate,
          },
          { withCredentials: true }
        )
        .then((res) => {
          console.log(res.data);
          setHeaderTableData(res.data[0]);
          const months = res.data;
          setMainTableData(res.data.slice(1));
          const data = months?.map((item) => item.Sub);
          setSubTableData(data.slice(1));

          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [endDate, startDate]);

  return (
    <div className="flex flex-col gap-5">
      {headerTableData && (
        <PnlHeaderTable headers={HeaderTableHeaders} data={headerTableData} />
      )}
      {mainTableData && (
        <PnlMainTable
          headers={MainTableHeaders}
          data={mainTableData}
          subtableData={subTableData}
          subheaders={SubTableHeaders}
        />
      )}
    </div>
  );
};

export default ViewRange;
