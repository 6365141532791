import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  Cell,
} from "recharts";

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { profit_trigger, loss_trigger, net_pnl } = payload[0].payload;
    return (
      <div
        className="custom-tooltip"
        style={{
          background: "rgba(0, 0, 0, 0.6)",
          border: "none",
          borderRadius: "5px",
          padding: "6px",
          fontSize: "12px",
          fontWeight: "bold",
          color: "white",
        }}
      >
        <p className="profit_trigger">{`Profit Trigger: ${profit_trigger}`}</p>
        <p className="loss_trigger">{`Loss Trigger: ${loss_trigger}`}</p>
        <p className="net-pnl">{`Net PNL: ${net_pnl}`}</p>
      </div>
    );
  }

  return null;
};

const PnlBarChart = ({ profit_trigger, loss_trigger }) => {
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(api_server_url + `/dealer/pnlbarchart`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching bar chart data:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ResponsiveContainer width="100%" height="95%">
      {data && data?.length > 0 ? (
        <BarChart data={data} barGap={-12} stackOffset="none">
          <XAxis
            dataKey="dealer_id"
            tick={{
              fontSize: "10px",
              fill: "#828282",
              angle: -10,
              textAnchor: "end",
            }}
          />
          <YAxis
            tick={{
              fontSize: "10px",
              fill: "#828282",
            }}
            domain={[
              (dataMin) => Math.min(dataMin, 0),
              (dataMax) => Math.max(dataMax, 0),
            ]}
          />
          <Tooltip content={<CustomTooltip />} />
          <ReferenceLine y={0} stroke="white" />

          <Bar
            dataKey="loss_trigger"
            fill="rgba(211, 66, 66, 0.3)"
            barSize={12}
            xAxisId={0}
            radius={[3, 3, 0, 0]}
          />

          <Bar
            dataKey="profit_trigger"
            fill="rgba(68,198,98, 0.3)"
            barSize={12}
            xAxisId={0}
            radius={[3, 3, 0, 0]}
          />

          <Bar dataKey="net_pnl" barSize={12} radius={[4, 4, 0, 0]}>
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  entry.net_pnl > 0
                    ? entry.net_pnl > entry.profit_trigger
                      ? "#38F463" 
                      : "rgba(68,198,98, 0.8)" 
                    : entry.net_pnl < entry.loss_trigger
                    ? "#FD3A3A" 
                    : "rgba(211, 66, 66, 0.8)" 
                }
              />
            ))}
          </Bar>
        </BarChart>
      ) : (
        <div className="flex items-center justify-center h-full">
          <p>Data not available.</p>
        </div>
      )}
    </ResponsiveContainer>
  );
};

export default PnlBarChart;
