import React from 'react';
import InternalList from './InternalList';
import ExternalList from './ExternalList';

const List = () => {
    return (
        <div className='flex flex-col w-full'>
            <InternalList />
            <ExternalList />
        </div>
    );
}

export default List;
