import React, { useState, useEffect } from "react";
import axios from "axios";
import { FormControl, MenuItem, Select } from "@mui/material";

const DealerDropdown = ({
  dealerId,
  dealerName,
  setDealerId,
  setDealerName,
}) => {
  const [dealers, setDealers] = useState([]);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    axios.get(api_server_url + `/dealer/underlyingpnlchart`).then((res) => {
      const data = res.data;
      setDealers(data);
    });
  }, [api_server_url]);

  // Ensure dealerId matches one of the available options or an empty string
  const initialDealerId = dealers.length > 0 ? dealers[0].id : '';

  const handleDealer = (event) => {
    const selectedDealerId = event.target.value;
    // console.log(event.target.value);
    const selectedDealer = dealers.find((dealer) => dealer.id === selectedDealerId);
    if (selectedDealer) {
      setDealerId(selectedDealerId);
      setDealerName(selectedDealer.name);
    }
  };

  return (
    <FormControl size="small">
      <Select
        sx={{width:"420px"}}
        labelId="dealer-select-label"
        id="dealer-select"
        value={dealers.some(dealer => dealer.id === dealerId) ? dealerId : initialDealerId} // Use initialDealerId as fallback if dealerId is not in the available options
        onChange={handleDealer}
        className="rounded-md bg-transparent hover:border-gray-100 focus:outline-none cursor-pointer"
      >
        {dealers?.map((option, idx) => (
          <MenuItem key={idx} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DealerDropdown;
