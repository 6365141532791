import React from 'react'
import Admin from './Admin';
import Dealer from './Dealer';
import Custom from './Custom';

const Pnl = ({ isPnlAdmin, dealerId, dealerName, setDealerId, setDealerName, dateSelected }) => {
  return (
    <>
      {isPnlAdmin === "admin" ?
        <Admin dateSelected={dateSelected} />
        : (isPnlAdmin === "dealer" ?
        <Dealer dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName} />
          : <Custom />)}
    </>
  );
};

export default Pnl;
