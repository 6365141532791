import React, { useState, useRef, useEffect, lazy, Suspense } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import axios from "axios";
import ls from "localstorage-slim";
import { toast } from 'sonner';
import LogoutQuote from "./LogoutQuote";

const LoginSound = new Audio("/loginSound/login.wav")

const Login = ({ isLoggedIn, setIsLoggedIn }) => {
  const [isLoggedOut, setIsLoggedOut] = useState(localStorage.getItem('isLoggedOut') === 'true');
  const [isVisible, setIsVisible] = useState(false);
  // const [isLoginDivVisible, setIsLoginDivVisible] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const passwordInputRef = useRef(null);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const [isAnimationComplete, setIsAnimationComplete] = useState(false);

  useEffect(() => {
    if (isAnimationComplete) {
      const timeoutId = setTimeout(() => {
        ls.set("isLoggedIn", true);
        setIsLoggedIn(true);
      }, 4000);
      return () => clearTimeout(timeoutId);
    }
  }, [isAnimationComplete, setIsLoggedIn]);

  const playLoginSound = (level) => {
    try {
      // Check the level and play the corresponding alarm sound


      // Ensure the sound is loaded before attempting to play
      if (LoginSound && LoginSound instanceof Audio) {
        // Reset the playback position and play the sound
        LoginSound.autoplay = true;
        LoginSound.currentTime = 0;
        LoginSound.play();
      } else {
        console.warn(`No valid sound found for level ${level}`);
      }
    } catch (error) {
      console.error('Error in playAlarmSound:', error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (event.target.id === "name") {
        passwordInputRef.current.focus();
      } else {
        handleLogin();
      }
    }
  };

  const toggleVisibility = () => setIsVisible(!isVisible);
  const handleRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async () => {
    setIsLoading(true);

    try {
      if (!name || !password) {
        setLoginError("All fields are required");
        return setIsLoading(false);
      }

      const response = await axios.post(
        api_server_url + "/login",
        { user: name, password: password, remember_me: rememberMe },
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );

      if (response.data && response.data.message === "Login successful") {
        // toast.success('Login Successful');

        // setIsLoginDivVisible(false);

        const scopeH1 = document.getElementById("scope-h1");
        const loginDiv = document.getElementById("login-div");


        scopeH1.style.transition = "transform 3s";
        scopeH1.style.transform = "scale(4)";
        scopeH1.style.transformOrigin = "16% 50%";
        scopeH1.style.transitionDelay = "0s";

        playLoginSound();
        loginDiv.style.transition = "transform 1s";
        loginDiv.style.transform = "scale(0)";

        setIsAnimationComplete(true);
      } else {
        toast.error('Login Failed');
        console.error("Login was not successful or data format is unexpected:", response.data);
        setLoginError("Incorrect username or password. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response) {
        toast.error('Login Failed');
        console.error("Server responded with an error:", error.response.data);
        setLoginError("Incorrect username or password. Please try again.");
      } else if (error.request) {
        toast.error('Login Failed');
        setLoginError("Incorrect username or password. Please try again.");
        console.error("No response received:", error.request);
      } else {
        toast.error('Login Failed');
        setLoginError("Incorrect username or password. Please try again.")
        console.error("Error setting up the request:", error.message);
      }
    }
  };
  const handleVideoEnd = () => {
    setIsLoggedOut(false);
    // You can add additional logic here if needed
  };
  const onSkip = () => {
    setIsLoggedOut(false);
    ls.remove('isLoggedOut');
    // You can add additional logic here if needed
  };



  // if (ls.get('isLoggedOut')) {
  //   <LogoutQuote />
  // }
  // else
  return (
    <>
      {ls.get('isLoggedOut') ? <LogoutQuote onVideoEnd={handleVideoEnd} onSkip={onSkip}/> :
    <div style={{ position: "relative", overflow: "hidden" }}>
      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          height: "100%",
          opacity: 0.3,
          objectFit: "cover",
          position: "absolute",
          zIndex: "-1",
        }}
      >
        <source
          src="https://mudrakshnmcshaw.github.io/HarrierLogoutVideo/Login.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>

      <div className="flex flex-row items-center h-screen justify-center gap-80">
        <div id="scope-h1">
          <h1 className="text-5xl font-extrabold" >
            Trade<span className="text-[#71c2c6]">Sage</span>.AI
          </h1>
          <h1 className="text-xs text-center text-[#97a0aa] aldrich-font">Powered by <span className="text-xl font-extrabold mudraksh-text">Mudraksh</span></h1>
        </div>

        <div
          id="login-div"
          style={{ display: 'block' }}
          className={`w-[350px] max-w-md p-5 rounded-r-lg flex flex-col items-center justify-center`}
        >
          <div className="mb-4 flex flex-col items-center justify-center">
            <div className="relative mb-4">
              <input
                required
                type="text"
                id="name"
                placeholder="Username"
                className={`p-[5px] w-[250px] pl-4 rounded-lg border border-gray-600 bg-transparent hover:border-gray-400 focus:outline-none transition-all ${loginError ? "border-[#ff6565]" : ""}`}
                style={{ color: "#fff" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                onKeyDown={handleKeyPress}
              />
            </div>
            <div className="flex items-center mb-4">
              <div className="relative">
                <input
                  ref={passwordInputRef}
                  required
                  id="password"
                  type={isVisible ? "text" : "password"}
                  placeholder="Password"
                  className={`p-[5px] w-[250px] pl-4 rounded-lg border border-gray-600 bg-transparent hover:border-gray-400 focus:outline-none transition-all ${loginError ? "border-[#ff6565]" : ""}`}
                  style={{ color: "#fff" }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <button
                  className="focus:outline-none absolute top-1/2 transform -translate-y-1/2 right-3"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <FaEyeSlash className="text-lg text-gray-400 pointer-events-none " />
                  ) : (
                    <FaEye className="text-lg text-gray-400 pointer-events-none" />
                  )}
                </button>
              </div>
            </div>

            <div className="flex ml-16 w-full mb-4">
              <label className="flex ">
                <input
                  type="checkbox"
                  className={`form-checkbox mt-[2.3px] h-3 w-3 cursor-pointer`}
                  checked={rememberMe}
                  onChange={handleRememberMe}
                  style={{
                    borderColor: '#71c2c6',
                    backgroundColor: '#71c2c6',
                  }}
                />
                <span className={`ml-2 text-xs cursor-pointer`}>
                  Remember Me
                </span>
              </label>
            </div>

            {loginError && (
              <p className="text-[#ff6565] text-xs mb-2">
                Incorrect email or password. Please try again.
              </p>
            )}
            <button
              className="w-[50] mt-7 font-semibold px-6 py-2 text-white rounded-md focus:outline-none"
              style={{
                background: "#0a727a",
                transition: "background 0.3s",
              }}
              onClick={handleLogin}
              disabled={isLoading}
            >
              {isLoading ? "Logging in..." : "Login"}
            </button>
          </div>
        </div>
      </div>
    </div>
  }
  </>
  );
};

export default Login;