import React from 'react';
import CustomToggleButton from './custom/CustomToggleButton';
import ViewDate from './custom/ViewDate';
import ViewRange from './custom/ViewRange';
import axios from 'axios';
const Custom = () => {
    const [headerTableData, setHeaderTableData] = React.useState([]);
    const [mainTableData, setMainTableData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [subtableData, setSubTableData] = React.useState([]);
    const [customToggle, setCustomToggle] = React.useState('date');
    const [openChart, setOpenChart] = React.useState(false);
    const [date, setDate] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [rangeType, setRangeType] = React.useState();

    const api_server_url = process.env.REACT_APP_API_SERVER_URL;

    React.useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            // PortfolioUpdate
            if (startDate && endDate) {
                axios.post(`${api_server_url}/backoffice/margintable/all`, { range: "custom", custom_type: "range", range_type: rangeType, start_day: startDate, end_day: endDate }, { withCredentials: true })
                    .then((res) => {
                        const data = res.data;
                        const month = data?.map((item) => (item?.Monthly || item?.Quarterly || item?.Yearly || item?.Daily));
                        setSubTableData(month.slice(1));
                        setHeaderTableData(data[0]);
                        // console.log(data[0])
                        setMainTableData(data.slice(1));
                        // console.log(data.slice(1));
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        console.log("error", error);
                    })
                    .finally(() => {
                        setIsLoading(false);
                    });
            }
        };

        fetchData();
    }, [api_server_url, endDate, rangeType, startDate]);

    return (
        <div className={openChart ? '' : 'p-2 border border-[#307C9D] border-opacity-50  rounded-lg h-[78vh] overflow-hidden'}>
            <CustomToggleButton setRangeType={setRangeType} rangeType={rangeType} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} setDate={setDate} date={date} setOpenChart={setOpenChart} openChart={openChart} setCustomToggle={setCustomToggle} customToggle={customToggle} />
            {!openChart && ( // Check if charts are not open
                <>
                    {customToggle === "date" ? <ViewDate date={date} /> : <ViewRange subtableData={subtableData} mainTableData={mainTableData} headerTableData={headerTableData} />}
                </>
            )}
        </div>
    );
}

export default Custom;