import React, { useState } from 'react';
import axios from 'axios';
import ls from 'localstorage-slim';
import LogoutIcon from '@mui/icons-material/Logout';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button, Tooltip } from '@mui/material';

const Logout = ({ setIsLoggedIn }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isLogoutDialogOpen, setIsLogoutDialogOpen] = useState(false)
    const api_server_url = process.env.REACT_APP_API_SERVER_URL;

    const handleLogout = () => {
        setIsLoading(true);
        axios
            .get(api_server_url + '/logout', {
                headers: {
                    'Content-Type': 'application/json',
                },
                withCredentials: true
            })
            .then((response) => {
                if (response.data.message === 'Logout successful') {
                    console.log("logging out.....");
                    ls.set('isLoggedOut', true);
                    ls.remove('isLoggedIn');
                    setIsLoggedIn(false);
                }
            })
            .catch(error => {
                if (error.response && error.response.status === 401) {
                    console.log("Unauthorized Error here.............................");
                    setIsLoggedIn(false);
                    ls.set('isLoggedOut', true);
                    ls.remove('isLoggedIn');
                    setError('Error fetching data', error)
                } else {
                    console.error('Error fetching data:', error);
                }
            });

        setIsLoading(false);
    };

    const openLogoutDialog = () => {
        setIsLogoutDialogOpen(true);
    }

    return (
        <>
            <div className="text-center flex flex-col gap-5">
                {error && <p className="text-red-500">{error}</p>}
                <Tooltip title="Logout" placement='bottom-start'>
                    <button
                        type="button"
                        className=" text-[#fff] py-1 px-1 rounded-lg flex items-center justify-center"
                        onClick={openLogoutDialog}
                        disabled={isLoading}
                    >
                        <LogoutIcon color='primary'/>
                    </button>
                </Tooltip>
            </div>
            <Dialog
                open={isLogoutDialogOpen}
                onClose={() => setIsLogoutDialogOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{ '& .MuiDialog-paper': { backgroundColor: '#081D26' } }}
            >
                <DialogTitle id="alert-dialog-title">{"Confirm Logout"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ color: 'white' }}>
                        Are you sure you want to Logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setIsLogoutDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleLogout} color="primary" variant="outlined">
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Logout;
