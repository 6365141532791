import React from 'react';

const DealerTable = ({ data, dealerId }) => {
    return (
        <div className="overflow-auto flex  flex-col gap-4 min-w-full h-full">
            <div className=' min-w-full border-opacity-50 sticky top-0'>
                <table className="min-w-full ">
                    <thead className="">
                        <tr className="text-white border border-[#307c9d] border-opacity-50  whitespace-nowrap bg-[#001B27]">
                            <th className="px-4 w-[400px] py-1 text-center text-[13px] font-semibold uppercase tracking-wider border-opacity-50 border-r border-r-[#307c9d]" rowSpan="2">{dealerId}</th>
                            <th className="px-4 w-[250px] py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">VAR</th>
                            <th className="px-4 w-[200px] py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">ON MARGIN</th>
                            <th className="px-4 w-[200px] py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">QTY TRADED</th>
                        </tr>
                        {data?.filter(dealer => dealer.dealer_id)
                            ?.map((dealer, idx) => (
                                <tr key={idx} className="text-[12px] font-normal bg-[#01161F] border border-[#307c9d] border-opacity-50">
                                    <th
                                        className="px-4  py-1 text-[12px] font-normal whitespace-nowrap text-center tracking-wider border border-[#307c9d] border-opacity-50"
                                        data-var={dealer.var}
                                        data-var-percent={dealer.var_percent}
                                        onMouseOver={(e) => e.target.innerText = e.target.dataset.var ? e.target.dataset.var : ''}
                                        onMouseOut={(e) => e.target.innerText = e.target.dataset.varPercent ? e.target.dataset.varPercent : ''}
                                    >
                                        {dealer.var_percent}
                                    </th>
                                    <th
                                        className="px-4 py-1 whitespace-nowrap text-center font-normal tracking-wider border border-[#307c9d] border-opacity-50"
                                        data-on-margin={dealer.on_margin}
                                        data-on-margin-percent={dealer.on_margin_percent}
                                        onMouseOver={(e) => e.target.innerText = e.target.dataset.onMargin ? e.target.dataset.onMargin : ''}
                                        onMouseOut={(e) => e.target.innerText = e.target.dataset.onMarginPercent ? e.target.dataset.onMarginPercent : ''}
                                    >
                                        {dealer.on_margin_percent}
                                    </th>
                                    <th className="px-4 py-1 whitespace-nowrap text-center  font-normal border border-[#307c9d] border-opacity-50">{dealer?.qty_traded}</th>
                                </tr>
                            ))}
                    </thead>
                </table>
            </div>

            <div className='overflow-hidden overflow-y-auto'>
                <table className="min-w-full">
                    <thead className=" sticky top-[-0.01px]">
                        <tr className="border text-white bg-[#001B27] whitespace-nowrap">
                            <th className="px-4 py-1 w-[200px] text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">SEGMENT</th>
                            <th className="px-4 py-1 w-[250px] text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">VAR</th>
                            <th className="px-4 py-1 w-[200px] text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">ON MARGIN</th>
                            <th className="px-4 py-1 w-[200px] text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">QTY TRADED</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 text-[12px] overflow-y-auto border-opacity-50">
                        {data
                            ?.filter(dealer => !dealer.dealer_id) // Exclude data with dealer_id
                            ?.map((dealer, idx) => (
                                <tr key={idx} className="border-b border-b-[#307c9d] bg-[#01161F] border-opacity-50">
                                    <td className="px-4 py-1 whitespace-nowrap text-center tracking-wider border border-[#307c9d] border-opacity-50">{dealer.segment}</td>
                                    <td
                                        className="px-4 py-1 text-[12px] font-normal whitespace-nowrap text-center tracking-wider border border-[#307c9d] border-opacity-50"
                                        data-var={dealer.var}
                                        data-var-percent={dealer.var_percent}
                                        onMouseOver={(e) => e.target.innerText = e.target.dataset.var ? e.target.dataset.var : ''}
                                        onMouseOut={(e) => e.target.innerText = e.target.dataset.varPercent ? e.target.dataset.varPercent : ''}
                                    >
                                        {dealer.var_percent}
                                    </td>
                                    <td
                                        className="px-4 py-1 whitespace-nowrap text-center tracking-wider border border-[#307c9d] border-opacity-50"
                                        data-on-margin={dealer.on_margin}
                                        data-on-margin-percent={dealer.on_margin_percent}
                                        onMouseOver={(e) => e.target.innerText = e.target.dataset.onMargin ? e.target.dataset.onMargin : ''}
                                        onMouseOut={(e) => e.target.innerText = e.target.dataset.onMarginPercent ? e.target.dataset.onMarginPercent : ''}
                                    >
                                        {dealer.on_margin_percent}
                                    </td>
                                    <td className="px-4 py-1 whitespace-nowrap text-center tracking-wider border border-[#307c9d] border-opacity-50">{dealer.qty_traded}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>

    );
}

export default DealerTable;
