import React from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import io from "socket.io-client";
import { throttle } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast } from 'sonner';
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";

const columnHelper = createMRTColumnHelper();

const columns = [
    columnHelper.accessor('AccountID', {
        header: 'ID',
        size: 140,
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
    }),
    columnHelper.accessor('TradingSymbol', {
        header: 'Symbol',
        size: 260,
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
    }),
    columnHelper.accessor('ExchangeSegment', {
        header: 'Ex Segment',
        size: 135,
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
    }),
    columnHelper.accessor('ProductType', {
        header: 'Product Type',
        size: 120,
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
    }),
    columnHelper.accessor('Quantity', {
        header: 'QTY',
        size: 220,
        muiTableHeadCellProps: {
            align: 'center',
        },
        muiTableBodyCellProps: {
            align: 'center',
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
        Cell: ({ row }) => (
            <Box
                sx={{
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    gap: 1,
                    color: row.original.Quantity > 0 ? '#25d8f4' : '#ff7c52'
                }}
            >
                {row.original.Quantity && row.original.Quantity.toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
            </Box>
        )
    }),

    columnHelper.accessor('BuyAmount', {
        header: 'Buy Amt',
        size: 220,
        muiTableHeadCellProps: {
            align: 'center',
        },
        Cell: ({ row }) => (
            <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                {row.original.BuyAmount && Number(row.original.BuyAmount)?.toFixed(2).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
            </Box>
        ),
        muiTableBodyCellProps: {
            align: 'center',
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
    }),
    columnHelper.accessor('SellAmount', {
        header: 'Sell Amt',
        size: 220,
        muiTableHeadCellProps: {
            align: 'center',
        },
        Cell: ({ row }) => (
            <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                {row.original.SellAmount && Number(row.original.SellAmount)?.toFixed(2).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
            </Box>

        ),
        muiTableBodyCellProps: {
            align: 'center',
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
    }),
    columnHelper.accessor('NetAmount', {
        header: 'Net Amt',
        size: 220,
        muiTableHeadCellProps: {
            align: 'center',
        },
        Cell: ({ row }) => (
            <Box sx={{ padding: 0, margin: 0.5, display: 'flex', gap: 1 }}>
                {row.original.NetAmount && Number(row.original.NetAmount)?.toFixed(2).toLocaleString('en-IN', { style: 'currency', currency: 'INR' })}
            </Box>
        ),
        muiTableBodyCellProps: {
            align: 'center',
        },
        filterVariant: 'range',
        filterFn: 'betweenInclusive',
    }),
]

const CummulativePositions = () => {
    const [data, setData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [sorting, setSorting] = React.useState([]);
    const rowVirtualizerInstanceRef = React.useRef(null);
    const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

    const throttledSetData = React.useCallback(throttle((newData) => {
        setData(newData);
    }, 2000), []);

    React.useEffect(() => {
        const socket = io(socket_server_url + "/testClientPos");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on("test-clientpos", (newData) => {
            if (newData) {
                throttledSetData(newData);
                // console.log(newData)
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        });

        return () => {
            socket.disconnect();
            throttledSetData.cancel();
        };
    }, [throttledSetData]);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setTableData(data);
        }
    }, []);

    React.useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const handleExportRows = (rows) => {
        try {
            if (!Array.isArray(rows) || rows.length === 0) {
                throw new Error("No rows to export.");
            }
            toast.success('Downloaded Successfully')

            const doc = new jsPDF('landscape'); // Use landscape orientation

            // Process the table headers
            const tableHeaders = columns.map((c) => c.header || '');

            // Find the index of the 'dealerID' column
            const dealerIDIndex = columns.findIndex(c => c.id === 'dealerID');

            const tableData = rows.map((row) => {
                return columns.map((column) => {
                    const cellValue = row.original ? row.original[column.id] : '';
                    return cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
                });
            });

            autoTable(doc, {
                theme: 'grid',
                head: [tableHeaders],
                body: tableData,
                styles: {
                    overflow: 'linebreak',
                    cellWidth: 'wrap',
                    fontSize: 8,
                },
                columnStyles: {
                    // Set custom width for 'dealerID' column
                    [dealerIDIndex]: { cellWidth: 50 }, // Adjust the width as needed
                },
                startY: 10,
                margin: { top: 10, right: 10, bottom: 10, left: 10 },
                didDrawPage: function (data) {
                    doc.text('Page ' + doc.internal.getNumberOfPages(), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
                },
            });
            const csvContent = [tableHeaders.join(',')];
            tableData.forEach((row) => {
                csvContent.push(row.join(','));
            });
            const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);
            const csvLink = document.createElement('a');
            csvLink.href = csvUrl;
            csvLink.setAttribute('download', 'Net_positions.csv');
            document.body.appendChild(csvLink);
            csvLink.click();
            document.body.removeChild(csvLink);

            doc.save('Net_positions.pdf');
        } catch (error) {
            toast.error('Download Failed')
            console.error("Error while generating PDF: ", error);
            alert("An error occurred while generating the PDF: " + error.message);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        // columnFilterDisplayMode: 'popover',
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                //   fontStyle: 'italic',
                fontWeight: '800',
                backgroundColor: '#021b26',
                fontSize: '15px',
                paddingY: 1
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                paddingY: 0
            },
        },
        enablePagination: false,
        enableBottomToolbar: false,
        enableStickyHeader: true,
        enableDensityToggle: false,
        // initialState: { density: 'compact' },
        columnResizeMode: 'onChange',
        enableRowVirtualization: true,
        muiTableContainerProps: {
            sx: { maxHeight: '66vh', minWidth: 'full' },
            className: 'alarm-scrollbar'
        },
        onSortingChange: setSorting,
        state: { loading, sorting },
        rowVirtualizerInstanceRef,
        enableGlobalFilterModes: true,
        enableColumnActions: false,
        layoutMode: 'grid-no-grow', //constant column widths
        // renderRowActionMenuItems: () => [<MenuItem key="action">Action</MenuItem>],
        initialState: {
            density: 'compact',
            columnPinning: { left: ['AccountID'] },
        },
        rowVirtualizerOptions: { overscan: 5 }, renderTopToolbarCustomActions: ({ table }) => (
            <Button
                disabled={table.getPrePaginationRowModel().rows.length === 0}
                onClick={() =>
                    handleExportRows(table.getPrePaginationRowModel().rows)
                }
                style={{ py: 0.5, px: 0, marginBottom: '6px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize: "11px" }}
                startIcon={<DownloadingOutlinedIcon />}
            >
                Download
            </Button>
        ),
    });
    const skeletonRows = [...Array(10)].map((_, index) => (
        <TableRow key={index}>
            {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));
    React.useEffect(() => {
        const rootSocket = io(socket_server_url + "/");

        rootSocket.on('connect', () => console.log('Connected to root socket server'));
        rootSocket.on("disconnect", () => console.log("Disconnected from root socket server"));

        rootSocket.emit("clientpos", "Test Update from frontend");

        return () => {
            rootSocket.disconnect();
        };
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                // height: '75vh',
                overflow: 'hidden',
                // margin: '20px',
            }}
            className='MUITable-scrollbar border border-[#307C9D] border-opacity-50 rounded-md'
        >
            {loading ? (
                <TableContainer component={Paper} style={{ maxHeight: '450px', overflow: 'auto' }} className='alarm-scrollbar'>
                    <Table sx={{ minWidth: 600 }} aria-label="skeleton table">
                        <TableHead sx={{ backgroundColor: '#021B26' }}>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell key={column.id} style={{ ...column.style }}>
                                        <Skeleton animation="wave" height={30} />
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody sx={{ backgroundColor: '#021B26' }}>{skeletonRows}</TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <>
                    <MaterialReactTable table={table} />
                </>
            )}
        </Box>
    )
};

export default CummulativePositions;