import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import axios from "axios";
import { toast } from "sonner";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const confirm_password = process.env.REACT_APP_PASSWORD;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#021B26",
  border: "2px solid #000",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  overflowY: "auto",
  width: 400,
};

export default function EditPortfolio({
  open,
  onClose,
  rowData,
  setBlock,
  block,
}) {
  const [portfolioValue, setPortfolioValue] = useState(
    rowData?.portfolio_value || ""
  );
  const [varParameter, setVarParameter] = useState(rowData?.var_metric || "");
  const [isSwitch, setIsSwitch] = useState(false);
  const [password, setPassword] = useState("");
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const updateDealerInfo = (updatedInfo) => {
    return axios
      .post(api_server_url + "/admin/edit", updatedInfo, {
        withCredentials: true,
      })
      .then((response) => {
        console.log("Update successful:", response.data);
        setBlock(!block);
        setPassword("");
        onClose();
        toast.success(`Updated Successfully`);
      })
      .catch((error) => {
        onClose();
        toast.error(`Error updating try agian later`);
        console.error("Error updating dealer:", error);
        // Re-throw the error to propagate it to the calling code
        throw error;
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password !== confirm_password) {
      alert("Invailid password");
      return;
    }
    let updatedValue;
    if (isSwitch) {
      updatedValue = {
        var_metric: varParameter,
      };
    } else {
      updatedValue = {
        portfolioValue: portfolioValue.replace(/,/g, ""),
      };
    }
    // Call the update function with the appropriate value
    updateDealerInfo(updatedValue)
      .then(() => {
        console.log(updatedValue);
        if (onClose) onClose();
      })
      .catch((error) => {
        onClose();
        console.error("Error updating dealer:", error);
      });

    setPassword("");
  };

  const handleCancel = () => {
    if (onClose) onClose();
  };
  const checkPortfolio = (event) => {
    let numericValue = event.target.value.replace(/[^0-9]/g, "");
    // Remove commas from the numeric value
    numericValue = numericValue.replace(/,/g, "");
    // Format the numeric value with commas
    const formattedValue = new Intl.NumberFormat("en-IN").format(numericValue);
    // Set the formatted value to state
    setPortfolioValue(formattedValue);
    // console.log(formattedValue)
  };

  const handleToggleChange = () => {
    setIsSwitch((prev) => !prev);
  };

  return (
    <Modal
      open={open}
      // onClose={onClose}
      aria-labelledby="edit-dealer-modal-title"
      aria-describedby="edit-dealer-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <FormControlLabel
              control={
                <Switch
                  checked={isSwitch}
                  onChange={handleToggleChange}
                  name="pnlTriggerPercentSwitch"
                />
              }
              label={isSwitch ? "Use Var Parameter" : "Use Portfolio Value"}
            />
            {isSwitch ? (
              <TextField
                margin="dense"
                required
                fullWidth
                id="var_metric"
                label="Var Parameter"
                name="var_metric"
                type="text"
                size="small"
                value={varParameter}
                onChange={(e) => setVarParameter(e.target.value)}
              />
            ) : (
              <TextField
                margin="dense"
                required
                fullWidth
                id="portfolio_value"
                label="Portfolio Value"
                name="portfolio_value"
                type="text"
                size="small"
                value={portfolioValue}
                onChange={checkPortfolio}
              />
            )}
            <TextField
              margin="dense"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              type="password"
              size="small"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box sx={{ display: "flex", gap: 2, justifyContent: "end" }}>
              <Button
                onClick={handleCancel}
                style={{
                      py: 0.5,
                      px: 0,
                      marginBottom: "8px",
                      color: "#fff",
                      border: "1px solid #307c9d",
                      fontSize: "11px",
                      "&:hover": {
                        backgroundColor: "transparent", // Change background color on hover
                        color: "#509ebf",
                        border: "1px solid #509ebf",
                      },
                    }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                style={{
                  py: 0.5,
                  px: 0,
                  marginBottom: "8px",
                  backgroundColor: "#307c9d",
                  color: "#fff",
                  fontSize: "11px",
                }}
              >
                Save
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Modal>
  );
}
