import React from "react";
import { BarChart, Brush, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from "recharts";

const MarginBarAdmin = ({ data, marginColor, qtyColor, name }) => {
  console.log(data)

  const chartData = data?.map(obj => {
    const dealer = Object.keys(obj)[0];
    const value = obj[dealer];
    return { name: dealer, value };
  });

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
      <div className="flex w-full items-center">
        <h1 className="flex-grow text-center text-[14px] font-bold">{name}</h1>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={chartData}>
          <XAxis dataKey='name'
            tick={{ fontSize: "11px", fill: "white" }}
            scale="point"
            padding={{ left: 50, right: 50 }}
          />
          <YAxis
            tick={{ fontSize: "10px", fill: "white" }}
          />
          <Legend
            wrapperStyle={{
              fontSize: "12px",
              paddingLeft: "10px",
              color: "#ccc",
            }}
          />
          <Tooltip
            className='recharts-tooltip-wrapper'
            contentStyle={{
              background: "rgba(0, 0, 0, 0.6)",
              color: "white",
              borderRadius: "5px",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.9)",
              padding: "6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          />
          <ReferenceLine stroke='white' y={0} />
          <Bar dataKey='value' name={name} barSize={10} stackId="a" fill={marginColor ? "#22627D" : (qtyColor ? "#37C69B" : "#03D6F8")} />
          <Brush height={11} stroke="black" fill="slategray" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MarginBarAdmin;
