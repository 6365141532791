import React from "react";
import Box from '@mui/material/Box';
import axios from 'axios';  // Import Axios library
import { TextField, InputLabel, Button, Select, MenuItem } from '@mui/material';
import { toast } from 'sonner';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

const api_server_url = process.env.REACT_APP_API_SERVER_URL;
const confirm_password = process.env.REACT_APP_PASSWORD

const Firewall = () => {
  const [options, setOptions] = React.useState([]);
  const [selectedDealer, setSelectedDealer] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isDialogOpen, setisDialogOpen] = React.useState(false)

  const [formData, setFormData] = React.useState({
    dealer_id: '',
    trade_limit_day: '',
    qty_limit_day: '',
    trade_limit_sec: '',
    max_value_symbol: '',
    max_opt_val: '',
    max_fut_val: '',
    // max_position: '',
    max_lots_order: '',
    max_value_order: '',
    // enter_max_loss_position: '',
    max_loss_day: '',
  })

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleDealerChange = async (e) => {
    const selectedDealerID = e.target.value;
    setSelectedDealer(selectedDealerID);
    setFormData({
      ...formData,
      dealer_id: selectedDealerID,
    });
    axios.put(`${api_server_url}/firewall`, { dealer_id: selectedDealerID }, { withCredentials: true })
      .then((res) => {
        console.log(res.data)
        setFormData(res.data)

      })
      .catch((error) => {
        console.log("error", error)
      })
  };

  const handleClick = () => {
    setisDialogOpen(true)
  }

  const handleSubmit = async () => {
    if (password !== confirm_password) {
      alert('Incorrect password');
      return;
    } axios.post(`${api_server_url}/firewall`, formData)
      .then((res) => {
        console.log(res.data);
        toast.success('Firewall Updated Successfully')
        setisDialogOpen(false)
        setFormData('')
      })
      .catch((error) => {
        setFormData('')
        setisDialogOpen(false)
        toast.error('Some error occured');
        console.log('error', error)
      })
  }

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(api_server_url + '/dealers', {
          withCredentials: true,
        });
        const dealerData = response.data;
        const dealers = dealerData.dealers
        // Transform dealer data to the format expected by react-select
        const options = dealers?.map((dealer) => ({
          value: dealer.dealerID,
          // label: `${dealer.dealerID} - ${dealer.dealerName}`,
        }));
        setOptions(options);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          console.log('Unauthorized Error here.............................');
        } else {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Box className="grid grid-cols-2 gap-2 px-52 xl:px-0 xl:gap-6 place-items-center place-content-evenly xl:place-content-around w-full">
        <Box className="flex flex-col gap-7  w-full xl:w-[80%] h-full">
          <Box style={{ padding: 20, display: 'grid', gap: 15, width: '100%', border: '1px solid rgba(169, 169, 169, 0.2)', borderRadius: '10px', }} className='w-full'>
            <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Dealer ID</InputLabel>
              <Select
                value={selectedDealer}
                onChange={handleDealerChange}
                displayEmpty
                // // inputProps={{ 'aria-label': 'Without label' }}
                size='small'
                sx={{ width: '230px', p: 0.3, fontSize: '14px' }}
              >
                <MenuItem value="" disabled sx={{ color: 'gray' }}>
                  <div className="text-gray-500">Select a dealer</div>
                </MenuItem>
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {/* {option.label} */}
                  </MenuItem>
                ))}
              </Select>

            </Box>
          </Box>
          <Box style={{ padding: 20, border: '1px solid rgba(169, 169, 169, 0.2)', borderRadius: '10px', display: 'grid', gap: 15 }} className='w-full'>
            <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Trade Limit/Day</InputLabel>
              <TextField
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: 230
                }}
                margin="none"
                required={true}
                onChange={handleChange}
                id="trade_limit_day"
                // label="Enter Trade Limit"
                name="trade_limit_day"
                type="number"
                value={formData.trade_limit_day}
                // autoFocus
                InputProps={{
                  style: {
                    height: '45px',
                    padding: '10px 4px',
                  }
                }}
                InputLabelProps={{
                  style: {
                    lineHeight: '0.9',
                    textAlign: 'center',
                    fontSize: '13px'
                  }
                }}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Quantity Limit/Day</InputLabel>
              <TextField
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: 230
                }}
                margin="none"
                required={true}
                onChange={handleChange}
                id="qty_limit_day"
                // label="Enter Quantity Limit"
                name="qty_limit_day"
                type="number"
                value={formData.qty_limit_day}
                // autoFocus
                InputProps={{
                  style: {
                    height: '45px',
                    padding: '10px 4px',
                  }
                }}
                InputLabelProps={{
                  style: {
                    lineHeight: '0.9',
                    textAlign: 'center',
                    fontSize: '13px'
                  }
                }}
              />
            </Box>
            <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Trade Limit/Sec</InputLabel>
              <TextField
                sx={{
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  width: 230
                }}
                margin="none"
                required={true}
                onChange={handleChange}
                id="trade_limit_sec"
                // label="Enter Trade Limit"
                name="trade_limit_sec"
                type="number"
                value={formData.trade_limit_sec}
                // autoFocus
                InputProps={{
                  style: {
                    height: '45px',
                    padding: '10px 4px',
                  }
                }}
                InputLabelProps={{
                  style: {
                    lineHeight: '0.9',
                    textAlign: 'center',
                    fontSize: '13px'
                  }
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box style={{ padding: 20, border: '1px solid rgba(169, 169, 169, 0.2)', borderRadius: '10px', gap: 15 }} className="grid place-items-center xl:w-[85%]">
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Max Value Of Symbol</InputLabel>
            <TextField
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: 230
              }}
              margin="none"
              required={true}
              onChange={handleChange}
              id="max_value_symbol"
              // label="Enter Max Value"
              name="max_value_symbol"
              type="number"
              value={formData.max_value_symbol}
              // autoFocus
              InputProps={{
                style: {
                  height: '45px',
                  padding: '10px 4px',
                }
              }}
              InputLabelProps={{
                style: {
                  lineHeight: '0.9',
                  textAlign: 'center',
                  fontSize: '13px'
                }
              }}
            />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Max Option Net Value</InputLabel>
            <TextField
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: 230
              }}
              margin="none"
              required={true}
              onChange={handleChange}
              id="max_opt_val"
              // label="Enter Max Option"
              name="max_opt_val"
              type="number"
              value={formData.max_opt_val}
              // autoFocus
              InputProps={{
                style: {
                  height: '45px',
                  padding: '10px 4px',
                }
              }}
              InputLabelProps={{
                style: {
                  lineHeight: '0.9',
                  textAlign: 'center',
                  fontSize: '13px'
                }
              }}
            />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Max Future Net Value</InputLabel>
            <TextField
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: 230
              }}
              margin="none"
              required={true}
              onChange={handleChange}
              id="max_fut_val"
              // label="Enter Max Future"
              name="max_fut_val"
              type="number"
              value={formData.max_fut_val}
              // autoFocus
              InputProps={{
                style: {
                  height: '45px',
                  padding: '10px 4px',
                }
              }}
              InputLabelProps={{
                style: {
                  lineHeight: '0.9',
                  textAlign: 'center',
                  fontSize: '13px'
                }
              }}
            />
          </Box>
          <Box style={{ display: 'flex', gap: 15, alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Max Lots/Order</InputLabel>
            <TextField
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: 230
              }}
              margin="none"
              required={true}
              onChange={handleChange}
              id="max_lots_order"
              // label="Enter Max Order"
              name="max_lots_order"
              type="number"
              value={formData.max_lots_order}
              // autoFocus
              InputProps={{
                style: {
                  height: '45px',
                  padding: '10px 4px',
                }
              }}
              InputLabelProps={{
                style: {
                  lineHeight: '0.9',
                  textAlign: 'center',
                  fontSize: '13px'
                }
              }}
            />
          </Box>
          <Box style={{ display: 'flex', gap: 15, alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Max Value/Order</InputLabel>
            <TextField
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: 230
              }}
              margin="none"
              required={true}
              onChange={handleChange}
              id="max_value_order"
              // label="Enter Max Value/Order"
              name="max_value_order"
              type="number"
              value={formData.max_value_order}
              // autoFocus
              InputProps={{
                style: {
                  height: '45px',
                  padding: '10px 4px',
                }
              }}
              InputLabelProps={{
                style: {
                  lineHeight: '0.9',
                  textAlign: 'center',
                  fontSize: '13px'
                }
              }}
            />
          </Box>
          <Box style={{ display: 'flex', gap: 15, alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <InputLabel style={{ fontSize: '14px', color: 'white' }} htmlFor="my-input">Max Loss/Day</InputLabel>
            <TextField
              sx={{
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                  display: "none",
                },
                "& input[type=number]": {
                  MozAppearance: "textfield",
                },
                width: 230
              }}
              margin="none"
              required={true}
              onChange={handleChange}
              id="max_loss_day"
              // label="Enter Max Loss/Day"
              name="max_loss_day"
              type="number"
              value={formData.max_loss_day}
              // autoFocus
              InputProps={{
                style: {
                  height: '45px',
                  padding: '10px 4px',
                }
              }}
              InputLabelProps={{
                style: {
                  lineHeight: '0.9',
                  textAlign: 'center',
                  fontSize: '13px'
                }
              }}
            />
          </Box>
        </Box>
      </Box>
      <div className="flex items-center justify-center pt-12">
        <Button onClick={handleClick} style={{ backgroundColor: '#1b4152', px: 4, py: 2, borderRadius: 5, width: '120px', color: 'white' }}>
          Submit
        </Button>
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={() => setisDialogOpen(false)}
        // aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ '& .MuiDialog-paper': { backgroundColor: '#021B26' } }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Submit"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'white' }}>
            Are you sure you want to Submit this?
          </DialogContentText>
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            margin="normal"
            // label="Password"
            variant="outlined"
            size='small'
            required={true}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setisDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="error" variant="outlined">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Firewall;