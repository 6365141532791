import React from "react";
import BackofficeTab from "./BackofficeTab";

const Backoffice = () => {
  return (
    <BackofficeTab />
  );
};

export default Backoffice;
