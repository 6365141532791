import React from 'react'
import Admin from './Admin';

const Margin = () => {
  return (
    <div className="border-opacity-50 h-[80vh] p-2 pt-0 pl-0 rounded-lg">
      <Admin />
    </div>
  );
};

export default Margin