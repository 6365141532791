import Admin from "./Admin";

const Pnl = () => {
  return (
    <div className="pb-2 h-full">
      <Admin />
    </div>
  );
};

export default Pnl;
