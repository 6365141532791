import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const PnlDrawdownBars = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data} stackOffset="sign">
        <XAxis
          dataKey="Day"
          tick={{
            fontSize: "10px",
            fill: "white",
            angle: -25,
            textAnchor: "end",
          }}
          interval={1}
          scale="point"
          padding={{ left: 30, right: 30 }}
        />
        <YAxis
          tick={{
            fontSize: "11px",
            fill: "white",
          }}
        />
        <Tooltip
          contentStyle={{
            background: "rgba(0, 0, 0, 0.9)",
            color: "white",
            borderRadius: "5px",
            border:"none",
            padding: "6px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <ReferenceLine y={0} stroke="#ccc" />

        <Bar dataKey="NetPnl" stackId="stack" barSize={10}>
          {data?.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={entry.NetPnl > 0 ? "#44C662" : "#FF5757"}
              radius={entry.NetPnl > 0 ? [3, 3, 0, 0] : undefined}
            />
          ))}
        </Bar>

        <Bar
          dataKey="Drawdown"
          fill="rgba(242, 58, 58, 0.5)"
          stackId="stack"
          barSize={10}
          radius={[3, 3, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default PnlDrawdownBars;
