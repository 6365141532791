import React, { useState } from 'react';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import useFetchData from '../fetchData/useFetchData';

const CustomQuarterAccordion = ({ title, items, activeItem, handleItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ backgroundColor: activeItem ? '#1E4156' : '#011F2C', width: '100%', overflow: 'auto' }} className="accordion max-h-fit">
      <div className="accordion-summary sticky top-0 flex items-center cursor-pointer" onClick={toggleAccordion}>
        <p className='p-2 text-[14px] px-4 w-full overflow-auto bg-[#011F2C] flex items-center justify-between whitespace-nowrap'>
          {title} {isOpen ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}</p>
      </div>
      {isOpen && (
        <div className="accordion-details w-full overflow-auto flex flex-col">
          {items?.map((item, index) => (
            <button
              key={index}
              style={{
                fontSize: '12px',
                padding: '8px',
                paddingLeft: '15%',
                textAlign: 'left',
                backgroundColor: activeItem === item ? '#1E4156' : 'transparent',
              }}
              onClick={() => {
                handleItemClick(item);
                document.getElementById(item)?.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
            >
              {item}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const CustomMonthAccordion = ({ title, items, activeItem, handleItemClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div style={{ backgroundColor: activeItem ? '#1E4156' : '#011F2C', width: '100%', overflow: 'auto' }} className="accordion max-h-fit">
      <div className="accordion-summary sticky top-0 flex items-center cursor-pointer" onClick={toggleAccordion}>
        <p className='p-2 text-[14px] px-4 w-full overflow-auto bg-[#011F2C] flex items-center justify-between whitespace-nowrap'>
          {title} {isOpen ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon />}</p>
      </div>
      {isOpen && (
        <div className="accordion-details w-full overflow-auto flex flex-col">
          {items?.map((item, index) => (
            <button
              key={index}
              style={{
                fontSize: '12px',
                padding: '8px',
                paddingLeft: '15%',
                textAlign: 'left',
                backgroundColor: activeItem === item ? '#1E4156' : 'transparent',
              }}
              onClick={() => {
                handleItemClick(item);
                document.getElementById(item)?.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
            >
              {item}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

const DealerDateSelection = ({ dealerId }) => {
  const [data] = useFetchData(dealerId);
  const quarters = data?.years;
  const [activeMonthly, setActiveMonthly] = useState(null);
  const [activeQuarterly, setActiveQuarterly] = useState(null);
  const [activeLink, setActiveLink] = useState('today');

  const handleMonthlyClick = (link) => {
    setActiveMonthly(link);
    setActiveQuarterly(null);
    setActiveLink(null);
    document.getElementById(link)?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleQuarterlyClick = (link) => {
    setActiveQuarterly(link);
    setActiveMonthly(null);
    setActiveLink(null);
    document.getElementById(link)?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  
  const handleLinkClick = (link) => {
    setActiveLink(link);
    setActiveMonthly(null);
    setActiveQuarterly(null);
    document.getElementById(link)?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  return (
    <div className='flex flex-col sticky text-left top-24 border bg-[#011F2C] border-[#307C9D] border-opacity-50 h-fit overflow-auto rounded-md w-[10vw]'>
      <button className={`p-2 text-[14px] px-4 flex justify-start ${activeLink === 'today' ? 'bg-[#1E4156]' : ''}`} onClick={() => handleLinkClick('today')}>
        Today
      </button>
      <hr className='border border-[#307c9d] border-opacity-50 w-full' />
      <button className={`p-2 text-[14px] px-4 flex justify-start ${activeLink === 'this_month' ? 'bg-[#1E4156]' : ''}`} onClick={() => handleLinkClick('this_month')}>
        This Month
      </button>
      <hr className='border border-[#307c9d] border-opacity-50 w-full' />
      <button className={`p-2 text-[14px] px-4 flex justify-start ${activeLink === 'this_quarter' ? 'bg-[#1E4156]' : ''}`} onClick={() => handleLinkClick('this_quarter')}>
        This Quarter
      </button>
      <hr className='border border-[#307c9d] border-opacity-50 w-full' />
      <button className={`p-2 text-[14px] px-4 flex justify-start ${activeLink === 'this_year' ? 'bg-[#1E4156]' : ''}`} onClick={() => handleLinkClick('this_year')}>
        This Year
      </button>
      <hr className='border border-[#307c9d] border-opacity-50 w-full' />
      <CustomMonthAccordion
        title="Monthly"
        items={quarters?.map(month => month)}
        activeItem={activeMonthly}
        handleItemClick={() => handleMonthlyClick('monthly')}
      />
      <hr className='border border-[#307c9d] border-opacity-50 w-full' />
      <CustomQuarterAccordion
        title="Quarterly"
        items={quarters?.map(quarter => quarter)}
        activeItem={activeQuarterly}
        handleItemClick={() => handleQuarterlyClick('quarterly')}
      />
      <hr className='border border-[#307c9d] border-opacity-50 w-full' />
      <button className={`p-2 text-[14px] flex justify-start px-4 ${activeLink === 'yearly' ? 'bg-[#1E4156]' : ''}`} onClick={() => handleLinkClick('yearly')}>
        Yearly
      </button>
    </div>
  )
}

export default DealerDateSelection;
