import React from "react";
import PnlPieAdmin from "./PnlPieAdmin";
import PnlAllDealers from "./PnlAllDealers";
import PayoffPnlAdmin from "./PayoffPnlAdmin";
import ComponentZoomModal from "../../Hoc/ComponentZoomModal";
import PnlBarChart from "./PnlBarChart";

const PnlAdmin = () => {
  return (
    <div className="flex flex-col gap-3 ">
      <div className="border border-[#307C9D] border-opacity-50 rounded-lg pt-2 p-3 h-[40vh]">
        <div className="w-full flex relative">
          <h1 className="flex justify-center text-sm font-semibold w-full">
            Projection Chart
          </h1>
          <ComponentZoomModal component={PayoffPnlAdmin} />
        </div>
        <PayoffPnlAdmin />
      </div>

      <div className="flex flex-row gap-3">
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pt-2 pb-0 h-[35vh] w-[33%] ">
          <div className="w-full flex relative">
            <h1 className="flex justify-center text-sm font-semibold w-full">
              Intraday P&L
            </h1>
            <ComponentZoomModal component={PnlAllDealers} />
          </div>
          <PnlAllDealers />
        </div>

        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pt-2 pb-0 pl-0 h-[35vh] w-[33%] ">
          <div className="w-full flex relative">
            <h1 className="flex justify-center text-sm font-semibold w-full">
              Dealerwise P&L
            </h1>
            <ComponentZoomModal component={PnlBarChart} />
          </div>
          <PnlBarChart />
        </div>

        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pt-1 h-[35vh] w-[33%]">
          <div className="w-full flex relative">
            <ComponentZoomModal component={PnlPieAdmin} />
          </div>
          <PnlPieAdmin gap={2} />
        </div>
      </div>
    </div>
  );
};

export default PnlAdmin;
