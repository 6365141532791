import React from "react";
import {
  ComposedChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
  ResponsiveContainer,
} from "recharts";

const ScatterChart = ({ riskReward }) => {
  // Custom function to determine the color of scatter points
  const getScatterColor = (dataPoint) => {
    return dataPoint.NetPnl > dataPoint.line ? "green" : "red";
  };

  // Custom shape function to render scatter points with desired color
  const renderCustomScatterShape = (props) => {
    const { cx, cy, payload } = props;

    return (
      <circle
        cx={cx}
        cy={cy}
        r={4}
        fill={getScatterColor(payload)}
        strokeWidth={2}
        stroke={getScatterColor(payload)}
      />
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart data={riskReward}>
        <Tooltip
          contentStyle={{
            background: "rgba(0, 0, 0, 0.9)",
            color: "white",
            borderRadius: "5px",
            border: "none",
            padding: "6px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />

        <XAxis
          dataKey="Drawdown"
          type="number"
          tick={{
            fontSize: "11px",
            fill: "white",
          }}
        />
        <YAxis
          type="number"
          tick={{
            fontSize: "11px",
            fill: "white",
          }}
        />
        <Scatter
          name="Scatter"
          dataKey="NetPnl"
          shape={renderCustomScatterShape}
        />
        <Line
          dataKey="line"
          stroke="#ffffff8d"
          dot={false}
          activeDot={false}
          legendType="none"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ScatterChart;
