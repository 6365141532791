import React from "react";
import { Doughnut } from "react-chartjs-2";

function transformData(originalData, colors) {
  if (!originalData || originalData.length === 0) {
    return {
      labels: [],
      datasets: [],
      colors: colors,
    };
  }

  const filteredData = originalData.filter(item => item);
  // console.log(filteredData)

  const labels = filteredData?.map((item) => item.Segment);
  // console.log(labels)
  const dataValues = filteredData?.map((item) => item.MarginUsed );
  // console.log(dataValues)
  // const dataValues = filteredData?.map((item) => item.MarginUsed || item.MarketValue);

  return {
    // data: dataValues,
    labels: labels,
    datasets: [{
      data: dataValues,
      backgroundColor: colors,
      borderWidth: 0,
    }],
    colors: colors,
  };
}

function DealerPieChart({ originalData, colors, name, heading, totalValue }) {
  const { labels, datasets, colors: chartColors } = transformData(originalData, colors);
  // console.log(datasets)

  return (
    <div className="pie-chart-container justify-center flex flex-col" style={{ width: "100%", height: "100%" }}>
      <h1 className="text-[12px] text-center">{name}</h1>
      <div className='flex flex-col items-center justify-center w-full'>
        <div className="w-[56%] text-[12px]">
          <Doughnut
            data={{
              labels: labels,
              datasets: datasets
            }}
            options={{
              plugins: {
                legend: {
                  position: "right",
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const value = context.chart.data.datasets[0].data[context.dataIndex]?.toFixed(2);
                      return `${value}`;
                    },
                  },
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>
        <div className='flex items-center gap-1 text-[10px] whitespace-nowrap'>
          <h1>{heading}:</h1>
          <p>{typeof totalValue === 'number' ? totalValue?.toFixed(2) : totalValue}</p>
        </div>
      </div>
    </div>
  );
}

export default DealerPieChart;
