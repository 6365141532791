import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import io from "socket.io-client";
import { throttle } from 'lodash'
import '../../index.css';
import { Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { toast } from 'sonner';
import DownloadingOutlinedIcon from '@mui/icons-material/DownloadingOutlined';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// The headers and SubTableHeaders constants define the structure of the table columns.
// these aare the header objects 
const headers = [
    { id: 'dealer_id', label: 'Dealer Id', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, borderLeft: '1px solid rgba(48, 124, 157, 0.7)', color: '', whiteSpace: 'nowrap', width: '150px' } },
    { id: 'dealer_name', label: 'Name', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap', width: '150px' } },
    { id: 'margin_alloted', label: 'Margin Alloted', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap', width: '250px' } },
    { id: 'margin_used', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap', width: '250px' } },
    // { id: 'margin_percent', label: 'Margin %', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap' } },
    { id: 'realised_net', label: 'Realised', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', width: '250px' } },
    { id: 'unrealised_net', label: 'Unrealised', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap', width: '250px' } },
    { id: 'pnl_net', label: 'MTM', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap', width: '250px' } },
    // { id: 'realised_day', label: 'Realised Day', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap' } },
    // { id: 'unrealised_day', label: 'Unrealised Day', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap' } },
    // { id: 'pnl_day', label: 'Terminal P&L', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', whiteSpace: 'nowrap' } },
    { id: 'payoff', label: 'Payoff', style: { px: 1, m: 0, width: '80px' }, }
];

// these are the subHeaders obejets
const SubTableHeaders = [
    { id: 'symbol_name', label: 'Symbol name' },
    { id: 'underlying', label: 'Underlying' },
    { id: 'order_side', label: 'Side' },
    { id: 'realised', label: 'Realised' },
    { id: 'unrealised', label: 'Unrealised' },
    { id: 'net_pnl', label: 'MTM' },
    // { id: 'realisedDay', label: 'Realised Day' },
    // { id: 'unrealised_day', label: 'Unrealised Day' },
    // { id: 'day_pnl', label: 'Terminal P&L' },
    { id: 'total', label: 'Quantity' },
    { id: 'last_entry_price', label: 'Last Entry Price' },
    { id: 'buy_avg_price', label: 'Buy Avg Price', style: { width: '250px' } },
    { id: 'buy_qty', label: 'Buy Qty', style: { width: '250px' } },
    { id: 'sell_avg_price', label: 'Sell Avg Price', style: { width: '250px' } },
    { id: 'sell_qty', label: 'Sell Qty', style: { width: '250px' } },
    { id: 'avg_price', label: 'Avg Price' },
    { id: 'bid', label: 'Bid', style: { width: '250px' } },
    { id: 'ask', label: 'Ask', style: { width: '250px' } },
    { id: 'ltp', label: 'LTP', style: { width: '250px' } },
    // { id: 'last_order_type', label: 'Last Order Type' },
    { id: 'last_order_time', label: 'Last Order Time' },
];
// The getColorForAlertType function returns a color based on the type of alert.
const getColorForAlertType = (alert) => {
    switch (alert) {
        case 'margin':
            return 'rgba(98, 99, 42, 1)';
        case 'profit':
            return 'rgba(36, 83, 35, 1)';
        case 'loss':
            return 'rgba(72, 39, 39, 1)';
        case 'block':
            return 'grey';
        case 'firewall':
            return 'grey';
        default:
            return 'transparent';
    }
};

// here i'm fetching the data for client(admin) where i get the overall 

const FirstRow = React.memo((props) => {
    const { row } = props;

    const handleClick = () => {
        const url = `/payoffchart?dealer_id=${row.dealer_id}&dealer_name=Admin`;
        window.open(url);
    };
    return (
        <>
            <TableRow
                key={row?.dealer_id}
                sx={{
                    backgroundColor: '#000',
                    '&:hover': {
                        backgroundColor: '#1B4152',
                    },
                    '& > .MuiTableCell-root': {
                        py: 1.2,
                        borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                        borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                        fontWeight: '700',
                        fontSize: '13px'
                    },
                }}>
                <TableCell component="th" scope="row?" align='center'
                    sx={{ borderLeft: '1px solid rgba(48, 124, 157, 0.7)', font: 'caption', fontSize: '1rem' }}>
                    Admin
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(60, 83, 46, 0.1)' }}>Admin</TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(114, 57, 57, 0.14)' }}>
                    {row?.margin_alloted !== undefined ? row?.margin_alloted?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                </TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(114, 57, 57, 0.14)' }}>
                    <span>
                        {row?.margin_used !== undefined ? row?.margin_used?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                        &nbsp; {row?.margin_percent !== undefined ? `(${row?.margin_percent}%)` : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)', }}>
                    <span style={{ color: row?.realised_net !== undefined && row?.realised_net < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.realised_net !== undefined ? row?.realised_net?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}&nbsp;
                        {row?.realised_net_percent !== undefined ? `(${row?.realised_net_percent}%)` : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)' }}>
                    <span style={{ color: row?.unrealised_net !== undefined && row?.unrealised_net < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.unrealised_net !== undefined ? row?.unrealised_net?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}&nbsp;
                        {row?.unrealised_net_percent !== undefined ? `(${row?.unrealised_net_percent}%)` : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(90, 53, 49, 0.10)' }}>
                    <span style={{ color: row?.pnl_net !== undefined && row?.pnl_net < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.pnl_net !== undefined ? row?.pnl_net.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}&nbsp;
                        {row?.pnl_net_percent !== undefined ? `(${row?.pnl_net_percent}%)` : ''}
                    </span>
                </TableCell>
                <TableCell
                    align="center"
                    sx={{ backgroundColor: 'rgba(38, 99, 70, 0.10)', p: 0, m: 0, cursor: 'pointer' }}
                >
                    <TrendingUpIcon onClick={() => handleClick()} sx={{ color: '#307C9D' }} />
                </TableCell>
            </TableRow>
            <TableRow sx={{ height: 0.1 }}>
                <TableCell colSpan={11} style={{ height: '0px', padding: '8px' }}>
                </TableCell>
            </TableRow>
        </>
    )
})
//👍 The FirstRow and Row components render the first row and data rows of the table, respectively.

// here i'm fetching the data of each headers (dealers) and also for subheaders(positions) which is coming from newData.dealers

const Row = React.memo((props) => {

    const { row, highlightedRows, getColorForAlertType } = props;
    const [open, setOpen] = React.useState(false);

    const [subOrder, setSubOrder] = React.useState('asc');
    const [subOrderBy, setSubOrderBy] = React.useState('symbol_name');
    const [totalNetPnl, setTotalNetPnl] = React.useState(0);
    const [clickedRows, setClickedRows] = React.useState(new Set());
    const [cellBg, setCellBg] = React.useState([]);
    const [timeoutId, setTimeoutId] = React.useState(null);
    const sortedSubRows = stableSort(row.positions, subTableComparator);

    // const rowId = sortedSubRows?.map((row) => {
    //     const id = row.symbol_name + row.underlying;
    //     return id;
    // });

    // Function to handle subtable sort requests
    const handleSubRequestSort = (property) => (event) => {
        if (subOrderBy === property) {
            setSubOrder((prevOrder) => {
                if (prevOrder === 'asc') return 'desc';
                if (prevOrder === 'desc') return 'asc';
                return 'default';
            });
        } else {
            setSubOrder('asc');
            setSubOrderBy(property);
        }
    };
    function convertToNumber(value) {
        // If the value is already a number, return it
        if (!isNaN(parseFloat(value)) && isFinite(value)) {
            return parseFloat(value);
        }
        // If the value is a string representing a number, convert it to a number
        const numericValue = parseFloat(value);
        return isNaN(numericValue) ? value : numericValue;
    }


    // Comparator for subtable sorting
    function subTableComparator(a, b) {
        const aValue = convertToNumber(a[subOrderBy]);
        const bValue = convertToNumber(b[subOrderBy]);
        // console.log(aValue, bValue);

        if (aValue < bValue) {
            return subOrder === 'asc' ? -1 : 1;
        }
        if (aValue > bValue) {
            return subOrder === 'asc' ? 1 : -1;
        }
        return 0;
    }



    const handleClick = () => {
        const url = `/payoffchart?dealer_id=${row.dealer_id}&dealer_name=${encodeURIComponent(row.dealer_name)}`;
        window.open(url);
    };

    const alerts = ["block", "margin", "profit", "loss"];

    const isHighlighted = alerts.some(alertType => highlightedRows.has(`${row.dealer_id}-${alertType}`));

    const latestAlert = [...alerts].reverse().find(alertType => highlightedRows.has(`${row.dealer_id}-${alertType}`));

    const backgroundColor = latestAlert ? getColorForAlertType(latestAlert) : 'transparent';


    // calculate PNL sum in subtable 
    const handleTotalNetPnl = (netPnl, totalNetPnl, id) => {
        setCellBg(id)
        // Check if the row with the given ID has already been clicked
        if (clickedRows.has(id)) {
            console.log('Row with ID', id, 'has already been clicked.');
            // clearTimeout(timeoutId);
            return;
        }
        const newClickedRows = new Set(clickedRows);
        // console.log(newClickedRows);
        newClickedRows.add(id);
        setClickedRows(newClickedRows);
        const totalPnl = totalNetPnl + netPnl;
        setTotalNetPnl(totalPnl);
        clearTimeout(timeoutId);
        const newTimeoutId = setTimeout(() => {
            setCellBg(null);
            setClickedRows(new Set());
            setTotalNetPnl(0)
        }, 30000);

        setTimeoutId(newTimeoutId);
    };

    return (
        <React.Fragment>
            <TableRow
                onClick={() => setOpen(!open)}
                key={row.dealer_id}
                sx={{
                    cursor: 'pointer',
                    // backgroundColor: '#021B26',
                    '&:hover': {
                        backgroundColor: '#1B4152',
                    },
                    '& > .MuiTableCell-root': {
                        p: 0.6,
                        borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                        borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                        borderTop: '1px solid rgba(48, 124, 157, 0.7)',
                        fontSize: '12px'
                    },
                    '&&': {
                        borderTop: '1px solid rgba(48, 124, 157, 0.7)'
                    },
                    backgroundColor: backgroundColor,
                    animation: isHighlighted
                        ? 'blinking 1s infinite'
                        : 'none', // Blinking animation
                    '@keyframes blinking': {
                        '0%': { backgroundColor: 'transparent' },
                        '50%': { backgroundColor: backgroundColor }, // or any other color you prefer
                        '100%': { backgroundColor: 'transparent' },
                    },
                }}
            >
                <TableCell component="th" scope="row" align='center'
                    sx={{ borderLeft: '1px solid rgba(48, 124, 157, 0.7)', backgroundColor: 'rgba(60, 83, 46, 0.1)' }}>
                    {row?.dealer_id}
                </TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(60, 83, 46, 0.1)' }}>
                    {row?.dealer_name}
                </TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(114, 57, 57, 0.14)', }}>
                    {row?.margin_alloted !== undefined ? row?.margin_alloted?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                </TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(114, 57, 57, 0.14)', }}>
                    {row?.margin_used !== undefined ? row?.margin_used?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                    &nbsp;({row?.margin_percent !== undefined ? `${row?.margin_percent}%` : ''})
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)', }}>
                    <span className='flex items-center gap-4 justify-center w-full' style={{ color: row?.realised_net !== undefined && row?.realised_net < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.realised_net !== undefined ? row?.realised_net?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                        &nbsp;({row?.realised_net_percent !== undefined ? `${row?.realised_net_percent}%` : ''})
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)', }}>
                    <span style={{ color: row?.unrealised_net !== undefined && row?.unrealised_net < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.unrealised_net !== undefined ? row?.unrealised_net?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}&nbsp;
                        ({row?.unrealised_net_percent !== undefined ? `${row?.unrealised_net_percent}%` : ''})
                    </span>
                </TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(90, 53, 49, 0.10)', }}>
                    <span style={{ color: row.pnl_net !== undefined && row?.pnl_net < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.pnl_net !== undefined ? row?.pnl_net?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}&nbsp;
                        ({row?.pnl_net_percent !== undefined ? `${row?.pnl_net_percent}%` : ''})
                    </span>
                </TableCell>
                <TableCell
                    align="center"
                    sx={{ backgroundColor: 'rgba(38, 99, 70, 0.10)', p: 0, m: 0, cursor: 'pointer', }}
                    onClick={() => setOpen(null)}
                >
                    <TrendingUpIcon onClick={() => handleClick()} sx={{ color: '#307C9D' }} />
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#001017', maxWidth: 600, overflow: 'auto' }} colSpan={16}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {row?.dealer_name}
                            </Typography>
                            <TableContainer className='custom-scrollbar' style={{ maxHeight: 300, overflow: 'auto', backgroundColor: '#021B26', borderRadius: 10, border: '1px solid rgba(48, 124, 157, 0.7)' }}>
                                <Table size="small" aria-label="sticky table" stickyHeader>
                                    <TableHead sx={{ position: 'sticky', top: 0, }}>
                                        <TableRow sx={{
                                            backgroundColor: '#000',
                                            '&:hover': {
                                                backgroundColor: '#1B4152',
                                            },
                                            '& > .MuiTableCell-root': {
                                                py: 0.6,
                                                // borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                                                borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                                fontWeight: '700',
                                                fontSize: '13px'
                                            },
                                        }}>
                                            <TableCell align="center" colSpan={3} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', }}>
                                                Position
                                            </TableCell>
                                            <TableCell align="center" colSpan={3} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', }}>
                                                MTM
                                            </TableCell>
                                            <TableCell align="center" colSpan={7} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', }}>
                                                Value
                                            </TableCell>
                                            <TableCell align="center" colSpan={3} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', }}>
                                                LTP
                                            </TableCell>
                                            <TableCell align="center" colSpan={1} >
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            {SubTableHeaders.map((tableheader) => (
                                                <TableCell key={tableheader.id}
                                                    align='center'
                                                    sx={{
                                                        zIndex: -1, p: 1, fontSize: '12px', whiteSpace: 'nowrap', borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)', backgroundColor: '#001017'
                                                    }}>
                                                    {<TableSortLabel
                                                        active={subOrderBy === tableheader.id}
                                                        direction={subOrder}
                                                        onClick={handleSubRequestSort(tableheader.id)}
                                                        style={{ whiteSpace: 'nowrap', marginLeft: '20px' }}
                                                        align='center'
                                                    >
                                                        {tableheader.label}
                                                    </TableSortLabel>}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>
                                        {sortedSubRows?.length > 0 ? (
                                            sortedSubRows?.map((historyRow, index) => {
                                                const id = historyRow.symbol_name;
                                                return (
                                                    <TableRow key={`${row?.dealer_id}-${index}`} sx={{
                                                        backgroundColor: '#021B26',
                                                        '& > .MuiTableCell-root': {
                                                            py: 0.4,
                                                            borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                                        }
                                                    }}>
                                                        <TableCell align="center" sx={{ fontSize: '10px', borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.symbol_name}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.underlying}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }} style={{ color: historyRow?.order_side === 'Long' ? '#25d8f4' : '#ff7c52' }}>{historyRow?.order_side}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}><span style={{ color: historyRow?.realised < 0 ? '#FF6565' : '#97FF95' }}>
                                                            {historyRow?.realised}
                                                        </span></TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}><span style={{ color: historyRow?.unrealised < 0 ? '#FF6565' : '#97FF95' }}>
                                                            {historyRow?.unrealised}
                                                        </span></TableCell>
                                                        <Tooltip placement='right' title={`Total Net Pnl: ${totalNetPnl}`}>
                                                            <TableCell
                                                                onClick={() => handleTotalNetPnl(historyRow?.net_pnl, totalNetPnl, id)}
                                                                align="center"
                                                                sx={{
                                                                    fontSize: '12px',
                                                                    width: 100,
                                                                    borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                                                                    borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                                                    backgroundColor: clickedRows.has(id) ? 'rgba(48, 124, 157, 0.7)' : 'inherit', // Apply background color based on selection
                                                                }}
                                                            >
                                                                <span style={{ color: historyRow?.net_pnl < 0 ? '#FF6565' : '#97FF95' }}>
                                                                    {historyRow?.net_pnl}
                                                                </span>
                                                            </TableCell>

                                                        </Tooltip>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.total}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.last_entry_price}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.buy_avg_price}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.buy_qty}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.sell_avg_price}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.sell_qty}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 100, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.avg_price}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 130, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.bid}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 130, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.ask}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', width: 130, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.ltp}</TableCell>
                                                        <TableCell align="center" sx={{ fontSize: '12px', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}>{historyRow?.last_order_time}</TableCell>
                                                    </TableRow>
                                                )
                                            }))
                                            :
                                            (<TableRow>
                                                <TableCell colSpan={2}>
                                                    No Data Available
                                                </TableCell>
                                            </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
});

// these are the row Proptypes
Row.propTypes = {
    row: PropTypes.shape({
        dealer_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        dealer_name: PropTypes.string.isRequired,
        margin_alloted: PropTypes.number.isRequired,
        margin_used: PropTypes.number.isRequired,
        margin_percent: PropTypes.number.isRequired,
        pnl_net: PropTypes.number.isRequired,
        pnl_day: PropTypes.number.isRequired,
        pnl_day_percent: PropTypes.number.isRequired,
        pnl_net_percent: PropTypes.number.isRequired,
        realised_day: PropTypes.number.isRequired,
        realised_net: PropTypes.number.isRequired,
        realised_net_percent: PropTypes.number.isRequired,
        realised_day_percent: PropTypes.number.isRequired,
        unrealised_day: PropTypes.number.isRequired,
        unrealised_day_percent: PropTypes.number.isRequired,
        unrealised_net: PropTypes.number.isRequired,
        unrealised_net_percent: PropTypes.number.isRequired,
        positions: PropTypes.arrayOf(
            PropTypes.shape({
                symbol_name: PropTypes.string.isRequired,
                underlying: PropTypes.string.isRequired,
                order_side: PropTypes.string.isRequired,
                unrealised: PropTypes.number.isRequired,
                realised_day: PropTypes.number.isRequired,
                unrealised_day: PropTypes.number.isRequired,
                net_pnl: PropTypes.number.isRequired,
                day_pnl: PropTypes.number.isRequired,
                total: PropTypes.number.isRequired,
                avg_price: PropTypes.number.isRequired,
                last_entry_price: PropTypes.number.isRequired,
                bid: PropTypes.number.isRequired,
                ask: PropTypes.number.isRequired,
                ltp: PropTypes.number.isRequired,
                buy_avg_price: PropTypes.number.isRequired,
                sell_avg_price: PropTypes.number.isRequired,
                // last_order_type: PropTypes.string.isRequired,
                last_order_time: PropTypes.number.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};
// The descendingComparator function compares two objects based on a specified property in descending order.
function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

// The getComparator function returns a comparator function based on the order and orderBy props.
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// The stableSort function sorts an array of objects in a stable manner, meaning that the relative order of equal elements is preserved.
function stableSort(array, comparator) {
    // console.log(array)
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}

// The Surveillance component is the main component that renders the table.
export function Surveillance({ alertData }) {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [highlightedRows, setHighlightedRows] = React.useState(new Set());
    const [blinkTimeout, setBlinkTimeout] = React.useState(null);
    const [firstRowData, steFirstRowData] = React.useState([]);

    const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

    // here we are using throttling and also set the data in state
    const throttledSetData = React.useCallback(throttle((newData) => {
        if (newData) {
            steFirstRowData(newData?.client);
            setData(newData?.dealers);
            // console.log(newData?.client);
            setLoading(false);
        }
        else {
            setLoading(false);
        }

    }), []);

    // this is the sorting logic function
    const handleRequestSort = (property) => (event) => {
        if (orderBy === property) {
            setOrder((prevOrder) => {
                if (prevOrder === 'asc') return 'desc';
                if (prevOrder === 'desc') return 'default';
                return 'asc';
            });
        } else {
            setOrder('asc');
            setOrderBy(property);
        }
    };
    // this function is for sorting logic icons  in the table header
    const getSortIcon = (property) => {
        if (orderBy === property) {
            if (order === 'asc') return '↑';
            if (order === 'desc') return '↓';
            return '↕';
        }
        return null;
    };

    // this is socket call using this call i get the complete data of dealer and client 
    useEffect(() => {
        const socket = io(socket_server_url + "/testSurveillance");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on("test-surveillance", (newData) => {
            if (newData) {
                throttledSetData(newData);
                // console.log(newData)
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        });

        return () => {
            socket.disconnect();
            clearTimeout(blinkTimeout);
            throttledSetData.cancel();
        };
    }, [throttledSetData, blinkTimeout]);

    // whenever got alert get the blink row using this useEffect
    useEffect(() => {
        // console.log("1");
        // console.log(alertData);
        if (alertData) {
            const { dealer_id, alert } = alertData;
            const updatedSet = new Set(highlightedRows);
            if (dealer_id && alert) {
                // console.log("2");
                updatedSet.add(`${dealer_id}-${alert}`);
                // console.log(2)
                setBlinkTimeout(
                    setTimeout(() => {
                        updatedSet.delete(`${dealer_id}-${alert}`);
                        setHighlightedRows(updatedSet);
                    }, 30000) // 30 seconds duration
                );
            }
            setHighlightedRows(updatedSet);
        }
    }, [alertData]);

    // this is the skeleton rows during loading screen there are some rows which are created using this function

    const skeletonRows = [...Array(10)].map((_, index) => (
        <TableRow key={index}>
            {headers.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));
    //Function for download the table in .pdf .csv format
    const handleExportRows = () => {
        const doc = new jsPDF('landscape'); // Use landscape orientation

        // Prepare the table headers
        const tableHeaders = headers.map(header => header.label);
        toast.success('Downloaded Successfully');

        // Prepare the table data
        const tableData = stableSort(data, getComparator(order, orderBy))
            .map(row => headers.map(header => {
                // Check if row[header.id] is undefined or null, if so, return an empty string
                if (row[header.id] === undefined || row[header.id] === null) {
                    return '';
                } else {
                    return row[header.id].toString();
                }
            }));

        // Generate the table in the PDF
        autoTable(doc, {
            theme: 'grid',
            head: [tableHeaders],
            body: tableData,
            styles: { overflow: 'linebreak', fontSize: 8 },
            columnStyles: { 0: { cellWidth: 'auto' } }, // Customize column styles
        });
        const csvContent = [tableHeaders.join(',')];
        tableData.forEach((row) => {
            csvContent.push(row.join(','));
        });
        const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvBlob);
        const csvLink = document.createElement('a');
        csvLink.href = csvUrl;
        csvLink.setAttribute('download', 'Dashboard.csv');
        document.body.appendChild(csvLink);
        csvLink.click();
        document.body.removeChild(csvLink);

        // Save the PDF
        doc.save('Dashboard.pdf');
    };


    return (
        <Box sx={{ border: "1px solid rgba(48, 124, 157, 0.5)", p: 2, borderRadius: '5px' }}>
            {/* this is the download button for the complete table data using this you can download the file in .csv and .pdf */}
            <Button
                onClick={handleExportRows}
                // variant="contained"
                // color="primary"
                style={{ py: 0.5, px: 0, marginBottom: '8px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize: "11px" }}
                startIcon={<DownloadingOutlinedIcon />}
            >
                Download
            </Button>
            <TableContainer className='custom-scrollbar' component={Paper} style={{ maxHeight: '72vh', overflow: 'auto', backgroundColor: '#011F2C', border: '1px solid rgba(48, 124, 157, 0.7)' }}>
                <Table sx={{ minWidth: 600 }} stickyHeader aria-label="sticky table">
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableRow sx={{
                            backgroundColor: '#000',
                            '&:hover': {
                                backgroundColor: '#1B4152',
                            },
                            '& > .MuiTableCell-root': {
                                py: 0.6,
                                borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                                borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                fontWeight: '700',
                                fontSize: '13px'
                            }
                        }}>
                            <TableCell align="center" colSpan={2} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)', borderLeft: '1px solid rgba(48, 124, 157, 0.7)' }}>
                                DealerId
                            </TableCell>
                            <TableCell align="center" colSpan={2} sx={{
                                borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)'
                            }} >
                                Margin
                            </TableCell>
                            <TableCell align="center" colSpan={3} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)' }}>
                                Actual MTM
                            </TableCell>
                            <TableCell align="center" colSpan={1} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)' }}>
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{
                            backgroundColor: '#000',
                            '& > .MuiTableCell-root': {
                                py: 0.6,
                                borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                                borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                fontWeight: '700',
                                fontSize: '13px',
                                backgroundColor: '#021b26',
                            }
                        }}>
                            {headers.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align='center'
                                    sx={{ ...header.style, borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }}
                                >
                                    {loading ? (
                                        <Skeleton animation="wave" height={30} />
                                    ) : header.id !== "payoff" ? (
                                        <TableSortLabel
                                            active={orderBy === header.id}
                                            direction={orderBy === header.id ? order : 'asc'}
                                            onClick={handleRequestSort(header.id)}
                                            style={{ whiteSpace: 'nowrap' }}
                                            aria-label={header.label}
                                            IconComponent={null}
                                        >
                                            <Tooltip title={header.label} placement='bottom' arrow>
                                                <span>{header.label} {getSortIcon(header.id)}</span>
                                            </Tooltip>
                                        </TableSortLabel>
                                    ) : "Payoff"}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: '#021B26' }}>
                        {loading ? (
                            skeletonRows
                        ) : (
                            <>
                                <FirstRow row={firstRowData} />
                                <TableRow></TableRow>
                                {stableSort(data, getComparator(order, orderBy))?.map((row, rowIndex) => (
                                    <Row key={row.dealer_id} row={row} data={data} loading={loading} highlightedRows={highlightedRows}
                                        getColorForAlertType={getColorForAlertType} />
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

// The component uses Material UI components to style the table and provides a responsive design.
// The component also uses Lodash throttle function to limit the frequency of data fetches from the socket server.
// The component provides sorting functionality for the table columns and exporting functionality for the table data.