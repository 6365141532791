// import axios from 'axios'
// import { useState, useEffect } from "react";

// export default function useFetchMarginData() {
//     const api_server_url = process.env.REACT_APP_API_SERVER_URL;
//     const [marginBarData, setMarginBarData] = useState([]);
//     const [marketValue, setMarketValue] = useState([]);
//     const [qtyTradedValue, setQtyTradedValue] = useState([]);
//     const [isLoading, setIsLoading] = useState(true);

//     useEffect(() => {
//         const fetchData = () => {
//             // setIsLoading(true); // Set loading state to true while fetching data
//             // OvernightMargin
//             axios.post(`${api_server_url}/riskmargin/overnightmarginused`, { 'dealer_id': 'all' }, { withCredentials: true })
//                 .then((res) => {
//                     setMarginBarData(res.data);
//                 })
//                 .catch((error) => {
//                     console.log("error", error);
//                 });

//             // MarketValue
//             axios.post(`${api_server_url}/riskmargin/marketvalue`, { 'dealer_id': 'all' }, { withCredentials: true })
//                 .then((res) => {
//                     setMarketValue(res.data);
//                 })
//                 .catch((error) => {
//                     console.log("error", error);
//                 });

//             // Qunatity Traded
//             axios.post(`${api_server_url}/riskmargin/qtytraded`, { 'dealer_id': 'all' }, { withCredentials: true })
//                 .then((res) => {
//                     setQtyTradedValue(res.data);
//                 })
//                 .catch((error) => {
//                     console.log("error", error);
//                 })
//                 .finally(() => {
//                     // setIsLoading(false); // Set loading state to false after fetching is completed
//                 });
//         };

//         fetchData();
//         setInterval(() => {
//             setIsLoading(true);
//             setIsLoading(false)
//         }, 2000);
//         const intervalId = setInterval(fetchData, 10000);
//         return () => clearInterval(intervalId);
//     }, [api_server_url]);
//     return [marginBarData, marketValue, qtyTradedValue, isLoading]
// }


import { useState, useEffect } from "react";
import axios from 'axios';

export default function useFetchMarginData() {
    const api_server_url = process.env.REACT_APP_API_SERVER_URL;
    const [marginBarData, setMarginBarData] = useState([]);
    const [marketValue, setMarketValue] = useState([]);
    const [qtyTradedValue, setQtyTradedValue] = useState([]);
    const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true); // Set loading state to true when fetching data
            try {
                const [marginRes, marketValueRes, qtyTradedRes] = await Promise.all([
                    axios.post(`${api_server_url}/riskmargin/overnightmarginused`, { 'dealer_id': 'all' }, { withCredentials: true }),
                    axios.post(`${api_server_url}/riskmargin/marketvalue`, { 'dealer_id': 'all' }, { withCredentials: true }),
                    axios.post(`${api_server_url}/riskmargin/qtytraded`, { 'dealer_id': 'all' }, { withCredentials: true })
                ]);
                setMarginBarData(marginRes?.data);
                // setMarketValue(marketValueRes?.data);
                setQtyTradedValue(qtyTradedRes?.data);
                console.log(qtyTradedRes?.data)
            } catch (error) {
                console.error("Error fetching data:", error);
                // Handle error gracefully, e.g., display an error message to the user
            } finally {
                setIsLoading(false); // Set loading state to false after fetching is completed or error occurred
            }
        };

        const intervalId = setInterval(fetchData, 10000);
        fetchData();

        return () => clearInterval(intervalId);
    }, [api_server_url]);

    return [marginBarData, qtyTradedValue, isLoading];
}