import React, { useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Button } from '@mui/material';
import axios from 'axios'
import { toast } from 'sonner'
import CircularProgress from '@mui/material/CircularProgress';

const MarginManagerChart = ({ currentUnusedMargin, handleClose, modalLoading, setModalLoading, row, hedge }) => {
    // console.log(rowData);
    const apiUrl = `${process.env.REACT_APP_API_SERVER_URL}/sigmaoptimizer?used_margin=${row.utilizedMargin}&hedge=${hedge}&dealer_id=${row.id}`
    const [rowData, setRowData] = React.useState()

    const api_url = process.env.REACT_APP_API_SERVER_URL

    const chartOptions = {
        rotation: 75,
        // animation: true,
        legend: {
            position: 'bottom',
            labels: {
                boxWidth: 15,
                fontColor: 'black',
            },
        },
    };

    useEffect(() => {
        axios.get(apiUrl, { withCredentials: true })
            .then((res) => {
                // console.log(res.data);
                setRowData(res.data);
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setModalLoading(false)
            });
    }, [apiUrl, setModalLoading]);

    //Can not use Condition or statements in useEffect

    // if (!rowData || Object.keys(rowData).length === 0) {
    //     return <p>No data available.</p>;
    // }

    const handleHedgeBuy = (key, rowData) => [
        axios.put(`${api_url}/sigmaoptimizer`, { 'orders': rowData[key]['Orders'] }, { withCredentials: true })
            .then((res) => {
                console.log(res.data);
                toast.success('Hedge Order Placed Successfully');
                handleClose();
            })
            .catch((error) => {
                toast.error('Hedge Order Falied');
                console.log(error)
                handleClose();
            })
    ];

    return (
        <div className='flex gap-[25px]'>
            {modalLoading ? (
                <div className='flex items-center justify-center w-full'>
                    <CircularProgress color="inherit" />
                </div>
            ) : (
                rowData &&
                Object.keys(rowData)?.map((key) => {
                    const data = rowData[key];
                    console.log(rowData);
                    console.log(key)
                    const marginData = [
                        {
                            name: 'Current Unused Margin',
                            value: currentUnusedMargin,
                            color: '#359898',
                        },
                        {
                            name: 'Expected Used Margin',
                            value: data?.ExpectedMargin,
                            color: '#195353',
                        },
                        {
                            name: 'Current Used Margin',
                            value: data?.UsedMargin,
                            color: '#ffffff',
                        },
                    ];
                    const marginPieData = [
                        {
                            name: 'Current Unused Margin',
                            value: currentUnusedMargin,
                            color: '#359898',
                        },
                        {
                            name: 'Expected Used Margin',
                            value: data?.ExpectedMargin,
                            color: '#195353',
                        },
                        {
                            name: 'Current Used Margin',
                            value: data?.UsedMargin - data?.ExpectedMargin,
                            color: '#ffffff',
                        },
                    ];

                    const chartData = {
                        labels: marginPieData?.map(item => item.name),
                        datasets: [{
                            data: marginPieData?.map(item => item.value),
                            backgroundColor: marginPieData?.map(item => item.color),
                            borderColor: marginPieData?.map(item => item.color),
                            borderWidth: 1,
                        }],
                    };

                    return (
                        <div key={key} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 10, width: '29vw', backgroundColor: '#081D26', paddingTop: 20, borderRadius: 10, paddingBottom: 20 }}>
                            <h1 className='text-sm font-semibold'>{key}</h1>
                            <div className='flex flex-col items-center gap-2'>
                                <div className='grid grid-cols-4 w-full items-center place-content-between'>
                                    <h1 className='text-[11px] ml-5'>Call</h1>
                                    <p className='grid place-items-end text-[10px] whitespace-nowrap'>{data?.CallHedge?.Symbol}</p>
                                    {/* <p className='grid place-items-end text-[12px]'>₹{(data?.CallHedge?.Price)}</p> */}
                                    <p className='grid place-items-end text-[10px]'>{data?.CallHedge?.Price?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    <p className='grid place-items-end mr-5 text-[10px]'>{data?.CallHedge?.Quantity}</p>
                                </div>
                                <div className='grid grid-cols-4 items-center w-full place-content-between'>
                                    <h1 className='text-[11px] ml-5'>Put</h1>
                                    <p className='grid place-items-end text-[10px] whitespace-nowrap'>{data?.PutHedge?.Symbol}</p>
                                    <p className='grid place-items-end text-[10px]'>{data?.PutHedge?.Price?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                    <p className='grid place-items-end mr-5 text-[10px]'>{data?.PutHedge?.Quantity}</p>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', marginRight: '25px' }}>
                                <div style={{
                                    width: "20vw",
                                    height: "15vh",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <Doughnut data={chartData} options={chartOptions} />
                                </div>
                                <div className='flex flex-col items-center gap-2 w-full justify-center'>
                                    {marginData.map((item, index) => (
                                        <div key={index} className='flex gap-3 items-center justify-around text-left w-[100%]'>
                                            <div className='flex items-center justify-between w-full'>
                                                <div className='flex gap-2 items-center'>
                                                    <span style={{ backgroundColor: item.color, height: 6, width: 6, borderRadius: '100%' }}></span>
                                                    <h1 className='text-[12px] whitespace-nowrap'>{item.name}</h1>
                                                </div>
                                                <p className='text-[11px] whitespace-nowrap'>{item.value.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='flex  items-center justify-evenly w-[80%]'>
                                <div className='flex items-center gap-3 bg-[#11303d] px-4 py-[6px] rounded-[4px]'>
                                    <h1 className='text-[12px] whitespace-nowrap'>Cost</h1>
                                    <p className='text-[11px] whitespace-nowrap'>{data?.Cost.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                </div>
                                <div className='flex items-center justify-center gap-3 bg-[#11303d] px-4 py-[6px] rounded-[4px]'>
                                    <h1 className='text-[12px] whitespace-nowrap'>Margin Freed</h1>
                                    <p className='text-[11px] whitespace-nowrap'>{data?.MarginReleased.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                </div>
                            </div>
                            <Button sx={{ border: '1px solid #307C9D', fontWeight: 800, px: 2, py: 0.3 }} onClick={() => handleHedgeBuy(key, rowData)}>Buy</Button>
                        </div>
                    )
                })
            )}
            {rowData && Object.keys(rowData).length === 0 && <p>No data available.</p>}
        </div>
    );
};

export default MarginManagerChart;
