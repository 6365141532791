import React, { useState, useEffect } from "react";
import PnlAllUnderlyings from "./PnlAllUnderlyings";
import PnlPieDealer from "./PnlPieDealer";
import axios from "axios";
import PayoffPnlDealer from "./PayoffPnlDealer";
import DealerDropdown from "./DealerDropdown";
import MarginPieDealer from "./MarginPieDealer";
import MarginDealerLineChart from "./MarginDealerLineChart";
import ComponentZoomModal from "../../Hoc/ComponentZoomModal";

const Dealer = ({ dealerId, dealerName }) => {
  const [dealer, setDealer] = useState([]);

  const [dealerIdD, setDealerIdD] = useState("admin");
  const [dealerNameD, setDealerNameD] = useState("admin");

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    axios.get(api_server_url + `/dealer/underlyingpnlchart`).then((res) => {
      // console.log("set dealer", res.data);

      const dealerName = res.data;
      const dealerNameFinal = dealerName.map((dealer) => ({
        value: dealer.id,
        label: dealer.name,
      }));

      setDealer(dealerNameFinal);
    });
  }, [api_server_url, dealerIdD]);

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="flex items-center mb-3" style={{ position: "relative" }}>
        <div
          className="flex justify-start mt-1"
          style={{ position: "absolute", top: 0 }}
        >
          <DealerDropdown
            dealerId={dealerIdD}
            setDealerId={setDealerIdD}
            dealerName={dealerNameD}
            setDealerName={setDealerNameD}
          />
        </div>
      </div>
      <div className="flex items-center text-sm flex-grow justify-center gap-2">
        <span>{dealerIdD.charAt(0).toUpperCase() + dealerIdD.slice(1)}</span>
        {dealerNameD !== "admin" && <span>{dealerNameD}</span>}
      </div>
      <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-3 pt-2 h-[42vh]">
        <div className="w-full flex relative">
          <h1 className="flex justify-center text-sm font-semibold w-full">
            Projection Chart
          </h1>
          <ComponentZoomModal
            component={PayoffPnlDealer}
            componentProps={{ dealerId: dealerIdD, dealerName: dealerNameD }}
          />
        </div>
        <PayoffPnlDealer dealerId={dealerIdD} dealerName={dealerName} />
      </div>

      <div className="flex gap-2">
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pt-3 pb-0 h-[32vh] w-[50%]">
          <div className="w-full flex relative">
            <h1 className="flex justify-center text-sm font-semibold w-full">
              Intraday P&L
            </h1>
            <ComponentZoomModal
              component={PnlAllUnderlyings}
              componentProps={{ dealerId: dealerIdD, dealerName: dealerNameD }}
            />
          </div>
          <PnlAllUnderlyings dealerId={dealerIdD} dealerName={dealerName} />
        </div>

        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pb-1 h-[32vh] w-[50%]">
          <div className="w-full flex relative">
            <h1 className="flex justify-center text-sm font-semibold w-full">
              Dealer Margin Line
            </h1>
            <ComponentZoomModal
              component={MarginDealerLineChart}
              componentProps={{ dealerId: dealerIdD, dealerName: dealerNameD }}
            />
          </div>
          <MarginDealerLineChart dealerId={dealerIdD} dealerName={dealerName} />
        </div>
      </div>
      <div className="flex gap-2">
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[32vh] w-[50%]">
          <div className="w-full flex relative">
            <ComponentZoomModal
              component={PnlPieDealer}
              componentProps={{ dealerId: dealerIdD, dealerName: dealerNameD }}
            />
          </div>
          <PnlPieDealer dealerId={dealerIdD} dealerName={dealerName} />
        </div>
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[32vh] w-[50%]">
          <div className="w-full flex relative">
            <h1 className="flex justify-center text-sm font-semibold w-full">
              Segmentwise Margin
            </h1>
            <ComponentZoomModal
              component={MarginPieDealer}
              componentProps={{ dealerId: dealerIdD, dealerName: dealerNameD }}
            />
          </div>
          <MarginPieDealer dealerId={dealerIdD} dealerName={dealerName} />
        </div>
      </div>
    </div>
  );
};

export default Dealer;
