import React, { useState } from 'react';
import { Layout } from 'antd';
import MenuList from "./MenuList";
import '../../index.css';
// import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
const { Sider } = Layout;

const Sidebar = ({ isLoggedIn, setIsLoggedIn }) => {
  const [darkTheme] = useState(true);
  const [collapsed, setCollapsed] = useState(true);

  return (
    <div className='min-h-screen z-10'>
      <Layout className={`h-screen overflow-y-auto overflow-x-hidden shadow-2xl`}>
        <Sider
          collapsed={collapsed}
          collapsible
          trigger={null}
          theme={darkTheme ? 'dark' : 'light'}
          // sx={{ backgroundColor: "#1B4152" }}
          className={`sidebar-transition shadow-2xl ${collapsed ? 'max-w-24' : 'max-w-64'}`}
          onMouseEnter={() => setCollapsed(false)}
          onMouseLeave={() => setCollapsed(true)}
        >
          <MenuList darkTheme={darkTheme} setIsLoggedIn={setIsLoggedIn} isLoggedIn={isLoggedIn} collapsed={collapsed} setCollapsed={setCollapsed} />
        </Sider>
        <Layout>
          {/*<Button
            // className='toggle'
            // onClick={() => setCollapsed(!collapsed)}
            // icon={collapsed ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}
  />*/}
        </Layout>
      </Layout>
    </div>
  );
}

export default Sidebar;
