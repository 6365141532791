import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';


const Row = React.memo((props) => {
    const { row, headers, setOpenDrawdown } = props;

    const handleDrawdown = () => {
        setOpenDrawdown(true)
    }

    return (
        <TableRow
            key={row?.DealerID}
            sx={{
                '&:hover': {
                    backgroundColor: '#1B4152',
                },
                '& > .MuiTableCell-root': {
                    p: 0.4,
                    border: '1px solid #307C9D',
                },
                backgroundColor: '#021B26'
            }}
        >
            {headers?.map((header, idx) => (
                <TableCell key={header.id} component="th" scope="row" align='center' style={{
                    borderLeft: idx === 0 ? '1px solid #307C9D' : 'transparent', fontSize: '13px',
                    borderBottom: '1px solid #307C9D', borderRight: '1px solid #307C9D', padding: '7px', backgroundColor: '#000'
                }}>
                    {header.id === 'MaxDrawdown' ? (
                        <div className='flex justify-between'>
                            <span>{row[header.id]}</span>
                            <div>
                                <button onClick={handleDrawdown}>
                                    <TrendingUpIcon style={{ color: '#FF65659C' }}  />
                                </button>
                            </div>
                        </div>
                    ) : (
                        row[header.id] && (header.id === 'NetPnl' || header.id === 'TotalProfit' || header.id === 'TotalLoss')
                            ? <span style={{ color: row[header.id] > 0 ? '#70E66D' : '#FF6565' }}>
                                {row[header.id].toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                              </span>
                            : row[header.id] && (header.id === 'ProfitDays' || header.id === 'ProfitTriggers')
                                ? <span style={{ color: '#70E66D' }}>{row[header.id]}</span>
                                : row[header.id] && (header.id === 'LossDays' || header.id === 'LossTriggers')
                                    ? <span style={{ color: '#FF6565' }}>{row[header.id]}</span>
                                    : (typeof row[header.id] === 'number' ? row[header.id]?.toFixed(2) : row[header.id])
                    )}
                </TableCell>
            ))}
        </TableRow>
    );
});

Row.propTypes = {
    row: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
};

const DealerTable = ({ headers, data, setOpenDrawdown }) => {

    return (
        <Box className='MUITable-scrollbar' width="100%">
            <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto', width: '100%' }}>
                <Table sx={{ width: '100%' }} stickyHeader aria-label="sticky table">
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableRow>
                            {headers?.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align='center'
                                    sx={{ ...header.style, fontSize: '12px', borderRight: '1px solid #307C9D', borderTop: '1px solid #307C9D', backgroundColor: '#001017', fontWeight: 800 }}
                                >
                                    <Tooltip title={header.label} placement='bottom' arrow>
                                        <span>{header.label}</span>
                                    </Tooltip>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <>
                        <TableBody>
                            {data && <Row key={data.DealerID} row={data} headers={headers} setOpenDrawdown={setOpenDrawdown} />}
                        </TableBody>

                        {data?.Sub && (
                            <TableBody sx={{ backgroundColor: '#021B26', fontSize: '11px', padding: '5px' }}>
                                {data?.Sub?.map((quarterData, index) => (
                                    <TableRow key={index}>
                                        {headers?.map((header, idx) => (
                                            <TableCell key={header.id} align='center' style={{ borderLeft: idx === 0 ? '1px solid #307C9D' : 'transparent', borderBottom: '1px solid #307C9D', borderRight: '1px solid #307C9D', padding: '5px', fontSize: '11px', }}>
                                                {header.id === 'MaxDrawdown' ? (
                                                    <div className='flex justify-between'>
                                                        <span>{quarterData[header.id]}</span>
                                                        <div>
                                                            <button onClick={() => setOpenDrawdown(true)}>
                                                                <TrendingUpIcon style={{ color: '#FF65659C' }} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    quarterData[header.id] && (header.id === 'NetPnl' || header.id === 'TotalProfit' || header.id === 'TotalLoss')
                                                        ? <span style={{ color: quarterData[header.id] > 0 ? '#70E66D' : '#FF6565' }}>
                                                            {quarterData[header.id]?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                                                        </span>
                                                        : quarterData[header.id] && (header.id === 'ProfitDays' || header.id === 'ProfitTriggers')
                                                            ? <span style={{ color: '#70E66D' }}>{quarterData[header.id]}</span>
                                                            : quarterData[header.id] && (header.id === 'LossDays' || header.id === 'LossTriggers')
                                                                ? <span style={{ color: '#FF6565' }}>{quarterData[header.id]}</span>
                                                                : (typeof quarterData[header.id] === 'number' ? quarterData[header.id]?.toFixed(2) : quarterData[header.id])
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                    </>
                </Table>
            </TableContainer>
        </Box>
    );
};


DealerTable.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired,
};

export default DealerTable;
