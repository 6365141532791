import React, { useEffect, useState } from "react";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";

const PnlPieAdmin = (props) => {
  const [chartData, setChartData] = useState([]);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${api_server_url}/dealer/piechart`,
        { dealer_id: "ALL" },
        { withCredentials: true }
      );
      console.log(response.data);
      setChartData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, []);

  const generateColorsUnrealised = (data) => {
    const totalDataPoints = data.length;
    const baseColor = "#144714";
    const colors = [];
    for (let i = 0; i < totalDataPoints; i++) {
      const pnl = data[i].unrealised;
      const color =
        pnl < 0
          ? generateShades("#5B1B1B", totalDataPoints)[i]
          : generateShades(baseColor, totalDataPoints)[i];
      colors.push(color);
    }
    return colors;
  };

  const generateColorsRealised = (data) => {
    const totalDataPoints = data.length;
    const baseColor = "#044164";
    const colors = [];
    for (let i = 0; i < totalDataPoints; i++) {
      const pnl = data[i].realised;
      console.log(pnl);
      const color =
        pnl < 0
          ? generateShades("#8D320A", totalDataPoints)[i]
          : generateShades(baseColor, totalDataPoints)[i];
      colors.push(color);
    }
    return colors;
  };

  const generateShades = (baseColor, count) => {
    const shades = [];
    for (let i = 0; i <= count; i++) {
      const shade = modifyColor(baseColor, i / count);
      shades.push(shade);
    }
    console.log(shades);
    return shades;
  };

  const modifyColor = (color, factor) => {
    const hex = (color) => ("0" + Math.floor(color).toString(16)).slice(-2);
    const rgb = color.match(/\w\w/g).map((x) => parseInt(x, 16));
    const modifiedColor = `#${hex(rgb[0] * (1 - factor))}${hex(
      rgb[1] * (1 - factor)
    )}${hex(rgb[2] * (1 - factor))}`;
    // console.log(modifiedColor)
    return modifiedColor;
  };

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    legend: {
      display: true,
      position: "top",
    },
    labels: {
      fontSize: 12,
      boxWidth: 10,
    },
    cutoutPercentage: 0,
  };

  const data_realised = {
    labels: chartData?.map((entry) => entry.id),
    datasets: [
      {
        data: chartData?.map((entry) => entry.realised),
        backgroundColor: generateColorsRealised(chartData),
        hoverBackgroundColor: generateColorsRealised(chartData),
        borderWidth: 0,
      },
    ],
  };
  const data_unrealised = {
    labels: chartData?.map((entry) => entry.id),
    datasets: [
      {
        data: chartData?.map((entry) => entry.unrealised),
        backgroundColor: generateColorsUnrealised(chartData),
        hoverBackgroundColor: generateColorsUnrealised(chartData),
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      {chartData?.length < 0 ? (
        <div className="flex items-center justify-center h-full text-sm text-gray-500">
          Data not available
        </div>
      ) : (
        <div className="flex items-center justify-center h-full">
          <div className={`flex gap-${props.gap ?? 10} h-[90%] w-[80%]`}>
            <div className="h-[90%] w-[50%]">
              <h1 className="flex justify-center text-sm font-semibold w-full mb-2">
                Unrealised P&L
              </h1>
              <div className="h-[100%]">
                <Doughnut data={data_unrealised} options={chartOptions} />
              </div>
            </div>
            <div className="h-[90%] w-[50%]">
              <h1 className="flex justify-center text-sm font-semibold w-full mb-2">
                Realised P&L
              </h1>
              <div className="h-[100%]">
                <Doughnut data={data_realised} options={chartOptions} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PnlPieAdmin;
