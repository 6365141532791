import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from "recharts";

const DealerBarChart = ({ data, marginColor, barColor, name, dataKey, color }) => {
  if (!Array.isArray(data) || data.length === 0) {
    return <p className="flex items-center justify-center h-full">Data not available</p>;
  }

  const filteredData = data.filter((item) => item.Segment !== "Total");
  const hasSegment = data.some((item) => item.Segment !== undefined);
  const xAxisKey = hasSegment ? "Segment" : "Time";

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 className="text-center text-[12px] mb-2">{name}</h1>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={filteredData} barGap={5}>
          <XAxis
            dataKey={xAxisKey}
            tick={{
              fontSize: "10px",
              fill: "white",
              angle: -18,
              textAnchor: "end",
            }}
            padding={{ left: 50, right: 20 }}
            scale="point"
          />
          <YAxis tick={{ fontSize: "10px", fill: "white" }} />
          <ReferenceLine y={0} stroke="#ccc" />
          <Tooltip
            className="recharts-tooltip-wrapper"
            contentStyle={{
              background: "rgba(0, 0, 0, 0.6)",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          />
          {dataKey?.map((key, index) => (
            <Bar
              key={index}
              dataKey={key}
              barSize={5}
              fill={marginColor ? "#22627D" : barColor ? "#37C69B" : "#03D6F8"}
              radius={[3, 3, 0, 0]}
            />
          ))}
          <Bar
            dataKey="Margin"
            barSize={5}
            stackId="a"
            fill={marginColor ? "#22627D" : barColor ? "#37C69B" : "#03D6F8"}
            radius={[3, 3, 0, 0]}
          />
          <Bar
            dataKey="NetPnl"
            barSize={5}
            stackId="a"
            fill={data.someEntry >= 0 ? "#05B7063B" : "#B03535CC"}
            radius={[3, 3, 0, 0]}
          />
          <Bar dataKey="OrderQuantity" barSize={5} radius={[3, 3, 0, 0]}>
            {Array.isArray(color) &&
              color?.map((item, index) => (
                <Cell
                  key={index}
                  fill={item !== undefined ? item : "#318075"}
                />
              ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DealerBarChart;
