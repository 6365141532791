import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import FunctionsIcon from '@mui/icons-material/Functions';
import CloseIcon from '@mui/icons-material/Close';

import MarginManagerChart from './MarginManagerChart';

const style = {
    position: 'absolute',
    top: '50%',
    left: '52.6%',
    transform: 'translate(-50%, -50%)',
    width: '92.2vw',
    bgcolor: '#11303D',
    boxShadow: 24,
    p: '15px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
};

const closeButtonStyle = {
    marginLeft: 'auto',
    cursor: 'pointer',
};

export default function MarginModal({ row, hedge, }) {
    const [open, setOpen] = React.useState(false);
    const [modalLoading, setModalLoading] = React.useState(false)
    const handleOpen = () => {
        setOpen(true);
        setModalLoading(true)
        //axios call in marginMangerchart
    };

    const handleClose = () => setOpen(false);

    return (
        <div className='p-0 m-0'>
            <Button sx={{
                '&:hover': {
                    backgroundColor: '#246683',
                    // variant: 'contained',
                }, border: '1px solid #307C9D', fontWeight: 800, px: 0, py: 0.3
            }} variant='outlined' onClick={() => { handleOpen(row) }}><FunctionsIcon sx={{ p: 0, m: 0, fontSize: 25 }} /></Button>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                // onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <div className='flex'>
                            <h1 className='ml-2 font-semibold text-lg'>
                                {row.name} - {row.id}
                            </h1>
                            <div
                                style={{
                                    ...closeButtonStyle,
                                    paddingBottom: '20px',
                                    marginRight: '6px',
                                }}
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </div>
                        </div>
                        <MarginManagerChart
                            currentUnusedMargin={row.leftMargin}
                            handleClose={handleClose}
                            modalLoading={modalLoading}
                            setModalLoading={setModalLoading}
                            row={row}
                            hedge={hedge}
                        />
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}
