import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import io from "socket.io-client";
import { throttle } from 'lodash';
import { Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import TradeTable from './TradeTable';
import { toast } from 'sonner';

const headers = [
    { id: 'dealer_id', label: 'ID', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, borderLeft: '1px solid rgba(48, 124, 157, 0.7)', color: '' } },
    { id: 'dealer_name', label: 'Name', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '' } },
    { id: 'margin_alotted', label: 'Margin Alloted', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '' } },
    { id: 'margin_used', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '' } },
    { id: 'final_unrealised_pnl', label: 'Unrealised', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', width: '200px' } },
    { id: 'final_intra_pnl', label: 'Intraday', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', width: '200px' } },
    { id: 'final_ON_pnl', label: 'Overnight', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', width: '200px' } },
    // { id: 'pnl_day', label: 'Terminal P&L', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '', width: '150px' } },
    // { id: 'realised_day', label: 'Realised Day', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '' } },
    // { id: 'unrealised_day', label: 'Unrealised Day', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, color: '' } },
];

const FirstRow = React.memo((props) => {
    const { row } = props;
    // console.log(row)
    return (
        <>
            <TableRow
                key={row?.dealer_id}
                sx={{
                    backgroundColor: '#000',
                    '&:hover': {
                        backgroundColor: '#1B4152',
                        width: "100%"
                    },
                    '& > .MuiTableCell-root': {
                        py: 1.2,
                        borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                        borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                        fontWeight: '700',
                        fontSize: '13px'
                    },
                }}
            >
                <TableCell component="th" scope="row?" align='center'
                    sx={{ borderLeft: '1px solid rgba(48, 124, 157, 0.7)', font: 'caption', fontSize: '1rem' }}>
                    Admin
                </TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(60, 83, 46, 0.1)' }}>Admin</TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(114, 57, 57, 0.14)' }}>
                    {row?.margin_alotted !== undefined ? row?.margin_alotted?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                </TableCell>

                <TableCell align="center" sx={{ backgroundColor: 'rgba(114, 57, 57, 0.14)' }}>
                    <span>
                        {row?.margin_used !== undefined ? row?.margin_used?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                        &nbsp; {row?.margin_percent !== undefined ? `(${row?.margin_percent}%)` : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(90, 53, 49, 0.10)' }}>
                    <span style={{ color: row?.unrealised !== undefined && row?.unrealised < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.unrealised !== undefined ? row?.unrealised?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)', }}>
                    <span style={{ color: row?.intraday !== undefined && row?.intraday < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.intraday !== undefined ? row?.intraday?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)' }}>
                    <span style={{ color: row?.overnight !== undefined && row?.overnight < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.overnight !== undefined ? row?.overnight?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                    </span>
                </TableCell>
            </TableRow>
            <TableRow sx={{ height: 0.1, width: "100%" }}>
                <TableCell
                    colSpan={7}
                    style={{ height: '0px', padding: '8px' }}>
                </TableCell>
            </TableRow>
        </>
    )
});

const Row = React.memo((props) => {
    const { row, data } = props;
    // console.log(row)
    const [open, setOpen] = React.useState(false);
    // const [payoffOpen, setPayoffOpen] = React.useState(false);

    // const handleClose = () => setPayoffOpen(false);
    // const handleOpen = () => setPayoffOpen(true);

    return (
        <React.Fragment>
            <TableRow
                onClick={() => setOpen(!open)}
                key={row.dealer_id}
                sx={{
                    // backgroundColor: '#000',
                    '&:hover': {
                        backgroundColor: '#1B4152',
                        width: "100%",
                        cursor: "pointer",
                    },
                    '& > .MuiTableCell-root': {
                        py: 1.2,
                        borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                        borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                        fontWeight: '700',
                        fontSize: '13px'
                    },
                }}
            >
                <TableCell component="th" scope="row" align='center' style={{ cursor: 'pointer' }} sx={{ borderLeft: '1px solid rgba(48, 124, 157, 0.7)' }}>
                    {row?.dealer_id}
                </TableCell>
                <TableCell align="center">{row?.dealer_name}</TableCell>
                <TableCell align="center">
                    {row?.margin_alotted && row?.margin_alotted?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                </TableCell>
                <TableCell align="center">
                    {row?.margin_used && row?.margin_used?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })} &nbsp;({row?.margin_percent}%)
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(90, 53, 49, 0.10)' }}>
                    <span style={{ color: row?.unrealised !== undefined && row?.unrealised < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.unrealised !== undefined ? row?.unrealised?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)', }}>
                    <span style={{ color: row?.intraday !== undefined && row?.intraday < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.intraday !== undefined ? row?.intraday?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                    </span>
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: 'rgba(52, 100, 83, 0.1)' }}>
                    <span style={{ color: row?.overnight !== undefined && row?.overnight < 0 ? '#FF6565' : '#97FF95' }}>
                        {row?.overnight !== undefined ? row?.overnight?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }) : ''}
                    </span>
                </TableCell>
                {/* <TableCell align="center">
                    <span style={{ color: row.pnl_day < 0 ? '#ff6565' : '#97FF95' }}>
                        {row?.pnl_day && row?.pnl_day?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}&nbsp; ({row?.pnl_day_percent}%)
                    </span>
                </TableCell>
                <TableCell align="center">
                    <span style={{ color: row?.realised_day < 0 ? '#ff6565' : '#97FF95' }}>
                        {row?.realised_day && row?.realised_day?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}&nbsp;({row?.realised_day_percent}%)
                    </span>
                </TableCell>
                <TableCell align="center">
                    <span style={{ color: row?.unrealised_day < 0 ? '#ff6565' : '#97FF95' }}>
                        {row?.unrealised_day && row?.unrealised_day?.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}&nbsp; ({row?.unrealised_day_percent}%)
                    </span>
                </TableCell> */}
            </TableRow>


            <TableRow sx={{ width: "100%" }}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: '#021B26', borderTop: '1px solid rgba(48, 124, 157, 0.7)', borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderLeft: '1px solid rgba(48, 124, 157, 0.7)', borderBottom: '1px solid rgba(48, 124, 157, 0.7)' }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1, width: "100%" }}>
                            <Typography variant="h6" gutterBottom component="div">
                                {row.underlying}
                            </Typography>

                            <TableContainer style={{ maxHeight: 400, backgroundColor: '#021B26', borderRadius: 8, border: 'none', width: "100%" }}>
                                {row.underlyings && row.underlyings.length > 0 ? (
                                    <TradeTable data={row.underlyings} />
                                ) : (
                                    <Typography variant="body2">No positions available</Typography>
                                )}
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

        </React.Fragment>
    );
});

Row.propTypes = {
    row: PropTypes.shape({
        dealer_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        dealer_name: PropTypes.string.isRequired,
        margin_alotted: PropTypes.number.isRequired,
        margin_used: PropTypes.number.isRequired,
        margin_percent: PropTypes.number.isRequired,
        pnl_net: PropTypes.number.isRequired,
        pnl_day: PropTypes.number.isRequired,
        pnl_day_percent: PropTypes.number.isRequired,
        pnl_net_percent: PropTypes.number.isRequired,
        realised_day: PropTypes.number.isRequired,
        realised_day_percent: PropTypes.number.isRequired,
        unrealised_day: PropTypes.number.isRequired,
        unrealised_day_percent: PropTypes.number.isRequired,
        unrealised_net: PropTypes.number.isRequired,
        unrealised_net_percent: PropTypes.number.isRequired,
        positions: PropTypes.arrayOf(
            PropTypes.shape({
                underlying: PropTypes.string,
            }),
        ).isRequired,
    }).isRequired,
};


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}


const DateWisePositions = () => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [clientData, setClientData] = React.useState([]);
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

    const handleRequestSort = (property) => (event) => {
        if (orderBy === property) {
            setOrder((prevOrder) => {
                if (prevOrder === 'asc') return 'desc';
                if (prevOrder === 'desc') return 'default';
                return 'asc';
            });
        } else {
            setOrder('asc');
            setOrderBy(property);
        }
    };
    
    const getSortIcon = (property) => {
        if (orderBy === property) {
            if (order === 'asc') return '↑';
            if (order === 'desc') return '↓';
            return '↕';
        }
        return null;
    };
    
    const throttledSetData = React.useCallback(throttle((newData) => {
        // if (newData) {
            setData(newData.dealers);
            setClientData(newData.client);
            setLoading(false);
        // }
        // else {
        //     setLoading(false);
        // }

    }), []);
    useEffect(() => {
        const socket = io(socket_server_url + "/testDatePos");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on("test-datewisepositions", (newData) => {
            if (newData) {
                // console.log(newData);
                throttledSetData(newData);
                setLoading(false);
            }
            else {
                setLoading(false);
            }
        });

        return () => {
            socket.disconnect();
            throttledSetData.cancel();
        };
    }, [socket_server_url, throttledSetData]);

    useEffect(() => {
        const rootSocket = io(socket_server_url + "/");

        rootSocket.on('connect', () => console.log('Connected to root socket server'));
        rootSocket.on("disconnect", () => console.log("Disconnected from root socket server"));

        rootSocket.emit("datewiseposition", "Test Update from frontend");

        return () => {
            rootSocket.disconnect();
        };
    }, []);

    const skeletonRows = [...Array(10)].map((_, index) => (
        <TableRow key={index}>
            {headers.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));

    const handleExportRows = () => {
        const doc = new jsPDF('landscape'); // Use landscape orientation

        // Prepare the table headers
        const tableHeaders = headers.map(header => header.label);
        toast.success('Downloaded Successfully')
        // Prepare the table data
        const tableData = stableSort(data, getComparator(order, orderBy))
            .map(row => headers.map(header => row[header.id] ? row[header.id].toString() : ''));

        // Generate the table in the PDF
        autoTable(doc, {
            theme: 'grid',
            head: [tableHeaders],
            body: tableData,
            styles: { overflow: 'linebreak', fontSize: 8 },
            columnStyles: { 0: { cellWidth: 'auto' } }, // Customize column styles
        });
        const csvContent = [tableHeaders.join(',')];
        tableData.forEach((row) => {
            csvContent.push(row.join(','));
        });
        const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvBlob);
        const csvLink = document.createElement('a');
        csvLink.href = csvUrl;
        csvLink.setAttribute('download', 'DatewisePosition.csv');
        document.body.appendChild(csvLink);
        csvLink.click();
        document.body.removeChild(csvLink);
        // Save the PDF
        doc.save('DatewisePositions.pdf');
    };

    return (
        <Box className='alarm-scrollbar m-5'>
            <Button
                onClick={handleExportRows}
                variant="contained"
                color="primary"
                style={{ py: 0.5, px: 0, marginBottom: '6px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize: "11px" }}
                startIcon={<DownloadingOutlinedIcon />}
            >
                Download
            </Button>
            <TableContainer className='alarm-scrollbar' component={Paper} style={{ maxHeight: '75vh', overflow: 'auto', width: '100%', }}>
                <Table sx={{ minWidth: 600 }} stickyHeader aria-label="sticky table">
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1, py: 1, width: '100%', }}>
                        <TableRow sx={{
                            backgroundColor: '#000',
                            '&:hover': {
                                backgroundColor: '#1B4152',
                                width: '100%',
                            },
                            '& > .MuiTableCell-root': {
                                py: 0.6,
                                borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                                borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                fontWeight: '700',
                                fontSize: '13px'
                            },
                        }}>
                            <TableCell align="center" colSpan={2} sx={{ width: "25%", borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)', borderLeft: '1px solid rgba(48, 124, 157, 0.7)' }}>
                                DealerId
                            </TableCell>
                            <TableCell align="center" colSpan={2} sx={{
                                width: "35%", borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)'
                            }} >
                                Margin
                            </TableCell>
                            <TableCell align="center" colSpan={3} sx={{ width: "40%", borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)' }}>
                                Actual MTM
                            </TableCell>
                            {/* <TableCell align="center" colSpan={3} sx={{ borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)' }}>
                                Terminal P&L
                            </TableCell> */}
                        </TableRow>
                        <TableRow sx={{
                            backgroundColor: '#000',
                            '& > .MuiTableCell-root': {
                                py: 0.6,
                                borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                                borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                fontWeight: '700',
                                fontSize: '13px',
                            },
                        }}>
                            {headers.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align='center'
                                    sx={{ ...header.style, borderBottom: '1px solid rgba(48, 124, 157, 0.7)', borderRight: '1px solid rgba(48, 124, 157, 0.7)', borderTop: '1px solid rgba(48, 124, 157, 0.7)' }}
                                >
                                    {loading ? (
                                        <div>
                                            <Skeleton animation="wave" height={30} />
                                        </div>
                                    ) : (
                                        <TableSortLabel
                                            active={orderBy === header.id}
                                            direction={orderBy === header.id ? order : 'asc'}
                                            onClick={handleRequestSort(header.id)}
                                            style={{ whiteSpace: 'nowrap' }}
                                            aria-label={header.label}
                                            IconComponent={null}
                                        >
                                            <Tooltip title={header.label} placement='bottom' arrow>
                                                <span>{header.label} {getSortIcon(header.id)}</span>
                                            </Tooltip>
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: '#021B26', width: '100%' }}>
                        {loading ? (
                            skeletonRows
                        ) : (
                            <>
                                <FirstRow row={clientData} />
                                <TableRow></TableRow>
                                {stableSort(data, getComparator(order, orderBy))?.map((row) => (
                                    <Row key={row?.dealer_id} row={row} data={data} loading={loading} />
                                ))}
                            </>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default DateWisePositions;