import React from 'react';
import { Tooltip } from '@mui/material';
const CurrentDate = () => {
    // Create a new Date object and store it in a variable.
    const date = new Date();

    // Format the date as a string in the desired format.
    // This example uses 'en-US' formatting and options for month, day, and year.
    const dateString = date.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'short',
        day: 'numeric'
    });

    // Return the div with the current date.
    return (
        <div className='text-sm font-bold whitespace-nowrap'>
            {dateString}
        </div>
    );
};

export default CurrentDate;
