import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';

function createData(id, name, calories, fat, carbs, protein) {
  return {
    id,
    name,
    calories,
    fat,
    carbs,
    protein,
  };
};

const rows = [
  createData(1, 'mismatch error', 305, 3.7),
  createData(2, 'server down', 159, 6.0),
  createData(3, 'connection failure', 356, 16.0),
  createData(4, 'data feed stopped', 353, 16.0),
];

const headCells = [
  {
    id: 'id',
    numeric: true,
    disablePadding: true,
    label: 'Id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Alarm Keys',
  },
  {
    id: 'calories',
    numeric: true,
    disablePadding: false,
    label: 'Reason',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: 'Source',
  },
];

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ backgroundColor: '#001017' }}>
      <TableRow sx={{ border: '1px solid rgba(81, 81, 81, 1)' }}>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ paddingLeft: '30px', position: 'sticky', top: 0, borderRight: '1px solid rgba(81, 81, 81, 1)' }}

          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function InternalList() {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () =>
      rows.slice().sort((a, b) => {
        const comparator = order === 'desc' ? b[orderBy] - a[orderBy] : a[orderBy] - b[orderBy];
        return comparator !== 0 ? comparator : a.id - b.id;
      }),
    [order, orderBy]
  );

  return (
    <div className='MUITable-scrollbar'>
      <Typography sx={{ textAlign: 'center', mb: 2 }} variant='h5'>Internal Dependecies</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Paper sx={{ mb: 4 }}>
          <TableContainer>
            <Table sx={{ minWidth: 1250, overflow: 'overflow-x-auto', backgroundColor: '#021B26', border: '1px solid rgba(81, 81, 81, 1)' }}>
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {visibleRows.map((row) => (
                  <TableRow key={row.id} hover>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}>{row.id}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}>{row.name}</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(81, 81, 81, 1)' }}>{row.calories}</TableCell>
                    <TableCell align="center">{row.fat}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}
