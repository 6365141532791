import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Tooltip } from "@mui/material";

const Row = React.memo((props) => {
  const { row, headers } = props;
  return (
    <TableRow
      key={row?.DealerID}
      sx={{
        "&:hover": {
          backgroundColor: "#1B4152",
        },
        "& > .MuiTableCell-root": {
          p: 0.4,
          border: "1px solid rgba(48, 124, 157, 0.7)",
        },
        backgroundColor: "#021B26",
      }}
    >
      {headers?.map((header, idx) => (
        <TableCell
          key={header.id}
          component="th"
          scope="row"
          align="center"
          style={{
            borderLeft:
              idx === 0 ? "1px solid rgba(48, 124, 157, 0.7)" : "transparent",
            fontSize: "11px",
            cursor: "pointer",
            borderBottom: "1px solid rgba(48, 124, 157, 0.7)",
            borderRight: "1px solid rgba(48, 124, 157, 0.7)",
            padding: "5px",
            color:
              [
                "NetPnl",
                "TotalProfit",
                "TotalLoss",
                "AvgProfit",
                "AvgLoss",
              ]?.includes(header.id) && row[header.id] > 0
                ? "#70E66D"
                : [
                    "NetPnl",
                    "TotalProfit",
                    "TotalLoss",
                    "AvgProfit",
                    "AvgLoss",
                  ]?.includes(header.id) && row[header.id] < 0
                ? "#FF6565"
                : "#fff",
          }}
        >
          {row[header.id]}
        </TableCell>
      ))}
    </TableRow>
  );
});

Row.propTypes = {
  row: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
};
const PnlHeaderTable = ({ headers, data }) => {
  return (
    <Box className="">
      <TableContainer
        component={Paper}
        style={{ maxHeight: "30vh", overflow: "auto" }}
      >
        <Table sx={{ minWidth: 600 }} stickyHeader aria-label="sticky table">
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 1 }}>
            <TableRow>
              {headers?.map((header, idx) => (
                <TableCell
                  key={header.id}
                  align="center"
                  sx={{
                    ...header.style,
                    fontSize: "12px",
                    borderRight: "1px solid rgba(48, 124, 157, 0.7)",
                    borderBottom: "1px solid rgba(48, 124, 157, 0.7)",
                    borderLeft:
                      idx === 0
                        ? "1px solid rgba(48, 124, 157, 0.7)"
                        : "transparent",
                    borderTop: "1px solid rgba(48, 124, 157, 0.7)",
                    backgroundColor: "#001017",
                    fontWeight: 800,
                  }}
                  rowSpan={idx === 0 ? 2 : 1}
                >
                  <Tooltip title={header.label} placement="bottom" arrow>
                    <span>{header.label}</span>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: "#021B26", fontSize: "12px" }}>
            <Row row={data} headers={headers} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

PnlHeaderTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};

export default PnlHeaderTable;
