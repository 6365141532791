import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { Surveillance } from "./Surveillance";
import ControlPanel from "./ControlPanel";
import FirewallTab from "./firewall/FirewallTab";

export default function DashboardTab({
  isLoggedIn,
  setIsLoggedIn,
  alertData,
  collapsed,
}) {
  const location = useLocation();

  const getTabClass = (tabName) => {
    const isActive =
      location.pathname.includes(tabName) ||
      (location.pathname === "/" && tabName === "surveillance");
    return isActive ? "tab-link active" : "tab-link";
  };

  return (
    <div className="w-full mt-4 px-4 tabs tab-left-right">
      <nav className="rounded-lg navbar">
        <Link to="/" className={getTabClass("surveillance")}>
          Statistics
          <span className="nav-item-before" />
        </Link>
        <Link to="/controlPanel" className={getTabClass("controlPanel")}>
          Control Panel
          <span className="nav-item-before" />
        </Link>
        {/* <Link to="/firewall/edit" className={getTabClass('firewall')}>
          Firewall
          <span className="nav-item-before" />
        </Link> */}
      </nav>
      <div >
        <Routes>
          <Route path="/" element={<Surveillance alertData={alertData} />} />
          <Route
            path="/controlPanel"
            element={
              <ControlPanel
                collapsed={collapsed}
                isLoggedIn={isLoggedIn}
                setIsLoggedIn={setIsLoggedIn}
              />
            }
          />
          <Route path="/firewall/*" element={<FirewallTab />} />
        </Routes>
      </div>
    </div>
  );
}
