import MarginBarHorizontal from '../Margin/MarginBarHorizontal'
import Skeleton from '@mui/material/Skeleton';
import DealerSelection from '../../commoncomponent/DealerSelection';
import React, { useState } from 'react';
import useFetchDealerData from '../Margin/fetchData/useFetchDealerData';
import MarginBarDealer from '../Margin/MarginBarDealer';
import axios from "axios";
import ComponentZoomModal from "../../../Hoc/ComponentZoomModal";
import PnlBarVertical from '../Pnl/PnlBarHorizontal';
import PnlPayoff from '../Pnl/PnlPayoff';
import DealerTable from './DealerTable';

const Dealer = () => {
    const [dealerId, setDealerId] = useState("admin");
    const [dealerName, setDealerName] = useState("admin");
    const [segmentVarValue, setSegmentVarValue] = useState([]);
    const [barData, setBarData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [payoffChartData, setPayoffChartData] = useState([]);
    const [tableData, setTableData] = useState([])

    const [marginBarData, qtyTradedValue] = useFetchDealerData(dealerId);
    const marginDataName = `Segmentwise Overnight Margin Used`;
    const qtyTradedName = 'Segmentwise Quantity Traded '
    const pnlBarName = 'Segmentwise VAR'
    const fetchData = () => {
        const api_server_url = process.env.REACT_APP_API_SERVER_URL;
        setIsLoading(true);
        axios
            .post(api_server_url + `/riskpnl`, {
                dealer_id: dealerId,
            })
            .then((res) => {
                const responseData = res.data;
                const values = responseData.map((entry) => Object.values(entry)[0]); // Extracting values
                setSegmentVarValue(values);
                // console.log(values);

                setBarData(responseData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
            });

        axios
            .put(api_server_url + `/riskpnl`, {
                dealer_id: dealerId,
            })
            .then((res) => {
                // console.log(res.data);
                setPayoffChartData(res.data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error("Error:", error);
            });

        axios.post(`${api_server_url}/riskmargin/alldata`, { dealer_id: dealerId }, { withCredentials: true })
            .then((res) => {
                console.log(res.data)
                setTableData(res.data)
            })
            .catch((error) => {
                console.log('error in getallData route', error)
            })
    };

    React.useEffect(() => {
        fetchData();
    }, [dealerId]);

    return (
        <div className='flex flex-col px-4 rounded-lg h-[82vh] border border-[#307C9D] border-opacity-50 '>
            {/* MARGIN DEALER */}
            <div className="flex items-center z-10 py-2 sticky top-0">
                <div className="flex justify-start">
                    <DealerSelection dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName} />
                </div>
                <div className="flex text-[13px] items-center flex-grow justify-center gap-5 absolute left-[65%]">
                    {dealerId === dealerName ? (
                        <span className='font-semibold text-[14px]'>{dealerId.charAt(0).toUpperCase() + dealerId.slice(1)}</span>
                    ) : (
                        <>
                            <span className='font-semibold text-[14px]'>{dealerId.charAt(0).toUpperCase() + dealerId.slice(1)}</span>
                            <span className='font-semibold text-[14px]'>{dealerName}</span>
                        </>
                    )}
                </div>
            </div>
            <div className='flex flex-col gap-3 w-full h-[34vh]'>
                {/* add the border here for table and below chart */}
                <div className='flex items-center gap-4 w-full h-full rounded-lg border-opacity-50'>
                    <div className='w-[33%] h-full'><DealerTable data={tableData} dealerId={dealerId}/></div>
                    {/* below widht is 60vw */}
                    <div className="border border-[#307C9D] relative rounded-lg p-4 h-[35vh] w-[67%]">
                        <div className="w-full flex justify-end absolute top-2 right-2">
                            <ComponentZoomModal
                                component={PnlPayoff}
                                componentProps={{ payoffChartData: payoffChartData }}
                            />
                        </div>
                        <div className='flex items-center justify-center h-full'>
                            {isLoading ? (
                                <Skeleton
                                    animtion="wave"
                                    variant="rounded"
                                    width="100%"
                                    height="100%"
                                />
                            ) : payoffChartData.length > 0 ? (
                                <PnlPayoff payoffChartData={payoffChartData} />
                            ) : (
                                <div>Data is not available</div>
                            )}
                        </div>
                    </div>
                </div>
                {/* PNL DEALER */}
                <div className="flex gap-2 w-full h-[40vh]">
                    <div className="border border-[#307C9D] relative rounded-lg p-4 h-[35vh] w-[33%]">
                        <div className="w-full flex justify-end absolute top-2 right-2">
                            <ComponentZoomModal
                                component={PnlBarVertical}
                                componentProps={{ barData: barData, name: pnlBarName }}
                            />
                        </div>
                        <div className='flex items-center justify-center h-full'>
                            {isLoading ? (
                                <Skeleton
                                    animtion="wave"
                                    variant="rounded"
                                    width="100%"
                                    height="100%"
                                />
                            ) : barData.length > 0 ? (
                                <PnlBarVertical barData={barData} name={pnlBarName} />
                            ) : (
                                <div>Data is not available</div>
                            )}
                        </div>
                    </div>
                    <div className="border border-[#307C9D] relative rounded-lg p-4 h-[35vh] w-[33%]">
                        <div className="w-full flex justify-end absolute top-2 right-2">
                            <ComponentZoomModal
                                component={MarginBarDealer}
                                componentProps={{ data: marginBarData, name: marginDataName }}
                            />
                        </div>
                        <div className='flex items-center justify-center h-full'>
                            {isLoading ? <Skeleton animtion='wave' variant="rounded" width="100%" height={200} /> : (marginBarData.length > 0 ?
                                <MarginBarDealer data={marginBarData} name={marginDataName} /> :
                                <p>Data is not available</p>
                            )}
                        </div>
                    </div>
                    <div className="border border-[#307C9D] relative rounded-lg p-4 h-[35vh] w-[33%]">
                        <div className="w-full flex justify-end absolute top-2 right-2">
                            <ComponentZoomModal
                                component={MarginBarDealer}
                                componentProps={{ data: qtyTradedValue, name: qtyTradedName }}
                            />
                        </div>
                        <div className='flex items-center justify-center h-full'>
                            {isLoading ? <Skeleton animtion='wave' variant="rounded" width="100%" height={200} /> : (qtyTradedValue.length > 0 ?
                                <MarginBarDealer data={qtyTradedValue} name={qtyTradedName} qtyColor /> :
                                <p>Data is not available</p>
                            )}
                        </div>
                    </div>
                    {/* <div className="border border-[#307C9D] flex items-center justify-center  border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
                        {isLoading ? <Skeleton animtion='wave' variant="rounded" width="100%" height={200} /> : (qtyTradedValue.length > 0 ?
                            <MarginBarDealer data={qtyTradedValue} name={qtyTradedName} qtyColor /> :
                            <p>Data is not available</p>
                        )}
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Dealer;
