// import React from 'react';
// import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
// import { Box, Button } from '@mui/material';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';
// import { jsPDF } from 'jspdf';
// import autoTable from 'jspdf-autotable';
// import io from "socket.io-client";
// import { throttle } from 'lodash';
// import Skeleton from '@mui/material/Skeleton';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';
// // import FunctionsIcon from '@mui/icons-material/Functions';
// import { useTheme, LinearProgress } from '@mui/material';
// // import MarginModal from './charts/Modal';
// import TextField from '@mui/material/TextField';
// import { toast } from 'sonner';

// const columnHelper = createMRTColumnHelper();

// const DealerWiseMismatch = () => {
//     const [data, setData] = React.useState([]);
//     const [tableData, setTableData] = React.useState([]);
//     const [sorting, setSorting] = React.useState([]);
//     const rowVirtualizerInstanceRef = React.useRef(null);
//     const [loading, setLoading] = React.useState(false);
//     // const [modalLoading, setModalLoading] = React.useState(true)

//     const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;
//     const columns = [
//         columnHelper.accessor('client_id', {
//             header: 'Client_ID',
//             size: 40,
//             muiTableHeadCellProps: {
//                 align: 'center',
//             },
//             muiTableBodyCellProps: {
//                 align: 'center',
//             },
//             muiTableFooterCellProps: {
//                 align: 'center',
//             },
//         }),
//         columnHelper.accessor('symbol', {
//             header: 'Symbol',
//             size: 40,
//             muiTableHeadCellProps: {
//                 align: 'center',
//             },
//             muiTableBodyCellProps: {
//                 align: 'center',
//             },
//             muiTableFooterCellProps: {
//                 align: 'center',
//             },
//         }),
//         columnHelper.accessor('terminal_quantity', {
//             header: 'Terminal Quantity ',
//             size: 40,
//             muiTableHeadCellProps: {
//                 align: 'center',
//             },
//             muiTableBodyCellProps: {
//                 align: 'center',
//             },
//             muiTableFooterCellProps: {
//                 align: 'center',
//             },
//         }),
//         columnHelper.accessor('mudraksh_quantity', {
//             header: 'Mudraksh Quantity',
//             size: 40,
//             muiTableHeadCellProps: {
//                 align: 'center',
//             },
//             muiTableBodyCellProps: {
//                 align: 'center',
//             },
//             muiTableFooterCellProps: {
//                 align: 'center',
//             },
//         }),
//         columnHelper.accessor('data', {
//             header: 'Data',
//             size: 40,
//             muiTableHeadCellProps: {
//                 align: 'center',
//             },
//             muiTableBodyCellProps: {
//                 align: 'center',
//             },
//             muiTableFooterCellProps: {
//                 align: 'center',
//             },
//         }),
//     ];

//     const throttledSetData = React.useCallback(throttle((newData) => {
//         setLoading(false)
//         setData(newData);
//     }), []);

//     React.useEffect(() => {
//         const socket = io(socket_server_url + "/testMismatchPage");

//         socket.on('connect', () => console.log('Connected to server'));
//         socket.on("disconnect", () => console.log("Disconnected from server"));

//         socket.on("test-mismatchpage", (newData) => {
//             if (newData) {
//                 throttledSetData(newData);
//                 setLoading(false)
//                 console.log(newData)
//             }
//             else {
//                 setLoading(false)
//             }

//         });

//         return () => {
//             socket.disconnect();
//             throttledSetData.cancel();
//         };
//     }, [throttledSetData]);

//     React.useEffect(() => {
//         if (typeof window !== 'undefined') {
//             setTableData(data);
//         }
//     }, []);

//     React.useEffect(() => {
//         try {
//             rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
//         } catch (error) {
//             console.error(error);
//         }
//     }, [sorting]);

//     const handleExportRows = (rows) => {
//         try {
//             if (!Array.isArray(rows) || rows.length === 0) {
//                 throw new Error("No rows to export.");
//             }
//             toast.success('Donwloaded Successfully')
//             const doc = new jsPDF('landscape'); // Use landscape orientation

//             // Process the table headers
//             const tableHeaders = columns.map((c) => c.header || '');

//             // Find the index of the 'dealerID' column
//             const dealerIDIndex = columns.findIndex(c => c.id === 'dealerID');

//             const tableData = rows.map((row) => {
//                 return columns.map((column) => {
//                     const cellValue = row.original ? row.original[column.id] : '';
//                     return cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
//                 });
//             });

//             autoTable(doc, {
//                 theme: 'grid',
//                 head: [tableHeaders],
//                 body: tableData,
//                 styles: {
//                     overflow: 'linebreak',
//                     cellWidth: 'wrap',
//                     fontSize: 8,
//                 },
//                 columnStyles: {
//                     // Set custom width for 'dealerID' column
//                     [dealerIDIndex]: { cellWidth: 50 }, // Adjust the width as needed
//                 },
//                 startY: 10,
//                 margin: { top: 10, right: 10, bottom: 10, left: 10 },
//                 didDrawPage: function (data) {
//                     doc.text('Page ' + doc.internal.getNumberOfPages(), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
//                 },
//             });
//             const csvContent = [tableHeaders.join(',')];
//             tableData.forEach((row) => {
//                 csvContent.push(row.join(','));
//             });
//             const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
//             const csvUrl = URL.createObjectURL(csvBlob);
//             const csvLink = document.createElement('a');
//             csvLink.href = csvUrl;
//             csvLink.setAttribute('download', 'Sigma_Optimizer.csv');
//             document.body.appendChild(csvLink);
//             csvLink.click();
//             document.body.removeChild(csvLink);

//             doc.save('Sigma_Optimizer.pdf');
//         } catch (error) {
//             toast.error('Download Failed')
//             console.error("Error while generating PDF: ", error);
//             alert("An error occurred while generating the PDF: " + error.message);
//         }
//     };

//     const table = useMaterialReactTable({
//         columns,
//         data,
//         // columnFilterDisplayMode: 'popover',
//         enablePagination: false,
//         enableBottomToolbar: false,
//         enableStickyHeader: true,
//         enableDensityToggle: false,
//         initialState: { density: 'compact' },
//         columnResizeMode: 'onChange',
//         enableRowVirtualization: true,
//         muiTableContainerProps: { sx: { maxHeight: '70vh' } },
//         onSortingChange: setSorting,
//         state: { loading, sorting },
//         rowVirtualizerInstanceRef,
//         enableGlobalFilterModes: true,
//         enableColumnActions: false,
//         muiTableHeadCellProps: {
//             sx: {
//                 border: '1px solid rgba(48, 124, 157, 0.7)',
//                 fontWeight: '800',
//                 backgroundColor: '#021b26',
//                 fontSize: '15px',
//                 paddingY: 1.5
//             },
//         },
//         muiTableBodyCellProps: {
//             sx: {
//                 border: '1px solid rgba(48, 124, 157, 0.7)',
//                 paddingY: 0
//             },
//         },
//         rowVirtualizerOptions: { overscan: 5 }, renderTopToolbarCustomActions: ({ table }) => (
//             <Button
//                 disabled={table.getPrePaginationRowModel().rows.length === 0}
//                 onClick={() =>
//                     handleExportRows(table.getPrePaginationRowModel().rows)
//                 }
//                 style={{ marginBottom: '10px', backgroundColor: '#1d1d1d', color: '#3b9599', border: '1px solid #3b9599' }}
//                 startIcon={<FileDownloadIcon />}
//             >
//                 Download
//             </Button>
//         ),
//     });


//     const skeletonRows = [...Array(10)].map((_, index) => (
//         <TableRow key={index}>
//             {columns.map((column, colIndex) => (
//                 <TableCell key={colIndex}>
//                     <Skeleton animation="wave" height={30} />
//                 </TableCell>
//             ))}
//         </TableRow>
//     ));

//     React.useEffect(() => {
//         const rootSocket = io(socket_server_url + "/");

//         rootSocket.on('connect', () => console.log('Connected to root socket server'));
//         rootSocket.on("disconnect", () => console.log("Disconnected from root socket server"));

//         rootSocket.emit("marginpage", "Test Update from frontend");

//         return () => {
//             rootSocket.disconnect();
//         };
//     }, []);

//     return (
//         <>
//             {/* <h1 className='bg-[] ml-[20px] mt-[10px] px-4 py-2 w-fit'>Margin Manager</h1> */}
//             <Box Box
//                 sx={{
//                     display: 'flex',
//                     flexDirection: 'column',
//                     // height: '85vh',
//                     overflow: 'hidden',
//                     margin: '20px',
//                 }}
//                 className='MUITable-scrollbar'
//             >
//                 {
//                     loading ? (
//                         <TableContainer component={Paper} style={{ maxHeight: '450px', overflow: 'auto' }} >
//                             <Table sx={{ minWidth: 600 }} aria-label="skeleton table">
//                                 <TableHead sx={{ backgroundColor: '#021B26' }}>
//                                     <TableRow>
//                                         {columns.map((column) => (
//                                             <TableCell key={column.id} style={{ ...column.style }}>
//                                                 <Skeleton animation="wave" height={30} />
//                                             </TableCell>
//                                         ))}
//                                     </TableRow>
//                                 </TableHead>
//                                 <TableBody sx={{ backgroundColor: '#021B26' }}>{skeletonRows}</TableBody>
//                             </Table>
//                         </TableContainer>
//                     ) : (
//                         <MaterialReactTable table={table} />
//                     )}
//             </Box>
//         </>
//     )

// };

// export default DealerWiseMismatch;

import React from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable } from 'material-react-table';
import { Box, Button } from '@mui/material';
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { toast } from 'sonner';
import axios from 'axios';
import RefreshIcon from '@mui/icons-material/Refresh';

const columnHelper = createMRTColumnHelper();

const DealerWiseMismatch = () => {
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    const api_server_url = process.env.REACT_APP_API_SERVER_URL;

    const columns = [
        columnHelper.accessor('DealerID', {
            header: 'Dealer_ID',
            size: 25,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            muiTableFooterCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('TradingSymbol', {
            header: 'Symbol',
            size: 90,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            muiTableFooterCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('TerminalQuantity', {
            header: 'Terminal Quantity ',
            size: 40,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            muiTableFooterCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('InternalQuantity', {
            header: 'Mudraksh Quantity',
            size: 40,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            muiTableFooterCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('Status', {
            header: 'Status',
            size: 40,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            muiTableFooterCellProps: {
                align: 'center',
            },
        }),
    ];

    const fetchData = async () => {
        try {
            const response = await axios.get(`${api_server_url}/mismatch`, { withCredentials: true });
            setData(response.data);
            // toast.success('Data Refreshed Successfully');
        } catch (error) {
            console.log(error);
            toast.error('Failed to Refresh Data');
        } finally {
            setLoading(false);
        }
    };

    React.useEffect(() => {
        setLoading(true);
        fetchData();

        const fetchDataInterval = setInterval(() => {
            fetchData();
        }, 30000); // 30 seconds

        return () => clearInterval(fetchDataInterval);
    }, []);

    const handleRefresh = () => {
        setLoading(true);
        fetchData();
    };
    const handleExportRows = (rows) => {
        try {
            if (!Array.isArray(rows) || rows.length === 0) {
                throw new Error("No rows to export.");
            }
            toast.success('Donwloaded Successfully')
            const doc = new jsPDF('landscape'); // Use landscape orientation

            // Process the table headers
            const tableHeaders = columns.map((c) => c.header || '');

            // Find the index of the 'dealerID' column
            const dealerIDIndex = columns.findIndex(c => c.id === 'dealerID');

            const tableData = rows.map((row) => {
                return columns.map((column) => {
                    const cellValue = row.original ? row.original[column.id] : '';
                    return cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
                });
            });

            autoTable(doc, {
                theme: 'grid',
                head: [tableHeaders],
                body: tableData,
                styles: {
                    overflow: 'linebreak',
                    cellWidth: 'wrap',
                    fontSize: 8,
                },
                columnStyles: {
                    // Set custom width for 'dealerID' column
                    [dealerIDIndex]: { cellWidth: 50 }, // Adjust the width as needed
                },
                startY: 10,
                margin: { top: 10, right: 10, bottom: 10, left: 10 },
                didDrawPage: function (data) {
                    doc.text('Page ' + doc.internal.getNumberOfPages(), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
                },
            });
            const csvContent = [tableHeaders.join(',')];
            tableData.forEach((row) => {
                csvContent.push(row.join(','));
            });
            const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);
            const csvLink = document.createElement('a');
            csvLink.href = csvUrl;
            csvLink.setAttribute('download', 'Sigma_Optimizer.csv');
            document.body.appendChild(csvLink);
            csvLink.click();
            document.body.removeChild(csvLink);

            doc.save('Sigma_Optimizer.pdf');
        } catch (error) {
            toast.error('Download Failed')
            console.error("Error while generating PDF: ", error);
            alert("An error occurred while generating the PDF: " + error.message);
        }
    };


    const table = useMaterialReactTable({
        columns,
        data,
        enablePagination: false,
        enableBottomToolbar: false,
        enableStickyHeader: true,
        enableDensityToggle: false,
        initialState: { density: 'compact' },
        enableRowVirtualization: true,
        muiTableContainerProps: { sx: { maxHeight: '70vh' }, className:'alarm-scrollbar' },
        state: { loading },
        enableGlobalFilterModes: true,
        enableColumnActions: false,
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                fontWeight: '800',
                backgroundColor: '#021b26',
                fontSize: '15px',
                paddingY: 1.5
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                paddingY: 0
            },
        },
        rowVirtualizerOptions: { overscan: 5 }, renderTopToolbarCustomActions: ({ table }) => (
            <Box sx={{display:'flex', alignItems:'center', gap:2}}>
                <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    onClick={() =>
                        handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    style={{ py: 0.5, px: 0, marginBottom: '6px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize: "11px" }}
                    startIcon={<DownloadingOutlinedIcon />}
                >
                    Download
                </Button>
                <Button
                    onClick={handleRefresh}
                    style={{py:0.5, px:0, marginBottom: '6px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize:"11px" }}
                    startIcon={<RefreshIcon />}
                >
                    Refresh
                </Button>
            </Box>
        ),
    });

    const skeletonRows = [...Array(10)].map((_, index) => (
        <TableRow key={index}>
            {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));
    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'hidden',
                }}
                className='border border-[#307C9D] border-opacity-50 rounded-md'
            >
                {loading ? (
                    <TableContainer component={Paper} style={{ maxHeight: '450px', overflow: 'auto' }} className='alarm-scrollbar'>
                        <Table sx={{ minWidth: 600 }} aria-label="skeleton table">
                            <TableHead sx={{ backgroundColor: '#021B26' }}>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} style={{ ...column.style }}>
                                            <Skeleton animation="wave" height={30} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: '#021B26' }}>{skeletonRows}</TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <MaterialReactTable table={table} />
                )}
            </Box>
        </>
    )

};

export default DealerWiseMismatch;
