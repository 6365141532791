import React from "react";
import {
  Area,
  AreaChart,
  Brush,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const DealerLineChart = ({ name, data }) => {

  // console.log(data);
  let xAxisKey, yAxisKey;

  if (data && data.length > 0) {
    const firstDataPoint = data[0];
    xAxisKey = Object.keys(firstDataPoint).find((key) => key !== "MarginUsed");
    // yAxisKey = "MarginUsed" || "LinePnl" ;

    yAxisKey = Object.keys(firstDataPoint).includes("BarPnl")
      ? "BarPnl"
      : "MarginUsed";
  }

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 className="text-center text-[12px] mb-2">{name}</h1>
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          data={data}
          isAnimationActive={false}
        >
          <defs>
            <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="0%" stopColor="#3b9599" stopOpacity="0.4" />
              <stop offset="100%" stopColor="#3b9599" stopOpacity="0" />
            </linearGradient>
          </defs>
          <XAxis
            dataKey='Time'
            tick={{
              fontSize: "10px",
              fill: "white",
              angle: -18,
              textAnchor: "end",
            }}
          />
          <YAxis tick={{ fontSize: "10px", fill: "white" }} />
          <Tooltip
            contentStyle={{
              background: "rgba(0, 0, 0, 0.6)",
              color: "white",
              borderRadius: "5px",
              border:"none",
              padding: "6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          />
          <Area
            type="monotone"
            dataKey={yAxisKey}
            stroke="#3b9599"
            fill="url(#gradient)"
          />
          {/* <Area
            type="monotone"
            dataKey='MarginUsed'
            stroke="#3b9599"
            fill="url(#gradient)"
          /> */}

          {/* <Brush height={11} stroke="black" fill="slategray" /> */}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DealerLineChart;
