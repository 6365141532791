import React, { useEffect, useState } from 'react';

// Define the PnlTable functional component
const PnlTable = ({ varValues, segmentVarValue }) => {
    // State to hold the admin VAR value
    const [adminVar, setAdminVar] = useState(null);

    // useEffect hook to extract and set the admin VAR value
    useEffect(() => {
        // Extracting the "admin" variable from varValues
        const newAdminVar = varValues?.find(item => 'admin' in item);
        const adminValue = newAdminVar.admin;
        setAdminVar(adminValue);
    }, [varValues]);

    // JSX to render the component
    return (
        <div className="overflow-hidden overflow-y-auto flex flex-col gap-3">
            {/* Admin VAR table */}
            <div className='min-w-full border border-[#307c9d] border-opacity-50 rounded-lg'>
                <table className="min-w-full bg-[#01161f] rounded-lg">
                    <thead>
                        <tr className="text-white">
                            <th className="px-4 py-2 text-left text-[13px] font-semibold w-[50%] rounded-lg whitespace-nowrap">Admin VAR:</th>
                            {/* Render admin VAR value if available */}
                            {adminVar ? (
                                <th className="px-4 py-2 text-center text-[12px] font-semibold w-[50%] rounded-lg">{adminVar}</th>
                            ) : null}
                        </tr>
                    </thead>
                </table>
            </div>

            {/* Dealer VAR tables */}
            {/* Table for regular dealers */}
            <div className='min-w-full border border-[#307c9d] border-opacity-50 rounded-lg max-h-[62.5%] overflow-hidden overflow-y-auto'>
                <table className="min-w-full">
                    <thead className='sticky top-0'>
                        <tr className="text-white bg-[#001b27]">
                            <th className="px-4 py-2 text-left text-[13px] font-semibold rounded-t-r-lg tracking-wider border-r border-[#307c9d] border-opacity-50">
                                DEALER
                            </th>
                            <th className="px-4 py-2 text-center text-[13px] font-semibold rounded-t-l-lg tracking-wider">VAR</th>
                        </tr>
                    </thead>
                    {/* Render table body for regular dealers */}
                    <tbody className='bg-[#01161f] z-[-10]'>
                        {varValues?.map((dealer, index) => {
                            const key = Object.keys(dealer)[0];
                            // Skip rendering for the 'admin' entry
                            if (key === 'admin') {
                                return null;
                            }
                            return (
                                <tr key={key} className='border-t border-t-[#307c9d] border-opacity-50'>
                                    <td className="px-4 py-1 text-left text-[12px] w-[50%] rounded-lg tracking-wider border-r border-r-[#307c9d] border-opacity-50 whitespace-nowrap">
                                        {key} {/* Accessing the dealer name */}
                                    </td>
                                    <td className="px-4 py-1 text-center text-[12px] w-[50%] rounded-lg tracking-wider">
                                        {dealer[key]} {/* Accessing the VAR value */}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            {/* Table for segment dealers */}
            <div className='min-w-full border border-[#307c9d] border-opacity-50 rounded-lg overflow-hidden overflow-y-auto'>
                <table className="min-w-full">
                    <thead className='sticky top-0'>
                        <tr className="text-white bg-[#001b27]">
                            <th className="px-4 py-2 text-left text-[13px] font-semibold rounded-t-r-lg tracking-wider border-r border-[#307c9d] border-opacity-50">
                                DEALER
                            </th>
                            <th className="px-4 py-2 text-center text-[13px] font-semibold rounded-t-l-lg tracking-wider">VAR</th>
                        </tr>
                    </thead>
                    {/* Render table body for segment dealers */}
                    <tbody className='bg-[#01161f] z-[-10]'>
                        {segmentVarValue?.map((segment, index) => (
                            <tr key={index} className='border-t border-t-[#307c9d] border-opacity-50'>
                                <td className="px-4 py-1 text-left text-[12px] w-[50%] rounded-lg tracking-wider border-r border-r-[#307c9d] border-opacity-50 whitespace-nowrap">
                                    {Object.keys(segment)[0]} {/* Accessing the dealer name */}
                                </td>
                                <td className="px-4 py-1 text-center text-[12px] w-[50%] rounded-lg tracking-wider">
                                    {segment[Object.keys(segment)[0]]} {/* Accessing the VAR value */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default PnlTable; 
