import React from 'react';
import axios from 'axios'

const api_server_url = process.env.REACT_APP_API_SERVER_URL;

const MarginTable = () => {
    const [tableData, setTableData] = React.useState([])
    // Sample data for demonstration
    React.useEffect(() => {
        const fetchData = () => {
            axios.post(`${api_server_url}/riskmargin/alldata`, { dealer_id: "all" }, { withCredentials: true })
                .then((res) => {
                    console.log(res.data)
                    setTableData(res.data)
                })
                .catch((error) => {
                    console.log('error in getallData route', error)
                })
        }
        fetchData();
    }, [])

    return (
        <div className="overflow-hidden overflow-y-auto flex flex-col gap-4 h-full">
            <table className="min-w-full  sticky top-0">
                <thead className="">
                    <tr className="border text-white border-[#307c9d] border-opacity-50  rounded-tr-lg rounded-tl-lg bg-[#001B27]">
                        <th className="px-[21px] py-1 text-center text-[13px] font-semibold rounded-tr-lg rounded-tl-lg uppercase tracking-wider border-opacity-50 border-r border-r-[#307c9d]" rowSpan="2">ADMIN</th>
                        <th className="px-4 py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">ON MARGIN</th>
                        <th className="px-4 py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">QTY TRADED</th>
                    </tr>
                    {tableData?.filter(dealer => dealer.dealer_id === "admin")?.map((dealer, idx) => (
                        <tr key={idx} className="border text-[12px] font-normal border-[#307c9d] border-opacity-50 bg-[#01161F]">
                            <th
                                className="px-4 py-1 text-[12px] font-normal whitespace-nowrap text-center tracking-wider border border-[#307c9d] border-opacity-50"
                                data-on-margin={dealer.on_margin}
                                data-on-margin-percent={dealer.on_margin_percent}
                                onMouseOver={(e) => e.target.innerText = e.target.dataset.onMargin ? e.target.dataset.onMargin : ''}
                                onMouseOut={(e) => e.target.innerText = e.target.dataset.onMarginPercent ? e.target.dataset.onMarginPercent : ''}
                            >
                                {dealer.on_margin_percent}
                            </th>
                            <th className="px-4 py-1 text-center text-[12px] font-normal tracking-wider border border-[#307c9d] border-opacity-50">{dealer?.qty_traded}</th>
                        </tr>
                    ))}
                </thead>

            </table>
            <div className='overflow-hidden overflow-y-auto border-[#307c9d] border-opacity-50 '>
                <table className="min-w-full overflow-hidden overflow-y-auto ">
                    <thead className=" sticky top-0 bg-[#001B27]">
                        <tr className="border text-white bg-[#001B27]">
                            <th className="px-4 py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50 rounded-tr-lg rounded-tl-lg">DEALER</th>
                            <th className="px-4 py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">ON MARGIN</th>
                            <th className="px-4 py-1 text-center text-[13px] font-semibold uppercase tracking-wider border border-[#307c9d] border-opacity-50">QTY TRADED</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-[#01161F] overflow-hidden">
                        {tableData?.filter(dealer => dealer.dealer_id !== "admin")?.map((dealer, idx) => (
                            <tr key={idx} className="border border-[#307c9d] border-opacity-50 bg-[#01161F]">
                                <td className="px-4 py-1 text-center text-[12px] font-normal tracking-wider rounded-tr-lg rounded-tl-lg border border-opacity-50 border-[#307c9d]">{dealer?.dealer_id}</td>
                                <td
                                    className="px-4 py-1 text-[12px] font-normal whitespace-nowrap text-center tracking-wider border border-[#307c9d] border-opacity-50"
                                    data-on-margin={dealer.on_margin}
                                    data-on-margin-percent={dealer.on_margin_percent}
                                    onMouseOver={(e) => e.target.innerText = e.target.dataset.onMargin ? e.target.dataset.onMargin : ''}
                                    onMouseOut={(e) => e.target.innerText = e.target.dataset.onMarginPercent ? e.target.dataset.onMarginPercent : ''}
                                >
                                    {dealer.on_margin_percent}
                                </td>
                                <td className="px-4 py-1 text-center text-[12px] font-normal tracking-wider border border-[#307c9d] border-opacity-50">{dealer?.qty_traded}</td>
                            </tr>
                        ))}
                        {(!tableData || tableData.filter(dealer => dealer.dealer_id !== "admin").length === 0) && (
                            <tr className="text-center text-gray-500 py-4">
                                <td colSpan="3">No data available</td>
                            </tr>
                        )}
                    </tbody>


                </table>
            </div>
        </div>
    );
}

export default MarginTable;