import React from 'react';
import { Layout } from 'antd';
import AlarmsData from './rightbarData/AlarmsData';
import RightBarData from './rightbarData/RightbarData';
import '../../../index.css'
const { Sider } = Layout;

const Rightbar = ({ collapsed, setCollapsed }) => {
  return (
    <div className='z-[10]'>
      <Layout style={{ backgroundColor: '#12394b' }} className={`alarm-scrollbar rightbar-layout ${collapsed ? 'rightbar-collapsed' : 'rightbar-expanded'} h-screen overflow-y-auto overflow-x-hidden scroll-smooth`}>
        <Sider
          collapsed={collapsed}
          collapsible
          trigger={null}
          theme='dark'
          width={350}
          collapsedWidth={0}
        >
          <AlarmsData collapsed={collapsed} setCollapsed={setCollapsed} />
          <RightBarData collapsed={collapsed} setCollapsed={setCollapsed} />
        </Sider>
      </Layout>
    </div>
  );
};

export default Rightbar;
