import React from 'react';
import HeaderTable from '../../components/HeaderTable';
import MainTable from '../../components/MainTable';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';

// Default headers and subheaders
const HeaderTableHeaders = [
  { id: 'total', label: 'Total', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, borderLeft: '1px solid #307C9D', width: '238px', fontSize: '12px' } },
  // { id: 'dealer_id', label: 'ID', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  // { id: 'dealer_name', label: 'Dealer Name', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'MarginUsed', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'AvgMargin', label: 'Avg Margin', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '150px', fontSize: '12px' } },
  { id: 'SigmaUse', label: 'Sigma Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'SigmaCost', label: 'Margin Benefit Cost', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'ROM', label: 'Rom', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '70px', fontSize: '12px' } },
  { id: 'TriggerHits', label: 'Trigger Hits', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'QtyTraded', label: 'Quantity Traded', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
];

const MainTableHeaders = [
  { id: 'rank', label: 'Rank', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '38px', fontSize: '12px' } },
  { id: 'DealerID', label: 'ID', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'Name', label: 'Name', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'MarginUsed', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'AvgMargin', label: 'Avg Margin', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '150px', fontSize: '12px' } },
  { id: 'SigmaUse', label: 'Sigma Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'SigmaCost', label: 'Margin Benefit Cost', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'ROM', label: 'Rom', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '70px', fontSize: '12px' } },
  { id: 'TriggerHits', label: 'Trigger Hits', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'QtyTraded', label: 'Quantity Traded', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
];

const subheaders = [
  { id: 'rank', label: 'Rank', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '38px', fontSize: '12px' } },
  { id: 'DealerID', label: 'ID', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'Month', label: 'Month', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'MarginUsed', label: 'Margin Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'AvgMargin', label: 'Avg Margin', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '150px', fontSize: '12px' } },
  { id: 'SigmaUse', label: 'Sigma Used', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'SigmaCost', label: 'Margin Benefit Cost', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'ROM', label: 'Rom', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '70px', fontSize: '12px' } },
  { id: 'TriggerHits', label: 'Trigger Hits', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
  { id: 'QtyTraded', label: 'Quantity Traded', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' } },
];


const ViewRange = ({ subtableData, mainTableData, headerTableData }) => {

  // const [headerTableData, setHeaderTableData] = React.useState([]);
  // const [mainTableData, setMainTableData] = React.useState([]);
  // const [isLoading, setIsLoading] = React.useState(true);
  // const [subtableData, setSubTableData] = React.useState([]);

  // const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  // React.useEffect(() => {
  //   const fetchData = () => {
  //     setIsLoading(true);
  //     // PortfolioUpdate
  //     if (startDate && endDate) {
  //       axios.post(`${api_server_url}/backoffice/margintable/all`, { range: "custom", custom_type: "range", range_type: 'calendar_day', start_day: startDate, end_day: endDate }, { withCredentials: true })
  //         .then((res) => {
  //           // months are in dealers list so need to map it here and extract the month from here
  //           const data = res.data;
  //           const month = data?.map((item) => (item?.Monthly || item?.Quarterly || item?.Yearly || item?.Daily));
  //           // console.log(month);
  //           // let monthId = month === 'Monthly' ? 'Month' : month === 'Quarterly' ? 'Quarter' : month === 'Daily' ? 'Day' : ''; // Dynamically set based on month
  //           // console.log(monthId);
  //           setSubTableData(month.slice(1));
  //           setHeaderTableData(data[0]);
  //           console.log(data[0])
  //           setMainTableData(data.slice(1));
  //           console.log(data.slice(1));
  //           setIsLoading(false);
  //         })
  //         .catch((error) => {
  //           console.log("error", error);
  //         })
  //         .finally(() => {
  //           setIsLoading(false);
  //         });

  //     }
  //   };

  //   fetchData();
  // }, [api_server_url, endDate, startDate]);

  return (
    <div className="flex flex-col gap-5">
      {/* {isLoading ? ( // Show skeleton if loading
        <Skeleton variant="rectangular" width="100%" height={200} />
      ) : (
        <> */}
      <HeaderTable headers={HeaderTableHeaders} data={headerTableData || []} />
      <MainTable headers={MainTableHeaders} data={mainTableData || []} subtableData={subtableData} subheaders={subheaders} />
      {/* </>
      )} */}
    </div>
  );
};

export default ViewRange;
