import React, { useEffect } from "react";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
  CartesianGrid,
  Area,
  Label,
  Line,
} from "recharts";

const Chart = ({ chartData, middle, exp }) => {
  useEffect(() => {
    return () => {};
  }, []);

  const gradientOffset = () => {
    const dataMax = Math.max(...chartData?.map((i) => i.y));
    const dataMin = Math.min(...chartData?.map((i) => i.y));

    if (dataMax <= 0) {
      return 0;
    }
    if (dataMin >= 0) {
      return 1;
    }

    return dataMax / (dataMax - dataMin);
  };

  const off = gradientOffset();

  return (
    <div style={{ width: "95%", height: "100%", paddingLeft: "10px" }}>
      <ResponsiveContainer>
        <ComposedChart
          style={{ zIndex: 1 }}
          width="100%"
          height="100%"
          data={chartData}
        >
          <CartesianGrid
            horizontal={false}
            stroke="white"
            strokeOpacity={0.3}
            strokeDasharray="2 2"
          />
          <XAxis
            dataKey="x"
            domain={["auto", "auto"]}
            interval={1}
            tick={{ fontSize: "10px", fill: "#828282" }}
          />
          <YAxis
            // dataKey="y"
            tickCount={10}
            // domain={["auto", "auto"]}
            tick={{ fontSize: "10px", fill: "#828282" }}
          />

          <ReferenceLine x={middle} stroke="white" strokeOpacity={0.6}>
            <Label
              value={middle}
              position="insideBottom"
              fill="white"
              fontSize={14}
              fontWeight={800}
            />
          </ReferenceLine>
          <ReferenceLine
            y={0}
            stroke="white"
            strokeOpacity={0.4}
            strokeDasharray="none"
          />
          <Tooltip
            content={(dataItem) => {
              if (dataItem.payload && dataItem.payload.length > 0) {
                const xValue = dataItem.payload[0].payload.x;
                const yValue = dataItem.payload[0].payload.y;
                const y_exp = dataItem.payload[0].payload.y_exp;

                const color =
                  yValue < 0 ? "rgba(255, 0, 0, 0.3)" : "rgba(0, 255, 0, 0.3)";

                return (
                  <div
                    style={{
                      background: color,
                      color: "white",
                      borderRadius: "5px",
                      padding: "3px",
                      fontSize: "13px",
                    }}
                  >
                    <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
                      Projection: {yValue?.toLocaleString("en-IN")}
                    </p>
                    <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
                      Payoff: {y_exp?.toLocaleString("en-IN")}
                    </p>
                    <p style={{ fontWeight: "bold", marginBottom: "2px" }}>
                      Spot: {xValue?.toLocaleString("en-IN")}
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />

          <defs>
            <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor="#205920" stopOpacity={0.5} />
              <stop offset={off} stopColor="#B03535" stopOpacity={0.5} />
            </linearGradient>
          </defs>
          <Line type="monotone" dataKey="y_exp" stroke="#ffffff8D" dot={false} />
          <Area
            type="monotone"
            dataKey="y"
            stroke="transparent"
            fill="url(#splitColor)"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
