import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import DealersLivePositions from "./DealersLivePositions";
import CummulativePositions from "./CummulativePositions";
import DealerWiseMismatch from "./DealerWiseMismatch";

export default function LivePositionsTab() {
  const location = useLocation();

  // const getTabClass = (tabName) => {
  //     const isActive = location.pathname.includes(tabName) || (location.pathname === '/livePositions' && tabName === 'Dealers');
  //     return `
  //     ${isActive ? 'bg-gray-800 text-white' : 'text-gray-500 hover:text-gray-200'}
  //         border-b-2 ${isActive ? 'border-[#3b9599]' : 'hover:border-gray-300'}
  //         rounded-t-lg px-4 py-2
  //         transition duration-300 ease-in-out
  //         font-semibold text-sm`;
  // };

  const getTabClass = (tabName) => {
    const isActive =
      location.pathname.includes(tabName) ||
      (location.pathname === "/livePositions" && tabName === "Dealers");
    return isActive ? "tab-link active" : "tab-link";
  };

  return (
    <div className="w-full mt-4 px-4 tabs tab-left-right">
      <nav className="rounded-lg navbar">
        <Link to="Dealers" className={getTabClass("Dealers")}>
          Dealerwise
          <span className="nav-item-before" />
        </Link>
        <Link to="Cumulative" className={getTabClass("Cumulative")}>
          Net Positions
          <span className="nav-item-before" />
        </Link>
        <Link to="DealerwiseMismatch" className={getTabClass("DealerwiseMismatch")}>
          Dealerwise Mismatch
          <span className="nav-item-before" />
        </Link>
        {/* <ul className="flex space-x-1 gap-5">
          <li>
            <Link to="Dealers" className={getTabClass("Dealers")}>
              Dealerwise
            </Link>
          </li>
          <li>
            <Link to="Cumulative" className={getTabClass("Cumulative")}>
              Net Positions
            </Link>
          </li>
        </ul> */}
      </nav>
      <Routes>
        <Route index element={<DealersLivePositions />} />
        <Route path="Dealers" element={<DealersLivePositions />} />
        <Route path="Cumulative" element={<CummulativePositions />} />
        <Route path="DealerwiseMismatch" element={<DealerWiseMismatch />} />
      </Routes>
    </div>
  );
}
