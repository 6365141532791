import React, { useEffect } from "react";
import TerminalBuy from "../TerminalBuy"; // Importing the TerminalBuy component
import axios from "axios"; // Importing Axios for making HTTP requests

// Define the TerminalTab functional component
const TerminalTab = () => {
  // Define state to store multileg data
  const [multilegData, setMultilegData] = React.useState("");
  // Get the API server URL from environment variables
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  // useEffect hook to fetch multileg data when the component mounts or when api_server_url changes
  useEffect(() => {
    // Function to fetch multileg data
    const fetchData = () => {
      axios
        .get(api_server_url + "/multileg") // GET request to fetch multileg data
        .then((res) => {
          console.log(res.data); // Logging the fetched data to the console
          setMultilegData(res.data); // Updating state with the fetched multileg data
        })
        .catch((error) => {
          console.log(error); // Logging errors, if any
        });
    };

    fetchData(); // Call the fetchData function
  }, [api_server_url]); // useEffect dependencies: api_server_url

  // JSX to render the TerminalBuy component with multilegData as prop
  return (
    <>
      {multilegData && <TerminalBuy multilegData={multilegData} />}
    </>
  );
};

export default TerminalTab; 
