import * as React from "react";
// import Box from "@mui/material/Box";
// import Modal from "@mui/material/Modal";
import axios from "axios";
// import CloseIcon from "@mui/icons-material/Close";
import { toast } from "sonner";
// import { AppBar, Toolbar } from "@mui/material";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// const style = {
//   position: "absolute",
//   top: "40%",
//   left: "53%",
//   transform: "translate(-50%, -50%)",
//   width: "90%",
//   bgcolor: "#001017",
//   borderRadius: "5px",
//   border: "1px solid #307C9D",
// };

// const appBarStyle = {
//   borderBottom: "1px solid #307C9D",
//   bgcolor: "#1d3d2f",
//   borderRadius: "5px 5px 0 0",
//   height: "35px",
// };


// Define the component
export default function TerminalBuy({ multilegData, setShowModal, showModal }) {
  // const handleClose = () => setShowModal(false);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

   // Define state variables using React hooks
  const [selectedInstrumentType, setSelectedInstrumentType] = React.useState(
    multilegData?.InstrumentType[0]
  );
  const [selectedExchgSeg, setSelectedExchgSeg] = React.useState(
    multilegData?.ExchangeSegment[0]
  );
  const [selectedUnderlying, setSelectedUnderlying] = React.useState(
    multilegData?.Underlyings[0]
  );
  const [selectedOptionType, setSelectedOptionType] = React.useState("CE");
  const [strikePrices, setStrikePrices] = React.useState([]);
  const [selectedStrikePrice, setSelectedStrikePrice] = React.useState(0);
  const [selectedExpiry, setSelectedExpiry] = React.useState(null);
  const [selectedExpiries, setSelectedExpiries] = React.useState([]);
  const [selectedPrice, setSelectedPrice] = React.useState();
  const [lotSize, setLotSize] = React.useState("0");
  const [validity, setValidity] = React.useState(multilegData?.Validity[0]);
  const [orderType, setOrderType] = React.useState(multilegData?.OrderType[0]);
  const [dealerList, setDealerList] = React.useState(multilegData?.DealerList);
  const [selectedDealer, setSelectedDealer] = React.useState(
    dealerList.length > 0 ? dealerList[0].id : null
  );
  const [selectedProductType, setSelectedProductType] = React.useState(
    selectedInstrumentType === "EQ"
      ? multilegData?.ProductTypeEQ[0]
      : multilegData?.ProductTypeFNO[0]
  );
  const [openDialogBoxBuy, setOpenDialogBoxBuy] = React.useState(false);
  const [openDialogBoxSell, setOpenDialogBoxSell] = React.useState(false);

  const options = multilegData?.Underlyings.map((option, index) => ({
    value: option,
    label: option,
  }));


  // Function to handle changes in the selected symbol
  const handleSymbolChange = (selectedOption) => {
    setSelectedUnderlying(selectedOption.value);
  };

  //   console.log(selectedExpiries);
  // console.log(multilegData?.ClientID);

  
  // Function to handle changes in the selected instrument type
  const handleInstrumentTypeChange = (value) => {
    setSelectedInstrumentType(value);
  };

  const handleProductTypeChange = (value) => {
    setSelectedProductType(value);
  };

  // const handleDealerChange = (value) => {
  //   setSelectedDealer(value);
  // };

  const dealerOptions = multilegData?.DealerList?.map((option) => ({
    value: option.id,
    label: `${option.id}`,
  }));

  const handleDealerChange = (e) => {
    const val = e?.map((i) => i.value);
    setSelectedDealer(val);
  };


  // Function to handle the "Buy" dialog box
  const handleDialogBoxBuy = () => {
    setOpenDialogBoxBuy(true);
  };


  // Function to handle the "Sell" dialog box
  const handleDialogBoxSell = () => {
    setOpenDialogBoxSell(true);
  };


  // Function to handle the buy action
  const handleBuy = async () => {
    var orders = selectedDealer?.map((dealerID) => {
      return {
        ExchangeSegment: selectedExchgSeg,
        Underlying: selectedUnderlying,
        ContractExpiration: selectedExpiry,
        OptionType: selectedOptionType,
        Strike: selectedStrikePrice,
        DealerID: dealerID,
        ClientID: multilegData.ClientID,
        ProductType: selectedProductType,
        OrderType: orderType,
        OrderSide: "BUY",
        Validity: validity,
        Quantity: lotSize,
        LimitPrice: selectedPrice,
        InstrumentType: selectedInstrumentType,
      };
    });
    try {
      const response = await axios.post(
        api_server_url + `/multileg`,
        orders
        // [
        //   {
        //     ExchangeSegment: selectedExchgSeg,
        //     Underlying: selectedUnderlying,
        //     ContractExpiration: selectedExpiries,
        //     OptionType: selectedOptionType,
        //     Strike: selectedStrikePrice,
        //     DealerID: selectedDealer,
        //     ClientID: multilegData.ClientID,
        //     ProductType: selectedProductType,
        //     OrderType: orderType,
        //     OrderSide: "BUY",
        //     Validity: validity,
        //     Quantity: lotSize,
        //     LimitPrice: selectedPrice,
        //     InstrumentType: selectedInstrumentType,
        //   },
        // ]
      );
      console.log("Post successful");
      setOpenDialogBoxBuy(false);
      toast.success("Success");
    } catch (error) {
      console.error("Error during POST request:", error.response.data);
      const errorMesage = error.response.data;
      setOpenDialogBoxBuy(false);
      toast.error(errorMesage);
    }
  };


  // Function to handle the sell action
  const handleSell = async () => {
    try {
      console.log(lotSize);
      const response = await axios.post(api_server_url + `/multileg`, [
        {
          ExchangeSegment: selectedExchgSeg,
          Underlying: selectedUnderlying,
          ContractExpiration: selectedExpiries,
          OptionType: selectedOptionType,
          Strike: selectedStrikePrice,
          DealerID: selectedDealer,
          ClientID: multilegData.ClientID,
          ProductType: selectedProductType,
          OrderType: orderType,
          OrderSide: "SELL",
          Validity: validity,
          Quantity: lotSize,
          LimitPrice: selectedPrice,
          InstrumentType: selectedInstrumentType,
        },
      ]);
      setOpenDialogBoxBuy(false);
      toast.success("Success");
    } catch (error) {
      console.error("Error during POST request:", error);
      const errorMesage = error.response.data;
      setOpenDialogBoxBuy(false);
      toast.error(errorMesage);
    }
  };

  // Function to fetch price data
  const handleFetch = async () => {
    try {
      const response = await axios.post(api_server_url + `/price`, {
        ExchangeSegment: selectedExchgSeg,
        Underlying: selectedUnderlying,
        ContractExpiration: selectedExpiry,
        OptionType: selectedOptionType,
        StrikePrice: selectedStrikePrice,
        InstrumentType: selectedInstrumentType,
      });
      // console.log("Fetch Successful");
      // console.log(response.data);
      setSelectedPrice(response.data);
    } catch (error) {
      console.error("Error in Fetch:", error);
    }
  };


  // Use effects to call putCall function when dependencies change
  React.useEffect(() => {
    setSelectedOptionType("CE");
  }, []);

  const putCall = () => {
    const putData = {
      ExchangeSegment: selectedExchgSeg,
      InstrumentType: selectedInstrumentType,
      Underlying: selectedUnderlying,
      OptionType: selectedOptionType,
    };
    axios
      .put(api_server_url + `/multileg`, putData)
      .then((res) => {
        // console.log("Put call");
        // console.log(res.data);
        setSelectedPrice(res.data.Price);
        setSelectedStrikePrice(res.data.AtmStrike);
        setStrikePrices(res.data.Strikes);
        setLotSize(res.data.LotSize);
        setSelectedExpiries(res.data.Expiries);
        // setSelectedExpiry(res.data.Expiries[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    if (
      selectedInstrumentType &&
      selectedExchgSeg &&
      selectedUnderlying &&
      selectedOptionType
    ) {
      putCall();
    }
  }, [
    selectedInstrumentType,
    selectedExchgSeg,
    selectedUnderlying,
    selectedOptionType,
  ]);

  return (
    <div className="border border-[#307C9D] border-opacity-50 rounded-md gap-6 p-2">
      {/* <Modal
        open={showModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > */}
      {/* <Box sx={style}> */}

      <div className="flex flex-col p-6 pt-4 pb-4 gap-1">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <label className="text-[14px] pl-1">Dealer</label>
            {/* <select
              className="border p-1 text-sm pr-4 w-[210px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              value={selectedDealer}
              onChange={(e) => handleDealerChange(e.target.value)}
            >
              {multilegData?.DealerList.map((option) => (
                <option key={option.id} value={option.id}>
                  {`${option.id} : ${option.name}`}
                </option>
              ))}
            </select> */}
            <Select
              options={dealerOptions}
              isMulti
              value={dealerOptions?.find(
                (option) => option.value === selectedDealer
              )}
              onChange={handleDealerChange}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "blue",
                  primary: "black",
                },
              })}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#081D26",
                  borderColor: "white",
                  borderStyle: "solid",
                  textAlign: "top",
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  color: "white",
                  textAlign: "center",
                }),
                input: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#081D26",
                  color: "white",
                }),
              }}
              className="w-[310px]"
            />
          </div>
          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Exchg-Seg</label>
            <select
              className="border text-sm p-1 pr-4 w-[140px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              value={selectedExchgSeg}
              onChange={(e) => setSelectedExchgSeg(e.target.value)}
            >
              {multilegData?.ExchangeSegment.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Order Type</label>
            <select
              className="border text-sm p-1 pr-4 w-[100px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              onChange={(e) => setOrderType(e.target.value)}
              value={orderType}
            >
              {multilegData?.OrderType.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-[14px] pl-1">Inst Name</label>

            <select
              className="border text-sm p-1 pr-4 w-[140px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              value={selectedInstrumentType}
              onChange={(e) => handleInstrumentTypeChange(e.target.value)}
            >
              {multilegData?.InstrumentType.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="text-[14px] pl-1">Symbol</label>
            <Select
              options={options}
              value={{
                value: selectedUnderlying,
                label: selectedUnderlying,
              }}
              onChange={handleSymbolChange}
              isSearchable={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "blue",
                  primary: "black",
                },
              })}
              styles={{
                control: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#081D26",
                  borderColor: "white",
                  borderStyle: "solid",
                }),
                singleValue: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
                input: (provided, state) => ({
                  ...provided,
                  color: "white",
                }),
                menu: (provided, state) => ({
                  ...provided,
                  backgroundColor: "#081D26",
                  color: "white",
                }),
              }}
              className="text-sm text-black w-[180px] cursor-pointer"
            />
          </div>

          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Opt Type</label>
            <select
              className="border p-1 text-sm pr-4 w-[90px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              disabled={
                selectedInstrumentType === "EQ" ||
                selectedInstrumentType === "FUTIDX" ||
                selectedInstrumentType === "FUTSTK"
              }
              value={selectedOptionType}
              onChange={(e) => setSelectedOptionType(e.target.value)}
            >
              {multilegData?.OptionType?.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="flex justify-around pt-6 pl-12 pr-12">
          <div className="flex flex-col">
            <label className="text-[14px] pl-1">Total Qty</label>
            <input
              className="border p-1 text-sm w-[90px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              value={lotSize}
              onChange={(event) => setLotSize(event.target.value)}
            ></input>
          </div>

          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Expiry Date</label>
            <select
              className="border p-1 text-sm pr-4 w-[120px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              disabled={selectedInstrumentType === "EQ"}
              value={selectedExpiry}
              onChange={(e) => setSelectedExpiry(e.target.value)}
            >
              {selectedExpiries?.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Strike Price</label>
            <select
              className="border p-1 text-sm pr-4 w-[140px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              disabled={selectedInstrumentType === "EQ"}
              value={selectedStrikePrice}
              onChange={(e) => setSelectedStrikePrice(e.target.value)}
            >
              {strikePrices?.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Prod Type</label>
            <select
              className="border p-1 text-sm pr-4 w-[130px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              value={selectedProductType}
              onChange={(e) => handleProductTypeChange(e.target.value)}
            >
              {selectedInstrumentType === "EQ"
                ? multilegData?.ProductTypeEQ.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))
                : multilegData?.ProductTypeFNO.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
            </select>
          </div>

          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Validity</label>
            <select
              className="border p-1 text-sm pr-4 w-[110px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              onChange={(e) => setValidity(e.target.value)}
              value={validity}
            >
              {multilegData?.Validity.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="flex flex-col ">
            <label className="text-[14px] pl-1">Price</label>
            <input
              className="border p-1 text-sm pr-4 w-[100px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
              value={selectedPrice}
              onChange={(e) => setSelectedPrice(e.target.value)}
            ></input>
          </div>

          <div className="pt-5">
            <button
              className="bg-[#03202D] text-[#93F2FF] pr-[20px] pl-[20px] pb-1 pt-1 h-[38px] rounded-md cursor-pointer "
              onClick={handleFetch}
              style={{ border: "1.2px solid #93F2FF", fontWeight: 600 }}
            >
              Fetch
            </button>
          </div>
        </div>
        <div className="flex gap-4 justify-center mt-8">
          <div className="pt-3">
            <button
              className="bg-[#1CA319] text-[#fff] pr-[25px] pl-[25px] pb-1 pt-1 h-[38px] rounded-md cursor-pointer hover:bg-[#43c340]"
              onClick={handleDialogBoxBuy}
              style={{ fontWeight: 600 }}
            >
              Buy
            </button>
          </div>

          <div className="pt-3">
            <button
              className="bg-[#A32626] text-[#fff] pr-[25px] pl-[25px] pb-1 pt-1 h-[38px] rounded-md cursor-pointer hover:bg-[#bf4646]"
              onClick={handleDialogBoxSell}
              style={{ fontWeight: 600 }}
            >
              Sell
            </button>
          </div>
        </div>
      </div>
      {/* </Box> */}
      {/* </Modal> */}
      <Dialog
        open={openDialogBoxBuy}
        onClose={() => setOpenDialogBoxBuy(false)}
        aria-labelledby="terminal-dialog-title"
        aria-describedby="terminal-dialog-description"
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#021B26" } }}
      >
        <DialogTitle id="terminal-dialog-title">{"Confirm Buy"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="terminal-dialog-description"
            sx={{ color: "white" }}
          >
            Are you sure you want to Buy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDialogBoxBuy(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleBuy}
            color="success"
            variant="outlined"
            autoFocus
          >
            Buy
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openDialogBoxSell}
        onClose={() => setOpenDialogBoxSell(false)}
        aria-labelledby="terminal-dialog-title"
        aria-describedby="terminal-dialog-description"
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#021B26" } }}
      >
        <DialogTitle id="terminal-dialog-title">{"Confirm Sell"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="terminal-dialog-description"
            sx={{ color: "white" }}
          >
            Are you sure you want to Sell?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setOpenDialogBoxSell(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSell}
            color="error"
            variant="outlined"
            autoFocus
          >
            Sell
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
