import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Colors } from "chart.js";
import { Pie, Doughnut } from "react-chartjs-2";
import seedrandom from "seedrandom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
// import { set } from 'lodash';

ChartJS.register(ArcElement, Tooltip, Colors);

const generateChartData = (data, label, colors) => {
  return {
    labels: Object.keys(data),
    datasets: [
      {
        // label: label,
        data: Object.values(data).map((item) => {
          if (label === "Margin Unused") {
            return item;
          } else if (label === "P&L") {
            return item.pnl;
          } else {
            return item.market_value;
          }
        }),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };
};
const generateDiffColors = (count, color) => {
  const shades = [];
  for (let i = 0; i < count; i++) {
    const shade = modifyColor(color, i / (count * 1.3));
    shades.push(shade);
  }
  return shades;
};

const generateRandomColors = (count, seed = "light") => {
  const rng = seedrandom(seed);
  const colors = [];
  const baseColor =
    "#" +
    Math.floor(rng() * 16777215)
      .toString(16)
      .padStart(6, "0");
  colors.push(baseColor);

  for (let i = 1; i < count; i++) {
    let newColor = baseColor;
    while (
      newColor === baseColor ||
      parseInt(newColor.substr(1), 16) < 0x888888
    ) {
      // Ensure the new color is different from the base color and not too dark
      newColor =
        "#" +
        Math.floor(rng() * 16777215)
          .toString(16)
          .padStart(6, "0");
    }
    colors.push(newColor);
  }

  return colors;
};

const generateColors = (data) => {
  const totalDataPoints = Object.keys(data).length;
  const shades = generateShades("#00FF00", totalDataPoints);
  return shades;
};

const generateShades = (baseColor, count) => {
  const shades = [];
  for (let i = 0; i < count; i++) {
    const shade = modifyColor(baseColor, i / count);
    shades.push(shade);
  }
  return shades;
};

const modifyColor = (color, factor) => {
  const hex = (color) => ("0" + Math.floor(color).toString(16)).slice(-2);
  const rgb = color.match(/\w\w/g).map((x) => parseInt(x, 16));
  const modifiedColor = `#${hex(rgb[0] * (1 - factor))}${hex(
    rgb[1] * (1 - factor)
  )}${hex(rgb[2] * (1 - factor))}`;
  return modifiedColor;
};

const sortUnderlyingsByPnl = (underlyings) => {
  return Object.fromEntries(
    Object.entries(underlyings).sort(([, a], [, b]) => b.pnl - a.pnl)
  );
};

const MarginPieAdmin = () => {
  // const data = {
  //   margin: [
  //     { name: 'Used', value: 30.0 },
  //     { name: 'Unused', value: 70.0 },
  //   ],
  //   underlyings: {
  //     RELIANCE: { market_value: 521290, pnl: 6424 },
  //     TATA: { market_value: 350000, pnl: -5000 },
  //     INFY: { market_value: 150000, pnl: -2000 },
  //     GOOGLE: { market_value: 800000, pnl: 12000 },
  //     AMAZON: { market_value: 700000, pnl: -8000 },
  //     APPLE: { market_value: 600000, pnl: 15000 },
  //     MICROSOFT: { market_value: 900000, pnl: -10000 },
  //     FACEBOOK: { market_value: 750000, pnl: 5000 },
  //     IBM: { market_value: 400000, pnl: 3000 },
  //     TESLA: { market_value: 950000, pnl: -20000 },
  //   },
  // };

  const [chartData, setChartData] = useState();
  const [dealer, setDealer] = useState("admin");
  const [allDealers, setAllDealers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dataAvailable, setDataAvailable] = useState(true);

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        api_server_url + "/dealer/piechart",
        { dealer_id: dealer },
        { withCredentials: true }
      );
      // console.log(response.data);
      setChartData(response.data);

      const responseDealer = await axios.get(
        api_server_url + "/dealer/piechart",
        { withCredentials: true }
      );
      // console.log(responseDealer.data);
      setAllDealers(responseDealer.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      if (error.response.status === 500) {
        setDataAvailable(false);
      }
    }
  };

  const handleClient = (event) => {
    // console.log(event.target.value);
    setDealer(event.target.value);
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(intervalId);
  }, [dealer]);

  const chartOptions = {
    rotation: 180,
    // animation: true,
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 15,
        fontColor: "black",
      },
    },
  };

  if (!dataAvailable) {
    return (
      <div className="flex items-center justify-center h-full text-sm text-gray-500">
        <p>Data not available.</p>
      </div>
    );
  }

  if (!chartData) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  const marginColors = ["#1D634A", "#2C775C", "#439376", "#66B095", "#8ECCB5"];
  const marginChartData = generateChartData(
    chartData.margin,
    "Margin Unused",
    marginColors
  );
  // console.log(Object.keys(chartData.margin));

  const positivePnlCount = Object.values(chartData.underlyings ?? {}).filter(
    (item) => item.pnl >= 0
  ).length;

  // Replace shades of green with shades of red
  const pnlPositiveColors = ["#3B621B", "#76A84D", "#96C570"];

  const pnlNegativeColors = generateDiffColors(
    chartData.underlyings
      ? Object.keys(chartData.underlyings).length - positivePnlCount
      : 0,
    "#223D0C"
  ); // Red shades for negative P&L

  const pnlColors = [
    ...pnlPositiveColors.slice().reverse(),
    ...pnlNegativeColors,
  ];
  const pnlChartData = generateChartData(
    sortUnderlyingsByPnl(chartData.underlyings),
    "P&L",
    pnlColors
  );

  // const underlyingColors = generateRandomColors(Object.keys(chartData.underlyings).length, "a");
  // console.log("Length ..............", chartData.underlyings);
  const underlyingColors = generateDiffColors(
    Object.keys(chartData.underlyings).length,
    "#5db3d6"
  );
  const underlyingChartData = generateChartData(
    chartData.underlyings,
    "Market Value",
    underlyingColors
  );

  // console.log(marginChartData);
  // console.log(pnlChartData);
  // console.log(underlyingChartData);

  // useEffect(() => {
  //   // Sort underlyings by P&L before updating the chart data
  //   // setChartData((prevData) => ({
  //   //   ...prevData,
  //   //   underlyings: ,
  //   // }));z
  // }, []);

  return (
    <div className="flex flex-col">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "29vw",
            height: "24vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Doughnut data={marginChartData} options={chartOptions} />
        </div>
      </div>
    </div>
  );
};

export default MarginPieAdmin;
