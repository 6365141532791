import React, { useEffect } from "react";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import axios from "axios";
import OptionChainTable from "./OptionChainTable";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

// OptionChainHeader Component: Responsible for rendering the header section of the Option Chain feature
const OptionChainHeader = () => {
  const [underlyings, setUnderlyings] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [selectedUnderlying, setSelectedUnderlying] = React.useState([]);
  const [expiry, setExpiry] = React.useState([]);
  const [selectedExpiry, setSelectedExpiry] = React.useState("");
  const [tableData, setTableData] = React.useState([]);
  const [spot, setSpot] = React.useState([]);
  const [lastRefreshTime, setLastRefreshTime] = React.useState(
    new Date().toLocaleTimeString()
  );
  const [strikeDistance, setStrikeDistance] = React.useState();
  const [atm, setAtm] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  // const [selectedTerminal, setSelectedTerminal] = React.useState("");
  // const [multilegData, setMultilegData] = React.useState("");
  // const [showModal, setShowModal] = React.useState(false);
  const [dataChange, setDataChange] = React.useState(false);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;


  // Function to fetch data from the server
  const fetchData = async () => {
    try {
      // console.log("sending post in refresh")
      const response = await axios.post(api_server_url + "/optionchain", {
        Underlying: selectedUnderlying,
        ContractExpiration: selectedExpiry,
      });
      setIsLoading(false);
      setTableData(response.data);
      setSpot(response.data[0]?.Spot);
      setStrikeDistance(response.data[0]?.StrikeDist);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  // Function to handle refresh button click
  const handleRefreshClick = () => {
    setLastRefreshTime(new Date()?.toLocaleTimeString());
    fetchData();
  };

  // Function to calculate ATM (At-The-Money) value
  const calculateAtm = (spot, strikeDistance) => {
    if (spot && strikeDistance) {
      const calculatedAtm = Math.round(spot / strikeDistance) * strikeDistance;
      const calculatedAtmString = calculatedAtm.toString();
      setAtm(calculatedAtmString);
    }
  };

  // Function to handle change in underlying or expiry dropdown
  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "underlying") {
      setSelectedUnderlying(value);
      setExpiry(data[value]);
      setSelectedExpiry(data[value][0]);
    } else if (name === "expiry") {
      setSelectedExpiry(value);
    }
  };


  // Effect hook to fetch initial data and set up interval for auto-refresh
  useEffect(() => {
    axios.get(api_server_url + "/optionchain").then((res) => {
      setData(res.data);

      const underlyingsValue = Object.keys(res.data);
      setUnderlyings(underlyingsValue);
      setSelectedUnderlying(underlyingsValue[0]);

      const expiryValue = res.data[underlyingsValue[0]];
      setExpiry(expiryValue);
      setSelectedExpiry(expiryValue[0]);

      axios
        .post(api_server_url + "/optionchain", {
          Underlying: underlyingsValue[0],
          ContractExpiration: expiryValue[0],
        })
        .then((res) => {
          setIsLoading(false);
          setTableData(res.data);
          setSpot(res.data[0]?.Spot);
          setStrikeDistance(res.data[0]?.StrikeDist);
          calculateAtm(res.data[0]?.Spot, res.data[0]?.StrikeDist);
          setDataChange(true);
        });
    });

    setLastRefreshTime(new Date()?.toLocaleTimeString());
  }, [api_server_url]);


  // Effect hook to fetch data on selectedUnderlying or selectedExpiry change
  useEffect(() => {
    const fetchData = (scroll = false) => {
      // console.log(selectedExpiry);
      axios
        .post(api_server_url + `/optionchain`, {
          Underlying: selectedUnderlying,
          ContractExpiration: selectedExpiry,
        })
        .then((res) => {

          setTableData(res.data);
          setSpot(res.data[0]?.Spot);
          setStrikeDistance(res.data[0]?.StrikeDist);
          calculateAtm(res.data[0]?.Spot, res.data[0]?.StrikeDist);
          handleRefreshClick();
          setDataChange(scroll)
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData(true);
    const intervalId = setInterval(fetchData, 10000);

    return () => clearInterval(intervalId);
  }, [selectedUnderlying, selectedExpiry, api_server_url]);

  // const handleTerminalChange = (e) => {
  //   // console.log(e);
  //   setSelectedTerminal(e);

  //   axios
  //     .get(api_server_url + "/multileg")
  //     .then((res) => {
  //       // console.log(res.data);

  //       setMultilegData(res.data);

  //       setShowModal(true);
  //     })
  //     .catch((e) => {
  //       console.log("Error: " + e.message);
  //     });
  // };

  
// Render the component
  return (
    <div className="w-full border border-[#307C9D] border-opacity-50 rounded-md pt-2">
      <div className="pl-2 pb-2 pt-1 pr-0 flex justify-between">
        <div className="flex justify-between">
          <FormControl style={{ width: "180px" }}>
            <Select
              labelId="underlying-label"
              id="underlying"
              name="underlying"
              size="small"
              value={selectedUnderlying}
              onChange={(e) => handleChange(e)}
              className="bg-transparent hover:border-gray-100 focus:outline-none cursor-pointer"
              style={{ height: "35px" }} 
            >
              {underlyings?.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div className="pl-3">
            <FormControl style={{ width: "140px" }}>
              <Select
                labelId="expiry-label"
                id="expiry"
                name="expiry"
                size="small"
                value={selectedExpiry}
                onChange={(e) => {
                  setSelectedExpiry(e.target.value);
                  handleChange(e); // Call handleChange function
                }}
                className="bg-transparent hover:border-gray-100 focus:outline-none cursor-pointer"
                style={{ height: "35px" }} 
              >
                {expiry?.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="pl-3">
            <p className="p-2 pl-5 pr-5 bg-gray-600 rounded-[4px] h-[35px] flex items-center justify-center font-bold opacity-[80%]">
              {spot}
            </p>
          </div>

          <div className="pl-3">
            <p className="p-2 bg-gray-600 rounded-[4px] w-[150px] h-[35px] flex items-center justify-center opacity-[80%]">
              <AccessTimeIcon />
              <span className="pr-2"></span>
              {lastRefreshTime}
            </p>
          </div>

          <div className="pl-3">
            <button onClick={handleRefreshClick}>
              <AutorenewIcon
                fontSize="large"
                className="bg-[#307c9dc3] rounded-[5px] p-1"
                style={{ height: "35px", width: "35px" }}
              />
            </button>
          </div>
        </div>

        {/* {selectedTerminal === "buy" && showModal && (
          <TerminalBuy
            multilegData={multilegData}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        )}

        {selectedTerminal === "sell" && showModal && (
          <TerminalSell
            multilegData={multilegData}
            setShowModal={setShowModal}
            showModal={showModal}
          />
        )} */}

        {/* <div style={{ display: "flex" }}>
          <button
            className="flex items-center justify-center p-2 w-[65px] h-[35px] rounded-l-sm transition-all bg-transparent border-t border-b border-l border-[#54c652] text-[#54c652] font-bold hover:text-black hover:bg-[#54c652]"
            onClick={() => handleTerminalChange("buy")}
          >
            Buy
          </button>
          <button
            className="flex items-center justify-center p-2 w-[65px] h-[35px] rounded-r-sm transition-all bg-transparent border border-[#ff6565] text-[#ff6565] font-bold hover:text-black hover:bg-[#ff6565]"
            onClick={() => handleTerminalChange("sell")}
          >
            Sell
          </button>
        </div> */}
      </div>

      <div>
        <OptionChainTable
          tableData={tableData}
          atm={atm}
          selectedExpiry={selectedExpiry}
          selectedUnderlying={selectedUnderlying}
          isLoading={isLoading}
          dataChange={dataChange}
        />
      </div>
    </div>
  );
};

export default OptionChainHeader;
