import React from 'react';
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import AllChartsAdmin from '../admin/AllChartAdmin';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DateSelected from './SelectedDate';
import SelectedDateBetween from './SelectedDateBetween';

const CustomToggleButton = ({ setRangeType, rangeType, setCustomToggle, customToggle, setOpenChart, openChart, date, setDate, startDate, setStartDate, setEndDate, endDate }) => {
    const handleChartOpen = () => {
        setOpenChart(true);
    };
    return (
        !openChart ? (
            <div className='flex justify-between mb-5'>
                <div className="flex justify-start">
                    <ToggleButtonGroup
                        color="primary"
                        value={customToggle}
                        exclusive
                        onChange={(e) => setCustomToggle(e.target.value)}
                        aria-label="Platform"
                    >
                        <ToggleButton value="date" sx={{ px: 1, py: 0.5, fontSize: '10px' }}>View Date</ToggleButton>
                        <ToggleButton value="range" sx={{ px: 1, py: 0.5, fontSize: '10px' }}>View Range</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                {customToggle === "date" ? (
                    // <div className="date-selected-container">
                    <DateSelected date={date} setDate={setDate} />
                    // </div>
                ) : (
                    // <div className="date-selected-container">
                    <SelectedDateBetween setRangeType={setRangeType} startDate={startDate} setStartDate={setStartDate} setEndDate={setEndDate} endDate={endDate} />
                    // </div>
                )}
                <button className='bg-gray-800 p-1' onClick={handleChartOpen}><ShowChartIcon fontSize='small' /></button>
            </div>
        ) : (
            <AllChartsAdmin
                setShowGraphs={setOpenChart}
                requestData={
                    customToggle === "date"
                        ? { range: "custom", custom_type: "date", date: date }
                        : { range: "custom", custom_type: "range", range_type: rangeType, start_day: startDate, end_day: endDate }
                }
            />

        )
    );
};

export default CustomToggleButton;
