import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import CurrentTime from './CurrentTime';
import { Tooltip } from '@mui/material';
import CurrentDate from './CurrentDate';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Logout from '../../pages/login/Logout';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { io } from 'socket.io-client';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

export function Navbar({ collapsed, setCollapsed, isLoggedIn, setIsLoggedIn }) {
    const location = useLocation();
    const [stockData, setStockData] = useState({});
    const indexesToShow = [
        { index1: 'NIFTY', index2: 'BANKNIFTY' },
        { index1: 'MIDCAP', index2: 'FINNIFTY' },
    ];
    const [currentIndex, setCurrentIndex] = useState(0);
    const handleClick = () => {
        setCollapsed(prevCollapsed => !prevCollapsed);
    };

    useEffect(() => {
        const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;
        const socket = io(socket_server_url + '/testIndexValues');
        socket.on('connect', () => console.log('Connected to Index socket server'));
        socket.on("disconnect", () => console.log("Disconnected from Index socket server"));

        socket.on('test-indexvalues', (data) => {
            setStockData(data);
            // console.log(data);
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        setCurrentIndex(prevIndex => (prevIndex + 1) % indexesToShow.length);
    }, [indexesToShow.length, stockData]);

    // useEffect(() => {
    //     const keys = Object.keys(stockData);
    //     if (keys.includes('NIFTY') && keys.includes('BANKNIFTY') && keys.includes('FINNIFTY') && keys.includes('SENSEX')) {
    //         setIndexesToShow();
    //     }
    // }, [stockData]);

    const getPageName = () => {
        const path = location.pathname.split('/').filter(Boolean);
        const pageNames = {
            'orderBook': 'Order Book',
            'sigmaOptimizer': 'Sigma Optimizer',
            'livePositions': 'Live Positions',
            'dealerwiseMismatch': 'DealerwiseMismatch',
            'datewiseposition': 'Datewise Position',
            'optionsChain': 'Option Chain',
            'dashboard': 'Statistics',
            'riskmanagement': 'Risk Stats',
            'backoffice': 'Backoffice',
        };
        return pageNames[path[0]] || 'Dashboard';
    };
    const currentPageName = getPageName(location.pathname);

    return (
        <nav className="bg-[#1B4152] text-white flex items-center justify-between sticky top-0 z-20 h-[3.56rem] w-full min-w-[1024px]">
            <div className='flex w-[50%] justify-between items-center'>
                <div className='ml-3 flex gap-2 text-start items-center justify-start whitespace-nowrap text-xs w-[26.50rem] h-[3.56rem]'>
                    {indexesToShow.length > 0 && (
                        <>
                            <div className='flex text-[0.88rem] gap-[0.7rem] bg-[rgba(1,31,44,.31)] h-[2.94rem] w-[13.13rem] rounded-[0.38rem] pl-[0.5625rem] pt-[0.4375rem] pr-[0.5625rem] pb-[0.4375rem]'>                            <div className='flex'>

                                <p className='flex'>

                                    {stockData[indexesToShow[currentIndex]?.index1]?.absolute_change >= 0 ? (
                                        <PlayArrowRoundedIcon style={{ color: '#97FF95', transform: 'rotate(-90deg)' }} fontSize='small' />
                                    ) : (
                                        <PlayArrowRoundedIcon style={{ color: '#FF8585', transform: 'rotate(90deg)' }} fontSize='small' />
                                    )}
                                </p>
                                <div className='flex flex-col w-[6.31rem] gap-1'>
                                    <h1 className='text-[0.9rem] font-bold text-start'>{indexesToShow[currentIndex]?.index1}</h1>
                                    <p className='text-[0.9rem]'>{stockData[indexesToShow[currentIndex]?.index1]?.current_price || '0.00'}</p>
                                </div>
                            </div>
                                <div className='flex flex-col w-full items-center justify-end p-0' style={{ color: stockData[indexesToShow[currentIndex]?.index1]?.absolute_change >= 0 ? '#97FF95' : '#FF8585' }}>
                                    <p className='font-semibold w-full' style={{ textAlign: 'right' }}>{stockData[indexesToShow[currentIndex]?.index1]?.absolute_change || '0.00'}</p>
                                    <p className='font-semibold w-full' style={{ textAlign: 'right' }}>({stockData[indexesToShow[currentIndex]?.index1]?.percentage_change || '0.00'})</p>
                                </div>
                            </div>
                            <div className='h-8 border-[0.5px] border-[#307c9d]'></div>
                            <div className='flex text-[0.88rem] gap-[0.7rem] bg-[rgba(1,31,44,.31)] h-[2.94rem] w-[13.13rem] rounded-[0.38rem] pl-[0.5625rem] pt-[0.4375rem] pr-[0.5625rem] pb-[0.4375rem]'>
                                <div className='flex'>
                                    <p className='flex'>
                                        {stockData[indexesToShow[currentIndex]?.index2]?.absolute_change >= 0 ? (
                                            <PlayArrowRoundedIcon style={{ color: '#97FF95', transform: 'rotate(-90deg)' }} fontSize='small' />
                                        ) : (
                                            <PlayArrowRoundedIcon style={{ color: '#FF8585', transform: 'rotate(90deg)' }} fontSize='small' />
                                        )}
                                    </p>
                                    <div className='flex flex-col w-[6.31rem] gap-1'>
                                        <h1 className='text-[0.9rem] font-bold text-start'>{indexesToShow[currentIndex]?.index2}</h1>
                                        <p className='text-[0.9rem]'>{stockData[indexesToShow[currentIndex]?.index2]?.current_price || '0.00'}</p>
                                    </div>
                                </div>
                                <div className='flex flex-col w-full items-center justify-end p-0' style={{ color: stockData[indexesToShow[currentIndex]?.index2]?.absolute_change >= 0 ? '#97FF95' : '#FF8585' }}>
                                    <p className='font-semibold w-full' style={{ textAlign: 'right' }}>{stockData[indexesToShow[currentIndex]?.index2]?.absolute_change || '0.00'}</p>
                                    <p className='font-semibold w-full' style={{ textAlign: 'right' }}>({stockData[indexesToShow[currentIndex]?.index2]?.percentage_change || '0.00'})</p>
                                </div>
                            </div>
                            {/* <div key={indexesToShow[currentIndex]?.index1} className='transition-all ease-in-out duration-1000 flex flex-col gap-[55px] items-center justify-center'>

                            <div className='flex items-center justify-start gap-1 '>


                            </div>
                        </div>
                        <div key={indexesToShow[currentIndex]?.index2} className='transition-all ease-in-out duration-1000 flex gap-[70px] items-center justify-center'>
                            <h1 className='w-[10px] font-bold text-start'>{indexesToShow[currentIndex]?.index2}</h1>
                            <div className='flex items-center justify-start gap-1'>
                                <p className='font-medium'>{stockData[indexesToShow[currentIndex]?.index2]?.current_price || '0.00'}</p>
                                <div className='flex gap-1 items-center justify-start' style={{ color: stockData[indexesToShow[currentIndex]?.index2]?.absolute_change >= 0 ? '#97FF95' : '#FF6565' }}>
                                    <p className='font-medium'>{stockData[indexesToShow[currentIndex]?.index2]?.absolute_change || '0.00'}</p>
                                    
                                </div>
                            </div>
                        </div> */}
                        </>
                    )}
                </div>
                <div className="text-sm font-bold">{currentPageName}</div>
            </div>
            <div className='mr-9 flex gap-10 items-center mx-auto justify-center text-center'>
                <div className='flex flex-col'>
                    <CurrentDate />
                    <CurrentTime />
                </div>
                <Logout setIsLoggedIn={setIsLoggedIn} />
                <Tooltip title="Alarms" placement='bottom-start'>
                    <Link to='/alarmsPage'><NotificationsActiveIcon sx={{ fontSize: '24px' }} /></Link>
                </Tooltip>
                <div className='z-10'>
                    <Tooltip title="Market Overview" placement='bottom-start'>
                        <Button
                            className={`sidebar-transition shadow-2xl toggle2`}
                            onClick={handleClick}
                            icon={collapsed ? <KeyboardDoubleArrowLeftIcon sx={{ fontSize: '24px' }} /> : <KeyboardDoubleArrowRightIcon sx={{ fontSize: '24px' }} />}
                        />
                    </Tooltip>
                </div>
            </div>
        </nav>
    );
}
