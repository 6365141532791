import React from 'react';
import CustomToggleButton from './Custom/CustomToggleButton';
import ViewDate from './Custom/ViewDate'
import ViewRange from './Custom/ViewRange'


const Custom = ({ dateSelected }) => {
    const [customToggle, setCustomToggle] = React.useState('date');
    const [openChart, setOpenChart] = React.useState(false);
    const [date, setDate] = React.useState();
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState()
    const [rangeType, setRangeType] = React.useState();
    
    return (
        <div className={openChart ? '' : 'p-2 border border-[#307C9D] border-opacity-50  rounded-lg h-[78vh]'}>
            <CustomToggleButton setRangeType={setRangeType} rangeType={rangeType} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} setDate={setDate} date={date} setOpenChart={setOpenChart} openChart={openChart} setCustomToggle={setCustomToggle} customToggle={customToggle} />
            {!openChart && (
                <>
                    {customToggle === "date" ? <ViewDate date={date} /> : <ViewRange endDate={endDate} startDate={startDate} />}
                </>
            )}
        </div>
    );
}

export default Custom;