import React from "react";
import { Doughnut } from "react-chartjs-2";

function transformData(originalData, colors) {
  const filteredData = originalData?.filter(item => item?.Segment !== 'Total');

  const labels = originalData?.map((item) => item?.Segment || item?.WeekDay || item?.DOW || item?.Segment);
  const dataValues = originalData?.map((item) => item?.Margin || item?.MarginUsed || item?.Pnl || item?.dayPnl);

  return {
    labels: labels,
    datasets: [{
      data: dataValues,
      backgroundColor: colors,
      borderWidth: 0,
    }],
    colors: colors, // Add colors to the returned object
  };
}

function PieChart({ originalData, colors, name }) {

  // console.log(originalData?.map((item) => item?.Pnl))
  const transformedData = transformData(originalData, colors);

  // console.log(transformedData)

  return (
    <div
      className="pie-chart-container flex gap-5 flex-col"
      style={{ width: "100%", height: "100%" }}
    >
      <h1 className="text-[12px] text-center">{name}</h1>
      <div className='flex justify-between'>
        <div className="w-[50%]">
          <Doughnut
            data={transformedData}
            options={{
              plugins: {
                legend: {
                  position: "right",
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      const label = context?.chart?.data?.labels[context?.dataIndex];
                      const value = context?.chart?.data?.datasets[0]?.data[context?.dataIndex]?.toFixed(2);
                      return `${label}: ${value}`;
                    },
                  },
                },
              },
              maintainAspectRatio: false,
            }}
          />
        </div>
        <div className="w-[70%] pl-2 flex flex-col justify-center h-full">
          <ul className="w-full flex flex-col justify-center gap-2">
            {transformedData?.labels?.map((label, index) => (
              <li className="text-xs flex items-center gap-3" key={index}>
                <span className="h-2 w-2 rounded-full" style={{ backgroundColor: transformedData.colors[index] }}></span> {/* Use colors[index] for the background color */}
                <div className="flex w-full items-center justify-between ">
                  <span>{label}:</span>
                  <span className="flex justify-start text-[11px]">{transformedData?.datasets[0]?.data[index]?.toFixed(2)}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PieChart;
