// import React from "react";
// import {
//   BarChart,
//   Bar,
//   XAxis,
//   YAxis,
//   Tooltip,
//   ReferenceLine,
//   ResponsiveContainer,
// } from "recharts";

// const DealerMultipleBarChart = ({ data, name }) => {
//   if (!data || !data.data || !data.data.length) {
//     return null;
//   }

//   const xAxisDataKey = Object.keys(data.data[0]).find(
//     (key) => key.includes("Month") || key.includes("Segment")
//   );
//   const dynamicYAxisDataKey = data?.datakey;
//   console.log(xAxisDataKey)
//   console.log(dynamicYAxisDataKey)

//   return (
//     <div style={{
//       width: "100%",
//       height: "100%",
//       display: "flex",
//       justifyContent: "center",
//       alignItems: "center",
//       flexDirection: "column",
//     }}>
//       <div>
//         <h1 className="flex-grow text-center text-[12px] mb-2">{name}</h1>
//       </div>

//       <ResponsiveContainer width="100%" height="100%">
//         <BarChart data={data.data}>
//           <XAxis
//             // dataKey={xAxisDataKey}
//             dataKey = 'Time'
//             tick={{
//               fontSize: "10px",
//               fill: "white",
//               angle: -12,
//               textAnchor: "end",
//             }}
//           />
//           <YAxis
//             tick={{
//               fontSize: "10px",
//               fill: "white",
//             }}
//           />
//           <Tooltip
//             contentStyle={{
//               background: "rgba(0, 0, 0, 0.9)",
//               color: "white",
//               border: "none",
//               borderRadius: "5px",
//               padding: "6px",
//               fontSize: "12px",
//               fontWeight: "bold",
//             }}
//           />
//           <ReferenceLine y={0} stroke="#ccc" />
//           {dynamicYAxisDataKey?.map((item) => (
//             <Bar
//               dataKey={item}
//               fill="#0a0a"
//               barSize={5}
//               radius={[3, 3, 0, 0]}
//             />
//           ))}
//         </BarChart>
//       </ResponsiveContainer>
//     </div>
//   );
// };

// export default DealerMultipleBarChart;



import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const DealerMultipleBarChart = ({ data, name }) => {
  if (!data || !data.data || !data.data.length) {
    return null;
  }

  // Extract dynamic keys excluding "Time"
  const dynamicYAxisDataKey = Object.keys(data.data[0]).filter(
    (key) => key !== "Time"
  );

  return (
    <div style={{
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    }}>
      <div>
        <h1 className="flex-grow text-center text-[12px] mb-2">{name}</h1>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <BarChart data={data.data}>
          <XAxis
            dataKey="Time"
            tick={{
              fontSize: "10px",
              fill: "white",
              angle: -12,
              textAnchor: "end",
            }}
          />
          <YAxis
            tick={{
              fontSize: "10px",
              fill: "white",
            }}
          />
          <Tooltip
            contentStyle={{
              background: "rgba(0, 0, 0, 0.9)",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          />
          <ReferenceLine y={0} stroke="#ccc" />
          {dynamicYAxisDataKey.map((item, index) => (
            <Bar
              key={index}
              dataKey={item}
              fill={data.datakey[item]}
              barSize={8} // Adjust the bar size as needed
              radius={[3, 3, 0, 0]}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default DealerMultipleBarChart;
