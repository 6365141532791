import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import io from "socket.io-client";
import { throttle } from 'lodash';
import { Tooltip } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { toast } from 'sonner';
import axios from 'axios';

const headers = [
  { id: 'dealer_id', label: 'ID', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5, borderLeft: '1px solid rgba(81, 81, 81, 1)' } },
  { id: 'trade_limit_day', label: 'Trade Lmt/d', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5, width: '10px', whiteSpace: 'wrap' } },
  { id: 'qty_limit_day', label: 'Qty Lmt/d', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5 } },
  { id: 'trade_limit_sec', label: 'Trade Lmt/s', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5 } },
  { id: 'max_value_symbol', label: 'Max Val of Sym', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5, width: '150px' } },
  { id: 'max_opt_val', label: 'Max Opt Net Val', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5, width: '150px', whiteSpace: 'wrap' } },
  { id: 'max_fut_val', label: 'Max Fut Net Val', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5 } },
  // { id: 'max_position_val', label: 'Max Lots/Pos', style: { pl: 0.5, pr: 0, pt: 0.5, pb: 0.5 } },
  { id: 'max_lots_order', label: 'Max Lots/Order', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5 } },
  { id: 'max_value_order', label: 'Max Val/Order', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5 } },
  { id: 'max_loss_day', label: 'Max Loss/d', style: { pl: 1, pr: 0, pt: 0.5, pb: 0.5 } },
];


const api_server_url = process.env.REACT_APP_API_SERVER_URL;
// Row components render the first row and data rows of the table, respectively.

const Row = React.memo((props) => {
  const { row, data } = props;
  // const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow
        key={row.dealer_id}
        sx={{
          '&:hover': {
            backgroundColor: '#1B4152', // or any color you prefer
            // cursor: 'pointer'
          },
          '& > .MuiTableCell-root': {
            p: 0.8,
            borderRight: '1px solid rgba(81, 81, 81, 1)',
          },
          backgroundColor: '#021B26'
        }}
      >
        <TableCell component="th" scope="row" align='center' style={{ cursor: 'pointer' }} sx={{ borderLeft: '1px solid rgba(81, 81, 81, 1)' }}>
          {row.dealer_id}
        </TableCell>
        <TableCell align="center">{row.trade_limit_day}</TableCell>
        <TableCell align="center">
          {row.qty_limit_day && row.qty_limit_day.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell>
        <TableCell align="center">
          {row.trade_limit_sec && row.trade_limit_sec.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell>
        <TableCell align="center">
          <span>
            {row.max_value_symbol && row.max_value_symbol.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
          </span>
        </TableCell>
        <TableCell align="center">
          {row.max_opt_val && row.max_opt_val.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell>
        <TableCell align="center">
          {row.max_fut_val && row.max_fut_val.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell>
        {/* <TableCell align="center">
            {row.max_position_val && row.max_position_val.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell> */}
        <TableCell align="center">
          {row.max_lots_order && row.max_lots_order.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell>
        <TableCell align="center">
          {row.max_value_order && row.max_value_order.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell>
        <TableCell align="center">
          {row.max_loss_day && row.max_loss_day.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
});
// these are the row Proptypes

Row.propTypes = {
  row: PropTypes.shape({
    dealer_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    trade_limit_day: PropTypes.number.isRequired,
    qty_limit_day: PropTypes.number.isRequired,
    trade_limit_sec: PropTypes.number.isRequired,
    max_value_symbol: PropTypes.number.isRequired,
    max_opt_val: PropTypes.number.isRequired,
    max_fut_val: PropTypes.number.isRequired,
    max_position_val: PropTypes.number.isRequired,
    max_lots_order: PropTypes.number.isRequired,
    max_value_order: PropTypes.number.isRequired,
    max_loss_day: PropTypes.number.isRequired,
    // positions: PropTypes.arrayOf(
    //     PropTypes.shape({
    //         underlying: PropTypes.string,
    //     }),
    // ).isRequired,
  }).isRequired,
};

// The descendingComparator function compares two objects based on a specified property in descending order.
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
// The getComparator function returns a comparator function based on the order and orderBy props.
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
// The stableSort function sorts an array of objects in a stable manner, meaning that the relative order of equal elements is preserved.
function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}


const View = () => {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const throttledSetData = React.useCallback(throttle((newData) => {
    if (newData) {
      setData(newData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, 2000), []);

  const handleRequestSort = (property) => (event) => {
    if (orderBy === property) {
      setOrder((prevOrder) => {
        if (prevOrder === 'asc') return 'desc';
        if (prevOrder === 'desc') return 'default';
        return 'asc';
      });
    } else {
      setOrder('asc');
      setOrderBy(property);
    }
  };

  const getSortIcon = (property) => {
    if (orderBy === property) {
      if (order === 'asc') return '↑';
      if (order === 'desc') return '↓';
      return '↕';
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${api_server_url}/firewall`);
        const newData = response.data; // Modify this based on your API response structure
        console.log(newData)
        throttledSetData(newData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    fetchData();  // Call the fetchData function

    // Remove the socket cleanup logic as it's not needed with Axios

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttledSetData]);

  const skeletonRows = [...Array(10)].map((_, index) => (
    <TableRow key={index}>
      {headers.map((column, colIndex) => (
        <TableCell key={colIndex}>
          <Skeleton animation="wave" height={30} />
        </TableCell>
      ))}
    </TableRow>
  ));
  //Function for download the table in .pdf .csv format
  const handleExportRows = () => {
    const doc = new jsPDF('landscape'); // Use landscape orientation

    // Prepare the table headers
    const tableHeaders = headers.map(header => header.label);
    toast.success('Downloaded Successfully')
    // Prepare the table data
    const tableData = stableSort(data, getComparator(order, orderBy))
      .map(row => headers.map(header => row[header.id] ? row[header.id].toString() : ''));

    // Generate the table in the PDF
    autoTable(doc, {
      theme: 'grid',
      head: [tableHeaders],
      body: tableData,
      styles: { overflow: 'linebreak', fontSize: 8 },
      columnStyles: { 0: { cellWidth: 'auto' } }, // Customize column styles
    });
    const csvContent = [tableHeaders.join(',')];
    tableData.forEach((row) => {
      csvContent.push(row.join(','));
    });
    const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);
    const csvLink = document.createElement('a');
    csvLink.href = csvUrl;
    csvLink.setAttribute('download', 'View.csv');
    document.body.appendChild(csvLink);
    csvLink.click();
    document.body.removeChild(csvLink);
    // Save the PDF
    doc.save('View.pdf');
  };

  return (
    <Box
      className='MUITable-scrollbar m-5 mt-1'
    >
      <Button
        onClick={handleExportRows}
        variant="contained"
        color="primary"
        style={{ marginBottom: '10px', backgroundColor: '#1d1d1d', color: '#3b9599', border: '1px solid #3b9599' }}
        startIcon={<FileDownloadIcon />}
      >
        Download
      </Button>
      <TableContainer component={Paper} style={{ maxHeight: '70vh', overflow: 'auto', width: '100%', }}>
        <Table sx={{ minWidth: 600 }} stickyHeader aria-label="sticky table">
          <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={header.id}
                  align='center'
                  sx={{ ...header.style, borderRight: '1px solid rgba(81, 81, 81, 1)', borderTop: '1px solid rgba(81, 81, 81, 1)', zIndex: 100, height: "55px", background: "#001017", fontWeight: 800 }}
                >
                  {loading ? (
                    <div>
                      <Skeleton animation="wave" height={30} />
                    </div>
                  ) : (
                    <TableSortLabel
                      active={orderBy === header.id}
                      direction={orderBy === header.id ? order : 'asc'}
                      onClick={handleRequestSort(header.id)}
                      style={{ whiteSpace: 'nowrap' }}
                      aria-label={header.label}
                      IconComponent={null}
                    >
                      <Tooltip title={header.label} placement='bottom' arrow>
                        <span>{header.label} {getSortIcon(header.id)}</span>
                      </Tooltip>
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ backgroundColor: '#021B26' }}>
            {loading ? (
              skeletonRows
            ) : (
              stableSort(data, getComparator(order, orderBy))?.map((row, rowIndex) => (
                <Row key={row.dealer_id} row={row} data={data} loading={loading} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default View;