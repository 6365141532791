import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import io from 'socket.io-client'
import { EditPortfolio } from '../../../index'
import { throttle } from 'lodash';
// import EditIcon from '@mui/icons-material/Edit';
// import Typography from '@mui/material/Typography';
// import EditPortfolio from '../../../../pages/usersdata/EditPortfolio';
// import IconButton from '@mui/material/IconButton';


const RightBarData = ({ collapsed, setCollapsed }) => {
    const [data, setData] = useState(null)
    const [stocksDealerKeysWithoutTotal, setStocksDealerKeysWithoutTotal] = useState([])
    const [optionDealerKeysWithoutTotal, setOptionDealerKeysWithoutTotal] = useState([])
    const [futureDealerKeysWithoutTotal, setFutureDealerKeysWithoutTotal] = useState([])
    // const [block, setBlock] = React.useState(true);
    // const [isEditPortfolioOpen, setIsEditPortfolioOpen] = React.useState(false)
    // const [selectedRowForEdit, setSelectedRowForEdit] = React.useState(null);
    const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

    const throttledSetData = React.useCallback(throttle((newData) => {
        setData(newData);
    }, 2000), []);

    useEffect(() => {
        const socket = io(socket_server_url + "/testRightSidebar");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on("test-rightsidebar", (newData) => {
            if (newData) {
                throttledSetData(newData);
                setStocksDealerKeysWithoutTotal(newData.equity ? Object.keys(newData.equity).filter((key) => key !== 'total') : null);
                setOptionDealerKeysWithoutTotal(newData.stocks_fno ? Object.keys(newData.stocks_fno).filter((key) => key !== 'total') : null);
                setFutureDealerKeysWithoutTotal(newData.index_fno ? Object.keys(newData.index_fno).filter((key) => key !== 'total') : null);
                // console.log(newData)
                // console.log(newData.future);

            } else {
                console.log("No data in the overview");
            }
        });

        return () => {
            socket.disconnect();
            throttledSetData.cancel();
        };
    }, [throttledSetData]);

    React.useEffect(() => {
        const rootSocket = io(socket_server_url + "/");

        rootSocket.on('connect', () => console.log('Connected to root socket server'));
        rootSocket.on("disconnect", () => console.log("Disconnected from root socket server"));

        rootSocket.emit("overview", "Test Update from frontend");

        return () => {
            rootSocket.disconnect();
        };
    }, []);

    // const handlePortfolioEdit = (rowData) => {
    //     console.log('handlePortfolio');
    //     setSelectedRowForEdit(rowData);
    //     setIsEditPortfolioOpen(true);
    //     console.log(rowData)
    // }

    return !collapsed && (
        <div className="text-white  p-2 space-y-4 mt-12 min-h-screen overflow-hidden bg-[#12394b]">
            <div className='flex flex-col'>
                <div className='flex justify-between items-center mt-2'>
                    <div className="p-2 rounded-lg">
                        <h6 className="text-xs font-semibold">Current Portfolio Value</h6>
                        <p className="text-[18px]">{new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                        }).format(data?.total_portfolio)}</p>
                    </div>
                    <div className="p-2 rounded-lg ">
                        <h6 className="text-xs font-semibold">9:15 Portfolio Value</h6>
                        <p className="text-[18px]">{new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                        }).format(data?.initial_portfolio)}</p>
                    </div>
                </div>

                <div className='flex justify-between'>
                    <div className="p-2 rounded-lg flex flex-col gap-2">
                        <h4 className="text-xs font-semibold">Realised</h4>
                        <p style={{ color: data?.realised_pnl > 0 ? '#97FF95' : '#FFa665' }} className="text-xs">{new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                        }).format(data?.realised_pnl)}<br/>({data?.perc_realised_pnl}%)</p>
                    </div>
                    <div className="p-2 rounded-lg flex flex-col gap-2">
                        <h4 className="text-xs font-semibold">Unrealised</h4>
                        <p style={{ color: data?.unrealised_pnl > 0 ? '#97FF95' : '#FFa665' }} className="text-xs">{new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                        }).format(data?.unrealised_pnl)}<br/>({data?.perc_unrealised_pnl}%)</p>
                    </div>
                    <div className="p-2 rounded-lg flex flex-col gap-2">
                        <h4 className="text-xs font-semibold">Total Pnl</h4>
                        <p style={{ color: data?.total_pnl > 0 ? '#97FF95' : '#FFa665' }} className="text-xs">{new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                        }).format(data?.total_pnl)}<br/>({data?.perc_total_pnl}%)</p>
                    </div>
                </div>
            </div>
            <hr className='border-[0.5] border-[#307d9d]' />
            <div className="rounded-lg flex flex-col items-center justify-center">
                <h1 className="text-lg mb-1 font-bold">Position Stats</h1>
                <div className="flex flex-col gap-3 p-2 w-full bg-[#0D303e] rounded-lg">
                    <div className='flex items-center justify-between'>
                        <p className='text-[15px]'>Available Margin</p>
                        <p className='text-xs'>{new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                        }).format(data?.unused_margin)}</p>
                    </div>
                    <div className='border w-full flex flex-col border-[0.5] border-[#307c9d] rounded-lg p-2 items-center justify-center'>
                        {/* <div className='flex flex-col justify-center items-center w-full'> */}
                        <div className='flex gap-2 flex-col justify-center items-center w-full'>
                            <div className='grid grid-cols-3 w-full place-content-between'>
                                <p className='text-sm font-bold'>Segment</p>
                                <p className='text-sm grid place-items-end font-bold'>Margin</p>
                                <p className='text-sm grid place-items-end font-bold'>P&L</p>
                            </div>
                            <div className='grid grid-cols-3 w-full place-content-between'>
                                <p className='text-xs'>Equity</p>
                                <p className='text-xs grid place-items-end text-[#8ABDD3]'>
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(data?.market_value?.equity?.margin)}
                                </p>
                                <p style={{ color: data?.market_value?.equity?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-xs grid place-items-end'>
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(data?.market_value?.equity?.pnl)}
                                </p>
                            </div>
                        </div>

                        <hr className='mt-3 border-[0.5] border-[#30739d] w-full' />
                        <div className='flex flex-col items-center w-full gap-3 mt-2 justify-center'>
                            <div className='grid grid-cols-3 w-full place-content-between'>
                                <p className='text-[14px] font-bold'>Index F&O</p>
                                <p className='text-xs grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                    style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                }).format(data?.market_value?.index_fno?.margin)}</p>
                                <p style={{ color: data?.market_value?.index_fno?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-xs grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                    style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                }).format(data?.market_value?.index_fno?.pnl)}</p>
                            </div>
                            <div className='w-full flex flex-col items-center gap-2'>
                                <div className='grid grid-cols-3 w-full place-content-between'>
                                    <div className='flex gap-2 items-center'>
                                        <span className='h-1 w-1 bg-[#ff6565] rounded-full'></span>
                                        <p className='text-[13px]'>Short Opt.</p>
                                    </div>
                                    <p className='text-[11px] grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.index_opt_short?.margin)}</p>
                                    <p style={{ color: data?.market_value?.index_opt_short?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-[11px] grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.index_opt_short?.pnl)}</p>
                                </div>
                                <div className='grid grid-cols-3 w-full place-content-between'>
                                    <div className='flex gap-2 items-center'>
                                        <span className='h-1 w-1 bg-[#97ff95] rounded-full'></span>
                                        <p className='text-[13px]'>Long Opt.</p>
                                    </div>
                                    <p className='text-[11px] grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.index_opt_long?.margin)}</p>
                                    <p style={{ color: data?.market_value?.index_opt_long?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-[11px] grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.index_opt_long?.pnl)}</p>
                                </div>
                                <div className='grid grid-cols-3 w-full place-content-between'>
                                    <div className='flex gap-2 items-center text-left'>
                                        <span className='h-1 w-1 bg-[#ffffff] rounded-full'></span>
                                        <p className='text-[13px]'>Future</p>
                                    </div>
                                    <p className='text-[11px] grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.index_fut?.margin)}</p>
                                    <p style={{ color: data?.market_value?.index_fut?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-[11px] grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.index_fut?.pnl)}</p>
                                </div>
                            </div>
                        </div>
                        <hr className='mt-3 border-[0.5] border-[#30739d] w-full' />
                        <div className='flex flex-col w-full gap-3 mt-2'>
                            <div className='grid grid-cols-3 w-full place-content-between'>
                                <p className='text-[14px] font-bold'>Stocks F&O</p>
                                <p className='text-xs grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                    style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                }).format(data?.market_value?.stock_fno?.margin)}</p>
                                <p style={{ color: data?.market_value?.stock_fno?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-xs grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                    style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                }).format(data?.market_value?.stock_fno?.pnl)}</p>
                            </div>
                            <div className='w-full flex flex-col items-center gap-2'>
                                <div className='grid grid-cols-3 w-full place-content-between'>
                                    <div className='flex gap-2 items-center'>
                                        <span className='h-1 w-1 bg-[#ff6565] rounded-full'></span>
                                        <p className='text-[13px]'>Short Opt.</p>
                                    </div>
                                    <p className='text-[11px] grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.stock_opt_short?.margin)}</p>
                                    <p style={{ color: data?.market_value?.stock_opt_short?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-[11px] grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.stock_opt_short?.pnl)}</p>
                                </div>
                                <div className='grid grid-cols-3 w-full place-content-between'>
                                    <div className='flex gap-2 items-center'>
                                        <span className='h-1 w-1 bg-[#97ff95] rounded-full'></span>
                                        <p className='text-[13px]'>Long Opt.</p>
                                    </div>
                                    <span className='text-[11px] grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.stock_opt_long?.margin)}</span>
                                    <span style={{ color: data?.market_value?.stock_opt_long?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-[11px] grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.stock_opt_long?.pnl)}</span>
                                </div>
                                <div className='grid grid-cols-3 w-full place-content-between'>
                                    <div className='flex gap-2 items-center text-left'>
                                        <span className='h-1 w-1 bg-[#ffffff] rounded-full'></span>
                                        <p className='text-[13px]'>Future</p>
                                    </div>
                                    <span className='text-[11px] grid place-items-end text-[#8ABDD3]'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.stock_fut?.margin)}</span>
                                    <span style={{ color: data?.market_value?.stock_fut?.pnl > 0 ? '#97FF95' : '#ff6565' }} className='text-[11px] grid place-items-end'>{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.market_value?.stock_fut?.pnl)}</span>
                                </div>
                            </div>
                        </div>
                        {/* </div> */}
                    </div>
                </div>
            </div>
            {stocksDealerKeysWithoutTotal ? (
                <Accordion style={{ backgroundColor: '#0D303E', borderRadius: '0.5rem', color: 'white', border: '' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        aria-controls="equity-panel"
                        id="panel1a-header"
                        className='flex items-center justify-around'
                    >
                        <div className="rounded-lg m-0 p-0 flex flex-col gap-1 w-full">
                            <div className='flex flex-col gap-1'>
                                <h6 className="text-sm font-bold">Equity</h6>
                                <div className="flex flex-row items-center justify-between w-full">
                                    <p className="text-xl font-medium">
                                        {new Intl.NumberFormat('en-IN', {
                                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                        }).format(data?.equity?.total?.abs_pnl)}
                                    </p>
                                    <p style={{ color: data?.equity?.total?.perc_pnl > 0 ? '#97FF95' : '#FF6565' }} className="text-xs font-semibold">
                                        {data?.equity?.total?.perc_pnl}%
                                    </p>
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails id="equity-panel">
                        <div className='flex flex-col items-center gap-4'>
                            {stocksDealerKeysWithoutTotal?.map((dealer) => {
                                return (
                                    <div key={dealer} className="p-4 flex rounded-lg bg-[#0D303e] min-w-full justify-between items-center">
                                        <div className='flex flex-col gap-2'>
                                            <h6 className="text-sm font-semibold">{dealer}</h6>
                                            <div className="flex gap-4">
                                                <span style={{ color: data?.equity?.dealer?.perc_pnl > 0 ? '#97FF95' : '#FF6565' }} className="font-semibold text-[12px]">{data?.equity[dealer]?.perc_pnl}%</span>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <h6 style={{ color: data?.equity[dealer]?.abs_pnl > 0 ? '#97FF95' : '#FF6565' }} className="text-lg font-medium">{new Intl.NumberFormat('en-IN', {
                                                style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                            }).format(data?.equity[dealer]?.abs_pnl)}</h6>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ) : null}

            {optionDealerKeysWithoutTotal ? (
                <Accordion style={{ backgroundColor: '#0D303E', borderRadius: '0.5rem', color: 'white', border: 'none' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        aria-controls="StocksF&O-panel"
                        id="panel1a-header"
                        // className='flex items-center justify-around border-none'
                        sx={{ border: 'none' }}
                    >
                        <div className="rounded-lg m-0 p-0 flex flex-col gap-1 w-full">
                            <h6 className="text-sm font-bold">Stocks F&O</h6>
                            <div className="flex items-center justify-between w-full">
                                <p className="text-xl font-medium">{new Intl.NumberFormat('en-IN', {
                                    style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                }).format(data?.stocks_fno?.total?.abs_pnl)}</p>
                                <p style={{ color: data?.stocks_fno?.total?.perc_pnl > 0 ? '#97FF95' : '#FF6565' }} className="text-xs font-semibold">{data?.stocks_fno?.total?.perc_pnl}%</p>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails id="StocksF&O-panel">
                        <div className='flex flex-col items-center gap-4'>
                            {optionDealerKeysWithoutTotal?.map((dealer) =>
                            (
                                <div key={dealer} className="p-4 flex rounded-lg bg-[#0D303e] min-w-full justify-between items-center">
                                    <div className='flex flex-col gap-2'>
                                        <h6 className="text-sm font-semibold">{dealer}</h6>
                                        <div className="flex gap-4">
                                            <span style={{ color: data?.stocks_fno[dealer]?.perc_pnl > 0 ? '#97FF95' : '#FF6565' }} className="font-semibold text-[12px]">{data?.stocks_fno[dealer]?.perc_pnl}%</span>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <h6 style={{ color: data?.stocks_fno[dealer]?.abs_pnl > 0 ? '#97FF95' : '#FF6565' }} className="text-lg font-medium">{new Intl.NumberFormat('en-IN', {
                                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                        }).format(data?.stocks_fno[dealer]?.abs_pnl)}</h6>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ) : null}

            {futureDealerKeysWithoutTotal ? (
                <Accordion style={{ backgroundColor: '#0D303E', borderRadius: '0.5rem', color: 'white', border: 'none' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                        aria-controls="IndexF&O-panel"
                        id="panel1a-header"
                        className='flex items-center justify-around'
                    >
                        <div className="rounded-lg m-0 p-0 flex flex-col gap-1 w-full">
                            <div className='flex flex-col gap-1'>
                                <h6 className="text-sm font-bold">Index F&O</h6>
                                <div className="flex items-center justify-between w-full">
                                    <p className="text-xl font-medium">{new Intl.NumberFormat('en-IN', {
                                        style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                    }).format(data?.index_fno?.total?.abs_pnl)}</p>
                                    <p style={{ color: data?.index_fno?.total?.perc_pnl > 0 ? '#97FF95' : '#FF6565' }} className="text-xs font-semibold">{data?.index_fno?.total?.perc_pnl}%</p>
                                </div>
                            </div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails id="IndexF&O-panel">
                        <div className='flex flex-col items-center gap-4'>
                            {futureDealerKeysWithoutTotal?.map((dealer) => (
                                <div key={dealer} className="p-4 flex rounded-lg bg-[#0D303e] min-w-full justify-between items-center">
                                    <div className='flex flex-col gap-2'>
                                        <h6 className="text-sm font-semibold">{dealer}</h6>
                                        <div className="flex gap-4">
                                            <span style={{ color: data?.index_fno[dealer]?.perc_pnl > 0 ? '#97FF95' : '#FF6565' }} className="font-semibold text-[12px]">{data?.index_fno[dealer]?.perc_pnl}%</span>
                                        </div>
                                    </div>
                                    <div>
                                        <h6 style={{ color: data?.index_fno[dealer]?.abs_pnl > 0 ? '#97FF95' : '#FF6565' }} className="text-lg font-medium">{new Intl.NumberFormat('en-IN', {
                                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                                        }).format(data?.index_fno[dealer]?.abs_pnl)}</h6>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </AccordionDetails>
                </Accordion>
            ) : null}
            {/* {isEditPortfolioOpen && (
                <EditPortfolio
                    open={isEditPortfolioOpen}
                    rowData={selectedRowForEdit}
                    onClose={() => setIsEditPortfolioOpen(false)}
                    block={block}
                    setBlock={setBlock}
                />
            )} */}
        </div>
    );
}

export default RightBarData;