import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from "recharts"; 

// Define the PnlBarVertical functional component
const PnlBarVertical = ({ barData, name }) => {
  // Function to calculate background color based on value
  const calculateBackgroundColor = (value) => {
    if (value >= 0 && value < 3) {
      return "#65C163"; // Green color
    } else if (value >= 3 && value < 5) {
      return "#BA4F4F"; // Red color
    } else {
      return "#8D2C2C"; // Dark red color
    }
  };

  // Prepare final data with calculated background color
  const finalData = barData.map((item) => ({
    name: Object.keys(item)[0],
    value: Object.values(item)[0],
    backgroundColor: calculateBackgroundColor(Object.values(item)[0]),
  }));

  // Extracting background colors from finalData
  const colorsData = finalData?.map((dataItem) => dataItem.backgroundColor);

  // CustomTooltip component for displaying tooltip
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const { name, value } = payload[0].payload;

      return (
        <div
          className="custom-tooltip"
          style={{
            background: "rgba(0, 0, 0, 0.9)",
            color: "white",
            borderRadius: "5px",
            padding: "6px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          <p>{`${name} : ${value}`}</p>
        </div>
      );
    }
    return null;
  };

  // JSX to render the component
  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
      <div className="flex w-full items-center">
        <h1 className="flex-grow text-center text-[13px] font-bold">{name}</h1>
      </div>
      {/* ResponsiveContainer for making the chart responsive */}
      <ResponsiveContainer>
        <BarChart data={finalData}>
          {/* XAxis component for horizontal axis */}
          <XAxis
            padding={{ left: 0, right: 10, top: 4 }}
            dataKey="name"
            textAnchor="end"
            tick={{ fontSize: "10px", fill: "white" }}
            angle={-16}
          />
          {/* YAxis component for vertical axis */}
          <YAxis interval={0}
            width={50} tick={{ fontSize: "9px", fill: "white" }} />
          {/* Tooltip component */}
          <Tooltip content={<CustomTooltip />} />
          {/* Bar component for rendering bars */}
          <Bar dataKey="value" barSize={10}>
            {/* Cell component to customize each bar's color */}
            {finalData?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.backgroundColor} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default PnlBarVertical; 
