import React from 'react'
import TextField from '@mui/material/TextField';
import { Button, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import axios from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'sonner';

const api_server_url = process.env.REACT_APP_API_SERVER_URL;
const HedgePrice = ({ collapsed }) => {
    const [niftyHedge, setNiftyHedge] = React.useState(null);
    const [bniftyHedge, setBniftyHedge] = React.useState(null);
    const [fniftyHedge, setFNiftyHedge] = React.useState(null);
    const [enableEdit, setEnableEdit] = React.useState(false);

    const handleEdit = () => {
        setEnableEdit(!enableEdit)
    }

    const handleDone = () => {
        axios.post(`${api_server_url}/sigmaoptimizer`, {
            'nifty_hedge': niftyHedge,
            'bnifty_hedge': bniftyHedge,
            'fnifty_hedge': fniftyHedge,
        }, {
            withCredentials: true
        })
            .then((res) => {
                toast.success('Updated Successfully');
                console.log(res.data);
                setEnableEdit(!enableEdit);
            })
            .catch((error) => {
                toast.error('Update Failed');
                console.log(error);
            });
    };

    React.useEffect(() => {
        axios.get(`${api_server_url}/sigmaoptimizer/gethedges`, {
            withCredentials: true
        })
            .then((res) => {
                setNiftyHedge(res.data['Nifty 50'])
                setBniftyHedge(res.data['Nifty Bank'])
                setFNiftyHedge(res.data['Nifty Fin Service'])
            })
            .catch((error) => {
                console.log(error);
            });
    }, [])

    const checkNiftyHedgePrice = (event, max) => {
        if (event.target.value > max) {
            event.target.value = max
        }
        if (event.target.value < 0) {
            event.target.value = 0
        }
        setNiftyHedge(event.target.value)
    }
    const checkBNiftyHedgePrice = (event, max) => {
        if (event.target.value > max) {
            event.target.value = max
        }
        if (event.target.value < 0) {
            event.target.value = 0
        }
        setBniftyHedge(event.target.value)
    }
    const checkFNiftyHedgePrice = (event, max) => {
        if (event.target.value > max) {
            event.target.value = max
        }
        if (event.target.value < 0) {
            event.target.value = 0
        }
        setFNiftyHedge(event.target.value)
    }

    return (
        <div className={`z-0 flex justify-between fixed bottom-5 left-24 items-center w-[92vw] right-3 bg-[#001017] border rounded-lg border-solid border-[#307C9D] py-3 px-12`}>
            <h1 className='font-semibold text-[16px]'>Hedge Price</h1>
            <div className='flex items-center gap-4'>
                <div className='flex items-center gap-2 justify-center'>
                    <h1 className='text-xs'>Nifty 50</h1>
                    <TextField sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                        },
                        "& input[type=number]": {
                            MozAppearance: "textfield",
                        },
                        width: 60
                    }}
                        size='small'
                        onChange={(e) => checkNiftyHedgePrice(e, 999)}
                        value={niftyHedge || ''}
                        disabled={!enableEdit}
                    />
                </div>
                <div className='flex items-center gap-2 justify-center'>
                    <h1 className='text-xs '>Nifty Bank</h1>
                    <TextField sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                        },
                        "& input[type=number]": {
                            MozAppearance: "textfield",
                        },
                        width: 60
                    }}
                        type='number'
                        size='small'
                        onChange={(e) => checkBNiftyHedgePrice(e, 999)}
                        value={bniftyHedge || ''}
                        disabled={!enableEdit}
                    />
                </div>
                <div className='flex items-center gap-2 justify-center'>
                    <h1 className='text-xs'>Fin Nifty</h1>
                    <TextField sx={{
                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                            display: "none",
                        },
                        "& input[type=number]": {
                            MozAppearance: "textfield",
                        },
                        width: 60
                    }}
                        size='small'
                        onChange={(e) => checkFNiftyHedgePrice(e, 999)}
                        value={fniftyHedge || ''}
                        disabled={!enableEdit}
                    />
                </div>
            </div>
            <div className='flex items-center gap-2'>
                {!enableEdit ? (
                    <Tooltip title="Edit" placement='bottom-start'>
                        <Button
                            // disabled={enableEdit}
                            onClick={handleEdit}
                            variant="outlined"
                            sx={{
                                fontSize:'11px',
                                color:'white',
                                backgroundColor:'#246683',
                                fontWeight:800,
                                cursor:'pointer',
                                '&:hover': {
                                    backgroundColor: '#246683',
                                    border:'1px solid white'
                                },
                            }}
                        >
                            {/* <EditIcon /> */}
                            Edit
                        </Button>
                    </Tooltip>

                ) : (
                    <Tooltip title="Cancel" placement='bottom-start'>

                        <Button
                            // disabled={enableEdit}
                            onClick={handleEdit}
                            variant="outlined"
                            sx={{
                                fontSize:'11px',
                                color:'white',
                                backgroundColor:'#246683',
                                fontWeight:800,
                                cursor:'pointer',
                                '&:hover': {
                                    backgroundColor: '#246683',
                                    border:'1px solid white'
                                },
                            }}
                        >
                            {/* <CloseIcon /> */}
                            Close
                        </Button>
                    </Tooltip>
                )}
                <Tooltip title="Confirm" placement='bottom-start'>
                    <span>
                        <Button
                            disabled={!enableEdit}
                            onClick={handleDone}
                            variant="outlined"
                            sx={{
                                fontSize:'11px',
                                color:'white',
                                backgroundColor:'#246683',
                                fontWeight:800,
                                cursor:'pointer',
                                '&:hover': {
                                    backgroundColor: '#246683',
                                    border:'1px solid white'
                                },
                            }}
                        >
                            {/* <DoneIcon /> */}
                            Save
                        </Button>
                    </span>
                </Tooltip>

            </div>
        </div>
    )
}

export default HedgePrice
