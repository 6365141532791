import React, { createContext, useState, useMemo } from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

export const ThemeContext = createContext({
  themeMode: 'dark',
  toggleTheme: () => {},
});

export const CustomThemeProvider = ({ children }) => {
  const [themeMode, setThemeMode] = useState('dark');

  const toggleTheme = () => {
    setThemeMode(prevMode => (prevMode === 'dark' ? 'gray' : 'dark'));
  };

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode === 'gray' ? undefined : 'dark',
          ...(themeMode === 'dark' && {
            background: {
              default: '#021B26',
              paper: '#bdbdbd',
            },
            text: {
              primary: '#ffffff',
              secondary: '#757575',
            },
          }),
        },
      }),
    [themeMode]
  );

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
