import React from 'react';
import AlarmsTabs from './Tabs';
const AlarmsMain = () => {
    return (
        <div className='mt-5'>
            <AlarmsTabs />
        </div>
    );
}

export default AlarmsMain;
