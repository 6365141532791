import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useState } from "react";
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

const subHeaders = ["Order Side", "Entry Date", "Exit Date", "Qty", "Buy Avg Price", "Sell Avg Price", "P&L"];
const subHeadersUnrealised = ["Order Side", "Entry Date", "Qty", "Buy Avg Price", "Sell Avg Price", "P&L"];


const Row = ({index, entry}) => {
    const [showTable, setShowTable] = useState(true);
    const [showIntradayColumn, setShowIntradayColumn] = useState(false);
    const [showUnrealisedColumn, setShowUnrealisedColumn] = useState(false);
    const [showOvernightColumn, setShowOvernightColumn] = useState(false);

    const handleCollapse = (cellId) => {
        if (cellId === "unrealised") {
            setShowUnrealisedColumn(!showUnrealisedColumn);
        } else if(cellId === "intraday") {
            setShowIntradayColumn(!showIntradayColumn);
        } else { 
            setShowOvernightColumn(!showOvernightColumn);
        }
    }

    return (
        <>
            <TableRow
                key={index}
                sx={{ p: 0, m: 0, border: '1px solid rgba(48, 124, 157, 0.7)',position:"sticky", '&:hover': { backgroundColor: '#1B41559E', cursor: 'pointer' }}}
                onClick={() => setShowTable(!showTable)}
            >
                <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)',fontSize:"14px", fontWeight: "bold"}}>{entry.underlying}</TableCell>
                <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)',fontSize:"14px", color: entry?.unrealised >= 0 ? '#97FF95' : '#FF6565' }}>{entry.unrealised}</TableCell>
                <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)',fontSize:"14px", color: entry?.intraday >= 0 ? '#97FF95' : '#FF6565' }}>{entry.intraday}</TableCell>
                <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)',fontSize:"14px", color: entry?.overnight >= 0 ? '#97FF95' : '#FF6565' }}>{entry.overnight}</TableCell>
            </TableRow>

            <TableRow>
                {showTable && <td colSpan={4}> 
                    <div className="pt-2 p-3">
                        <TableContainer style={{ overflow: 'auto'}}>
                            <Table sx={{ backgroundColor: "#021B264D", border: '1px solid rgba(48, 124, 157, 0.7)', overflow:"auto"}} align="center">
                                <TableHead
                                    sx={{
                                        backgroundColor: "#00293B8D",
                                        fontSize: "13px",
                                        fontWeight:'bold'
                                    }}
                                    align="center"
                                >
                                    <TableRow sx={{ border: '1px solid rgba(48, 124, 157, 0.4)' }}>
                                        <TableCell sx={{width : "25%", p: 1, fontSize: "13px", whiteSpace: 'nowrap', border: '1px solid rgba(48, 124, 157, 0.4)',fontWeight:'bold', borderRight:'2px solid rgba(48, 124, 157)' }} align="center">
                                            Symbol
                                        </TableCell>

                                        {showUnrealisedColumn ? (
                                            <TableCell colSpan={6} id="unrealised" sx={{p: 1, fontSize: "13px", whiteSpace: 'nowrap', border: '1px solid rgba(48, 124, 157, 0.4)', paddingLeft: 0, paddingRight: 0, borderRight:'2px solid rgba(48, 124, 157)',fontWeight:'bold' }} align="center">
                                                Unrealised
                                                <button onClick={() => handleCollapse("unrealised")}> <KeyboardArrowLeftRoundedIcon /> </button>
                                            </TableCell>
                                        ) : (
                                            <TableCell id="unrealised" sx={{width : "25%", p: 1, fontSize: "13px", whiteSpace: 'nowrap', border: '1px solid rgba(48, 124, 157, 0.4)', paddingLeft: 0, paddingRight: 0, borderRight:'2px solid rgba(48, 124, 157)',fontWeight:'bold' }} align="center">
                                                Unrealised
                                                <button onClick={() => handleCollapse("unrealised")}> <KeyboardArrowRightRoundedIcon /> </button>
                                            </TableCell>
                                        )}

                                        {showIntradayColumn ? (
                                            <TableCell colSpan={7} id="intraday" sx={{p: 1, fontSize: "13px", whiteSpace: 'nowrap', border: '1px solid rgba(48, 124, 157, 0.4)', paddingLeft: 0, paddingRight: 0, borderRight:'2px solid rgba(48, 124, 157)',fontWeight:'bold' }} align="center">
                                                Intraday
                                                <button onClick={() => handleCollapse("intraday")}> <KeyboardArrowLeftRoundedIcon />  </button>
                                            </TableCell>
                                        ) : (
                                            <TableCell id="intraday" sx={{width : "25%", p: 1, fontSize: "13px", whiteSpace: 'nowrap', border: '1px solid rgba(48, 124, 157, 0.4)', paddingLeft: 0, paddingRight: 0, borderRight:'2px solid rgba(48, 124, 157)',fontWeight:'bold' }} align="center">
                                                Intraday
                                                <button onClick={() => handleCollapse("intraday")}> <KeyboardArrowRightRoundedIcon />  </button>
                                            </TableCell>
                                        )}

                                        {showOvernightColumn ? (
                                            <TableCell colSpan={7} id="overnight" sx={{ p: 1, fontSize: "14px", whiteSpace: 'nowrap', border: '1px solid rgba(48, 124, 157, 0.4)', paddingLeft: 0, paddingRight: 0, borderRight:'2px solid rgba(48, 124, 157)',fontWeight:'bold' }} align="center">
                                                Overnight
                                                <button onClick={() => handleCollapse("overnight")}> <KeyboardArrowLeftRoundedIcon />  </button>
                                            </TableCell>
                                        ) : (
                                            <TableCell id="overnight" sx={{ p: 1, fontSize: "13px", whiteSpace: 'nowrap', border: '1px solid rgba(48, 124, 157, 0.4)', paddingLeft: 0, paddingRight: 0,fontWeight:'bold' }} align="center">
                                                Overnight
                                                <button onClick={() => handleCollapse("overnight")}> <KeyboardArrowRightRoundedIcon />  </button>
                                            </TableCell>
                                        )}
                                                            
                                    </TableRow>

                                    <TableRow>
                                            <TableCell sx={{border: '1px solid rgba(48, 124, 157, 0.4)', borderRight: '2px solid rgba(48, 124, 157)'}}></TableCell>
                                               {showUnrealisedColumn ? (subHeadersUnrealised.map((item, idx) => (
                                                    <TableCell
                                                        key={idx}
                                                        sx={{
                                                            p: 1,
                                                            fontWeight: 'bold',
                                                            fontSize: "14px",
                                                            whiteSpace: 'nowrap',
                                                            border: '1px solid rgba(48, 124, 157, 0.4)',
                                                            backgroundColor: "rgba(98, 99, 42, 0.15)",
                                                            borderRight: idx === subHeadersUnrealised.length - 1 ? '2px solid rgba(48, 124, 157)' : '1px solid rgba(48, 124, 157, 0.4)'
                                                        }}
                                                        align="center"
                                                        >
                                                        {item}
                                                    </TableCell>
                                                    ))
                                                ) : (
                                                    <TableCell
                                                        sx={{
                                                            p: 1,
                                                            fontSize: "14px",
                                                            fontWeight:'bold',
                                                            whiteSpace: 'nowrap',
                                                            border: '1px solid rgba(48, 124, 157, 0.4)',
                                                            backgroundColor: "rgba(98, 99, 42, 0.15)",
                                                            borderRight: '2px solid rgba(48, 124, 157)'
                                                        }}
                                                        align="center">
                                                        P&L
                                                    </TableCell> 
                                                )}

                                                {showIntradayColumn ? (
                                                    subHeaders.map((item, idx) => (
                                                        <TableCell
                                                            key={idx}
                                                            sx={{
                                                                p: 1,
                                                                fontSize: "14px",
                                                                fontWeight:'bold',
                                                                whiteSpace: 'nowrap',
                                                                border: '1px solid rgba(48, 124, 157, 0.4)',
                                                                backgroundColor: 'rgba(38, 99, 70, 0.10)',
                                                                borderRight: idx === subHeaders.length - 1 ? '2px solid rgba(48, 124, 157)' : '1px solid rgba(48, 124, 157, 0.4)'
                                                            }}
                                                            align="center"
                                                            >
                                                            {item}
                                                        </TableCell>
                                                    ))
                                                    ) : (
                                                    <TableCell
                                                        sx={{
                                                            p: 1,
                                                            fontSize: "14px",
                                                            fontWeight:'bold',
                                                            whiteSpace: 'nowrap',
                                                            border: '1px solid rgba(48, 124, 157, 0.4)',
                                                            backgroundColor: 'rgba(38, 99, 70, 0.10)',
                                                            borderRight: '2px solid rgba(48, 124, 157)'
                                                        }}
                                                        align="center"
                                                    >
                                                        P&L
                                                    </TableCell>
                                                )}

                                                {showOvernightColumn ? (
                                                    subHeaders.map((item, idx) => (
                                                        <TableCell
                                                            key={idx}
                                                            sx={{
                                                                p: 1,
                                                                fontSize: "14px",
                                                                whiteSpace: 'nowrap',
                                                                fontWeight: 'bold',
                                                                border: '1px solid rgba(48, 124, 157, 0.4)',
                                                                backgroundColor: 'rgba(90, 53, 49, 0.20)',
                                                            }}
                                                            align="center" >
                                                            {item}
                                                        </TableCell>
                                                        ))
                                                    ) : (
                                                    <TableCell
                                                        sx={{
                                                            p: 1,
                                                            fontSize: "14px",
                                                            fontWeight: 'bold',
                                                            whiteSpace: 'nowrap',
                                                            border: '1px solid rgba(48, 124, 157, 0.4)',
                                                            backgroundColor: 'rgba(90, 53, 49, 0.20)',
                                                        }}
                                                    align="center">
                                                        P&L
                                                    </TableCell>
                                                )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ fontSize: "12px", borderRight: "1px solid rgba(48, 124, 157, 0.4)" , whiteSpace: 'nowrap'}} align="center">
                                        {entry?.positions?.map((position, idx) =>  (
                                            <TableRow sx={{'&:hover': {
                                                    backgroundColor: '#1B4152',
                                                    width:"100%",
                                                    cursor:"pointer",
                                                },
                                                '& > .MuiTableCell-root' : {
                                                    whiteSpace:"no- wrap",
                                                    overflow : "hidden"
                                                    }
                                                }}
                                            >
                                            <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', borderRight :'2px solid rgba(48, 124, 157)'}} align="center">
                                                {position?.symbol}
                                            </TableCell>

                                            {showUnrealisedColumn ? (
                                                <>
                                                    <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor : "rgba(98, 99, 42, 0.15)", color: position?.unrealised.order_side === "Long" ? 'rgb(37, 216, 244)' : 'rgb(255, 124, 82)' }} align="center">
                                                    {position?.unrealised.order_side}
                                                    </TableCell>
                                                    <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor : "rgba(98, 99, 42, 0.15)" }} align="center">
                                                        {position?.unrealised.entry_date}
                                                    </TableCell>
                                                    <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor : "rgba(98, 99, 42, 0.15)", color: position?.unrealised.qty >= 0 ? 'rgb(37, 216, 244)' : 'rgb(255, 124, 82)'}} align="center">
                                                        {position?.unrealised.qty}
                                                    </TableCell>
                                                    <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor : "rgba(98, 99, 42, 0.15)" }} align="center">
                                                        {position?.unrealised.buy_avg_price}
                                                    </TableCell>
                                                    <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor : "rgba(98, 99, 42, 0.15)" }} align="center">
                                                        {position?.unrealised.sell_avg_price}
                                                    </TableCell>
                                                    <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', borderRight:'2px solid rgba(48, 124, 157)' , backgroundColor : "rgba(98, 99, 42, 0.15)", color: position?.unrealised.pnl >= 0 ? '#97FF95' : '#FF6565' }} align="center">
                                                        {position?.unrealised.pnl}
                                                     </TableCell>
                                                </>
                                                ) : (
                                                <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', borderRight:'2px solid rgba(48, 124, 157)' , backgroundColor : "rgba(98, 99, 42, 0.15)", color: position?.unrealised.pnl >= 0 ? '#97FF95' : '#FF6565' }} align="center">
                                                    {position?.unrealised.pnl}
                                                </TableCell>
                                            )}


                                                {showIntradayColumn ? (
                                                    <>
                                                        <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: "rgba(38, 99, 70, 0.10)", color: position?.intraday.order_side === "Long" ? 'rgb(37, 216, 244)' : 'rgb(255, 124, 82)' }} align="center">
                                                            {position?.intraday.order_side}
                                                        </TableCell>
                                                        <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: "rgba(38, 99, 70, 0.10)" }} align="center">
                                                            {position?.intraday.entry_date}
                                                        </TableCell>
                                                        <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: "rgba(38, 99, 70, 0.10)" }} align="center">
                                                            {position?.intraday.exit_date}
                                                        </TableCell>
                                                        <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: "rgba(38, 99, 70, 0.10)", color: position?.intraday.qty >= 0 ? 'rgb(37, 216, 244)' : 'rgb(255, 124, 82)' }} align="center">
                                                            {position?.intraday.qty}
                                                        </TableCell>
                                                        <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: "rgba(38, 99, 70, 0.10)" }} align="center">
                                                            {position?.intraday.buy_avg_price}
                                                        </TableCell>
                                                        <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: "rgba(38, 99, 70, 0.10)" }} align="center">
                                                            {position?.intraday.sell_avg_price}
                                                        </TableCell>
                                                        <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', borderRight:'2px solid rgba(48, 124, 157)', backgroundColor: "rgba(38, 99, 70, 0.10)", color: position?.intraday.pnl >= 0 ? '#97FF95' : '#FF6565' }} align="center">
                                                            {position?.intraday.pnl}
                                                         </TableCell>
                                                    </>
                                                ) : (
                                                    <TableCell sx={{ p: 1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', borderRight:'2px solid rgba(48, 124, 157)', backgroundColor: "rgba(38, 99, 70, 0.10)", color: position?.intraday.pnl >= 0 ? '#97FF95' : '#FF6565' }} align="center">
                                                        {position?.intraday.pnl}
                                                    </TableCell>
                                                )}


                                                {showOvernightColumn ? (
                                                    <>
                                                        <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: 'rgba(90, 53, 49, 0.20)', color: position?.overnight.order_side === "Long" ? 'rgb(37, 216, 244)' : 'rgb(255, 124, 82)' }} align="center">
                                                        {position?.overnight.order_side}
                                                        </TableCell>
                                                        <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: 'rgba(90, 53, 49, 0.20)' }} align="center">
                                                            {position?.overnight.entry_date}
                                                        </TableCell>
                                                        <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor : 'rgba(90, 53, 49, 0.20)' }} align="center">
                                                            {position?.overnight.exit_date}
                                                        </TableCell>
                                                        <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: 'rgba(90, 53, 49, 0.20)', color: position?.overnight.qty >= 0 ? 'rgb(37, 216, 244)' : 'rgb(255, 124, 82)' }} align="center">
                                                            {position?.overnight.qty}
                                                        </TableCell>
                                                        <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: 'rgba(90, 53, 49, 0.20)' }} align="center">
                                                            {position?.overnight.buy_avg_price}
                                                        </TableCell>
                                                        <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: 'rgba(90, 53, 49, 0.20)' }} align="center">
                                                            {position?.overnight.sell_avg_price}
                                                        </TableCell>
                                                        <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: 'rgba(90, 53, 49, 0.20)', color: position?.overnight.pnl >= 0 ? '#97FF95' : '#FF6565' }} align="center">
                                                            {position?.overnight.pnl}
                                                        </TableCell>
                                                    </>
                                                ) : (
                                                    <TableCell sx={{p:1, fontSize: "12px", border: '1px solid rgba(48, 124, 157, 0.4)', backgroundColor: 'rgba(90, 53, 49, 0.20)', color: position?.overnight.pnl >= 0 ? '#97FF95' : '#FF6565' }} align="center">
                                                        {position?.overnight.pnl}
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </td>}
            </TableRow>
        </>
    )
}

const TradeTable = ({ data }) => {

    return (
        <TableContainer style={{ overflow: 'auto', width:"100%" }} className="alarm-scrollbar">
            <Table aria-label="sticky table" align="center" sx={{
                border: '1px solid rgba(48, 124, 157, 0.7)', tableLayout: 'fixed' 
            }}>
                <TableHead sx={{fontSize: "12px", zIndex: 1 }} align="center">
                    <TableRow sx={{ backgroundColor: "#0010178D", border: '1px solid rgba(48, 124, 157, 0.7)'}}>
                        <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)', fontWeight: 'bold' }}>Underlying</TableCell>
                        <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)', fontWeight: 'bold' }}>Unrealised</TableCell>
                        <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)', fontWeight: 'bold' }}>Intraday</TableCell>
                        <TableCell align="center" sx={{ p: 1, border: '1px solid rgba(48, 124, 157, 0.7)', fontWeight: 'bold' }}>Overnight</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody sx={{
                    backgroundColor: "#021B26", border: '1px solid rgba(48, 124, 157, 0.7)'
                }}>
                    {data?.map((entry, index) => (
                        <Row index={index} entry={entry}/>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TradeTable;
