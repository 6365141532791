import axios from 'axios'
import { useState, useEffect } from "react";
export default function useFetchDealerData(dealerId) {
    // console.log(dealerId)
    const api_server_url = process.env.REACT_APP_API_SERVER_URL;
    const [marginBarData, setMarginBarData] = useState([]);
    const [marketValue, setMarketValue] = useState([]);
    const [qtyTradedValue, setQtyTradedValue] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = () => {
            // OvernightMargin
            axios.post(`${api_server_url}/riskmargin/overnightmarginused`, { 'dealer_id': dealerId }, { withCredentials: true })
                .then((res) => {
                    console.log(res.data)
                    setMarginBarData(res.data);
                })
                .catch((error) => {
                    console.log("error", error);
                });

            // MarketValue
            axios.post(`${api_server_url}/riskmargin/marketvalue`, { 'dealer_id': dealerId }, { withCredentials: true })
                .then((res) => {
                    setMarketValue(res.data);
                })
                .catch((error) => {
                    console.log("error", error);
                });
            // Qunatity Traded
            axios.post(`${api_server_url}/riskmargin/qtytraded`, { 'dealer_id': dealerId }, { withCredentials: true })
                .then((res) => {
                    console.log(res.data)
                    setQtyTradedValue(res.data);
                })
                .catch((error) => {
                    console.log("error", error);
                });
        };
        fetchData();
        setInterval(() => {
            setIsLoading(true);
            setIsLoading(false)
        }, 2000);
        const intervalId = setInterval(fetchData, 10000);
        return () => clearInterval(intervalId);
    }, [api_server_url, dealerId]);

    return [marginBarData, qtyTradedValue, isLoading]
}
