import React from "react";
import DealerMain from "./Dealer/DealerMain";
import DealerDateSelection from "../components/dealer/DealerDateSelection";
import DealerSelection from "../../commoncomponent/DealerSelection";

const Dealer = ({
  isPnlAdmin,
  dealerId,
  dealerName,
  setDealerId,
  setDealerName,
}) => {
  return (
    <div className="flex flex-col gap-2 border-[1px] border-[#307C9D] p-2 w-full h-[78vh] rounded-lg overflow-auto">
     <div className="flex items-center border-b border-b-[#307C9D] pb-2" style={{ position: 'relative' }}>
        <div className="flex justify-start" style={{ position: 'absolute', top: 0 }}>
          <DealerSelection dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName} />
        </div>
        <div className="flex items-center flex-grow justify-center mt-2 mb-2 text-[12px] gap-5">
          {dealerId === dealerName ? (
            <span className='font-semibold text-[14px]'>{dealerId.charAt(0).toUpperCase() + dealerId.slice(1)}</span>
          ) : (
            <>
              <span className='font-semibold text-[14px]'>{dealerId.charAt(0).toUpperCase() + dealerId.slice(1)}</span>
              <span className='font-semibold text-[14px]'>{dealerName}</span>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-2 overflow-auto ">
        <DealerDateSelection dealerId={dealerId} />
        <div className='flex flex-col w-full gap-6 scroll-smooth overflow-y-auto alarm-scrollbar'>
          <div id="today">
            <DealerMain
              dealerId={dealerId}
              range="today"
              showSingleBarChartInMiddle={true}
            />
          </div>
          <div id="this_month">
            <DealerMain
              dealerId={dealerId}
              range="this_month"
              showSingleBarChartInMiddle={true}
            />
          </div>
          {["this_quarter", "this_year", "monthly", "quarterly", "yearly"].map(
            (range, index) => (
              <div key={index} id={range}>
                <DealerMain
                  dealerId={dealerId}
                  range={range}
                  showSingleBarChartInMiddle={false}
                />
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Dealer;
