import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Colors,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
import seedrandom from "seedrandom";
import axios from "axios";
import { CircularProgress } from "@mui/material";
// import { set } from 'lodash';

ChartJS.register(ArcElement, Tooltip, Colors);

const generateChartData = (data, label, colors) => {
  return {
    labels: Object.keys(data),
    datasets: [
      {
        label: label,
        data: Object.values(data).map((item) => {
          if (label === "Margin Usage") {
            return item;
          } else if (label === "P&L") {
            return item.pnl;
          } else {
            return item.market_value;
          }
        }),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };
};
const generateDiffColors = (count, color) => {
  const shades = [];
  for (let i = 0; i < count; i++) {
    const shade = modifyColor(color, i / (count * 1.3));
    shades.push(shade);
  }
  return shades;
};

const generateRandomColors = (count, seed = "light") => {
  const rng = seedrandom(seed);
  const colors = [];
  const baseColor =
    "#" +
    Math.floor(rng() * 16777215)
      .toString(16)
      .padStart(6, "0");
  colors.push(baseColor);

  for (let i = 1; i < count; i++) {
    let newColor = baseColor;
    while (
      newColor === baseColor ||
      parseInt(newColor.substr(1), 16) < 0x888888
    ) {
      // Ensure the new color is different from the base color and not too dark
      newColor =
        "#" +
        Math.floor(rng() * 16777215)
          .toString(16)
          .padStart(6, "0");
    }
    colors.push(newColor);
  }

  return colors;
};

const generateColors = (data) => {
  const totalDataPoints = Object.keys(data).length;
  const shades = generateShades("#00FF00", totalDataPoints);
  return shades;
};

const generateShades = (baseColor, count) => {
  const shades = [];
  for (let i = 0; i < count; i++) {
    const shade = modifyColor(baseColor, i / count);
    shades.push(shade);
  }
  return shades;
};

const modifyColor = (color, factor) => {
  const hex = (color) => ("0" + Math.floor(color).toString(16)).slice(-2);
  const rgb = color.match(/\w\w/g).map((x) => parseInt(x, 16));
  const modifiedColor = `#${hex(rgb[0] * (1 - factor))}${hex(
    rgb[1] * (1 - factor)
  )}${hex(rgb[2] * (1 - factor))}`;
  return modifiedColor;
};

const sortUnderlyingsByPnl = (underlyings) => {
  return Object.fromEntries(
    Object.entries(underlyings).sort(([, a], [, b]) => b.pnl - a.pnl)
  );
};

const MarketValueDealer = ({ dealerId, dealerName }) => {

  const [chartData, setChartData] = useState();
  const [dataAvailable, setDataAvailable] = useState(true);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const fetchData = async () => {
    try {
      const response = await axios.post(
        api_server_url + "/dealer/piechart",
        { dealer_id: dealerId },
        { withCredentials: true }
      );
      // console.log(response.data);
      setChartData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setDataAvailable(false);
    }
  };

  useEffect(() => {
    fetchData(); // Initial call

    // Set up an interval to call fetchData every 60 seconds
    const intervalId = setInterval(fetchData, 60000);

    // Cleanup function to clear the interval if the component unmounts
    return () => clearInterval(intervalId);
  }, [api_server_url, dealerId]);

  const chartOptions = {
    rotation: 180,
    // animation: true,
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 15,
        fontColor: "black",
      },
    },
  };

  if (!dataAvailable) {
    return (
      <div className="flex items-center justify-center h-full text-sm text-gray-500">
        <p>Data not available.</p>
      </div>
    );
  }

  if (!chartData) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  const marginColors = ["#2f9079", "#51b59d"];
  const marginChartData = generateChartData(
    chartData.margin,
    "Margin Usage",
    marginColors
  );
  // console.log(Object.keys(chartData.margin));

  // Calculate the total number of positive P&L values
  const positivePnlCount = Object.values(chartData.underlyings ?? {}).filter(
    (item) => item.pnl >= 0
  ).length;

  const pnlPositiveColors = generateDiffColors(positivePnlCount, "#649763"); // Green shades for positive P&L
  const pnlNegativeColors = generateDiffColors(
    chartData.underlyings
      ? Object.keys(chartData.underlyings).length - positivePnlCount
      : 0,
    "#ef4848"
  ); // Red shades for negative P&L
  const pnlColors = [
    ...pnlPositiveColors.slice().reverse(),
    ...pnlNegativeColors,
  ];
  const pnlChartData = generateChartData(
    sortUnderlyingsByPnl(chartData.underlyings),
    "P&L",
    pnlColors
  );

  // const underlyingColors = generateRandomColors(Object.keys(chartData.underlyings).length, "a");
  // console.log("Length ..............", chartData.underlyings);
  const underlyingColors = generateDiffColors(
    Object.keys(chartData.underlyings).length,
    "#5db3d6"
  );
  const underlyingChartData = generateChartData(
    chartData.underlyings,
    "Market Value",
    underlyingColors
  );

  // console.log(marginChartData);
  // console.log(pnlChartData);
  // console.log(underlyingChartData);

  // useEffect(() => {
  //   // Sort underlyings by P&L before updating the chart data
  //   // setChartData((prevData) => ({
  //   //   ...prevData,
  //   //   underlyings: ,
  //   // }));
  // }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        style={{
          width: "30vw",
          height: "25vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Doughnut data={underlyingChartData} options={chartOptions} />
      </div>
      <h3 className="text-center pt-2" style={{ fontSize: "small" }}>
        Market Value Dealer
      </h3>
    </div>
  );
};

export default MarketValueDealer;
