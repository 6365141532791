import { useState } from "react";
import { Tab, Tabs } from "@mui/material";

function YearSelector({ years }) {
    const [activeTab, setActiveTab] = useState(0); // State to track active tab index

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue); // Update active tab index
    };

    return (
        <Tabs
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            sx={{ width: '100%', overflow: 'hidden', p: 0, m: 0, height: "36px" }}
            value={activeTab} // Set the active tab using the state
            onChange={handleTabChange} // Handle tab change
        >
            {years?.map((y, idx) =>
                <Tab
                    sx={{ minWidth: 'auto', px: 1.7, py: 0, m: 0, color: 'white', height: "36px" }} // Adjust the width of the tab
                    value={idx} // Set the value to the index
                    label={`${y}`}
                    key={`tab_${idx}`}
                />
            )}
        </Tabs>
    );
}

export default YearSelector;
