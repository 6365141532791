import React, { useState, useEffect } from "react";
import PnlBarHorizontal from "./PnlBarHorizontal";
import PnlPayoff from "./PnlPayoff";
import axios from "axios";
import { Skeleton } from "@mui/material";
import PnlTable from "./PnlTable";
import ComponentZoomModal from "../../../Hoc/ComponentZoomModal";

const pnlBarName = "Dealerwise VAR";

const Admin = () => {
  const [varValue, setVarValue] = useState([]); // State variable for VAR values
  const [segmentVarValue, setSegmentVarValue] = useState([]); // State variable for segment VAR values
  const [barData, setBarData] = useState([]); // State variable for bar data
  const [payoffChartData, setPayoffChartData] = useState([]); // State variable for payoff chart data
  const [isLoading, setIsLoading] = useState(true); // State variable to indicate loading state

  // Function to fetch data from the server
  const fetchData = () => {
    setIsLoading(true);
    const api_server_url = process.env.REACT_APP_API_SERVER_URL;

    // Fetch VAR data for all dealers
    axios
      .get(api_server_url + "/riskpnl")
      .then((res) => {
        const data = res.data;
        const dataKeys = data.map((entry) => Object.keys(entry)[0]); // Extracting keys
        console.log(data);
        const dataValues = data.map((entry) => Object.values(entry)[0]); // Extracting values
        setVarValue(data); // Set VAR values
        setBarData(data); // Set Bar Chart values
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // Fetch segment VAR data for the admin dealer
    axios
      .post(api_server_url + `/riskpnl`, {
        dealer_id: "admin",
      })
      .then((res) => {
        const responseData = res.data;
        const values = responseData?.map((entry) => Object.values(entry)[0]); // Extracting values
        setSegmentVarValue(responseData);
        setIsLoading(false);
        // console.log(values);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    // Fetch payoff chart data for all dealers
    axios
      .put(api_server_url + `/riskpnl`, {
        dealer_id: "all",
      })
      .then((res) => {
        setPayoffChartData(res.data); // Set payoff chart data
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // useEffect hook to fetch data when component mounts
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex gap-2 h-full w-full border border-[#307C9D] border-opacity-50 rounded-md p-2 pb-1">
      <div className="flex flex-col py-4 h-[100%] w-[20%]">
        {isLoading ? (
          <Skeleton
            animtion="wave"
            variant="rounded"
            width="100%"
            height="100%"
          />
        ) : varValue.length > 0 ? (
          <PnlTable varValues={varValue} segmentVarValue={segmentVarValue} />
        ) : (
          <p>Data not available</p>
        )}
      </div>

      <div className="flex flex-col gap-2 py-4 h-[77vh] w-[80%]">
        <h1 className="w-full text-center text-[13px] pb-[14px] mt-2 font-bold">
          All Dealers - VAR
        </h1>
        <div className="border border-[#307C9D] relative rounded-lg p-4 h-[45%] w-full">
          <div className="w-full flex justify-end absolute top-2 right-2">
            <ComponentZoomModal
              component={PnlBarHorizontal}
              componentProps={{ barData: barData, name: pnlBarName }}
            />
          </div>
          <div className="flex items-center justify-center h-full">
            {isLoading ? (
              <Skeleton
                animtion="wave"
                variant="rounded"
                width="100%"
                height="100%"
              />
            ) : barData.length > 0 ? (
              <PnlBarHorizontal barData={barData} name={pnlBarName} />
            ) : (
              <div>Data is not available</div>
            )}
          </div>
        </div>
        <div className="border border-[#307C9D] relative rounded-lg p-4 h-[45%] w-full">
          <div className="w-full flex justify-end absolute top-2 right-2">
            <ComponentZoomModal
              component={PnlPayoff}
              componentProps={{ payoffChartData: payoffChartData }}
            />
          </div>
          <div className="flex items-center justify-center h-full">
            {isLoading ? (
              <Skeleton
                animtion="wave"
                variant="rounded"
                width="100%"
                height="100%"
              />
            ) : payoffChartData.length > 0 ? (
              <PnlPayoff payoffChartData={payoffChartData} />
            ) : (
              <div>Data is not available</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
