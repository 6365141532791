import React from "react";
import Alarms from "./Alarms";
import List from "./List/List";
import { Link, Route, Routes, useLocation } from "react-router-dom";

export default function ResponseTab() {
  const location = useLocation();

  // const getTabClass = (tabName) => {
  //     const isActive = location.pathname.includes(tabName) || (location.pathname === '/alarmsPage' && tabName === 'Alarms');
  //     return `
  //     ${isActive ? 'bg-gray-800 text-white' : 'text-gray-500 hover:text-gray-200'}
  //     border-b-2 ${isActive ? 'border-[#3b9599]' : 'hover:border-gray-300'}
  //     rounded-t-lg px-4 py-2
  //     transition duration-300 ease-in-out
  //     font-semibold text-sm`;
  // };

  const getTabClass = (tabName) => {
    const isActive =
      location.pathname.includes(tabName) ||
      (location.pathname === "/alarmsPage" && tabName === "Alarms");
    return isActive ? "tab-link active" : "tab-link";
  };

  return (
    <div className="w-full mt-4 px-4 tabs tab-left-right">
      <nav className="rounded-lg navbar mb-2">
        <Link to="Alarms" className={getTabClass("Alarms")}>
          Alarms
          <span className="nav-item-before" />
        </Link>
        {/* <ul className="flex space-x-1 gap-5">
          <li>
            <Link to="Alarms" className={getTabClass("Alarms")}>
              Alarm
            </Link>
          </li>

          <li>
            <Link to="List" className={getTabClass("List")}>
              List
            </Link>
          </li>
        </ul> */}
      </nav>
      <Routes>
        <Route index element={<Alarms />} />
        <Route path="Alarms" element={<Alarms />} />
        <Route path="List" element={<List />} />
      </Routes>
    </div>
  );
}
