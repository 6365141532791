import React, { useState } from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable, } from 'material-react-table';
import { Box, Button } from '@mui/material';
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import io from "socket.io-client";
import { throttle } from 'lodash';
import Skeleton from '@mui/material/Skeleton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip, IconButton } from '@mui/material';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import { toast } from 'sonner';
import CancelIcon from '@mui/icons-material/Cancel';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { TextField, Typography } from '@mui/material';
import AutorenewIcon from '@mui/icons-material/Autorenew';

const columnHelper = createMRTColumnHelper();
const confirm_password = process.env.REACT_APP_PASSWORD;
const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;
const api_server_url = process.env.REACT_APP_API_SERVER_URL;

const bgColor = (reason) => {
    switch (reason.toLowerCase()) {
        case 'filled':
            return '#97FF95';
        case 'cancelled':
            return 'gray';
        case 'rejected':
            return '#FF6565';
        case 'open':
            return 'yellow';
        default:
            return '#fff';
    }
};

const Response = () => {
    const [data, setData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [sorting, setSorting] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [isAllCancleDialog, setIsAllCancleDialog] = React.useState(false)
    const [rowToCancle, setRowToCancle] = React.useState(null)
    const [isModifyDialog, setIsModifyDialog] = React.useState(false)
    const initialRowToModify = { input_price: '' };
    const [rowToModify, setRowToModify] = React.useState(initialRowToModify)
    const [ltpData, setLtpData] = React.useState([])
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [isCancelDialog, setIsCancelDialog] = React.useState(false);
    const rowVirtualizerInstanceRef = React.useRef(null);

    const calculateRowClassName = (rowTime) => {
        // console.log(rowTime);
        const highlightDuration = 100;
        const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);

        const calculatedTime = currentTimeInSeconds - rowTime;
        // console.log(calculatedTime)
        return calculatedTime <= highlightDuration ? 'yellow' : 'white';
    };

    const handleInputChange = (e) => {
        setRowToModify({ ...rowToModify, input_price: e.target.value });
        // fetchModifyData(rowToModify)
        // console.log('rowToModify')
    };


    const openCancleDialog = (rowData) => {
        setRowToCancle(rowData);
        setIsCancelDialog(true);
    }

    const openAllCancelDialog = (rowData) => {
        setIsAllCancleDialog(true)
    }

    const openModifyDialog = (rowData) => {
        setRowToModify(rowData);
        setIsModifyDialog(true);
        fetchModifyData(rowData)
    }

    const handleSelected = (row) => {
        if (row.original.order_status === 'Open') {

            const isSelected = selectedRows?.some(selectedRow => selectedRow?.id === row.id);
            let newSelected = [];

            if (!isSelected) {
                // If the row is not already selected, add it to the list
                newSelected = [...selectedRows, row];
            } else {
                // If the row is already selected, remove it from the list
                newSelected = selectedRows?.filter(selectedRow => selectedRow?.id !== row.id);
            }

            setSelectedRows(newSelected);
            // console.log(newSelected);
        }
    }

    const handleCancle = (rowData) => {
        if (!rowToCancle) return;

        let cancelRow = [rowToCancle]
        axios.post(`${api_server_url}/dealer/cancel`, cancelRow, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => {
            toast.success('Cancelled Successfully');
            setRowToCancle(null)
            setIsCancelDialog(false)
            setSelectedRows([])
        }).catch((error) => {
            toast.error('cancelled');
            console.log('error', error)
            setSelectedRows([])
            setIsCancelDialog(false)
            setRowToCancle(null)
        })
    };
    const handleAllCancle = (rowData) => {
        let cancelRow = selectedRows?.map((val) => val?.original || [])
        // console.log(cancelRow)

        axios.post(`${api_server_url}/dealer/cancel`, cancelRow, {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => {
            toast.success('Cancelled Successfully');
            setIsAllCancleDialog(false)
            setSelectedRows([])
        }).catch((error) => {
            toast.error('cancelled');
            console.log('error', error)
            setSelectedRows([])
            setIsAllCancleDialog(false)
        })
    };

    const handleModify = (rowData) => {
        // console.log(rowData)
        if (!rowToModify) return;
        axios.post(`${api_server_url}/dealer/modify`, [rowToModify], {
            withCredentials: true,
            headers: {
                "Content-Type": "application/json",
            }
        }).then((res) => {
            toast.success('Modify Successfully');
            setIsModifyDialog(false)
        }).catch((error) => {
            toast.error('Modify Failed');
            console.log('error', error)
            setIsModifyDialog(false)
        })
    };

    const fetchModifyData = React.useCallback((rowData) => {
        // console.log(rowData);
        axios.post(
            `${api_server_url}/price`,
            {
                "ExchangeSegment": rowData?.exchange_segment,
                "ExchangeInstrumentID": rowData?.symbol_id
            },
            { withCredentials: true }  // Pass options as the third argument
        )
            .then((res) => {
                // console.log(res.data);
                setLtpData(res.data);
            })
            .catch((error) => {
                console.log('error in fetchModifyData', error);
            });
    }, [setLtpData]);

    React.useEffect(() => {
        fetchModifyData();
    }, [fetchModifyData]);

    const handleRefresh = (rowToModify) => {
        fetchModifyData(rowToModify);
        // console.log('refresh');
    };

    const columns = [
        columnHelper.accessor('dealer_id', {
            header: 'ID',
            size: 140,
            Cell: ({ cell }) => (
                <Box align='center' style={{ border: 0, padding: 0, margin: 0 }}>
                    {cell.getValue()}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('order_status', {
            header: 'Status',
            size: 130,
            Cell: ({ cell }) => (
                <Box align='center' style={{ border: 0, padding: 0, margin: 0 }}>
                    {
                        <span style={{ color: bgColor(cell.getValue()) }}>
                            {cell.getValue()}
                        </span>
                    }
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('symbol', {
            header: 'Symbol',
            size: 270,
            Cell: ({ cell }) => (
                <Box
                    align='center'
                    style={{
                        border: 0, padding: 0,
                        color: calculateRowClassName(cell.row.original.time_stamp),
                    }}
                >
                    {cell.getValue()}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('order_id', {
            header: 'Order ID',
            size: 150,
            Cell: ({ cell }) => (
                <Box align='center' style={{ border: 0, padding: 0, margin: 0 }}>
                    {cell.getValue()}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('order_side', {
            header: 'Order Side',
            size: 160,
            Cell: ({ row }) => (
                <Box align='center' style={{ border: 0, padding: 0, color: row.original.order_side === 'Long' ? '#25d8f4' : '#ff7c52', }}>
                    {row.original.order_side}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('time', {
            header: 'Time',
            size: 180,
            Cell: ({ cell }) => (
                <Box align='center' style={{ border: 0, padding: 0, margin: 0 }}>
                    {cell.getValue()}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('order_type', {
            header: 'Type',
            size: 130,
            Cell: ({ cell }) => (
                <Box align='center' style={{ border: 0, padding: 0, margin: 0 }}>
                    {cell.getValue()}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('qty', {
            header: 'QTY',
            size: 230,
            Cell: ({ row }) => (
                <Box align='center' style={{ border: 0, padding: 0, margin: 0 }}>
                    {row.original.qty}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('avg_price', {
            header: 'Price',
            size: 230,
            Cell: ({ row }) => (
                <div>
                    {row.original.order_status === 'Open' ? (
                        <Box align='center' >
                            {row.original.input_price && row.original.input_price.toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </Box>
                    ) : (
                        <Box align='center'>
                            {row.original.avg_price && parseFloat(row.original.avg_price).toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })}
                        </Box>
                    )}
                </div>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('reject_reason', {
            header: 'Reject Reason',
            size: 130,
            enableColumnFilter: false,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: (props) => {
                const [open, setOpen] = useState(false);
                const rejectReason = props.row.original.reject_reason;
                const isRejected = props.row.original.order_status === 'Rejected';
                const handleTooltipToggle = () => {
                    if (isRejected) {
                        setOpen(!open);
                    }
                };

                return (
                    <Box align='center' style={{ border: 'none', textAlign: 'center', padding: 0, margin: 0 }}>
                        {isRejected ? (
                            <Tooltip
                                onClose={() => setOpen(false)}
                                open={open}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={<div style={{ whiteSpace: 'pre-line', padding: 4 }}>{rejectReason || 'No reason provided'}</div>}
                                placement='left'
                            >
                                <IconButton onClick={handleTooltipToggle} size='small'>
                                    {open ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <IconButton disabled size='small'>
                                <VisibilityOffIcon />
                            </IconButton>
                        )}
                    </Box>
                );
            },
            enableSorting: false,
        }),
        columnHelper.accessor('Cancel', {
            id: 'Cancel',
            header: 'Cancel',
            enableColumnFilter: false,
            size: 100,
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1, maxWidth: '120px' }}>
                    {row.original.order_status === 'Open' ? (
                        <Button
                            sx={{ backgroundColor: '#1d1d1d', color: '#ff7373', border: '1px solid #ff7373', px: 0, py: 0.1 }}
                            onClick={() => openCancleDialog(row.original)}
                        >
                            <CancelIcon />
                        </Button>
                    ) : (
                        <Button
                            disabled
                            sx={{ backgroundColor: 'transparent', color: '#808080', border: '1px solid #808080', px: 0, py: 0.1 }}
                        >
                            <CancelIcon />
                        </Button>
                    )}
                </Box>
            ),
            muiTableFooterCellProps: {
                align: 'center',
            },
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            enableSorting: false,
        }),
        columnHelper.accessor('Modify', {
            id: 'mofify',
            header: 'Modify',
            enableColumnFilter: false,
            size: 100,
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0.6, display: 'flex', maxWidth: '120px' }}>
                    {row.original.order_status === 'Open' ? (
                        <Button
                            sx={{ backgroundColor: '#1d1d1d', color: '#3b9599', border: '1px solid #3b9599', px: 0, py: 0.1 }}
                            onClick={() => openModifyDialog(row.original)}
                        >
                            <ModeEditIcon />
                        </Button>
                    ) : (
                        <Button
                            disabled
                            sx={{ backgroundColor: 'transparent', color: '#808080', border: '1px solid #808080', px: 0, py: 0.1 }}
                        >
                            <ModeEditIcon />
                        </Button>
                    )}
                </Box>
            ),
            muiTableFooterCellProps: {
                align: 'center',
            },
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            enableSorting: false,
        }),

    ];

    const throttledSetData = React.useCallback(throttle((newData) => {
        setData(newData);
        // console.log(newData)
    }, 2000), []);

    React.useEffect(() => {
        const socket = io(socket_server_url + "/testResponseLog");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on("test-responselog", (newData) => {
            if (newData) {
                throttledSetData(newData);
                setLoading(false)
                // console.log(newData)
                // console.log(newData[0]?.time_stamp);
                // console.log(calculateRowClassName(newData[0]?.time_stamp))
            } else {
                setLoading(false)
            }
        });
        // socket.emit('test-responselog', filter);
        return () => {
            socket.disconnect();
            throttledSetData.cancel();
        };
    }, [throttledSetData]);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setTableData(data);
            setIsLoading(false);
        }
    }, [data]);

    React.useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    React.useEffect(() => {
        const rootSocket = io(socket_server_url + "/");

        rootSocket.on('connect', () => console.log('Connected to root socket server'));
        rootSocket.on("disconnect", () => console.log("Disconnected from root socket server"));

        rootSocket.emit("responselog", "Test Update from frontend");

        return () => {
            rootSocket.disconnect();
        };
    }, []);

    const handleExportRows = (rows) => {
        try {
            if (!Array.isArray(rows) || rows.length === 0) {
                throw new Error("No rows to export.");
            }
            toast.success('Donwloaded Successfully')
            const doc = new jsPDF('landscape'); // Use landscape orientation

            // Process the table headers
            const tableHeaders = columns.map((c) => c.header || '');

            // Find the index of the 'dealerID' column
            const dealerIDIndex = columns.findIndex(c => c.id === 'dealerID');

            const tableData = rows.map((row) => {
                return columns.map((column) => {
                    const cellValue = row.original ? row.original[column.id] : '';
                    return cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
                });
            });

            autoTable(doc, {
                theme: 'grid',
                head: [tableHeaders],
                body: tableData,
                styles: {
                    overflow: 'linebreak',
                    cellWidth: 'wrap',
                    fontSize: 8,
                },
                columnStyles: {
                    // Set custom width for 'dealerID' column
                    [dealerIDIndex]: { cellWidth: 50 }, // Adjust the width as needed
                },
                startY: 10,
                margin: { top: 10, right: 10, bottom: 10, left: 10 },
                didDrawPage: function (data) {
                    doc.text('Page ' + doc.internal.getNumberOfPages(), doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
                },
            });
            const csvContent = [tableHeaders.join(',')];
            tableData.forEach((row) => {
                csvContent.push(row.join(','));
            });
            const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);
            const csvLink = document.createElement('a');
            csvLink.href = csvUrl;
            csvLink.setAttribute('download', 'Order_book.csv');
            document.body.appendChild(csvLink);
            csvLink.click();
            document.body.removeChild(csvLink);

            doc.save('Order_book.pdf');
        } catch (error) {
            toast.error('Download Failed')
            console.error("Error while generating PDF: ", error);
            alert("An error occurred while generating the PDF: " + error.message);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        getRowId: (row) => row.id,
        muiTableBodyRowProps: ({ row }) => ({
            //implement row selection click events manually
            onClick: () => { handleSelected(row) },
            hover: false,
            selected: selectedRows.some(selectedRow => selectedRow.id === row.id),
            sx: {
                cursor: 'pointer',
                backgroundColor: selectedRows.some(selectedRow => selectedRow.id === row.id) ? '#fff' : 'inherit', // Set background color to white if row is selected
                p: 0,
                border: 'none'
            },
        }),
        // muiTableProps: {
        //     sx: {
        //         border: '1px solid rgba(48, 124, 157, 0.7)',
        //         caption: {
        //             captionSide: 'top',
        //         },
        //     },
        // },
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                //   fontStyle: 'italic',
                fontWeight: '800',
                backgroundColor: '#021b26',
                fontSize: '15px',
                paddingY: 1.5
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                paddingY: 0,
            },
        },
        onRowSelectionChange: setSelectedRows,
        state: { selectedRows, loading, sorting },
        // columnFilterDisplayMode: 'popover',
        enablePagination: false,
        enableBottomToolbar: false,
        enableStickyHeader: true,
        enableDensityToggle: false,
        // enableColumnResizing: true,
        // initialState: { density: 'compact' },
        columnResizeMode: 'onChange',
        enableRowVirtualization: true,
        onSortingChange: setSorting,
        rowVirtualizerInstanceRef,
        enableGlobalFilterModes: true,
        enableColumnActions: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            density: 'compact',
            columnPinning: { left: ['dealer_id', 'symbol', 'order_status'] },
        },
        muiTableContainerProps: {
            sx: { maxHeight: '75vh' },className:'alarm-scrollbar'
        },
        rowVirtualizerOptions: { overscan: 5 }, renderTopToolbarCustomActions: ({ table }) => (
            <div className='flex gap-4'>
                <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    onClick={() =>
                        handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    style={{ py: 0.5, px: 0, marginBottom: '6px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize: "11px" }}
                    startIcon={<DownloadingOutlinedIcon />}
                >
                    Download
                </Button>
                {selectedRows?.length >= 2 && (
                    <Button
                        onClick={() => openAllCancelDialog()}
                        sx={{ backgroundColor: '#1d1d1d', color: '#ff7373', border: '1px solid #ff7373' }}
                    >
                        Cancel
                    </Button>
                )}
            </div>
        ),
    });
    const skeletonRows = [...Array(10)].map((_, rowIndex) => (
        <TableRow key={rowIndex}>
            {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <div>
                        <Skeleton animation="wave" height={30} />
                    </div>
                </TableCell>
            ))}
        </TableRow>
    ));

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '20px',
                    overflow: 'hidden',
                }}
                className='MUITable-scrollbar'
            >
                {loading ? (
                    <TableContainer component={Paper} style={{ maxHeight: '450px', overflow: 'auto' }} className='alarm-scrollbar'>
                        <Table sx={{ minWidth: 600 }} aria-label="skeleton table">
                            <TableHead sx={{ backgroundColor: '#021B26' }}>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} style={{ ...column.style }}>
                                            <Skeleton animation="wave" height={30} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: '#021B26' }}>{skeletonRows}</TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <MaterialReactTable
                        table={table}
                    />
                )}
            </Box>
            <Dialog
                open={isCancelDialog}
                onClose={() => setIsCancelDialog(false)}
                aria-labelledby="all-square-off-dialog-title"
                aria-describedby="all-square-off-dialog-description"
                sx={{ '& .MuiDialog-paper': { backgroundColor: '#011F2C' } }} // Example background color
            >
                <DialogTitle id="all-square-off-dialog-title">{"Confirm Cancel"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="all-square-off-dialog-description" sx={{ color: 'white' }}>
                        Are you sure you want to Cancel?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsCancelDialog(false)}>No</Button>
                    <Button onClick={handleCancle} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isAllCancleDialog}
                // onClose={() => setIsAllCancleDialog(false)}
                aria-labelledby="all-square-off-dialog-title"
                aria-describedby="all-square-off-dialog-description"
                sx={{ '& .MuiDialog-paper': { backgroundColor: '#011F2C' } }} // Example background color
            >
                <DialogTitle id="all-square-off-dialog-title">{"Confirm Cancel"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="all-square-off-dialog-description" sx={{ color: 'white' }}>
                        Are you sure you want to Cancel?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAllCancleDialog(false)}>No</Button>
                    <Button onClick={handleAllCancle} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isModifyDialog}
                // onClose={() => setIsModifyDialog(false)}
                aria-labelledby="all-square-off-dialog-title"
                aria-describedby="all-square-off-dialog-description"
                sx={{ '& .MuiDialog-paper': { backgroundColor: '#011F2C' } }} // Example background color
            >
                <DialogTitle id="all-square-off-dialog-title">{'Confirm Modify'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="all-square-off-dialog-description" sx={{ color: 'white' }}>
                        Are you sure you want to Modify?
                    </DialogContentText>
                    <TextField
                        type="text"
                        // defaultValue={rowToModify?.input_price || ''}
                        value={rowToModify?.input_price}
                        onChange={handleInputChange}
                        fullWidth
                        autoFocus
                        margin="normal"
                        label="Modify"
                        size='small'
                        variant="outlined"
                        style={{ marginBottom: '0px' }}
                    />
                </DialogContent>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 0, marginTop: 0 }}>
                    <Typography style={{ fontSize: '14px', marginLeft: '25px' }}>LTP: {ltpData}</Typography>
                    <Tooltip title="Refresh" placement='left' arrow>
                        <Button style={{ marginRight: '3px' }} onClick={() => handleRefresh(rowToModify)} color="primary">
                            <AutorenewIcon />
                        </Button>
                    </Tooltip>
                </Box>
                <DialogActions>
                    <Button onClick={() => setIsModifyDialog(false)}>No</Button>
                    <Button onClick={handleModify} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default Response;
