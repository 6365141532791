import MarginBarAdmin from './MarginBarAdmin';
import MarginBarHorizontal from './MarginBarHorizontal';
import Skeleton from '@mui/material/Skeleton';
import useFetchMarginData from "./fetchData/useFetchAdminData";
import MarginTable from './MarginTable';
import ComponentZoomModal from "../../../Hoc/ComponentZoomModal";
import React, { useState, useEffect } from 'react';

const marginDataName = 'All Dealers- Overnight Margin'
const marketValueName = 'Market Value'
const qtyTradedName = 'Qty Traded Value'
const marginBarName = 'Overnight Margin Used'

const Admin = () => {
    const [marginBarData, qtyTradedValue,isLoading] = useFetchMarginData();

         
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setIsLoading(false);
    //     }, 0);

    //     return () => clearTimeout(timer);
    // }, []);

    return (
        <div className='flex w-full gap-5 h-full border border-[#307C9D] border-opacity-50 rounded-md p-2'>
            <div className='w-[30vw] h-full'><MarginTable /></div>
            <div className="w-[70vw] flex flex-col gap-2">
                <div className="border border-[#307C9D] relative rounded-lg p-4 h-[38vh] w-full">
                    <div className="w-full flex justify-end absolute top-2 right-2">
                        <ComponentZoomModal
                            component={MarginBarAdmin}
                            componentProps={{ data: marginBarData, name: marginBarName }}
                        />
                    </div>
                    <div className='flex items-center justify-center h-full w-full'>
                        {isLoading ? (
                            <Skeleton animation='wave' variant="rounded" width="100%" height={200} />
                        ) : (marginBarData && marginBarData.length > 0 ? (
                            <MarginBarAdmin data={marginBarData} marginColor name={marginBarName} />
                        ) :
                            <p>Data is not available</p>
                        )}
                    </div>
                </div>
                <div className="border border-[#307C9D] relative rounded-lg p-4 h-[38vh] w-full">
                    <div className="w-full flex justify-end absolute top-2 right-2">
                        <ComponentZoomModal
                            component={MarginBarAdmin}
                            componentProps={{ data: qtyTradedValue, name: qtyTradedName }}
                        />
                    </div>
                    <div className='flex items-center justify-center h-full w-full'>
                        {isLoading ? (
                            <Skeleton animation='wave' variant="rounded" width="100%" height={200} />
                        ) : (qtyTradedValue && qtyTradedValue.length > 0 ? (
                            <MarginBarAdmin data={qtyTradedValue} qtyColor name={qtyTradedName} />
                        ) :
                            <p>Data is not available</p>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Admin;
