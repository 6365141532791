import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
  ReferenceDot,
  ErrorBar,
  ReferenceLine,
} from "recharts";
import { CircularProgress } from "@mui/material";

const MarginBarChart = () => {
  const [liveData, setLiveDataInternal] = useState([]);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;
  const [dataAvailable, setDataAvailable] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(api_server_url + `/dealer/barchart`)
        .then((res) => {
          // console.log(res.data);
          const chartData = res.data?.chart_data || [];
          const userNameData = chartData?.map((user) => user.name) || [];
          const usedMarginData =
            chartData?.map((dealer) => dealer.used_margin) || [];
          const totalMarginData =
            chartData?.map(
              (dealer) => dealer.total_margin - dealer.used_margin
            ) || [];
          const total = chartData?.map((dealer) => dealer.total_margin) || [];

          const trigger = chartData?.map((item) => item.trigger);

          const transformedData = userNameData.map((name, index) => ({
            name,
            usedMargin: usedMarginData[index],
            totalMargin: totalMarginData[index],
            total: total[index],
            trigger: trigger[index],
          }));

          // console.log(transformedData);
          setLiveDataInternal(transformedData);

          setDataAvailable(false);
        })

        .catch((error) => {
          console.error("Error fetching bar chart data:", error);

          setDataAvailable(false);
        });
    };
    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [api_server_url]);

  if (dataAvailable) {
    return (
      <div className="flex items-center justify-center h-full text-sm text-gray-500">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className="justify-center"
      style={{
        width: "100%",
        height: "95%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ResponsiveContainer>
        {liveData.length > 0 ? (
          <BarChart data={liveData} barGap={-20} >
            <XAxis
              dataKey="name"
              tick={{
                fontSize: "11px",
                fill: "#828282",
              }}
              scale="point"
              padding={{ left: 50, right: 50 }}
            />
            <YAxis
              tick={{
                fontSize: "10px",
                fill: "#828282",
              }}
            />
            <ReferenceLine y={0} stroke="white" />
            <Tooltip
              content={({ payload }) => {
                if (payload && payload.length > 0) {
                  const { name, usedMargin, totalMargin, total } =
                    payload[0].payload;
                  return (
                    <div
                      className="custom-tooltip"
                      style={{
                        background: "rgba(0, 0, 0, 0.6)",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        padding: "6px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      <p>Name: {name}</p>
                      <p>Used: {usedMargin?.toLocaleString("en-IN")}</p>
                      <p>Unused: {totalMargin?.toLocaleString("en-IN")}</p>
                      <p>Total: {total?.toLocaleString("en-IN")}</p>
                    </div>
                  );
                }
                return null;
              }}
            />

            <Legend
              wrapperStyle={{
                fontSize: "12px",
                paddingLeft: "10px",
                color: "#ccc",
              }}
              payload={[
                { value: "Used Margin", type: "rect", color: "#407388" },
                {
                  value: "Unused Margin",
                  type: "rect",
                  color: "#1B4152",
                },
              ]}
            />
            <Bar dataKey="usedMargin" stackId="a" fill="#407388" barSize={20}>
              {liveData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.usedMargin > entry.trigger ? "#A83B3B" : "#407388"
                  }
                />
              ))}
            </Bar>

            <Bar
              dataKey="totalMargin"
              stackId="a"
              fill="#1B4152"
              stroke="#97BFD0"
              strokeWidth={1}
              barSize={20}
              radius={[4, 4, 0, 0]}
            >
              {liveData?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    entry.usedMargin > entry.trigger ? "#6F1111" : "#1B4152"
                  }
                />
              ))}
            </Bar>

            <Bar
              dataKey="trigger"
              fill="none"
              stroke="#97BFD0"
              strokeWidth={1}
              barSize={20}
              // radius={[3, 3, 0, 0]}
            />

            {/* {liveData?.map((entry, index) => (
              <ReferenceDot
                key={`dot-${index}`}
                x={entry.name}
                y={entry.trigger}
                r={3}
                opacity={0.7}
                fill={"white"}
              />
            ))} */}
          </BarChart>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p>Data not available.</p>
          </div>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default MarginBarChart;
