import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@material-tailwind/react';
import Button from '@mui/material/Button';

// Define custom theme styling
const customTheme = createTheme({
    components: {
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    color: '#bbdefb',
                    borderRadius: 20,
                    borderWidth: 19,
                    borderColor: '#2196f3',
                    border: '1px solid',
                    backgroundColor: '#011620',
                }
            }
        }
    }
});

export default function DateSelected({ date: initialStartDate, setDate }) {
    // const [date, setDate] = React.useState();
    const [startDate, setStartDate] = React.useState(initialStartDate)

    const handleSetDate = () => {
        if (startDate) {
            const year = startDate.$y;
            const month = startDate.$M + 1; // Adding 1 because month is zero-based
            const day = startDate.$D;

            // Ensure month and day have leading zeros if necessary
            const formattedMonth = month < 10 ? `0${month}` : `${month}`; // Ensure month is a string
            const formattedDay = day < 10 ? `0${day}` : `${day}`; // Ensure day is a string

            const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
            setDate(formattedDate);
            console.log(formattedDate);
        } else {
            console.error('No date selected');
        }
    }

    return (
        // <ThemeProvider theme={customTheme} sx={{ p: 0, m: 0 }} style={{ padding: 0, margin: 0 }}>
        <LocalizationProvider sx={{ py: 0, px:1 }} dateAdapter={AdapterDayjs}>
            <DemoContainer sx={{ px: 1, py: 0 }} style={{ display: 'flex', alignItems: 'center', gap: 3 }} components={['DatePicker', 'DatePicker']}>
                <DesktopDatePicker
                    slotProps={{
                        input: {
                            sx: {
                                paddingY: '4px', // Adjust padding vertically
                                paddingX: '12px', // Adjust padding horizontally
                            },
                        },
                        layout: {
                            sx: {
                                '.MuiDateCalendar-root': {
                                    color: '#bbdefb',
                                    borderRadius: 2,
                                    borderWidth: 1,
                                    borderColor: '#2196f3',
                                    border: '1px solid',
                                    backgroundColor: '#011620',
                                    px: 1,
                                    py: 0,
                                    fontSize: '12px',
                                },
                            },
                        },
                    }}
                    openTo="year"
                    views={['year', 'month', 'day']}
                    onChange={value => setStartDate(value)}
                />
                <Button onClick={handleSetDate} style={{ fontSize: '11px', fontWeight: 600, backgroundColor: '#1B4152', py: 0, px: 1, color: '#fff' }}>Set Date</Button>
            </DemoContainer>
        </LocalizationProvider>
        // </ThemeProvider>
    );
}
