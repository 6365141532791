import React, { useState } from "react";

import PnlAdmin from "./PnlAdmin";
import PnlDealer from "./PnlDealer";
import MarginDealer from "./MarginDealer";

const Pnl = ({ isPnlAdmin }) => {
  // const [isPnlAdmin, setIsPnlAdmin] = useState("admin");
  const [dealerId, setDealerId] = useState("admin");
  const [dealerName, setDealerName] = useState("admin");

  return (
    <>
      <div className="flex justify-between">
        {/* <div className="w-fit">{isPnlAdmin==="admin" ? <></>: <DealerDropdown dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName}/>}</div> */}
      </div>
      {isPnlAdmin === "admin" ? (
        <PnlAdmin />
      ) : (
        <div>
          <div>
            <h1>P&L</h1>
            <PnlDealer dealerId={dealerId} dealerName={dealerName} />
          </div>
          <div>
            <h1>Margin</h1>
            <MarginDealer dealerId={dealerId} dealerName={dealerName} />
          </div>
        </div>
      )}
    </>
  );
};

export default Pnl;
