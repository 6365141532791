import React from 'react';
import DealerMain from './dealer/DealerMain';
import DealerDateSelection from '../components/dealer/DealerDateSelection';
import DealerSelection from '../../commoncomponent/DealerSelection';
import useFetchData from '../components/fetchData/useFetchData';

const Dealer = ({ dealerId, dealerName, setDealerId, setDealerName, dateSelected }) => {
  const [data] = useFetchData("dealer")
  const year = data?.years?.join(",");
  // console.log(year)
  return (
    <div className='flex flex-col gap-2 border-[1px] border-[#307C9D] p-2 w-full h-[78vh] rounded-lg overflow-hidden'>
      <div className="flex items-center border-b border-b-[#307C9D] pb-2" style={{ position: 'relative' }}>
        <div className="flex justify-start" style={{ position: 'absolute', top: 0 }}>
          <DealerSelection dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName} />
        </div>
        <div className="flex items-center flex-grow justify-center mt-2 mb-2 text-[12px] gap-5">
          {dealerId === dealerName ? (
            <span className='font-semibold text-[14px]'>{dealerId.charAt(0).toUpperCase() + dealerId.slice(1)}</span>
          ) : (
            <>
              <span className='font-semibold text-[14px]'>{dealerId.charAt(0).toUpperCase() + dealerId.slice(1)}</span>
              <span className='font-semibold text-[14px]'>{dealerName}</span>
            </>
          )}
        </div>
      </div>
      <div className="flex gap-2 overflow-auto">
        <DealerDateSelection dealerId={dealerId} dealerName={dealerName} />
        <div className='flex flex-col w-full gap-6 scroll-smooth overflow-y-auto alarm-scrollbar'>
          <div id="today">
            <DealerMain dealerId={dealerId} range='today' dateSelected={dateSelected} />
          </div>
          <div id="this_month">
            <DealerMain dealerId={dealerId} range='this_month' dateSelected={dateSelected} />
          </div>
          <div id="this_quarter">
            <DealerMain dealerId={dealerId} range='this_quarter' dateSelected={dateSelected} />
          </div>
          <div id="this_year">
            <DealerMain dealerId={dealerId} range='this_year' dateSelected={dateSelected} />
          </div>
          <div id="monthly">
            <DealerMain dealerId={dealerId} range='monthly' dateSelected={dateSelected} />
          </div>
          <div id="quarterly">
            <DealerMain dealerId={dealerId} range='quarterly' dateSelected={dateSelected} />
          </div>
          <div id="yearly">
            <DealerMain dealerId={dealerId} range='yearly' dateSelected={dateSelected} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dealer;
