import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip } from '@mui/material';

const Row = React.memo((props) => {
    const { row, headers, subheaders, subtableData } = props;
    const [open, setOpen] = React.useState(false);
    // console.log(subtableData)

    return (
        <React.Fragment>
            <TableRow
                key={row.DealerID}
                onClick={() => setOpen(!open)}
                sx={{
                    '&:hover': {
                        backgroundColor: '#1B4152',
                        cursor: 'pointer'
                    },
                    '& > .MuiTableCell-root': {
                        p: 0.4,
                        fontSize: '11px',
                    },
                    backgroundColor: '#021B26'
                }}
            >
                {headers?.map((header, idx) => (
                    <TableCell
                        key={header.id}
                        component="th"
                        scope="row"
                        align='center'
                        style={{ fontSize: '11px', cursor: 'pointer', borderBottom: '1px solid #307C9D', borderRight: '1px solid #307C9D', padding: '5px', }}
                    >
                        {
                            row[header.id] && (header.id === 'AvgMargin' || header.id === 'SigmaCost')
                                ? row[header.id].toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                : (typeof row[header.id] === 'number' ? (header.id === "Rank" ? row[header.id] : row[header.id].toFixed(2)) : row[header.id])
                        }
                    </TableCell>
                ))}
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, padding: 0, backgroundColor: '#001017', maxWidth: 600, overflow: 'auto' }} colSpan={16}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box>
                            <TableContainer className='custom-scrollbar' style={{ maxHeight: 300, backgroundColor: '#021B26' }}>
                                <Table aria-label="sticky table" stickyHeader>
                                    {/* <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                                        <TableRow>
                                            {subheaders.map((subheader) => (
                                                <TableCell
                                                    key={subheader.id}
                                                    align='center'
                                                    sx={{ ...subheader.style, fontSize: '12px', borderRight: '1px solid #307C9D', borderTop: '1px solid #307C9D', borderBottom: '1px solid #307C9D', backgroundColor: '#001017', fontWeight: 800 }}
                                                >
                                                    <Tooltip title={subheader.label} placement='bottom' arrow>
                                                        <span>{subheader.label}</span>
                                                    </Tooltip>
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead> */}
                                    <TableBody>
                                        {subtableData && subtableData.length > 0 && subtableData.map((subRow, index) => (
                                            <TableRow key={`${row?.DealerID}-${index}`} sx={{ backgroundColor: '#042939', borderBottom: '1px solid #307C9D' }}>
                                                {subheaders?.map((subheader) => (
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        align='center'
                                                        key={subheader.id}
                                                        sx={{ ...subheader.style, fontSize: '11px', borderRight: '1px solid #307C9D', padding: '5px', borderBottom: '1px solid #307C9D' }}
                                                    >
                                                        <span>
                                                            {
                                                                subRow[subheader.id] && (subheader.id === 'AvgMargin' || subheader.id === 'SigmaCost')
                                                                    ? subRow[subheader.id].toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                                                                    : (typeof subRow[subheader.id] === 'number' ? subRow[subheader.id].toFixed(2) : subRow[subheader.id])
                                                            }
                                                        </span>
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
});

Row.propTypes = {
    row: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    subheaders: PropTypes.array.isRequired,
    subtableData: PropTypes.array,
};


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    if (!Array.isArray(array)) {
        return [];
    }

    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


const MainTable = ({ headers, data, subheaders, subtableData }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('desc'); // Default orderBy

    const handleRequestSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const getSortIcon = (property) => {
        if (orderBy === property) {
            return order === 'asc' ? '↑' : '↓';
        }
        return '';
    };

    return (
        <Box>
            <TableContainer className='custom-scrollbar' component={Paper} style={{ maxHeight: '60vh', overflow: 'auto', backgroundColor: '#011F2C', border: '1px solid #307C9D', }}>
                <Table sx={{ minWidth: 600 }} stickyHeader aria-label="sticky table">
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableRow>
                            {headers.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align='center'
                                    onClick={() => handleRequestSort(header.id)}
                                    sx={{ ...header.style, fontSize: '12px', borderRight: '1px solid #307C9D', borderBottom: '1px solid #307C9D', backgroundColor: '#001017', fontWeight: 800, cursor: 'pointer' }}
                                >
                                    <Tooltip title={`${header.label} ${getSortIcon(header.id)}`} placement='bottom' arrow>
                                        <span>{header.label}</span>
                                    </Tooltip>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: '#021B26', fontSize: '11px' }}>
                        {stableSort(data, getComparator(order, orderBy))?.map((row, index) => (
                            <Row
                                key={index} // Use index as the key if row.DealerID is not unique
                                row={row} // Ensure that each row is an object
                                headers={headers}
                                subheaders={subheaders}
                                subtableData={subtableData[index]} // Pass the subtableData for this row
                            />
                        ))}

                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

MainTable.propTypes = {
    headers: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    subheaders: PropTypes.array.isRequired,
    subtableData: PropTypes.array.isRequired,
};

export default MainTable;
