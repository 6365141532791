import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import Firewall from '../Firewall';
import View from './View';

export default function FirewallTab() {
    const location = useLocation();

    const getTabClass = (tabName) => {
        const isActive = location.pathname.includes(tabName) || (tabName === 'edit' && location.pathname === '/firewall');
        const baseClass = `
            ${isActive ? 'bg-[#1B4152] text-white' : 'text-gray-500 hover:text-gray-200'}
            border-[1px] border-[#1B4152] px-4 py-2
            transition duration-300 ease-in-out
            font-semibold text-sm`;
    
        // Add additional styles for rounded borders
        if (tabName === 'edit') {
            return `${baseClass} rounded-l-md`;
        } else if (tabName === 'view') {
            return `${baseClass} rounded-r-md`;
        } else {
            return baseClass;
        }
    };
    

    return (
        <div className=" px-4 w-full border border-[#307C9D] border-opacity-50 rounded-md pt-4 pb-4">
            <nav className="w-full">
                <ul className="flex w-full items-center justify-center rounded-lg">
                    <li className="rounded-l-lg">
                        <Link to="/firewall/edit" className={getTabClass('edit')}>
                            Edit
                        </Link>
                    </li>
                    <li className="whitespace-nowrap">
                        <Link to="/firewall/view" className={getTabClass('view')}>
                            View
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="mt-6">
                <Routes>
                    <Route path="/edit" element={<Firewall />} />
                    <Route path="/view" element={<View />} />
                </Routes>
            </div>
        </div>
    );
}
