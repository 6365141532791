import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useRef } from "react";
import PanToolAltRoundedIcon from "@mui/icons-material/PanToolAltRounded";
import MultilegBuy from "./MultilegBuy";
import MultilegSell from "./MultilegSell";
import axios from "axios";

// Define the OptionChainTable component
const OptionChainTable = ({
  tableData,
  atm,
  selectedExpiry,
  selectedUnderlying,
  isLoading,
  dataChange,
}) => {
  const [multilegData, setMultilegData] = React.useState();
  const [modalData, setModalData] = React.useState();
  const [showModalCallBuy, setShowModalCallBuy] = React.useState(false);
  const [showModalPutBuy, setShowModalPutBuy] = React.useState(false);
  const [showModalCallSell, setShowModalCallSell] = React.useState(false);
  const [showModalPutSell, setShowModalPutSell] = React.useState(false);
  const tableRef = useRef(null);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  // useEffect hook to handle scrolling when data changes
  useEffect(() => {
    if (dataChange && tableRef?.current) {
      const halfway = Math.ceil(tableRef.current.rows.length / 2);
      const rowHeight =
        tableRef?.current?.rows[1]?.offsetTop -
        tableRef?.current?.rows[0]?.offsetTop;
      tableRef.current.parentNode.scrollTop = halfway * rowHeight;
    }
  }, [dataChange, tableData]);

  // Function to determine color for up movement in option price
  const getUpColor = (strike) => {
    var colorGreen = "#97FF95";
    return strike <= atm ? colorGreen : "";
  };

  // Function to determine color for down movement in option price
  const getDownColor = (strike) => {
    return strike > atm ? "#FF6565" : "";
  };

  // Function to determine font size based on whether strike is ATM
  const getATM = (strike) => {
    var small = "13px";
    var large = "18px";
    return strike !== atm ? small : large;
  };

  // Function to handle buying a call option
  const handleCallBuy = (rowData) => {
    axios
      .get(api_server_url + "/multileg")
      .then((res) => {
        setMultilegData(res.data);
        const tempModalData = {
          strike_price: rowData?.StrikePrice,
          selectedExpiry: selectedExpiry,
          selectedUnderlying: selectedUnderlying,
          type: "CE",
          exchange_segment: "NSEFO",
          inst_name: "OPTIDX",
          price: (rowData?.CE.Price).toString(),
          runPutRequest: false,
        };
        setModalData(tempModalData);
        setShowModalCallBuy(true);
      })
      .catch((e) => {
        console.log("Error: " + e.message);
      });
  };

  // Function to handle buying a put option
  const handlePutBuy = (rowData) => {
    axios
      .get(api_server_url + "/multileg")
      .then((res) => {
        setMultilegData(res.data);
        const tempModalData = {
          strike_price: rowData?.StrikePrice,
          selectedExpiry: selectedExpiry,
          selectedUnderlying: selectedUnderlying,
          type: "PE",
          exchange_segment: "NSEFO",
          inst_name: "OPTIDX",
          price: (rowData?.PE.Price).toString(),
          runPutRequest: false,
        };
        setModalData(tempModalData);
        setShowModalPutBuy(true);
      })
      .catch((e) => {
        console.log("Error: " + e.message);
      });
  };

  // Function to handle selling a call option
  const handleCallSell = (rowData) => {
    axios
      .get(api_server_url + "/multileg")
      .then((res) => {
        setMultilegData(res.data);
        const tempModalData = {
          strike_price: rowData?.StrikePrice,
          selectedExpiry: selectedExpiry,
          selectedUnderlying: selectedUnderlying,
          type: "CE",
          exchange_segment: "NSEFO",
          inst_name: "OPTIDX",
          price: (rowData?.CE.Price).toString(),
          runPutRequest: false,
        };
        setModalData(tempModalData);
        setShowModalCallSell(true);
      })
      .catch((e) => {
        console.log("Error: " + e.message);
      });
  };

  // Function to handle selling a put option
  const handlePutSell = (rowData) => {
    axios
      .get(api_server_url + "/multileg")
      .then((res) => {
        setMultilegData(res.data);
        const tempModalData = {
          strike_price: rowData?.StrikePrice,
          selectedExpiry: selectedExpiry,
          selectedUnderlying: selectedUnderlying,
          type: "PE",
          exchange_segment: "NSEFO",
          inst_name: "OPTIDX",
          price: (rowData?.PE.Price).toString(),
          runPutRequest: false,
        };
        // console.log(tempModalData);
        setModalData(tempModalData);
        setShowModalPutSell(true);
        // handleOpen();
      })
      .catch((e) => {
        console.log("Error: " + e.message);
      });
  };

  return (
      <TableContainer
        stickyHeader
        aria-label="sticky-table"
        component={Paper}
        style={{
          overflow: "auto",
          height: "75vh",
          backgroundColor: "#011F2C",
        }}
        className="alarm-scrollbar"
      >
        {/* Table component from Material-UI */}
        <Table
          ref={tableRef}
          style={{
            border: "1px solid #307C9D",
            // height: '80vh'
          }}
        >
          {/* TableHead component from Material-UI */}
          <TableHead
            stickyHeader
            aria-label="sticky-table"
            style={{
              border: "1px solid #307C9D",
              position: "sticky",
              top: -0.1,
              zIndex: 1,
              backgroundColor: "#011F2C",
            }}
          >
            {/* Table cells for column headers */}
            {/* Each TableCell represents a column header */}
            {/* Header cells for various data fields */}
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              B/S
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              IV
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              V(L)
            </TableCell>
            {/* <TableCell style={{backgroundColor:"#001017", fontSize:"14px", padding:0, margin:0, border:"1px solid #307C9D"}}>OI(L)</TableCell> */}
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              GAMMA
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              VEGA
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              THETA
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              DELTA
            </TableCell>
            {/* <TableCell>2x</TableCell> */}
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              LTP(CE)
            </TableCell>

            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              STRIKE
            </TableCell>
            {/* <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              STRIKE
            </TableCell> */}

            {/* PE Data */}
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              LTP(PE)
            </TableCell>
            {/* <TableCell>2x</TableCell> */}
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              DELTA
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              THETA
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              VEGA
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              GAMMA
            </TableCell>
            {/* <TableCell style={{backgroundColor:"#001017", fontSize:"14px", padding:0, margin:0, border:"1px solid #307C9D"}}>OI(L)</TableCell> */}
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              V(L)
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                fontWeight: "bold",
              }}
              align="center"
            >
              IV
            </TableCell>
            <TableCell
              style={{
                backgroundColor: "#001017",
                fontSize: "14px",
                padding: "6px 1px",
                margin: 0,
                border: "1px solid #307C9D",
                fontWeight: "bold",
              }}
              align="center"
            >
              B/S
            </TableCell>
            {/* </TableRow> */}
          </TableHead>

          {/* If loading, render Skeletons as placeholders */}
          {isLoading &&
            [...Array(10)].map((_, rowIndex) => (
              <TableRow key={rowIndex}>
                {[...Array(17)].map((_, colIndex) => (
                  <TableCell
                    key={colIndex}
                    style={{ padding: 15, backgroundColor: "#001017" }}
                  >
                    <Skeleton variant="text" width={50} height={20} />
                  </TableCell>
                ))}
              </TableRow>
            ))}

          {/* Modal components for buying and selling options */}
          {/* Modal components will be rendered conditionally based on state */}
          {showModalCallBuy && (
            <MultilegBuy
              multilegData={multilegData}
              modalData={modalData}
              setShowModalCallBuy={setShowModalCallBuy}
              showModalCallBuy={showModalCallBuy}
            />
          )}

          {showModalCallSell && (
            <MultilegSell
              multilegData={multilegData}
              modalData={modalData}
              setShowModalCallSell={setShowModalCallSell}
              showModalCallSell={showModalCallSell}
            />
          )}

          {showModalPutSell && (
            <MultilegSell
              multilegData={multilegData}
              modalData={modalData}
              setShowModalPutSell={setShowModalPutSell}
              showModalPutSell={showModalPutSell}
            />
          )}

          {showModalPutBuy && (
            <MultilegBuy
              multilegData={multilegData}
              modalData={modalData}
              setShowModalPutBuy={setShowModalPutBuy}
              showModalPutBuy={showModalPutBuy}
            />
          )}

          {/* TableBody component from Material-UI */}
          <TableBody>
            {tableData?.map((rowData, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  {/* CE Data */}
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                    }}
                    align="center"
                  >
                    <button
                      className="bg-opacity-70 bg-[#25A922] rounded-sm hover:bg-[#36BA33]"
                      onClick={() => handleCallBuy(rowData)}
                    >
                      <PanToolAltRoundedIcon style={{ color: "#ccc" }} />
                    </button>

                    <span style={{ margin: "0 5px" }}></span>

                    <button
                      className="bg-opacity-70 bg-[#A32626] rounded-sm hover:bg-[#BA3F3F]"
                      onClick={() => handleCallSell(rowData)}
                    >
                      <PanToolAltRoundedIcon
                        style={{ color: "#ccc", transform: "rotate(180deg)" }}
                      />
                    </button>
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      // fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getUpColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.CE.IV}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      margin: 0,
                      padding: "6px 1px",
                      border: "1px solid #307C9D",
                      color: getUpColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.CE.Volume}
                  </TableCell>
                  {/* <TableCell style={{backgroundColor:"#001720", fontSize:"13px", padding:0, margin:0, border:"1px solid #307C9D"}}>OI</TableCell> */}
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getUpColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.CE.Gamma}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getUpColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.CE.Vega}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getUpColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.CE.Theta}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getUpColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.CE.Delta}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getUpColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.CE.Price}
                  </TableCell>

                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      // fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      fontWeight: "bold",
                      fontSize: getATM(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.StrikePrice}
                  </TableCell>
                  {/* PE Data */}
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.PE.Price}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.PE.Delta}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.PE.Theta}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.PE.Vega}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.PE.Gamma}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.PE.Volume}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    {rowData.PE.IV}
                  </TableCell>
                  <TableCell
                    style={{
                      backgroundColor: "#001720",
                      fontSize: "13px",
                      padding: "6px 1px",
                      margin: 0,
                      border: "1px solid #307C9D",
                      color: getDownColor(rowData.StrikePrice),
                    }}
                    align="center"
                  >
                    <button
                      className="bg-opacity-70 bg-[#25A922] rounded-sm hover:bg-[#36BA33]"
                      onClick={() => handlePutBuy(rowData)}
                    >
                      <PanToolAltRoundedIcon style={{ color: "#ccc" }} />
                    </button>

                    <span style={{ margin: "0 5px" }}></span>
                    <button
                      className="bg-opacity-70 bg-[#A32626] rounded-sm hover:bg-[#BA3F3F]"
                      onClick={() => handlePutSell(rowData)}
                    >
                      <PanToolAltRoundedIcon
                        style={{ color: "#ccc", transform: "rotate(180deg)" }}
                      />
                    </button>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
};

export default OptionChainTable;
