import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { BarChart as BarChartIcon, Margin as MarginIcon, Assignment as AssignmentIcon } from '@mui/icons-material';
import AddToQueueIcon from '@mui/icons-material/AddToQueue';

const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const MenuList = ({ collapsed }) => {
    const location = useLocation();
    const imagePath = process.env.PUBLIC_URL + '/assests/logo2.svg';
    const [toggled, setToggled] = React.useState(false);
    const [hasImage, setHasImage] = React.useState(false);

    const isActive = (path) => {
        const currentPath = location.pathname;
        
        // Check if the current path includes 'controlPanel' or 'firewall'
        if (currentPath.includes('controlPanel') || currentPath.includes('firewall')) {
            return path === '/';
        }
    
        // Check if the current path exactly matches the provided path or if it starts with the provided path followed by a '/'
        return currentPath === path || currentPath.startsWith(path + '/');
    };
    

    // Style for active links
    const activeLinkStyle = {
        backgroundColor: '#307C9D', // Change to your desired active background color
        color: '#fff', // Change to your desired active text color
    };

    const theme = 'dark';
    const themes = {
        light: {
            sidebar: {
                backgroundColor: '#ffffff',
                color: '#607489',
            },
            menu: {
                menuContent: '#fbfcfd',
                icon: '#0098e5',
                hover: {
                    backgroundColor: '#c5e4ff',
                    color: '#44596e',
                },
                disabled: {
                    color: '#9fb6cf',
                },
            },
        },
        dark: {
            sidebar: {
                backgroundColor: '#1b4152',
                color: '#8ba1b7',
            },
            menu: {
                menuContent: '#123442',
                icon: '#fff',
                hover: {
                    backgroundColor: '#35667b',
                    color: '#b6c8d9',
                },
                disabled: {
                    color: '#3e5e7e',
                },
            },
        },
    };
    const menuItemStyles = {
        root: {
            fontSize: '13px',
            fontWeight: 500,
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${'ps-disabled'}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: collapsed ? 'transparent' : '#fff',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            [`&.${'ps-disabled'}`]: {
                color: themes[theme].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };

    return (
        <Sidebar
            collapsed={collapsed}
            toggled={toggled}
            onBackdropClick={() => setToggled(false)}
            backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
            rootStyles={{
                color: themes[theme].sidebar.color,
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                {/* <SidebarHeader rtl={rtl} style={{ marginBottom: '24px', marginTop: '16px' }} /> */}
                <div style={{ flex: 1, marginBottom: '32px' }}>
                    <div className='flex items-center justify-center'>
                        <Link to='/'>
                            {collapsed &&
                                <img src={imagePath} alt="" width={60} height={60} className='pr-4 pb-0 pt-[22px] pl-4 mb-[9px]' />
                            }
                            {!collapsed && (
                                <div className='flex flex-col text-center gap-0 pr-12'>
                                    <span className='text-[20px] font-bold text-white pt-3 pr-2 pb-0'>
                                        Trade<span className='text-[#71c2c6]'>Sage</span>.AI
                                    </span>
                                    <span className='text-[#909CA8] text-[10px]'>Powered by Mudraksh</span>
                                </div>
                            )}
                        </Link>
                    </div>
                    <Menu menuItemStyles={menuItemStyles}>
                        <SubMenu label="RMS" icon={<BarChartIcon />}>
                            <Link to='/' ><MenuItem style={isActive('/') ? activeLinkStyle : {}}>Dashboard</MenuItem></Link>
                            <Link to='/dashboard' ><MenuItem style={isActive('/dashboard') ? activeLinkStyle : {}}>Statistics</MenuItem></Link>
                            <Link to='/riskmanagement' ><MenuItem style={isActive('/riskmanagement') ? activeLinkStyle : {}}>Overnight Risk</MenuItem></Link>
                        </SubMenu>
                        <SubMenu label="MAlgo" icon={<MarginIcon />}>
                            <Link to='/sigmaOptimizer' ><MenuItem style={isActive('/sigmaOptimizer') ? activeLinkStyle : {}}>Sigma Optimizer</MenuItem></Link>
                            <Link to='/optionsChain' ><MenuItem style={isActive('/optionsChain') ? activeLinkStyle : {}}>Option Chain</MenuItem></Link>
                        </SubMenu>
                        <SubMenu label="OMS" icon={<AssignmentIcon />}>
                            <Link to='/datewiseposition'><MenuItem style={isActive('/datewiseposition') ? activeLinkStyle : {}}>Date Wise Positions</MenuItem></Link>
                            <Link to='/livePositions'><MenuItem style={isActive('/livePositions') ? activeLinkStyle : {}}>Live Positions</MenuItem></Link>
                            <Link to='/orderBook'><MenuItem style={isActive('/orderBook') ? activeLinkStyle : {}}>Order Book</MenuItem></Link>
                        </SubMenu>
                        <SubMenu label="Backoffice" icon={<AddToQueueIcon />}>
                            <Link to='/backoffice'><MenuItem style={isActive('/backoffice') ? activeLinkStyle : {}}>Backoffice</MenuItem></Link>
                        </SubMenu>
                    </Menu>
                </div>
                {/* <SidebarFooter collapsed={collapsed} /> */}
            </div>
        </Sidebar>
    );
}

export default MenuList;