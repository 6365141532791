import React from "react";
import MarginLineChart from "./MarginLineChart";
import MarginBarChart from "./MarginBarChart";
import MarginPieAdmin from "./MarginPieAdmin";
import ComponentZoomModal from "../../Hoc/ComponentZoomModal";

const MarginAdmin = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[40vh] w-[100%]">
        <div className="w-full flex relative">
          <h1 className="flex justify-center text-sm font-semibold w-full">
            Floor Margin
          </h1>
          <ComponentZoomModal component={MarginLineChart} />
        </div>
        <MarginLineChart />
      </div>
      <div className="flex flex-row gap-3">
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pb-1 h-[38vh] w-[50%]">
          <div className="w-full flex relative">
            <h1 className="flex justify-center text-sm font-semibold w-full">
              Dealerwise Margin - Bar
            </h1>
            <ComponentZoomModal component={MarginBarChart} />
          </div>
          <MarginBarChart />
        </div>

        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pb-2 h-[38vh] w-[50%]">
          <div className="w-full flex relative">
            <h1 className="flex justify-center text-sm font-semibold w-full">
              Dealerwise Margin - Pie
            </h1>
            <ComponentZoomModal component={MarginPieAdmin} />
          </div>
          <MarginPieAdmin />
        </div>
      </div>
    </div>
  );
};

export default MarginAdmin;
