import React from "react";
import { Select, MenuItem } from "@mui/material";
import useFetchData from "../fetchData/useFetchData";
import YearSelector from "./YearSelector";

const AdminDateSelection = ({ dateSelected, setDateSelected }) => {
  console.log(dateSelected);
  const [showYearSelection, setShowYearSelection] = React.useState(false);
  const [data] = useFetchData("admin");
  const years = data?.years;

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    setShowYearSelection(selectedValue === "quarterly" || selectedValue === "monthly");
    setDateSelected(selectedValue);
  };

  return (
    <div className="flex items-center justify-center">
      <Select defaultValue="today" value={dateSelected} size="small" onChange={handleSelect} sx={{ px: 1, py: 0, fontSize: '12px' }}>
        <MenuItem value="today">Today</MenuItem>
        <MenuItem value="this_month">This Month</MenuItem>
        <MenuItem value="this_quarter">This Quarter</MenuItem>
        <MenuItem value="this_year">This Year</MenuItem>
        <MenuItem value="monthly">Monthly</MenuItem>
        <MenuItem value="quarterly">Quarterly</MenuItem>
        <MenuItem value="yearly">Yearly</MenuItem>
      </Select>
      {showYearSelection && (
        <div className="w-[17vw] mt-[-12px] h-[36px]">
          <YearSelector years={years} />
        </div>
      )}
    </div>
  );
};

export default AdminDateSelection;
