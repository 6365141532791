import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, ReferenceLine } from "recharts";

const AdminBarChart = ({ data, name, color }) => {
    // Extracting ROM and AvgMargin and Month data from the provided data
    const chartData = data?.map((item, idx) => ({
        idx: idx,
        month: item?.Month,
        ROM: item?.ROM,
        AvgMargin: item?.MarginUsed,
        QtyTraded: item?.OrderQuantity,
        time: item?.Time
    }));

    return (
        <div
            className="justify-center"
            style={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: 'column'
            }}
        >
            <h1 className="text-[12px] text-center">{name}</h1>
            <ResponsiveContainer width="100%" height="100%">
                <BarChart data={chartData}>
                    <XAxis
                        dataKey="time"
                        tick={{
                            fontSize: "10px",
                            fill: "white",
                            angle: -18,
                            textAnchor: "end",
                          }}
                        scale="point"
                        padding={{ left: 50, right: 50 }}
                    />
                    <YAxis
                        tick={{
                            fontSize: "10px",
                            fill: "white",
                        }}
                        domain={['auto', 'auto']} // Set domain to auto for dynamic calculation of Y-axis range
                        allowDecimals={false} // Ensure Y-axis values are integers
                    />
                    <Tooltip
                        contentStyle={{
                            background: "rgba(0, 0, 0, 0.6)",
                            color: "white",
                            borderRadius: "5px",
                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.9)",
                            padding: "6px",
                            fontSize: "12px",
                            fontWeight: "bold",
                        }}
                    />
                    <ReferenceLine y={0}/>
                    <Bar dataKey="ROM" name="ROM" fill={color} barSize={10} key="ROM" />
                    <Bar dataKey="AvgMargin" name="Avg Margin" fill="#FFA500" barSize={10} key="AvgMargin" />
                    <Bar dataKey="QtyTraded" name="Qty Traded" fill="#03D6F8" barSize={10} key="QtyTraded" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default AdminBarChart;
