import React, { useState, useEffect } from 'react';
import { Tooltip } from '@mui/material';
const CurrentTime = () => {
    const [time, setTime] = useState(new Date());

    const getIST = (date) => {
        const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
        return new Date(utc + (3600000 * 5.5));
    };

    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(getIST(new Date()));
        }, 1000);

        return () => clearInterval(timerId);
    }, []);

    let hours = time.getHours();
    const minutes = String(time.getMinutes()).padStart(2, '0');
    const seconds = String(time.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    const strTime = hours.toString().padStart(2, '0') + ':' + minutes + ':' + seconds + ' ' + ampm;

    return (
        <div className='text-xs whitespace-nowrap'>
            <span>{strTime}</span>
        </div>
    );
};

export default CurrentTime;
