import React from 'react'
import Admin from './Admin';
import Dealer from './Dealer';
import Custom from './Custom';

const Margin = ({ isMarginAdmin, dealerId, dealerName, setDealerId, setDealerName, dateSelected }) => {
  return (
    <>
      {isMarginAdmin === "admin" ?
        <Admin dateSelected={dateSelected} />
        : (isMarginAdmin === "dealer" ?
          <Dealer dateSelected={dateSelected} dealerId={dealerId} setDealerId={setDealerId} dealerName={dealerName} setDealerName={setDealerName} />
          : <Custom />)}
    </>
  );
};

export default Margin;
