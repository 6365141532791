import React, { useState, useEffect } from "react";
import MarginPieDealer from "./MarginPieDealer";
import MarketValueDealer from "./MarketValueDealer";
import MarginDealerLineChart from "./MarginDealerLineChart";
import axios from "axios";

const MarginDealer = ({ dealerId, dealerName }) => {
  const [dealer, setDealer] = useState([]);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    axios.get(api_server_url + `/dealer/underlyingpnlchart`).then((res) => {

      const dealerName = res.data;
      const dealerNameFinal = dealerName.map((dealer) => ({
        value: dealer.id,
        label: dealer.name,
      }));

      setDealer(dealerNameFinal);
    });
  }, [api_server_url]);

  return (
    <div className="flex flex-col gap-3">
      <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pb-1 h-[32vh]">
        <div className="flex justify-center text-sm font-semibold">
          Dealer Margin
        </div>
        <MarginDealerLineChart dealerId={dealerId} dealerName={dealerName} />
      </div>

      <div className="flex flex-row gap-4">
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[32vh] w-[50%]">
          <MarginPieDealer dealerId={dealerId} dealerName={dealerName} />
        </div>
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[32vh] w-[50%]">
          <MarketValueDealer dealerId={dealerId} dealerName={dealerName} />
        </div>
      </div>
    </div>
  );
};

export default MarginDealer;
