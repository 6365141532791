import React from "react";
import PropTypes from "prop-types";

const Row = React.memo((props) => {
  const { row, headers, subheaders, subtableData } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <tr
        key={row.DealerID}
        onClick={() => setOpen(!open)}
        style={{
          "&:hover": {
            backgroundColor: "#1B4152",
            cursor: "pointer",
          },
          "& > .MuiTableCell-root": {
            fontSize: "11px",
          },
          backgroundColor: "#021B26",
        }}
      >
        {headers?.map((header, idx) => (
          <td
            key={header.id}
            component="tr"
            align="center"
            style={{
              fontSize: "11px",
              cursor: "pointer",
              borderBottom: "1px solid rgba(48, 124, 157, 0.7)",
              borderRight: idx === headers.length - 1 ? "none" : "1px solid rgba(48, 124, 157, 0.7)",
              padding: "5px",
              width: "75px",
              color:
                [
                  "NetPnl",
                  "TotalProfit",
                  "TotalLoss",
                  "AvgProfit",
                  "AvgLoss",
                ].includes(header.id) && row[header.id] > 0
                  ? "#70E66D"
                  : [
                      "NetPnl",
                      "TotalProfit",
                      "TotalLoss",
                      "AvgProfit",
                      "AvgLoss",
                    ].includes(header.id) && row[header.id] < 0
                  ? "#FF6565"
                  : "#fff",
            }}
          >
            {row[header.id]}
          </td>
        ))}
      </tr>

      <tr>
        <td
          style={{
            paddingBottom: 0,
            padding: 0,
            backgroundColor: "#001017",
            maxWidth: 600,
            overflow: "auto",
            width: "75px",
          }}
          colSpan={17}
        >
          <div style={{ display: open ? "block" : "none" }}>
            <table
              className="custom-scrollbar"
              style={{
                maxHeight: 300,
                backgroundColor: "#021B26",
                width: "100%",
              }}
            >
              <tbody>
                {subtableData?.map((subRow, index) => (
                  <tr
                    key={`${row?.DealerID}-${index}`}
                    style={{
                      backgroundColor: "#042939",
                    }}
                  >
                    {subheaders?.map((subheader, idx) => (
                      <td
                        key={subheader.id}
                        style={{
                          ...subheader.style,
                          fontSize: "11px",
                          borderRight: idx === headers.length - 1 ? "none" : "1px solid rgba(48, 124, 157, 0.7)",
                          padding: "5px ",
                          borderBottom: "1px solid rgba(48, 124, 157, 0.7)",
                          width: "75px", 
                          textAlign: "center",
                        }}
                      >
                        <span>
                          {typeof subRow[subheader.id] === "number"
                            ? subRow[subheader.id].toFixed(0)
                            : subRow[subheader.id]}
                        </span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
});

Row.propTypes = {
  row: PropTypes.object.isRequired,
  headers: PropTypes.array.isRequired,
  subheaders: PropTypes.array.isRequired,
  subtableData: PropTypes.array.isRequired,
};

const PnlMainTable = ({ headers, data, subheaders, subtableData }) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("DealerID"); // Default orderBy

  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getSortIcon = (property) => {
    if (orderBy === property) {
      return order === "asc" ? "↑" : "↓";
    }
    return "";
  };

  return (
    <div style={{ border: "1px solid rgba(48, 124, 157, 0.7)" }}>
      <table
        className="custom-scrollbar"
        component="div"
        style={{
          maxHeight: "60vh",
          overflow: "auto",
          backgroundColor: "#011F2C",
          width: "100%",
        }}
      >
        <thead>
          <tr>
            {headers?.map((header, idx) => (
              <th
                key={header.id}
                align="center"
                onClick={() => handleRequestSort(header.id)}
                style={{
                  ...header.style,
                  fontSize: "11px",
                  padding : "6px 0",
                  borderRight: idx === headers.length - 1 ? "none" : "1px solid rgba(48, 124, 157, 0.7)",
                  borderBottom: "1px solid rgba(48, 124, 157, 0.7)",
                  backgroundColor: "#001017",
                  fontWeight: 800,
                  cursor: "pointer",
                  width: "75px",
                  textAlign: "center",
                }}
              >
                {header.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody style={{ backgroundColor: "#021B26", fontSize: "11px" }}>
          {data?.map((row, index) => (
            <Row
              key={row.DealerID} // Assuming DealerID is a unique identifier
              row={row}
              headers={headers}
              subheaders={subheaders}
              subtableData={subtableData[index]} // Pass the subtableData for this row
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

PnlMainTable.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  subheaders: PropTypes.array.isRequired,
  subtableData: PropTypes.array.isRequired,
};

export default PnlMainTable;
