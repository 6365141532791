import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "sonner";
import { AppBar, Toolbar } from "@mui/material";
import Select from "react-select";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";

// Style object for the modal
const style = {
  position: "absolute",
  top: "40%",
  left: "53%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "#001017",
  borderRadius: "5px",
  border: "1px solid #307C9D",
};

// Style object for the app bar
const appBarStyle = {
  borderBottom: "1px solid #307C9D",
  bgcolor: "#1d3d2f",
  borderRadius: "5px 5px 0 0",
  height: "35px",
};

export default function MultilegBuy({
  multilegData,
  modalData,
  setShowModalCallBuy,
  showModalCallBuy,
  showModalPutBuy,
  setShowModalPutBuy,
}) {
  // Function to handle closing the modal
  const handleClose = () => {
    if (showModalCallBuy) {
      setShowModalCallBuy(false);
    } else {
      setShowModalPutBuy(false);
    }
  };

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;
  const [runPutRequest, setRunPutRequest] = React.useState(false);
  const [selectedInstrumentType, setSelectedInstrumentType] = React.useState(
    modalData?.inst_name
  );
  const [selectedExchgSeg, setSelectedExchgSeg] = React.useState("");
  const [selectedUnderlying, setSelectedUnderlying] = React.useState("");
  const [selectedOptionType, setSelectedOptionType] = React.useState("");
  const [strikePrices, setStrikePrices] = React.useState([
    modalData?.strike_price,
  ]);
  const [selectedStrikePrice, setSelectedStrikePrice] = React.useState();
  const [selectedExpiry, setSelectedExpiry] = React.useState();
  const [selectedExpiries, setSelectedExpiries] = React.useState([
    modalData?.selectedExpiry,
  ]);
  const [selectedPrice, setSelectedPrice] = React.useState("");
  const [lotSize, setLotSize] = React.useState("0");
  const [validity, setValidity] = React.useState(multilegData?.Validity[0]);
  const [orderType, setOrderType] = React.useState(multilegData?.OrderType[0]);
  const [dealerList, setDealerList] = React.useState(multilegData?.DealerList);
  const [selectedDealer, setSelectedDealer] = React.useState(
    dealerList.length > 0 ? dealerList[0].id : null
  );
  const [selectedProductType, setSelectedProductType] = React.useState(
    selectedInstrumentType === "EQ"
      ? multilegData?.ProductTypeEQ?.[0]
      : multilegData?.ProductTypeFNO?.[0]
  );
  const [openDialogBox, setOpenDialogBox] = React.useState(false);

  // Mapping options for Select components
  const options = multilegData?.Underlyings?.map((option, index) => ({
    value: option,
    label: option,
  }));

  // Mapping options for Select components
  const ExchgOptions = (multilegData?.ExchangeSegment || []).map((option) => ({
    value: option,
    label: option,
  }));

  // Function to handle changes in selected symbol
  const handleSymbolChange = (selectedOption) => {
    setRunPutRequest(true);
    setSelectedUnderlying(selectedOption.value);
  };

  // const handleStrikeChange = (selectedOption) => {
  //   setSelectedStrikePrice(selectedOption.value)
  // }

  // Function to handle changes in selected instrument type
  const handleInstrumentTypeChange = (value) => {
    setSelectedInstrumentType(value);
  };

  // Function to handle changes in selected product type
  const handleProductTypeChange = (value) => {
    setSelectedProductType(value);
  };

  // const handleDealerChange = (value) => {
  //   setSelectedDealer(value);
  // };

  const dealerOptions = multilegData?.DealerList?.map((option) => ({
    value: option.id,
    label: `${option.id}`,
  }));

  // Function to handle changes in selected dealer(s)
  const handleDealerChange = (e) => {
    const val = e?.map(((i) => i.value)) 
    setSelectedDealer(val);
  };

  // const handleDealerChange = (selectedOption) => {
  //   if (selectedOption.value) {
  //     setSelectedDealer(selectedOption.id);
  //   } else {
  //     console.error("Invalid selectedOption:", selectedOption);
  //   }
  // };

  // Function to handle the Buy action
  const handleBuy = async () => {
    var orders = selectedDealer?.map((dealerID) => {
      return {
        ExchangeSegment: selectedExchgSeg,
        Underlying: selectedUnderlying,
        ContractExpiration: selectedExpiry,
        OptionType: selectedOptionType,
        Strike: selectedStrikePrice,
        DealerID: dealerID,
        ClientID: multilegData.ClientID,
        ProductType: selectedProductType,
        OrderType: orderType,
        OrderSide: "BUY",
        Validity: validity,
        Quantity: lotSize,
        LimitPrice: selectedPrice,
        InstrumentType: selectedInstrumentType,
      };
    });
    try {
      const response = await axios.post(
        api_server_url + `/multileg`,
        orders
        // [
        //   {
        //     ExchangeSegment: selectedExchgSeg,
        //     Underlying: selectedUnderlying,
        //     ContractExpiration: selectedExpiry,
        //     OptionType: selectedOptionType,
        //     Strike: selectedStrikePrice,
        //     DealerID: selectedDealer,
        //     ClientID: multilegData.ClientID,
        //     ProductType: selectedProductType,
        //     OrderType: orderType,
        //     OrderSide: "BUY",
        //     Validity: validity,
        //     Quantity: lotSize,
        //     LimitPrice: selectedPrice,
        //     InstrumentType: selectedInstrumentType,
        //   },
        // ]
      );
      // console.log("Post successful");
      // console.log(response.data);
      setOpenDialogBox(false);
      toast.success("Success");
    } catch (error) {
      console.error("Error during POST request:", error);
      const errorMesage = error.response.data;
      setOpenDialogBox(false);
      toast.error(errorMesage);
    }
  };

  // Function to fetch data
  const handleFetch = async () => {
    try {
      const response = await axios.post(api_server_url + `/price`, {
        // ExchangeInstrumentID,
        ExchangeSegment: selectedExchgSeg,
        Underlying: selectedUnderlying,
        ContractExpiration: selectedExpiry,
        OptionType: selectedOptionType,
        StrikePrice: selectedStrikePrice,
        InstrumentType: selectedInstrumentType,
      });
      // console.log("Fetch Successful");
      // console.log(response.data);
    } catch (error) {
      console.error("Error in Fetch:", error);
    }
  };

  // Function to open dialog box for confirmation
  const handleDialogBox = () => {
    setOpenDialogBox(true);
  };

  // Function to retrieve strike prices and other data based on selected parameters
  const putCall = () => {
    axios
      .put(api_server_url + `/multileg`, {
        ExchangeSegment: selectedExchgSeg,
        InstrumentType: selectedInstrumentType,
        Underlying: selectedUnderlying,
        OptionType: selectedOptionType,
      })
      .then((res) => {
        // console.log(res.data);
        setStrikePrices(res.data.Strikes);
        setLotSize(res.data.LotSize);
        setSelectedExpiries(res.data.Expiries);
        if (runPutRequest)
          setSelectedStrikePrice(res.data.AtmStrike.toString());
      })
      .catch((error) => {
        console.log("Put call not sent - ", error);
      });
  };

  // UseEffect hook to run putCall function on component mount and when selected parameters change
  React.useEffect(() => {
    if (
      selectedInstrumentType &&
      selectedExchgSeg &&
      selectedUnderlying &&
      selectedOptionType
    ) {
      putCall();
    }
  }, [
    selectedInstrumentType,
    selectedExchgSeg,
    selectedUnderlying,
    selectedOptionType,
  ]);

  React.useEffect(() => {
    if (modalData?.exchange_segment) {
      setSelectedExchgSeg(modalData?.exchange_segment);
    }
  }, [modalData?.exchange_segment]);
  React.useEffect(() => {
    if (modalData?.inst_name) {
      setSelectedInstrumentType(modalData.inst_name);
    }
  }, [modalData?.inst_name]);
  React.useEffect(() => {
    if (modalData?.selectedUnderlying) {
      setSelectedUnderlying(modalData.selectedUnderlying);
    }
  }, [modalData?.selectedUnderlying]);

  React.useEffect(() => {
    if (modalData?.strike_price) {
      setSelectedStrikePrice(modalData?.strike_price);
    }
  }, [modalData?.strike_price]);

  React.useEffect(() => {
    if (modalData?.price) {
      setSelectedPrice(modalData?.price);
    }
  }, [modalData?.price]);

  React.useEffect(() => {
    if (modalData?.selectedExpiry) {
      setSelectedExpiry(modalData?.selectedExpiry);
    }
  }, [modalData?.selectedExpiry]);

  React.useEffect(() => {
    if (modalData?.type) {
      setSelectedOptionType(modalData?.type);
    }
  }, [modalData?.type]);

  React.useEffect(() => {
    if (modalData?.type) {
      setSelectedOptionType(modalData?.type);
    }
  }, [modalData?.type]);

  return (
    <div>
      <Modal
        open={showModalCallBuy || showModalPutBuy}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AppBar position="static" sx={appBarStyle}>
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ flexGrow: 1 }} />
              <CloseIcon
                onClick={handleClose}
                style={{ marginBottom: "38px", cursor: "pointer" }}
              />
            </Toolbar>
          </AppBar>

          <div className="flex flex-col pl-[70px] pb-[27px] pt-[25px] pr-[70px]">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <label className="text-[14px] pl-1">Dealer</label>
                {/* <select
                  className="border p-1 text-sm pr-4 w-[210px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  value={selectedDealer}
                  onChange={(e) => handleDealerChange(e.target.value)}
                >
                  {multilegData?.DealerList?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {`${option.id} : ${option.name}`}
                    </option>
                  ))}
                </select> */}
                <Select
                  options={dealerOptions}
                  isMulti
                  value={dealerOptions?.find(
                    (option) => option.value === selectedDealer
                  )}
                  onChange={handleDealerChange}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "blue",
                      primary: "black",
                    },
                  })}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      borderColor: "white",
                      borderStyle: "solid",
                      textAlign: "top",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "white",
                      textAlign: "center",
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      color: "white",
                    }),
                  }}
                  className="w-[310px]"
                />
              </div>

              {/* <div className="flex flex-col">
                <label className="text-[14px] pl-1">Dealer</label>
                <Select
                  value={multilegData?.DealerList.find(
                    (option) => option.id === selectedDealer
                  )}
                  onChange={(selectedOption) =>
                    handleDealerChange(selectedOption.value)
                  }
                  options={multilegData?.DealerList.map((option) => ({
                    value: option.id,
                    label: `${option.id} : ${option.name}`,
                  }))}
                  isSearchable={true}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                  }}
                  className="text-sm text-black w-[240px]"
                />
              </div> */}

              {/* <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Exchg-Seg</label>
                <select
                  className="border text-sm p-1 pr-4 w-[140px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  value={selectedExchgSeg}
                  onChange={(e) => setSelectedExchgSeg(e.target.value)}
                >
                  {multilegData?.ExchangeSegment.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="flex flex-col">
                <label className="text-[14px] pl-1">Exchg-Seg</label>
                <Select
                  options={ExchgOptions}
                  value={{
                    value: selectedExchgSeg,
                    label: selectedExchgSeg,
                  }}
                  onChange={(selectedOption) => {
                    setRunPutRequest(true);
                    setSelectedExchgSeg(selectedOption.value);
                  }}
                  isSearchable={true}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "blue",
                      primary: "black",
                    },
                  })}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      borderColor: "white",
                      borderStyle: "solid",
                      textAlign: "top",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "white",
                      textAlign: "center",
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      color: "white",
                    }),
                  }}
                  className="text-sm text-black"
                />
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Order Type</label>
                <select
                  className="border text-sm p-1 pr-4 w-[140px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  onChange={(e) => setOrderType(e.target.value)}
                  value={orderType}
                >
                  {multilegData?.OrderType.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label className="text-[14px] pl-1">Inst Name</label>

                <select
                  className="border text-sm p-1 pr-4 w-[140px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  value={selectedInstrumentType}
                  onChange={(e) => {
                    setRunPutRequest(true);
                    handleInstrumentTypeChange(e.target.value);
                  }}
                >
                  {multilegData?.InstrumentType.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              {/* <div className="flex flex-col">
                <label className="text-[14px] pl-1">Symbol</label>
                <select
                  className="border text-sm p-1 pr-4 w-[180px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  value={selectedUnderlying}
                  onChange={(e) => setSelectedUnderlying(e.target.value)}
                >
                  {multilegData?.Underlyings.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="flex flex-col">
                <label className="text-[14px] pl-1">Symbol</label>
                <Select
                  options={options}
                  value={{
                    value: selectedUnderlying,
                    label: selectedUnderlying,
                  }}
                  onChange={handleSymbolChange}
                  isSearchable={true}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "blue",
                      primary: "black",
                    },
                  })}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      borderColor: "white",
                      borderStyle: "solid",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      color: "white",
                    }),
                  }}
                  className="text-sm text-black w-[180px]"
                />
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Opt Type</label>
                <select
                  className="border p-1 text-sm pr-4 w-[120px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  disabled={
                    selectedInstrumentType === "EQ" ||
                    selectedInstrumentType === "FUTIDX" ||
                    selectedInstrumentType === "FUTSTK"
                  }
                  value={selectedOptionType}
                  onChange={(e) => {
                    setRunPutRequest(true);
                    setSelectedOptionType(e.target.value);
                  }}
                >
                  {multilegData?.OptionType?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex justify-between pt-6">
              {/* <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Strike Price</label>
                <select
                  className="border p-1 text-sm pr-4 w-[120px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  disabled={selectedInstrumentType === "EQ"}
                  value={selectedStrikePrice}
                  onChange={(e) => setSelectedStrikePrice(e.target.value)}
                >
                  {strikePrices?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div> */}

              <div className="flex flex-col">
                <label className="text-[14px] pl-1">Total Qty</label>
                <input
                  className="border p-1 text-sm w-[80px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  value={lotSize}
                  onChange={(event) => setLotSize(event.target.value)}
                ></input>
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Expiry Date</label>
                <select
                  className="border p-1 text-sm pr-4 w-[130px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  disabled={selectedInstrumentType === "EQ"}
                  value={selectedExpiry}
                  onChange={(e) => setSelectedExpiry(e.target.value)}
                >
                  {selectedExpiries?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col">
                <label className="text-[14px] pl-1">Strike Price</label>
                <Select
                  isDisabled={selectedInstrumentType === "EQ"}
                  isSearchable={true}
                  value={{
                    value: selectedStrikePrice,
                    label: selectedStrikePrice,
                  }}
                  options={strikePrices?.map((option) => ({
                    value: option,
                    label: option,
                  }))}
                  onChange={(selectedOption) =>
                    setSelectedStrikePrice(selectedOption.value)
                  }
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary25: "blue",
                      primary: "black",
                    },
                  })}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      borderColor: "white",
                      borderStyle: "solid",
                    }),
                    singleValue: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    input: (provided, state) => ({
                      ...provided,
                      color: "white",
                    }),
                    menu: (provided, state) => ({
                      ...provided,
                      backgroundColor: "#081D26",
                      color: "white",
                    }),
                  }}
                  className="text-sm text-black w-[120px]"
                />
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Prod Type</label>
                <select
                  className="border p-1 text-sm pr-4 w-[120px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  value={selectedProductType}
                  onChange={(e) => handleProductTypeChange(e.target.value)}
                >
                  {selectedInstrumentType === "EQ"
                    ? multilegData?.ProductTypeEQ.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))
                    : multilegData?.ProductTypeFNO.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                </select>
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Validity</label>
                <select
                  className="border p-1 text-sm pr-4 w-[100px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  onChange={(e) => setValidity(e.target.value)}
                  value={validity}
                >
                  {multilegData?.Validity.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col ">
                <label className="text-[14px] pl-1">Price</label>
                <input
                  className="border p-1 text-sm pr-4 w-[100px] h-[38px] border-gray-300 rounded-md bg-[#081D26] hover:border-gray-100 focus:outline-none cursor-pointer"
                  value={selectedPrice}
                  onChange={(e) => setSelectedPrice(e.target.value)}
                ></input>
              </div>

              <div className="pt-5">
                <button
                  className="bg-[#03202D] text-[#93F2FF] pr-[20px] pl-[20px] h-[38px] pb-1 pt-1 rounded-md cursor-pointer "
                  onClick={handleFetch}
                  style={{ border: "1.2px solid #93F2FF", fontWeight: 600 }}
                >
                  Fetch
                </button>
              </div>

              <div className="pt-5">
                <button
                  className="bg-[#03202D] text-[#97FF95] pr-[29px] pl-[29px] h-[38px] pb-1 pt-1 rounded-md cursor-pointer "
                  onClick={handleDialogBox}
                  style={{ border: "1.2px solid #97FF95", fontWeight: 600 }}
                >
                  Buy
                </button>
              </div>

              <div className="pt-5">
                <button
                  className="bg-[#03202D] text-[#BFBFBF] pr-[18px] h-[38px] pl-[18px] pb-1 pt-1 rounded-md cursor-pointer"
                  style={{ border: "1.2px solid #BFBFBF", fontWeight: 600 }}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Dialog
        open={openDialogBox}
        onClose={() => setOpenDialogBox(false)}
        aria-labelledby="all-square-off-dialog-title"
        aria-describedby="all-square-off-dialog-description"
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#021B26" } }}
      >
        <DialogTitle id="all-square-off-dialog-title">
          {"Confirm Buy"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="all-square-off-dialog-description"
            sx={{ color: "white" }}
          >
            Are you sure you want to Buy?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={() => setOpenDialogBox(false)}>
            Cancel
          </Button>
          <Button
            onClick={handleBuy}
            color="success"
            variant="outlined"
            autoFocus
          >
            Buy
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
