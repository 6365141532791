import React from 'react'
import AnalyticsTab from './AnalyticsTab'

const Analytics = () => {
  return (
    <div className=''>
        <AnalyticsTab />
    </div>
  )
}

export default Analytics;