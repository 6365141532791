import React, { useEffect } from 'react';

const LogoutQuote = ({ onVideoEnd, onSkip }) => {
    useEffect(() => {
        const videoElement = document.getElementById('logout-video');
        const handleVideoEnd = () => {
            // Remove 'isLoggedOut' from localStorage
            localStorage.removeItem('isLoggedOut');
            // Trigger the callback function passed as prop
            onVideoEnd();
        };
        // Add event listener for the 'ended' event
        videoElement.addEventListener('ended', handleVideoEnd);
        // Clean up by removing the event listener when component unmounts
        return () => {
            videoElement.removeEventListener('ended', handleVideoEnd);
        };
    }, [onVideoEnd]);

    return (
        <div>
            <video
                id="logout-video"
                autoPlay
                muted
                style={{
                    width: "100%",
                    height: "100%",
                    opacity: 0.7,
                    objectFit: "cover",
                    position: "absolute",
                    zIndex: "-1",
                }}
            >
                <source
                    src="https://mudrakshnmcshaw.github.io/HarrierLogoutVideo/Logout.mp4"
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
            <div
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: 0, // Align to the left side
                    transform: 'translateY(-50%)', // Center vertically
                    textAlign: 'left', // Align text to the right
                    color: 'white', // Text color
                    marginLeft: '5vw', // Add some left padding
                    marginTop: '10vh',
                    fontSize: '4rem',
                }}
            >
                Technology <b style={{ color: "orange", fontFamily: 'raleway' }}>will</b> win.
                <br />
                Not immediately but definitely.
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: '10px',
                    right: '10px',
                }}
            >
                <button onClick={onSkip} style={{ background: 'none', border: 'none', color: 'white', cursor: 'pointer' }}>
                    Go to Login
                </button>
            </div>
        </div>
    )
};

export default LogoutQuote;
