import React, { useState, useEffect } from "react";
import PnlAllUnderlyings from "./PnlAllUnderlyings";
import PnlPieDealer from "./PnlPieDealer";
import axios from "axios";
import PayoffPnlDealer from "./PayoffPnlDealer";
import DealerDropdown from "./DealerDropdown";

const PnlDealer = ({ dealerId, dealerName }) => {
  const [dealer, setDealer] = useState([]);

  const [dealerIdD, setDealerIdD] = useState("admin");
  const [dealerNameD, setDealerNameD] = useState("admin");

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    axios.get(api_server_url + `/dealer/underlyingpnlchart`).then((res) => {
      // console.log(res.data);

      const dealerName = res.data;
      const dealerNameFinal = dealerName.map((dealer) => ({
        value: dealer.id,
        label: dealer.name,
      }));

      setDealer(dealerNameFinal);
    });
  }, [api_server_url, dealerIdD]);

  return (
    <div className="flex flex-col gap-3 w-full">
      <div className="flex items-center text-sm flex-grow justify-center gap-2">
        <span>{dealerIdD.charAt(0).toUpperCase() + dealerIdD.slice(1)}</span>
        {dealerNameD !== "admin" && <span>{dealerNameD}</span>}
      </div>
      <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-3 pt-2 h-[42vh]">
        <div className="flex items-center" style={{ position: "relative" }}>
          <div
            className="flex justify-start"
            style={{ position: "absolute", top: 0 }}
          >
            <DealerDropdown
              dealerId={dealerIdD}
              setDealerId={setDealerIdD}
              dealerName={dealerNameD}
              setDealerName={setDealerNameD}
            />
          </div>
        </div>
        <div className="flex justify-center text-sm font-semibold">
          Projection Chart
        </div>
        <PayoffPnlDealer dealerId={dealerIdD} dealerName={dealerName} />
      </div>

      <div className="flex flex-row gap-4">
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 pt-3 pb-0 h-[32vh] w-[50%]">
          <div className="flex justify-center text-sm font-semibold">
            Underlying P&L
          </div>
          <PnlAllUnderlyings dealerId={dealerIdD} dealerName={dealerName} />
        </div>
        <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[32vh] w-[50%]">
          <PnlPieDealer dealerId={dealerIdD} dealerName={dealerName} />
        </div>
      </div>
    </div>
  );
};

export default PnlDealer;
