import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import { Paper } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

const headCells = [
  { id: 'date', disablePadding: true, label: 'Date Modified', align: 'center' },
  { id: 'portfolio', disablePadding: false, label: 'Updated Value', align: 'center' },
  { id: 'cashflow', disablePadding: false, label: 'Pay In / Pay Out', align: 'center' },
];

function EnhancedTableHead({ order, orderBy, onRequestSort }) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={{ position: 'sticky', top: 0, backgroundColor: '#001017', borderBottom: '1px solid #307C9D', borderRight: '1px solid #307C9D', padding: '5px', margin: '0px' }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>

  );
}
export default function PortfolioUpdate({ setShowPortfolio, data }) {
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('time');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedRows = useMemo(() => {
    return stableSort(data, getComparator(order, orderBy));
  }, [data, order, orderBy]);

  const handleClick = () => {
    setShowPortfolio(false);
  };

  return (
    <div className="flex flex-col gap-2 border border-[#307C9D] h-[78vh] border-opacity-50 rounded-lg p-4">
      <div className="self-start">
        <button onClick={handleClick} className="text-[#8AC3DB] text-sm">
          <ArrowBackIosRoundedIcon fontSize="small" />
          Back
        </button>
      </div>
      <Box className='alarm-scrollbar' sx={{ zIndex: 2 }}>
        <Paper sx={{ width: '100%', mb: 2, overflow: 'hidden' }}>
          <TableContainer style={{ maxHeight: '70vh', border: '1px solid #307C9D' }} className="alarm-scrollbar">
            <Table
              stickyHeader
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size='medium'
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody sx={{ backgroundColor: '#021B26' }}>
                {sortedRows.map((row, idx) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={idx}
                      style={{ padding: '5px', margin: '0px' }}
                    >
                      <TableCell align="center" sx={{ borderRight: '1px solid #307C9D', py: 0.6, borderBottom: '1px solid #307C9D' }}>
                        <div className='flex justify-center items-center' style={{ width: '60%', justifyContent: 'flex-end' }}>
                          {row.date}
                        </div>
                      </TableCell>


                      <TableCell align="center" sx={{ borderRight: '1px solid #307C9D', py: 0.6, borderBottom: '1px solid #307C9D' }}>
                        <div className='flex justify-center items-center' style={{ width: '60%', justifyContent: 'flex-end' }}>
                          {new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                          }).format(row?.portfolio)}
                        </div>
                      </TableCell>
                      <TableCell align="center" sx={{ py: 0.6, borderBottom: '1px solid #307C9D' }}>
                        <div className='flex justify-center items-center' style={{ width: '60%', justifyContent: 'flex-end', color: row.cashflow > 0 ? '#97FF95' : '#FF6565' }}>
                          {new Intl.NumberFormat('en-IN', {
                            style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0
                          }).format(row?.cashflow)}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </div>
  );
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
