import { BarChart, Bar, Brush, XAxis, YAxis, Tooltip, ReferenceLine, ResponsiveContainer } from "recharts";

const MarginBarDealer = ({ data, name, qtyColor }) => {

  // Extract dealer names and values from the marketValue data
  const chartData = data?.map(obj => {
    const dealer = Object.keys(obj)[0];
    const value = obj[dealer];
    return { name: dealer, value };
  });

  return (
    <div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: 'column' }}>
      <div className="flex w-full items-center">
        <h1 className="flex-grow text-center text-[13px] font-bold mb-3">{name}</h1>
      </div>
      <ResponsiveContainer>
        <BarChart data={chartData}>
          <XAxis
            dataKey="name"
            tick={{ fontSize: "10px", fill: "white", }}
            angle={-12}
            scale="point"
            padding={{ left: 20, right: 30, top: 4 }}
            textAnchor="end"
          />
          <YAxis
            tick={{ fontSize: "9px", fill: "white" }}
            // angle={-9}
            interval={0}
            width={50}
          />
          <Tooltip
            className='recharts-tooltip-wrapper'
            contentStyle={{
              background: "rgba(0, 0, 0, 0.6)",
              color: "white",
              borderRadius: "5px",
              boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.9)",
              padding: "6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          />
          {/* <Legend
            wrapperStyle={{
              fontSize: "12px",
              paddingLeft: "10px",
              color: "#ccc",
            }}
          /> */}
          <ReferenceLine stroke='white' y={0} />
          <Bar barSize={10} dataKey="value" stackId="a" fill={qtyColor ? '#37C69B' : '#03D6F8'} />
          <Brush height={11} stroke="black" fill="slategray" style={{ marginTop: 100 }} />
        </BarChart>
      </ResponsiveContainer>
    </div>

  );
};

export default MarginBarDealer;
