import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import Chart from "../../../pages/Dashboard/Chart"; // Importing the Chart component
import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

// Define the PayoffChart functional component
const PayoffChart = () => {
  // Initialize state variables using useState hook
  const location = useLocation(); // Get the current location
  const [dealerId, setDealerId] = useState(null); // State variable for dealer ID
  const [dealerName, setDealerName] = useState(null); // State variable for dealer name
  const [underlyings, setUnderlyings] = useState([]); // State variable for underlyings
  const [selectedUnderlying, setSelectedUnderlying] = useState(""); // State variable for selected underlying
  const [allPositions, setAllPositions] = useState([]); // State variable for all positions
  const [positions, setPositions] = useState([]); // State variable for positions
  const [allChartData, setAllChartData] = useState([]); // State variable for all chart data
  const [chartData, setChartData] = useState([]); // State variable for chart data
  const [showPositions, setShowPositions] = useState(false); // State variable to toggle display of positions
  const [greeks, setGreeks] = useState([]); // State variable for greeks data
  const [showGreeks, setShowGreeks] = useState(true); // State variable to toggle display of greeks

  // Function to fetch data from the API server
  const fetchData = (
    id,
    setUnderlyings,
    setAllChartData,
    setAllPositions,
    setChartData,
    setPositions,
    setGreeks,
    showGreeks
  ) => {
    const payoffData = { dealer_id: id }; // Data to be sent in the request body
    const api_server_url = process.env.REACT_APP_API_SERVER_URL; // API server URL
    axios
      .post(api_server_url + "/dealer/payoff", payoffData, {
        withCredentials: true, // Send cookies with the request
      })
      .then((response) => {
        // Handle successful response
        setUnderlyings(response.data.underlyings); // Set the underlyings state variable
        setAllChartData(response.data.projections); // Set the all chart data state variable
        setAllPositions(response.data.positions); // Set the all positions state variable
        
        // Set the positions, chart data, greeks, and selected underlying based on response data
        if (response.data.underlyings.length > 0) {
          const underlying = response.data.underlyings[0];
          setGreeks(response.data.projections[underlying]?.greeks);
          setChartData(response.data.projections[underlying]);
          setPositions(response.data.positions[underlying]);
          setShowPositions(true);
          setShowGreeks(true);
          setSelectedUnderlying(underlying);
        }
      })
      .catch((error) => {
        // Handle errors
        console.error("Error fetching data:", error);
      });
  };

  // useEffect hook to fetch data when component mounts
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search); // Get URL search parameters
    const id = searchParams.get("dealer_id"); // Get dealer ID from search parameters
    const name = decodeURIComponent(searchParams.get("dealer_name")); // Get dealer name from search parameters

    // Fetch data for the default underlying (first one in the list)
    fetchData(
      id,
      setUnderlyings,
      setAllChartData,
      setAllPositions,
      setChartData,
      setPositions,
      setGreeks,
      showGreeks
    );

    // Set dealer ID and dealer name state variables
    setDealerId(id);
    setDealerName(name);
  }, []); // useEffect dependency array

  useEffect(() => {}, [positions, chartData, selectedUnderlying, showGreeks]); // Empty useEffect to watch specific state variables

  // Function to handle click on underlying option
  const handleUnderlyingClick = (underlying) => {
    setSelectedUnderlying(underlying); // Set selected underlying state variable
    setPositions(allPositions[underlying]); // Set positions based on selected underlying
    setChartData(allChartData[underlying]); // Set chart data based on selected underlying
    setGreeks(allChartData[underlying]?.greeks); // Set greeks based on selected underlying
    setShowPositions(true); // Show positions
    setShowGreeks(true); // Show greeks
  };

  // Ref to parent div for scroll functionality
  const parentRef = useRef(null);

  // Function to scroll to bottom
  const scrollToBottom = () => {
    if (parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  };

  // Function to scroll to top
  const scrollToTop = () => {
    if (parentRef.current) {
      parentRef.current.scrollTop = 0;
    }
  };

  // JSX to render the component
  return (
    <div className="border border-[#307C9D] border-opacity-50 m-4 mt-8 p-4 rounded-lg h-[83vh] flex justify-between">
      {showPositions && (
        <div className="p-3 pl-5 flex flex-col">
          {/* Dealer ID and name display */}
          <ul
            className="flex space-x-1 gap-5 justify-between border border-slate-600 p-2 rounded-md w-auto "
            style={{ width: "300px" }}
          >
            <li className="pl-2">{dealerId}</li>
            <li className="pr-2">{dealerName}</li>
          </ul>
          {/* Select underlying dropdown */}
          <div className="">
            <div className="mt-4 mb-2 font-bold">UNDERLYINGS</div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select an Underlying
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                className="rounded-md pr-4 bg-transparent focus:outline-none cursor-pointer"
                onChange={(e) => handleUnderlyingClick(e.target.value)}
                label="Select an Underlying"
                value={selectedUnderlying}
              >
                {underlyings?.map((underlying, index) => (
                  <MenuItem key={index} value={underlying}>
                    {underlying}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Display positions */}
          <div className="flex flex-col">
            <div className="mt-4 mb-2 font-bold">POSITIONS</div>
            <div
              ref={parentRef}
              className="rounded-md border border-slate-600 w-[300px] max-h-[200px] overflow-y-scroll alarm-scrollbar"
            >
              {/* Scroll to bottom button */}
              {positions?.length > 3 ? (
                <div className="sticky top-0 flex justify-center">
                  <button onClick={scrollToBottom}>
                    <KeyboardArrowDownRoundedIcon />
                  </button>
                </div>
              ) : null}
              {/* Position items */}
              {positions?.map((position, index) => (
                <div
                  className="border-b p-2 border-slate-600 flex w-full justify-between items-center"
                  key={index}
                >
                  {/* Position details */}
                  <p className="w-[120px] text-[12px]">
                    {position.TradingSymbol}
                  </p>
                  <p className="text-[12px]">
                    {position.OrderAverageTradedPrice}
                  </p>
                  <p className="text-[12px]">{position.Quantity}</p>
                  {/* Indicator for positive or negative quantity */}
                  <p className="flex justify-end float-end">
                    {position.Quantity >= 0 ? (
                      <span
                        style={{
                          color: "green",
                          fontSize: "1.5rem",
                          height: "10px",
                          width: "10px",
                          borderRadius: "100%",
                          backgroundColor: "#97FF95",
                        }}
                      ></span>
                    ) : (
                      <span
                        style={{
                          color: "red",
                          fontSize: "1.5rem",
                          height: "10px",
                          width: "10px",
                          borderRadius: "100%",
                          backgroundColor: "#FF6565",
                        }}
                      ></span>
                    )}
                  </p>
                </div>
              ))}
              {/* Scroll to top button */}
              {positions.length > 3 ? (
                <div className="sticky top-0 flex justify-center">
                  <button onClick={scrollToTop}>
                    <KeyboardArrowUpRoundedIcon />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
          {/* Display greeks */}
          {Array.isArray(greeks) ? (
            <div className="flex items-center justify-center">
              {greeks.map((item, idx) => (
                <div className="p-3 flex items-center rounded-md mt-3 flex-col justify-center border border-slate-600 w-[300px] gap-2 h-[110px]">
                  {/* Greek values */}
                  <div className="flex items-center justify-between w-full text-sm">
                    <h1>Delta</h1>
                    <p>{(item?.delta).toFixed(2)}</p>
                  </div>
                  <div className="flex items-center justify-between w-full text-sm">
                    <h1>Gamma</h1>
                    <p>{(item?.gamma).toFixed(2)}</p>
                  </div>
                  <div className="flex items-center justify-between w-full text-sm">
                    <h1>Theta</h1>
                    <p>{(item?.theta).toFixed(2)}</p>
                  </div>
                  <div className="flex items-center justify-between w-full text-sm">
                    <h1>Vega</h1>
                    <p>{(item?.vega).toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="p-3 flex items-center rounded-md mt-3 flex-col justify-center border border-slate-600 w-[300px] gap-2 h-[110px]">
              {/* Greek values */}
              <div className="flex items-center justify-between w-full text-sm">
                <h1>Delta</h1>
                <p>{(greeks?.delta).toFixed(2)}</p>
              </div>
              <div className="flex items-center justify-between w-full text-sm">
                <h1>Gamma</h1>
                <p>{(greeks?.gamma).toFixed(2)}</p>
              </div>
              <div className="flex items-center justify-between w-full text-sm">
                <h1>Theta</h1>
                <p>{(greeks?.theta).toFixed(2)}</p>
              </div>
              <div className="flex items-center justify-between w-full text-sm">
                <h1>Vega</h1>
                <p>{(greeks?.vega).toFixed(2)}</p>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Display chart */}
      <div className=" w-[100%] h-[100%] pt-3">
        {selectedUnderlying && (
          <Chart
            exp={chartData?.chart_exp}
            chartData={chartData?.chart}
            middle={chartData?.middle}
          />
        )}
      </div>
    </div>
  );
};

export default PayoffChart; 
