import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import Chart from "../../../pages/Dashboard/Chart";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";

const fetchData = (
  id,
  setUnderlyings,
  setAllChartData,
  setAllPositions,
  setChartData,
  setPositions,
  setSelectedUnderlying,
  setShowPositions,
  setDataAvailable
) => {
  const payoffData = { dealer_id: id };
  // console.log(id);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;
  axios
    .post(api_server_url + "/dealer/payoff", payoffData, {
      withCredentials: true,
    })
    .then((response) => {
      // console.log(response.data);
      setUnderlyings(response.data.underlyings);
      setAllChartData(response.data.projections);
      setAllPositions(response.data.positions);
      // if (response.data.underlyings?.length() > 0) {
      const underlying = response.data.underlyings[0];
      setSelectedUnderlying(underlying);
      setChartData(response.data.projections[underlying]);
      setPositions(response.data.positions[underlying]);
      setShowPositions(underlying);

      // }
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      if (error.response.status === 500) {
        setDataAvailable(false);
      }
    });
};

const PayoffPnlDealer = ({ dealerId, dealerName }) => {
  // console.log(dealerId);
  const [underlyings, setUnderlyings] = useState([]);
  const [selectedUnderlying, setSelectedUnderlying] = useState(null);
  const [allPositions, setAllPositions] = useState([]);
  const [positions, setPositions] = useState([]);
  const [allChartData, setAllChartData] = useState([]);
  const [chartData, setChartData] = useState([]);
  // const [liveData, setLiveData] = useState([]);
  const [underlying, setUnderlying] = useState([]);
  const [showPositions, setShowPositions] = useState(false);
  const [dataAvailable, setDataAvailable] = useState(true);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  useEffect(() => {
    axios
      .post(api_server_url + `/dealer/payoff`, {
        dealer_id: dealerId,
      })
      .then((res) => {
        // console.log(res.data);
        setAllChartData(res.data.projections);

        setUnderlying(res.data.underlyings);
      })
      .catch((error) => {
        console.error("Error fetching data for the selected dealer:", error);
      });

    axios.get(api_server_url + `/dealer/underlyingpnlchart`).then((res) => {
      fetchData(
        dealerId,
        setUnderlyings,
        setAllChartData,
        setAllPositions,
        setChartData,
        setPositions,
        setSelectedUnderlying,
        setShowPositions,
        setDataAvailable
      );
    });
  }, [api_server_url, dealerId]);

  const handleUnderlyingClick = (underlying) => {
    setSelectedUnderlying(underlying);
    setPositions(allPositions[underlying]);

    setChartData(allChartData[underlying]);

    setShowPositions(true);
  };

  const parentRef = useRef(null);

  const scrollToBottom = () => {
    if (parentRef.current) {
      parentRef.current.scrollTop = parentRef.current.scrollHeight;
    }
  };

  const scrollToTop = () => {
    if (parentRef.current) {
      parentRef.current.scrollTop = 0;
    }
  };

  return (
    <div className="flex flex-row h-[100%] w-full justify-between overflow-y-hidden">
      <div className="flex flex-col h-[100%]">
        <div className="flex flex-col h-[100%] ">
          <h1 className="p-1 text-[14px] font-semibold">Underlyings</h1>
          <Select
            fullWidth
            size="small"
            className="rounded-md bg-transparent focus:outline-none cursor-pointer"
            onChange={(e) => handleUnderlyingClick(e.target.value)}
            value={selectedUnderlying}
          >
            {underlyings?.length > 0 ? (
              underlyings?.map((underlying, index) => (
                <MenuItem key={index} value={underlying}>
                  {underlying}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled>No Underlyings</MenuItem>
            )}
          </Select>
        </div>

        {showPositions && (
          <div className="flex flex-col h-[100%]">
            <h1 className="mt-2 mb-1 pl-1 text-[14px] font-semibold">
              Positions
            </h1>
            <div className="flex flex-col max-h-[55%] Table-scrollbar rounded-md border pl-1 pr-1 border-[#307C9D] border-opacity-50 w-[350px] ">
              {positions.length > 3 ? (
                <div className="flex justify-center sticky top-0">
                  <button onClick={scrollToTop}>
                    <KeyboardArrowUpRoundedIcon />
                  </button>
                </div>
              ) : null}
              <div ref={parentRef} className="overflow-hidden overflow-y-auto">
                {positions?.map((position, index) => (
                  <div
                    className=" border-b p-2  border-[#307C9D] border-opacity-50 flex w-full justify-between items-center"
                    key={index}
                  >
                    <p className="w-[120px] text-[12px]">
                      {position.TradingSymbol}
                    </p>
                    <p className="text-[12px]">
                      {position.OrderAverageTradedPrice}
                    </p>
                    <p className="text-[12px]">{position.Quantity}</p>
                    <p className="flex justify-end float-end">
                      {position.Quantity >= 0 ? (
                        <span
                          style={{
                            color: "green",
                            fontSize: "1.5rem",
                            height: "10px",
                            width: "10px",
                            borderRadius: "100%",
                            backgroundColor: "#97FF95",
                          }}
                        ></span>
                      ) : (
                        <span
                          style={{
                            color: "red",
                            fontSize: "1.5rem",
                            height: "10px",
                            width: "10px",
                            borderRadius: "100%",
                            backgroundColor: "#FF6565",
                          }}
                        ></span>
                      )}
                    </p>
                  </div>
                ))}
              </div>
              {positions.length > 3 ? (
                <div className="flex justify-center sticky">
                  <button onClick={scrollToBottom}>
                    <KeyboardArrowDownRoundedIcon />
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>

      <div className="pt-3 pr-1 h-[100%] w-[100%] flex justify-end">
        {selectedUnderlying && (
          <Chart exp={chartData?.chart_exp} chartData={chartData?.chart} middle={chartData?.middle} />
        )}
      </div>
    </div>
  );
};

export default PayoffPnlDealer;
