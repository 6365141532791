import React from 'react';
import axios from 'axios';

const api_uerver_url = process.env.REACT_APP_API_SERVER_URL;

export default function useFetchData(dealerId) {
    const [data, setData] = React.useState([]);

    React.useEffect(() => {
        const fetchData = () => {
            // for date selection admin
            axios.get(`${api_uerver_url}/backoffice/filters/${dealerId}`)
                .then((res) => {
                    setData(res.data);
                })
                .catch((error) => {
                    console.log('Error fetching data:', error);
                });
        };

        fetchData();
    }, [dealerId]);

    return [data];
}
