// OptionChainTabs Component Documentation

import React from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import OptionChainHeader from "./OptionChainHeader";
import TerminalTab from "./Terminal/TerminalTab";

// Import necessary dependencies from react-router-dom for navigation and location tracking
// Import OptionChainHeader and TerminalTab components for rendering respective content

export default function OptionChainTabs() {
  // Access the current location using useLocation hook from react-router-dom
  const location = useLocation();

  // // Function to determine the CSS classes for tabs based on current location
  // const getTabClass = (tabName) => {
  //     // Check if the tab is active based on whether its name is present in the current pathname
  //     const isActive = location.pathname.includes(tabName) ||
  //         (location.pathname === '/optionsChain' && tabName === 'optionChain');
  //     // Return appropriate CSS classes based on tab activity
  //     return `
  //         ${isActive ? 'bg-gray-800 text-white' : 'text-gray-500 hover:text-gray-200'}
  //         border-b-2 ${isActive ? 'border-[#3b9599]' : 'hover:border-gray-300'}
  //         rounded-t-lg px-4 py-2
  //         transition duration-300 ease-in-out
  //         font-semibold text-sm`;
  // };

  const getTabClass = (tabName) => {
    const isActive =
      location.pathname.includes(tabName) ||
      (location.pathname === "/optionsChain" && tabName === "optionChain");
    return isActive ? "tab-link active" : "tab-link";
  };

  return (
    // Render the main container for the component
    <div className="w-full mt-4 px-4 tabs tab-left-right">
      <nav className="rounded-lg navbar">
        <Link to="/optionsChain" className={getTabClass("optionChain")}>
          Option Chain
          <span className="nav-item-before" />
        </Link>

        <Link to="/optionsChain/terminal" className={getTabClass("terminal")}>
          Terminal
          <span className="nav-item-before" />
        </Link>
        {/* <ul className="flex space-x-1 gap-5">
          <li className="">
            <Link to="/optionsChain" className={getTabClass("optionChain")}>
              Option Chain
            </Link>
          </li>

          <li className="whitespace-nowrap">
            <Link
              to="/optionsChain/terminal"
              className={getTabClass("terminal")}
            >
              Terminal
            </Link>
          </li>
        </ul> */}
      </nav>
      {/* Render the content section based on route */}
      <div>
        <Routes>
          {/* Route to render OptionChainHeader component */}
          <Route path="/" element={<OptionChainHeader />} />
          {/* Route to render TerminalTab component */}
          <Route path="/terminal" element={<TerminalTab />} />
        </Routes>
      </div>
    </div>
  );
}
