import React, { useState, useEffect } from 'react'
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import axios from 'axios'
import AdminBarChart from "./AdminBarChart";
import HorizontalBars from "../../components/HorizontalBars";
import PieChart from '../../components/PieChart'
import Skeleton from '@mui/material/Skeleton';

const RomColor = '#76A84D';
const marginColor = '#0E7181';
const api_server_url = process.env.REACT_APP_API_SERVER_URL;

const AllChartsAdmin = ({ setShowGraphs, dateSelected, year }) => {
    const [romChartData, setRomChartData] = useState([]);
    const [avgMarginChartData, setAvgMarginChartData] = useState([]);
    const [qtyTradedChartData, setQtyTradedChartData] = useState([]);
    const [segment, setSegment] = useState([]);
    const [sigma, setSigma] = useState([])
    const [dayWiseMargin, setDayWiseMargin] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            try {
                const res = await axios.post(`${api_server_url}/backoffice/marginchart/all`, { range: dateSelected, year: year }, { withCredentials: true });
                const { ROM, AvgMarginUsed, QtyTraded, Segment, Sigma, DaywiseMargin } = res.data;
                console.log(res.data);
                setRomChartData(ROM);
                setAvgMarginChartData(AvgMarginUsed);
                setQtyTradedChartData(QtyTraded);
                setSegment(Segment);
                setSigma(Sigma)
                setDayWiseMargin(DaywiseMargin)
                setIsLoading(false)
            } catch (error) {
                console.error("Error fetching bar chart data:", error);
                setIsLoading(false);
            }
        }
        fetchData();
    }, [year, dateSelected]);

    const handleClick = () => {
        setShowGraphs(false);
    };

    const SegmentWisePnlColors = [
        "#0E7181",
        "#2399AB",
        "#65DEF1",
        "#ABEFFA",
        "#E5FCFF",
    ];

    const DayWisePnlColors = [
        "#1D3D03",
        "#2A4A11",
        "#3B621B",
        "#76A84D",
        "#96C570",
        "#C5E6AB",
    ];

    return (
        <div className="flex flex-col gap-2 h-[78vh] rounded-lg p-4 w-full">
            <div className="self-start flex items-center gap-4">
                <button onClick={handleClick} className="text-[#8AC3DB] text-sm">
                    <ArrowBackIosRoundedIcon fontSize="small" />
                    Back
                </button>
                {/* <AdminDateSelection dateSelected={dateSelected} setDateSelected={setDateSelected} /> */}
            </div>
            <div className="flex gap-2 w-full">
                {/* Conditionally render the top 3 charts if dateSelected is 'today' */}
                {dateSelected === 'today' ? (
                    <div className='flex flex-col w-full gap-3'>
                        <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[100%]">
                            {isLoading ? (
                                <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                            ) : (avgMarginChartData?.length > 0 ?
                                <AdminBarChart data={avgMarginChartData} color={marginColor} name='Avg Margin Used' /> :
                                <p>Data is not available</p>
                            )}
                        </div>
                        <div className='w-full flex gap-2'>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[50%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (segment?.length > 0 ?
                                    <PieChart originalData={segment} colors={SegmentWisePnlColors} name='Segment Wise Margin Used' /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[50%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (qtyTradedChartData?.length > 0 ?
                                    <AdminBarChart data={qtyTradedChartData} name='Quantity Traded' /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    // Render all 6 charts if dateSelected is not 'today'
                    <div className='flex flex-col w-full gap-2'>
                        <div className='flex w-full gap-2'>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[33%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (avgMarginChartData?.length > 0 ?
                                    <AdminBarChart data={avgMarginChartData} color={marginColor} name='Avg Margin Used' /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[33%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (segment?.length > 0 ?
                                    <PieChart originalData={segment} colors={SegmentWisePnlColors} name='Segment Wise Margin Used' /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[33%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (qtyTradedChartData?.length > 0 ?
                                    <AdminBarChart data={qtyTradedChartData} name='Quantity Traded' /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                        </div>
                        <div className='flex gap-2'>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[33%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (sigma?.length > 0 ?
                                    <HorizontalBars data={sigma} name='Sigma Optimizer Usage  |  Sigma Optimization Cost' /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[33%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (dayWiseMargin?.length > 0 ?
                                    <PieChart originalData={dayWiseMargin} colors={DayWisePnlColors} name='Day Wise Margin Used' /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[33%]">
                                {isLoading ? (
                                    <Skeleton animtion='wave' variant="rounded" width="100%" height={200} />
                                ) : (romChartData?.length > 0 ?
                                    <AdminBarChart data={romChartData} name='ROM' color={RomColor} /> :
                                    <p>Data is not available</p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AllChartsAdmin;
