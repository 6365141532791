import React, { useEffect, useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import io from "socket.io-client";
import Skeleton from '@mui/material/Skeleton'
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import { Tooltip, Button } from '@mui/material';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { toast } from 'sonner';
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";


function createData(dealer_id, alert, message, time) {
    return { dealer_id, alert, message, time };
}
const headers = [
    {
        id: 'dealer_id', numeric: false, disablePadding: true, label: 'Id', align: 'center', borderLeft: '1px solid #307c9d'
    },
    { id: 'alert', numeric: false, disablePadding: false, label: 'Alarm Keys', align: 'center' },
    { id: 'message', numeric: false, disablePadding: false, label: 'Message', align: 'center' },
    { id: 'time', numeric: false, disablePadding: false, label: 'Time', align: 'center' }
];

const Row = React.memo((props) => {
    const { row } = props;
    return (
        <>
            <TableRow
                key={row.rowIndex}
                sx={{
                    backgroundColor: '#011F2C',
                    '&:hover': {
                        backgroundColor: '#1B4152',
                    },
                    '& > .MuiTableCell-root': {
                        py: 1,
                        borderRight: '1px solid #307c9d',
                        borderBottom: '1px solid #307c9d',
                        // fontWeight: '400',
                        fontSize: '12px'
                    },
                }}
            >
                <TableCell align="center" style={{ borderLeft: '1px solid #307c9d' }}>
                    {row.dealer_id}
                </TableCell>
                <TableCell align="center">
                    {row.alert}
                </TableCell>
                <TableCell align="center">
                    {row.message}
                </TableCell>
                <TableCell align="center">
                    {row.time}
                </TableCell>
            </TableRow>
        </>
    )
})

Row.propTypes = {
    row: PropTypes.shape({
        dealer_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        alert: PropTypes.string.isRequired,
        message: PropTypes.string.isRequired,
        time: PropTypes.string.isRequired,
    }).isRequired,
};


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    // console.log(array)
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}


export default function Alarms() {
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('time'); // Changed to 'time' to match your data
    const [rows, setRows] = useState([]);
    const [loading, setLoading] = useState(true);
    const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

    const throttledSetData = React.useCallback(throttle((newData) => {
        if (newData) {
            setRows(newData);
            // console.log(newData);
            setLoading(false);
        }
        else {
            setLoading(false);
        }

    }, 2000), []);

    const handleRequestSort = (property) => (event) => {
        if (orderBy === property) {
            setOrder((prevOrder) => {
                if (prevOrder === 'asc') return 'desc';
                if (prevOrder === 'desc') return 'default';
                return 'asc';
            });
        } else {
            setOrder('asc');
            setOrderBy(property);
        }
    };

    const getSortIcon = (property) => {
        if (orderBy === property) {
            if (order === 'asc') return '↑';
            if (order === 'desc') return '↓';
            return '↕';
        }
        return null;
    };


    useEffect(() => {
        const socket = io(socket_server_url + "/testAlarms");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on('test-all-alarms', (newAlarms) => {
            const newRows = newAlarms.map(alarm => createData(alarm.dealer_id, alarm.alert, alarm.message, alarm.time));
            setRows(newRows);
            setLoading(false); // Set loading to false when data is fetched
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const skeletonRows = [...Array(10)].map((_, index) => (
        <TableRow key={index}>
            {headers.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));

    const handleExportRows = () => {
        const doc = new jsPDF('landscape'); // Use landscape orientation

        // Prepare the table headers
        const tableHeaders = headers.map(header => header.label);
        toast.success('Downloaded Successfully');

        // Prepare the table data
        const tableData = stableSort(rows, getComparator(order, orderBy))
            .map(row => headers.map(header => {
                // Check if row[header.id] is undefined or null, if so, return an empty string
                if (row[header.id] === undefined || row[header.id] === null) {
                    return '';
                } else {
                    return row[header.id].toString();
                }
            }));

        // Generate the table in the PDF
        autoTable(doc, {
            theme: 'grid',
            head: [tableHeaders],
            body: tableData,
            styles: { overflow: 'linebreak', fontSize: 8 },
            columnStyles: { 0: { cellWidth: 'auto' } }, // Customize column styles
        });
        const csvContent = [tableHeaders.join(',')];
        tableData.forEach((row) => {
            csvContent.push(row.join(','));
        });
        const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvBlob);
        const csvLink = document.createElement('a');
        csvLink.href = csvUrl;
        csvLink.setAttribute('download', 'alarm_table.csv');
        document.body.appendChild(csvLink);
        csvLink.click();
        document.body.removeChild(csvLink);

        // Save the PDF
        doc.save('alarm_table.pdf');
    };


    return (
        <Box>
            <Button
                onClick={handleExportRows}
                variant="contained"
                color="primary"
                style={{ py: 0.5, px: 0, marginBottom: '6px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize: "11px" }}
                    startIcon={<DownloadingOutlinedIcon />}
            >
                Download
            </Button>
            <TableContainer className='custom-scrollbar' component={Paper} style={{ maxHeight: '70vh', overflow: 'auto', backgroundColor: '#000' }}>
                <Table sx={{ minWidth: 600 }} stickyHeader aria-label="sticky table">
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableRow sx={{
                            backgroundColor: '#000',
                            '& > .MuiTableCell-root': {
                                py: 0.6,
                                borderRight: '1px solid rgba(48, 124, 157, 0.7)',
                                borderBottom: '1px solid rgba(48, 124, 157, 0.7)',
                                fontWeight: '700',
                                fontSize: '13px',
                                backgroundColor: '#021b26',
                            }
                        }}>
                            {headers.map((header, idx) => (
                                <TableCell
                                    key={header.id}
                                    align='center'
                                    sx={{
                                        ...header.style, borderRight: '1px solid #307c9d', borderTop: '1px solid #307c9d', borderBottom: '1px solid #307c9d',
                                        zIndex: 100, fontWeight: 700, borderLeft: idx === 0 ? '1px solid #307c9d' : 'transparent'
                                    }}
                                >
                                    {loading ? (
                                        <Skeleton animation="wave" height={30} />
                                    ) : (
                                        <TableSortLabel
                                            active={orderBy === header.id}
                                            direction={orderBy === header.id ? order : 'asc'}
                                            onClick={handleRequestSort(header.id)}
                                            style={{ whiteSpace: 'nowrap' }}
                                            aria-label={header.label}
                                            IconComponent={null}
                                        >
                                            <Tooltip title={header.label} placement='bottom' arrow>
                                                <span>{header.label} {getSortIcon(header.id)}</span>
                                            </Tooltip>
                                        </TableSortLabel>
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: '#021B26' }}>
                        {loading ? (
                            skeletonRows
                        ) : (
                            <>
                                {rows.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={headers.length} align="center" sx={{ color: '#fff' }}>
                                            No data available
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    <>
                                        {stableSort(rows, getComparator(order, orderBy))?.map((row, rowIndex) => (
                                            <Row key={row.rowIndex} row={row} />
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                    </TableBody>


                </Table>
            </TableContainer>
        </Box>
    );
};
