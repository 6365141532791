import * as React from "react";
import Box from "@mui/material/Box";
import { Button, IconButton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import { styled } from "@mui/material/styles";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const ColorButton = styled(Button)(({ theme }) => ({
  // color: theme.palette.getContrastText(p),
  // backgroundColor: purple[500],
  padding: 0,
  "&:hover": {
    color: "black",
    // borderRadius: '50%',
    // padding:0,margin:0
  },
}));

export default function ComponentZoomModal({
  component: Component,
  componentProps,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <IconButton
        aria-label="edit"
        size="small"
        sx={{
          color: "#1A5F7C",
          borderRadius: "100%",
          py: 2,
          px: 1.2,
          position: "absolute",
          top: -10,
          right: -12,
          bottom: 0,
          "&:hover": {
            color: "#307C9D",
          },
        }}
        onClick={handleOpen} // Move the onClick event here
      >
        <OpenInFullIcon fontSize="small" style={{}} />
      </IconButton>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "80%",
            bgcolor: "#021b26",
            border: "none",
            boxShadow: "white 24px",
            borderRadius: "10px",
            p: 4,
            overflow: "auto", // Enable scrolling if content exceeds modal dimensions
          }}
        >
          {Component && <Component {...componentProps} />}
        </Box>
      </Modal>
    </>
  );
}
