import React from 'react';
import LivePositionsTab from './Tabs';

const LivePositions = () => {
    return (
        <div className=''>
            <LivePositionsTab />
        </div>
    );
}

export default LivePositions;