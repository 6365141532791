import React from "react";
import {
  MaterialReactTable,
  createMRTColumnHelper,
  useMaterialReactTable,
} from "material-react-table";
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { throttle } from "lodash";
import "../../index.css";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Button,
  Box,
  Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddDealer from "../usersdata/AddDealer";
import EditDealer from "../usersdata/EditDealer";
import axios from "axios";
import ls from "localstorage-slim";
import EditPortfolio from "../usersdata/EditPortfolio";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { toast } from "sonner";
import { Tooltip } from "@mui/material";
import HedgePrice from "./HedgePrice";
import AddIcon from "@mui/icons-material/Add";

const columnHelper = createMRTColumnHelper();
const api_server_url = process.env.REACT_APP_API_SERVER_URL;
const confirm_password = process.env.REACT_APP_PASSWORD;
/**
 * ControlPanel component renders a control panel for managing dealer data.
 * It allows users to edit, delete, square off, and block/unblock dealers.
 *
 * @param {object} props - Component properties
 * @param {function} props.setIsLoggedIn - Function to set login status
 * @param {boolean} props.collapsed - Boolean indicating whether the panel is collapsed
 * @returns {JSX.Element} - React component
 */

const ControlPanel = ({ setIsLoggedIn, collapsed }) => {
  const [data, setData] = React.useState([]);
  const [footerData, setFooterData] = React.useState([]);
  const [tableData, setTableData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sorting, setSorting] = React.useState([]);
  const rowVirtualizerInstanceRef = React.useRef(null);
  const [loading, setLoading] = React.useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = React.useState(null);
  const [block, setBlock] = React.useState(true);
  const [isEditPortfolioOpen, setIsEditPortfolioOpen] = React.useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
  const [rowToDelete, setRowToDelete] = React.useState(null);
  const [isSquareOffDialogOpen, setIsSquareOffDialogOpen] =
    React.useState(false);
  const [rowToSquareOff, setRowToSquareOff] = React.useState(null);
  const [isBlockDialogOpen, setIsBlockDialogOpen] = React.useState(false);
  const [rowToBlockUnblock, setRowToBlockUnblock] = React.useState(null);
  const [isAllSquareOffDialogOpen, setIsAllSquareOffDialogOpen] =
    React.useState(false);
  const [rowToAllSquareOff, setRowToAllSquareOff] = React.useState(null);
  const [deletePassword, setDeletePassword] = React.useState("");
  const [sqDeletePassword, setSqDeletePassword] = React.useState("");
  const [sqAllDeletePassword, setSqAllDeletePassword] = React.useState("");
  const [blockDeletePassword, setBlockAllDeletePassword] = React.useState("");

  // Function to open block dialog for a dealer
  const openBlockDialog = (rowData) => {
    setRowToBlockUnblock(rowData);
    setIsBlockDialogOpen(true);
  };
  // Function to open squareoff dialog for dealer
  const openAllSquareOffDialog = (rowData) => {
    setRowToAllSquareOff(rowData);
    setIsAllSquareOffDialogOpen(true);
  };
  // Function for open delete dialog for dealer
  const openDeleteDialog = (rowData) => {
    setRowToDelete(rowData);
    setIsDeleteDialogOpen(true);
  };
  // functions for handling the dialog for dealer
  const openSquareOffDialog = (rowData) => {
    setRowToSquareOff(rowData);
    setIsSquareOffDialogOpen(true);
  };
  // functions for handling the dialog for dealer
  const handleEdit = (rowData) => {
    setSelectedRowForEdit(rowData);
    setIsEditModalOpen(true);
  };
  // functions for handling the dialog for dealer
  const handlePortfolioEdit = (rowData) => {
    setSelectedRowForEdit(rowData);
    setIsEditPortfolioOpen(true);
  };

  // Function to fetch dealer data from server
  const fetchControlPanelData = () => {
    axios
      .get(api_server_url + "/dealers", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        const newData = response.data;
        // console.log(newData);
        throttledSetData(newData["dealers"]);
        setFooterData(newData?.client);
        // console.log(newData['dealers']);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          console.log("Unauthorized Error here.............................");
          setIsLoggedIn(false);
          ls.remove("isLoggedIn");
        } else {
          console.error("Error fetching data:", error);
        }
        setLoading(false);
      });
  };

  // Function to handle deletion of a dealer
  const handleDelete = () => {
    if (deletePassword !== confirm_password) {
      alert("Incorrect password");
      return;
    }
    if (!rowToDelete) {
      console.error("No row selected for deletion");
      return;
    }
    const ddata = { dealer_id: rowToDelete.dealerID };
    console.log(ddata);
    axios
      .delete(api_server_url + "/dealers", {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(ddata),
      })
      .then((response) => {
        // console.log("Deletion successful:", response.data);
        setIsDeleteDialogOpen(false);
        toast.success(`${ddata.dealer_id} has been Deleted`);
        fetchControlPanelData();
        setDeletePassword("");
      })
      .catch((error) => {
        console.error("Error deleting data:", error);
        setLoading(false);
        setIsDeleteDialogOpen(false);
        toast.error("Event has not been created");
        setDeletePassword("");
      });
  };

  // Function to handle squareoff of dealer
  const handleSquareOff = () => {
    if (sqDeletePassword !== confirm_password) {
      alert("Incorrect password");
      return;
    }
    // console.log(id);
    if (!rowToSquareOff) return;
    // if(!rowToAllSquareOff) return;
    const sqdata = { dealer_id: rowToSquareOff.dealerID };
    // console.log(sqdata);
    axios
      .post(api_server_url + "/dealer/squareoff", sqdata, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response.data)
        setIsSquareOffDialogOpen(false);
        setSqDeletePassword("");
        toast.success(`${sqdata.dealer_id} has been SqauredOff Successfully!`);
        // console.log((newData));
      })
      .catch((error) => {
        toast.error(`${sqdata.dealer_id} has not been SqauredOff`);
        console.error("Error fetching data:", error);
        setLoading(false);
        setSqDeletePassword("");
        setIsSquareOffDialogOpen(false);
      });
  };

  //  Function to handle the Allsqoff of dealer
  const handleAllSquareOff = () => {
    if (sqAllDeletePassword !== confirm_password) {
      alert("Incorrect password");
      return;
    }
    const sqdata = { dealer_id: "admin" };
    // console.log(sqdata);
    axios
      .post(api_server_url + "/dealer/squareoff", sqdata, {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response.data)
        toast.success(`Admin has been SquaredOff`);
        setIsAllSquareOffDialogOpen(false);
        setSqAllDeletePassword("");
        // console.log((newData));
      })
      .catch((error) => {
        toast.error(`Admin has not been SquaredOff`);
        console.error("Error fetching data:", error);
        setLoading(false);
        setIsAllSquareOffDialogOpen(false);
        setSqAllDeletePassword("");
      });
  };

  // Function to block the dealer
  const handleBlock = (row) => {
    if (blockDeletePassword !== confirm_password) {
      alert("Incorrect password");
      return;
    }
    if (!rowToBlockUnblock) return;
    // Perform the block/unblock action
    axios
      .put(
        api_server_url + "/dealers",
        {
          dealerID: rowToBlockUnblock.dealerID,
          block: !rowToBlockUnblock.block,
        },
        {
          withCredentials: true,
        }
      )
      .then((res) => {
        // console.log(res);
        const message = `${rowToBlockUnblock.dealerID} has been ${
          !rowToBlockUnblock.block ? "Blocked" : "Unblocked"
        }`;
        toast.success(message);
        setBlockAllDeletePassword("");
        setIsBlockDialogOpen(false);
        setBlock(!block);
      })
      .catch((error) => {
        setIsBlockDialogOpen(false);
        const message = `${rowToBlockUnblock.dealerID} has not been ${
          !rowToBlockUnblock.block ? "Blocked" : "Unblocked"
        }`;
        toast.error(`${message} try again later`);
        setBlockAllDeletePassword("");
        console.error("Error during block/unblock:", error);
      });
  };

  const throttledSetData = React.useCallback(
    throttle((newData) => {
      setData(newData);
    }, 1000),
    []
  );

  React.useEffect(() => {
    fetchControlPanelData();

    return () => {
      throttledSetData.cancel();
    };
  }, [throttledSetData, block]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setTableData(data);
      setIsLoading(false);
    }
  }, [data, block]);

  React.useEffect(() => {
    if (
      rowVirtualizerInstanceRef.current &&
      typeof rowVirtualizerInstanceRef.current.scrollToIndex === "function"
    ) {
      try {
        rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
      } catch (error) {
        console.error(error);
      }
    }
  }, [sorting, block]);

  // these are the columns headers  of table
  const columns = [
    columnHelper.accessor("edit", {
      id: "edit",
      header: "Edit",
      size: 80,
      Cell: ({ row }) => (
        <Box sx={{ padding: 0, margin: 0, display: "flex", gap: 1 }}>
          <IconButton
            onClick={() => handleEdit(row.original)}
            aria-label="edit"
            size="small"
          >
            <Tooltip title="Edit" placement="bottom-start">
              <EditIcon fontSize="inherit" />
            </Tooltip>
          </IconButton>
        </Box>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      enableColumnFilter: false,
      enableSorting: false,
      Footer: ({ table }) => (
        <Box>
          <IconButton
            onClick={() => {
              handlePortfolioEdit();
            }}
            aria-label="edit"
            size="small"
            sx={{
              px: 2,
              py: 0.5,
              borderRadius: 1,
              fontSize: "15px",
              border: "1px solid #3b9599",
              color: "#fff",
            }}
          >
            <Tooltip title="Edit Portfolio" placement="bottom-start">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Box>
      ),
    }),
    columnHelper.accessor("dealerName", {
      header: "Dealer",
      size: 130,
      footer: "Admin" || "",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("dealerID", {
      header: "ID",
      size: 130,
      footer: footerData.dealerID || "",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("total_margin", {
      header: "Total Margin",
      size: 220,
      footer:
        footerData.total_margin &&
        (footerData.total_margin || "").toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      Cell: ({ row }) => (
        <Box sx={{ padding: 0, margin: 0, display: "flex", gap: 1 }}>
          {row.original.total_margin &&
            row.original.total_margin.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
        </Box>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      filterVariant: "range",
      filterFn: "betweenInclusive",
    }),

    columnHelper.accessor("margin_trigger_value", {
      header: "Margin Trigger",
      size: 175,
      Cell: ({ row }) => (
        <Box sx={{ padding: 0, margin: 0, display: "flex", gap: 1 }}>
          {row.original.margin_trigger_value &&
            row.original.margin_trigger_value.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
        </Box>
      ),
      // footer: (footerData.margin_trigger_value || '').toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("margin_trigger_percent", {
      header: "Margin Trigger %",
      size: 195,
      // footer: footerData.margin_trigger_percent || '',
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("used_margin", {
      header: "Used Margin",
      size: 220,
      footer:
        footerData.used_margin &&
        (footerData.used_margin || "").toLocaleString("en-IN", {
          style: "currency",
          currency: "INR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
      Cell: ({ row }) => (
        <Box sx={{ padding: 0, margin: 0, display: "flex", gap: 1 }}>
          {row.original.used_margin &&
            row.original.used_margin.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}
        </Box>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      filterVariant: "range",
      filterFn: "betweenInclusive",
    }),
    columnHelper.accessor("used_margin_percent", {
      header: "Used Margin %",
      size: 180,
      footer: footerData.used_margin_percent || "",
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("profit_trigger_value", {
      header: "Profit Trigger Value",
      size: 230,
      Cell: ({ row }) => (
        <Box sx={{ padding: 0, margin: 0, display: "flex", gap: 1 }}>
          {row.original.profit_trigger_value &&
            parseFloat(row.original.profit_trigger_value).toLocaleString(
              "en-IN",
              {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            )}
        </Box>
      ),
      // footer: parseFloat(footerData.profit_trigger_value || '').toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      filterVariant: "range",
      filterFn: "betweenInclusive",
    }),
    columnHelper.accessor("profit_trigger_percent", {
      header: "Profit Trigger %",
      size: 190,
      // footer: footerData.profit_trigger_value_percent || '',
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("loss_trigger_value", {
      header: "Loss Trigger Value",
      size: 220,
      Cell: ({ row }) => (
        <Box sx={{ padding: 0, margin: 0, display: "flex", gap: 1 }}>
          {row.original.loss_trigger_value &&
            parseFloat(row.original.loss_trigger_value).toLocaleString(
              "en-IN",
              {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              }
            )}
        </Box>
      ),
      // footer: parseFloat(footerData.loss_trigger_value || '').toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 }),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      filterVariant: "range",
      filterFn: "betweenInclusive",
    }),
    columnHelper.accessor("loss_trigger_percent", {
      header: "Loss Trigger %",
      size: 180,
      // footer: footerData.loss_trigger_value_percent || '',
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("var_trigger_percent", {
      header: "Var Trigger %",
      size: 180,
      // footer: footerData.loss_trigger_value_percent || '',
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
    }),
    columnHelper.accessor("block", {
      id: "block",
      header: "Action",
      size: 100,
      Cell: ({ row }) => (
        <Box>
          <Button
            sx={{
              backgroundColor: row.original.block ? "#923434" : "#923434",
              color: "white",
              width: "100%",
              borderRadius: 1,
              fontSize: "10px",
            }}
            onClick={() => openBlockDialog(row.original)}
          >
            {row.original.block === true ? "Unblock" : "Block"}
          </Button>
        </Box>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor("squareoff", {
      id: "squareoff",
      header: "Square Off",
      size: 100,
      Cell: ({ row }) => (
        <Box
          sx={{
            padding: 0,
            margin: 0,
            display: "flex",
            gap: 1,
            maxWidth: "120px",
            py: 0.5,
          }}
        >
          <Button
            sx={{
              "&:hover": { backgroundColor: "#1d1d1d" },
              color: "#ff7373",
              border: "1px solid #ff7373",
              py: 0.5,
              fontSize: "10px",
            }}
            onClick={() => openSquareOffDialog(row.original)}
          >
            Soff
          </Button>
        </Box>
      ),
      muiTableFooterCellProps: {
        align: "center",
      },
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor("delete", {
      id: "delete",
      header: "Delete",
      size: 70,
      Cell: ({ row }) => (
        <Box sx={{ padding: 0, margin: 0, display: "flex", gap: 1 }}>
          <Tooltip title="Delete" placement="bottom-start">
            <IconButton
              onClick={() => openDeleteDialog(row.original)}
              aria-label="delete"
              size="small"
            >
              <DeleteIcon
                fontSize="inherit"
                sx={{ color: "rgb(244, 67, 54)" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      ),
      muiTableHeadCellProps: {
        align: "center",
      },
      muiTableBodyCellProps: {
        align: "center",
      },
      muiTableFooterCellProps: {
        align: "center",
      },
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  const handleExportRows = (rows) => {
    try {
      if (!Array.isArray(rows) || rows.length === 0) {
        throw new Error("No rows to export.");
      }
      toast.success("Downloaded Successfully");
      const doc = new jsPDF("landscape"); // Use landscape orientation
      // Define the columns you want to include in the PDF
      const pdfColumns = columns.filter(
        (column) =>
          column.id !== "actions" &&
          column.id !== "squareoff" &&
          column.id !== "block"
      );
      const tableHeaders = pdfColumns.map((c) => c.header || "");

      const tableData = rows.map((row) => {
        return pdfColumns.map((column) => {
          // Accessing the data - ensure this matches your actual data structure
          const cellValue = row.original ? row.original[column.id] : "";
          return cellValue !== null && cellValue !== undefined
            ? cellValue.toString()
            : "";
        });
      });

      autoTable(doc, {
        theme: "grid",
        head: [tableHeaders],
        body: tableData,
        styles: {
          overflow: "linebreak",
          cellWidth: "wrap",
          fontSize: 8,
        },
        columnStyles: {
          0: { cellWidth: "auto" },
        },
        startY: 10,
        margin: { top: 10, right: 10, bottom: 10, left: 10 },
        didDrawPage: function (data) {
          // Footer with page number
          doc.text(
            "Page " + doc.internal.getNumberOfPages(),
            doc.internal.pageSize.width - 20,
            doc.internal.pageSize.height - 10
          );
        },
      });
      const csvContent = [tableHeaders.join(",")];
      tableData.forEach((row) => {
        csvContent.push(row.join(","));
      });
      const csvBlob = new Blob([csvContent.join("\n")], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);
      const csvLink = document.createElement("a");
      csvLink.href = csvUrl;
      csvLink.setAttribute("download", "ControlPanel.csv");
      document.body.appendChild(csvLink);
      csvLink.click();
      document.body.removeChild(csvLink);

      // Save the document
      doc.save("Response_log_data.pdf");
    } catch (error) {
      toast.error("Donwload Failed");
      console.error("Error while generating PDF: ", error);
      alert("An error occurred while generating the PDF: " + error.message);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    // columnFilterDisplayMode: 'popover',
    enablePagination: false,
    enableBottomToolbar: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableDensityToggle: false,
    columnResizeMode: "onChange",
    // enableColumnResizing: true,
    // enableColumnVirtualization: true,
    muiTableHeadCellProps: {
      sx: {
        border: "1px solid rgba(48, 124, 157, 0.7)",
        fontWeight: "800",
        backgroundColor: "#021b26",
        fontSize: "15px",
        paddingY: 1.5,
      },
    },
    muiTableBodyCellProps: {
      sx: {
        border: "1px solid rgba(48, 124, 157, 0.7)",
        paddingY: 0,
      },
    },
    muiTableFooterCellProps: {
      sx: (theme) => ({
        align: "center",
        display: "",
        border: "1px solid rgba(48, 124, 157, 0.7)",
      }),
    },
    enableRowVirtualization: true,
    muiTableContainerProps: {
      sx: { maxHeight: "52vh", maxWidth: "100%", overflow: "auto" },
      className:'alarm-scrollbar'
    },
    onSortingChange: setSorting,
    state: { isLoading, sorting },
    rowVirtualizerInstanceRef,
    enableGlobalFilterModes: true,
    // enableColumnPinning: true,
    layoutMode: "grid-no-grow", //constant column widths
    // renderRowActionMenuItems: () => [<MenuItem key="action">Action</MenuItem>],
    initialState: {
      density: "compact",
      columnPinning: { left: ["edit", "dealerID", "dealerName"] },
    },
    enableColumnActions: false,
    rowVirtualizerOptions: { overscan: 5 },
    renderTopToolbarCustomActions: ({ table }) => (
      <div className="flex gap-3">
        <div className="flex justify-end">
          {/* button for download the table in .csv and .pdf form */}
          <Button
            style={{
              py: 0.5,
              px: 0,
              marginBottom: "4px",
              backgroundColor: "#1d1d1d7d",
              color: "#3b9599",
              border: "1px solid #3b95995D",
              fontSize: "11px",
            }}
            startIcon={<DownloadingOutlinedIcon />}
            disabled={table.getPrePaginationRowModel().rows.length === 0}
            onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            }
            // startIcon={<FileDownloadIcon />}
          >
            Download
          </Button>
        </div>
        <Button
          style={{
            py: 0.5,
            px: 0,
            marginBottom: "4px",
            backgroundColor: "#1d1d1d7d",
            color: "#3b9599",
            border: "1px solid #3b95995D",
            fontSize: "11px",
          }}
        >
          <AddIcon />
          <AddDealer block={block} setBlock={setBlock} />
        </Button>
        {/*squareoff button for all the selected rows (it's active when selectedRows.length >= 2(must))*/}
        <Button
          style={{
            py: 0.5,
            px: 0,
            marginBottom: "4px",
            backgroundColor: "#1d1d1d7d",
            color: "#ff7373",
            border: "1px solid #ff7373",
            fontSize: "11px",
          }}
          // onClick={() => {handleSquareOff('admin') }}
          onClick={() => openAllSquareOffDialog()}
        >
          All Square Off
        </Button>
      </div>
      // </Box>
    ),
  });

  // this function showing the skeleton or loading rows
  const skeletonRows = [...Array(10)].map((_, index) => (
    <TableRow key={index}>
      {columns.map((column, colIndex) => (
        <TableCell key={colIndex}>
          <Skeleton animation="wave" height={30} />
        </TableCell>
      ))}
    </TableRow>
  ));

  return (
    <React.Fragment>
      <TableContainer 
        sx={{
          display: "flex",
          flexDirection: "column",
          fontSize: "10px",
          // backgroundColor: '#021B26',
          // height: '700px',
          overflow: "auto",
        }}
        className="alarm-scrollbar overflow-auto  border border-[#307C9D] border-opacity-50 rounded-md"
      >
        {loading ? (
          <TableContainer
            component={Paper}
            style={{
              maxHeight: "450px",
              overflow: "auto",
              backgroundColor: "#021B26",
            }}
            className="alarm-scrollbar"
          >
            <Table sx={{ minWidth: 600 }} aria-label="skeleton table">
              <TableHead sx={{ backgroundColor: "#021B26" }}>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} style={{ ...column.style }}>
                      <div style={{ height: "30px", overflow: "hidden" }}>
                        <Skeleton animation="wave" height={30} />
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody sx={{ backgroundColor: "#021B26" }}>
                {skeletonRows}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableContainer className="alarm-scrollbar">
            {/* showing the data in table */}
            <MaterialReactTable table={table} enableStickyFooter />
            {/* in the bottom of the page i've input field which is this component */}
            <HedgePrice collapsed={collapsed} />
          </TableContainer>
        )}

        {isEditModalOpen && (
          <EditDealer
            open={isEditModalOpen}
            rowData={selectedRowForEdit}
            onClose={() => setIsEditModalOpen(false)}
            block={block}
            setBlock={setBlock}
          />
        )}

        {isEditPortfolioOpen && (
          <EditPortfolio
            open={isEditPortfolioOpen}
            rowData={selectedRowForEdit}
            onClose={() => setIsEditPortfolioOpen(false)}
            block={block}
            setBlock={setBlock}
          />
        )}
      </TableContainer>

      <Dialog
        open={isSquareOffDialogOpen}
        onClose={() => setIsSquareOffDialogOpen(false)}
        aria-labelledby="square-off-dialog-title"
        aria-describedby="square-off-dialog-description"
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#021B26" } }} // Example background color
      >
        <DialogTitle id="square-off-dialog-title">
          {"Confirm Square Off"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="square-off-dialog-description"
            sx={{ color: "white" }}
          >
            Are you sure you want to square off this dealer?
          </DialogContentText>
          <TextField
            type="password"
            value={sqDeletePassword}
            onChange={(e) => setSqDeletePassword(e.target.value)}
            fullWidth
            autoFocus
            margin="normal"
            label="Password"
            variant="outlined"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIsSquareOffDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSquareOff}
            variant="outlined"
            color="error"
            autoFocus
          >
            Square Off
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isBlockDialogOpen}
        onClose={() => setIsBlockDialogOpen(false)}
        aria-labelledby="block-unblock-dialog-title"
        aria-describedby="block-unblock-dialog-description"
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#021B26" } }}
      >
        <DialogTitle id="block-unblock-dialog-title">
          {rowToBlockUnblock?.block ? "Confirm Unblock" : "Confirm Block"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="block-unblock-dialog-description"
            sx={{ color: "white" }}
          >
            Are you sure you want to{" "}
            {rowToBlockUnblock?.block ? "unblock" : "block"} this dealer?
          </DialogContentText>
          <TextField
            type="password"
            value={blockDeletePassword}
            onChange={(e) => setBlockAllDeletePassword(e.target.value)}
            fullWidth
            autoFocus
            margin="normal"
            label="Password"
            variant="outlined"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIsBlockDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleBlock}
            color="error"
            variant="outlined"
            autoFocus
          >
            {rowToBlockUnblock?.block ? "Unblock" : "Block"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isAllSquareOffDialogOpen}
        onClose={() => setIsAllSquareOffDialogOpen(false)}
        aria-labelledby="all-square-off-dialog-title"
        aria-describedby="all-square-off-dialog-description"
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#021B26" } }} // Example background color
      >
        <DialogTitle id="all-square-off-dialog-title">
          {"Confirm All Square Off"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="all-square-off-dialog-description"
            sx={{ color: "white" }}
          >
            Are you sure you want to square off all dealers?
          </DialogContentText>
          <TextField
            type="password"
            value={sqAllDeletePassword}
            onChange={(e) => setSqAllDeletePassword(e.target.value)}
            fullWidth
            autoFocus
            margin="normal"
            label="Password"
            size="small"
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              py: 0.5,
              px: 0,
              marginBottom: "4px",
              color: "#fff",
              border: "1px solid #307c9d",
              fontSize: "11px",
              "&:hover": {
                backgroundColor: "transparent", // Change background color on hover
                color: "#509ebf",
                border: "1px solid #509ebf",
              },
            }}
            onClick={() => setIsAllSquareOffDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAllSquareOff}
            style={{
              py: 0.5,
              px: 0,
              marginBottom: "4px",
              backgroundColor: "#1d1d1d7d",
              color: "#ff7373",
              border: "1px solid #ff7373",
              fontSize: "11px",
            }}
          >
            All Square Off
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => setIsDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ "& .MuiDialog-paper": { backgroundColor: "#021B26" } }}
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "white" }}
          >
            Are you sure you want to delete this?
          </DialogContentText>
          <TextField
            type="password"
            value={deletePassword}
            onChange={(e) => setDeletePassword(e.target.value)}
            fullWidth
            autoFocus
            margin="normal"
            label="Password"
            variant="outlined"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => setIsDeleteDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button onClick={handleDelete} color="error" variant="outlined">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ControlPanel;