import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import io from "socket.io-client";

function createData(dealer_id, alert, message, time) {
  return { dealer_id, alert, message, time };
}

const alarmColor = (alert) => {
  switch (alert) {
    case 'loss':
      return '#FF6565';
    case 'profit':
      return '#97FF95';
    case 'rejected':
      return '#E5B23F';
    case 'margin':
      return '#FFEF9E';
    case 'block':
      return '#8DC1FF';
    default:
      return '#fff'
  }
};

export default function AlarmsData({ collapsed, setCollapsed }) {
  const [rows, setRows] = React.useState([]);
  const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;

  React.useEffect(() => {
    const socket = io(socket_server_url + '/testAlarms');

    socket.on('test-all-alarms', (newAlarms) => {
      try {
        const newRows = newAlarms.map((alarm) => createData(alarm.dealer_id, alarm.alert, alarm.message, alarm.time));
        setRows(newRows);
      } catch (error) {
        console.error('Error mapping alarms:', error);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [socket_server_url]);

  return !collapsed && (
    <div className='fixed max-w-[350px] top-0 z-[1] overflow-auto alarm-scrollbar'>
      <Accordion style={{ backgroundColor: '#3B5D6C', padding: 5, color: 'white', width: '350px' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Alarms</Typography>
        </AccordionSummary>
        <AccordionDetails className='alarm-scrollbar' style={{ backgroundColor: '#123442', maxHeight: '300px', overflow:'auto'}}>
          <div className='flex flex-col items-center gap-3 h-full overflow-hidden'>
            {rows.length > 0 ? (
              rows?.map((alarm, index) => (
                <div   key={`${alarm.time}-${index}`} style={{ borderColor: alarmColor(alarm.alert), borderWidth: '2px', borderStyle: 'solid', boxShadow: `0px 0px 4px 0px ${alarmColor(alarm.alert, 0.1)}` }} className="p-[7px] flex rounded-lg min-w-full justify-between items-center">
                  <div className='flex flex-col gap-2 w-full'>
                    <div className='flex items-center justify-between w-full'>
                      <h6 className="text-sm font-semibold">{alarm.dealer_id}</h6>
                      <div className='flex gap-2 items-center'>
                        <p style={{ backgroundColor: alarmColor(alarm.alert) }} className="text-[10px] px-2 py-1 font-semibold rounded-lg text-black">{alarm.alert}</p>
                        <p style={{ backgroundColor: `rgba(150, 203, 226, 0.1)` }} className="text-xs px-2 py-1 rounded-lg border">{alarm.time}</p>
                      </div>
                    </div>
                    <p className="text-[11px]">{alarm.message}</p>
                  </div>
                </div>
              ))
            ) : (
              <p className='text-xs'>No alarms available.</p>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
