import DashboardTab from './DashboardTabs';
import React from 'react';

const Dashboard = ({isLoggedIn, setIsLoggedIn, alertData, collapsed}) => {
    return (
        <div className=''>
            <DashboardTab isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} alertData={alertData} collapsed={collapsed}/>
        </div>
    );
}

export default Dashboard;
