import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import CloseIcon from "@mui/icons-material/Close";

const Drawdown = ({ data, setOpenDrawdown }) => {
  const handleClose = () => {
    setOpenDrawdown(false);
  };
  return (
    <>
    <div className="flex justify-start align-center">
      <CloseIcon
        onClick={handleClose}
        style={{ cursor: "pointer" }}
      />
    </div>

      <ResponsiveContainer width="100%" height="95%">
        <AreaChart data={data}>
          <XAxis
            dataKey="Max"
            tick={{
              fontSize: "10px",
              fill: "white",
              angle: -10,
              textAnchor: "end",
            }}
          />
          <YAxis tick={{ fontSize: "10px", fill: "white" }} />
          <ReferenceLine y={0} stroke="white" />
          <Tooltip
            contentStyle={{
              background: "rgba(0, 0, 0, 0.6)",
              color: "white",
              border: "none",
              borderRadius: "5px",
              padding: "6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          />
          <Area
            type="monotone"
            dataKey="Drawdown"
            stroke="#FF6565"
            fill="#FF656533"
          />
        </AreaChart>
      </ResponsiveContainer>
    </>
  );
};

export default Drawdown;
