import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Brush,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CircularProgress } from "@mui/material";

const PnlAllUnderlyings = ({ dealerId, dealerName }) => {
  const [liveData, setLiveData] = useState([]);
  const [underlying, setUnderlying] = useState([]);
  const [dataAvailable, setDataAvailable] = useState(true);
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;
  // console.log(dealerId);
  const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getColorByIndex = (index) => {
    const hue = (index * 137.508) % 360;
    const saturation = 30;
    const lightness = 40;
    return `hsl(${hue},${saturation}%,${lightness}%)`;
  };

  useEffect(() => {
    const fetchData = () => {
      // setDataAvailable(true);
      axios
        .post(api_server_url + `/dealer/underlyingpnlchart`, {
          dealer_id: dealerId,
        })
        .then((res) => {
          setLiveData(res.data.data);
          setUnderlying(res.data.underlyings);
          setDataAvailable(false);
        })
        .catch((error) => {
          console.error("Error fetching data for all underlyings:", error);
          setDataAvailable(false)
        });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => clearInterval(intervalId);
  }, [api_server_url, dealerId]);

  if (dataAvailable) {
    return (
      <div className="flex items-center justify-center h-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ height: "95%", width: "100%" }}>
      <ResponsiveContainer>
        {liveData?.length > 0 ? (
          <LineChart data={liveData}>
            <XAxis
              dataKey="minute"
              tick={{
                fontSize: "10px",
                fill: "#828282",
                angle: -18,
                textAnchor: "end",
              }}
            />
            <YAxis tick={{ fontSize: "10px", fill: "#828282" }} />

            <ReferenceLine y={0} stroke="white" />

            {underlying?.map((item, index) => {
              const lineColor = getColorByIndex(index);

              return (
                <Line
                  key={index}
                  type="monotone"
                  dataKey={item}
                  stroke={lineColor}
                  strokeWidth={2}
                  dot={false}
                />
              );
            })}

            <Legend
              wrapperStyle={{
                padding: "10px",
                paddingBottom: "0px",
                fontSize: "10px",
              }}
            />

            <Brush height={11} stroke="black" fill="slategray" />

            <Tooltip
              content={(dataItem) => {
                if (dataItem.payload && dataItem.payload.length > 0) {
                  const timeValue = dataItem.payload[0].payload.minute;

                  return (
                    <div
                      style={{
                        background: "rgba(1, 13, 18, 0.95)",
                        color: "white",
                        borderRadius: "5px",
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {underlying?.map((item) => (
                        <p
                          key={item}
                          style={{ fontWeight: "bold", marginBottom: "5px" }}
                        >
                          {item}: {(dataItem?.payload[0]?.payload[item])?.toLocaleString("en-IN")}
                        </p>
                      ))}
                      <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Time: {timeValue}
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
          </LineChart>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p>Data not available.</p>
          </div>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default PnlAllUnderlyings;
