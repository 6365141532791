import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import axios from 'axios';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { toast } from 'sonner';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#021B26',
    border: '2px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
    width: 400,
};

export default function EditDealer({ open, onClose, rowData, setBlock, block }) {
    const [dealerName, setDealerName] = useState(rowData?.dealerName || '');
    const [dealerId, setDealerId] = useState(rowData?.dealerID || '');
    // const [pnlTriggerValue, setPnlTriggerValue] = useState(rowData?.pnl_trigger_value || '');
    // const [pnlTriggerPercent, setPnlTriggerPercent] = useState(rowData?.pnl_trigger_percent || '');
    // const [isPnlTriggerPercent, setIsPnlTriggerPercent] = useState(false);
    // const [isMarginTriggerPercent, setIsMarginTriggerPercent] = useState(false);
    const [marginTriggerPercent, setMarginTriggerPercent] = useState(rowData?.margin_trigger_percent || '');
    const [isPnlTriggerPercent, setIsPnlTriggerPercent] = useState(false);
    const [pnlProfitValue, setPnlProfitValue] = useState(rowData?.profit_trigger_value || '');
    const [pnlLossValue, setPnlLossValue] = useState(rowData?.loss_trigger_value || '');
    const [pnlProfitPercent, setPnlProfitPercent] = useState(rowData?.profit_trigger_percent || '');
    const [pnlLossPercent, setPnlLossPercent] = useState(rowData?.loss_trigger_percent || '');
    const [varTriggerPercent, setVarTriggerPercent] = useState(rowData?.var_trigger_percent || '');
    // const [niftyHedge, setNiftyHedge] = useState(rowData?.nifty_hedge || '');
    // const [bankNiftyHedge, setBankNiftyHedge] = useState(rowData?.banknifty_hedge || '');
    // const [finNiftyHedge, setFinNiftyHedge] = useState(rowData?.finnifty_hedge || '');
    const [password, setPassword] = useState('')
    const api_server_url = process.env.REACT_APP_API_SERVER_URL;
    const confirm_password = process.env.REACT_APP_PASSWORD;
    console.log(process.env);
    // console.log(rowData)

    const updateDealerInfo = (updatedInfo) => {
        return axios.put(api_server_url + '/dealers', updatedInfo, { withCredentials: true })
            .then(response => {
                console.log('Update successful:', response.data);
                setBlock(!block);
                toast.success(`${updatedInfo.dealerName} Update successful`); // Assuming toast.success() takes a message parameter
                // Add any additional logic needed after a successful update
            })
            .catch(error => {
                console.error('Error updating dealer:', error);
                toast.error('Error updating dealer try again later'); // Assuming toast.error() takes a message parameter
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password !== confirm_password) {
            alert('Wrong password');
            return;
        }
        const updatedInfo = {
            dealerName,
            dealerID: dealerId,
            // pnl_trigger_value: isPnlTriggerPercent ? null : pnlTriggerValue,
            // pnl_trigger_percent: isPnlTriggerPercent ? pnlTriggerPercent : null,
            margin_trigger_percent: marginTriggerPercent,
            profit_trigger_value: !isPnlTriggerPercent ? pnlProfitValue : null,
            loss_trigger_value: !isPnlTriggerPercent ? pnlLossValue : null,
            profit_trigger_percent: isPnlTriggerPercent ? pnlProfitPercent : null,
            loss_trigger_percent: isPnlTriggerPercent ? pnlLossPercent : null,
            var_trigger_percent: varTriggerPercent,
            password: '',
            // nifty_hedge: niftyHedge,
            // banknifty_hedge: bankNiftyHedge,
            // finnifty_hedge: finNiftyHedge,
        };

        updateDealerInfo(updatedInfo)
            .then(() => {
                console.log(updatedInfo);
                if (onClose) onClose();
            })
            .catch(error => {
                onClose();
                console.error('Error updating dealer:', error);
            });
        setPassword('')
    };


    const handleCancel = () => {
        if (onClose) onClose();
    };

    const handleToggleChange = () => {
        setIsPnlTriggerPercent((prev) => !prev);
    };

    return (
        <Modal
            open={open}
            // onClose={onClose}
            aria-labelledby="edit-dealer-modal-title"
            aria-describedby="edit-dealer-modal-description"
        >
            <Box sx={style}>
                <form onSubmit={handleSubmit}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            id="dealer_name"
                            label="Dealer Name"
                            name="dealer_name"
                            type="text"
                            size="small"
                            value={dealerName}
                            onChange={(e) => setDealerName(e.target.value)}
                        />
                        <TextField
                            disabled
                            margin="dense"
                            required
                            fullWidth
                            id="dealer_id"
                            label="Dealer ID"
                            name="dealer_id"
                            type="text"
                            size="small"
                            value={dealerId}
                            onChange={(e) => setDealerId(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            // required
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                            fullWidth
                            id="margin_trigger_percent"
                            label="Margin Trigger %"
                            name="margin_trigger_percent"
                            type="number"
                            size="small"
                            value={marginTriggerPercent}
                            onChange={(e) => setMarginTriggerPercent(e.target.value)}
                        />
                        <TextField
                            margin="dense"
                            // required
                            sx={{
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                    display: "none",
                                },
                                "& input[type=number]": {
                                    MozAppearance: "textfield",
                                },
                            }}
                            fullWidth
                            id="var_trigger_percent"
                            label="Var Trigger %"
                            name="var_trigger_percent"
                            type="number"
                            size="small"
                            value={varTriggerPercent}
                            onChange={(e) => setVarTriggerPercent(e.target.value)}
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={isPnlTriggerPercent}
                                    onChange={handleToggleChange}
                                    name="pnlTriggerPercentSwitch"
                                />
                            }
                            label={isPnlTriggerPercent ? "Use Profit Trigger %" : "Use Profit Trigger"}
                        />
                        {isPnlTriggerPercent ? (
                            <>
                                <TextField
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    margin="dense"
                                    // required
                                    fullWidth
                                    id="profit_trigger_percent"
                                    label="Profit %"
                                    name="profit_trigger_percent"
                                    type="number"
                                    size="small"
                                    value={pnlProfitPercent}
                                    onChange={(e) => setPnlProfitPercent(e.target.value)}
                                />
                                <TextField
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    margin="dense"
                                    // required
                                    fullWidth
                                    id="loss_trigger_percent"
                                    label="Loss %"
                                    name="loss_trigger_percent"
                                    type="number"
                                    size="small"
                                    value={pnlLossPercent}
                                    onChange={(e) => setPnlLossPercent(e.target.value)}
                                />
                            </>
                        ) : (
                            <>
                                <TextField
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    margin="dense"
                                    // required
                                    fullWidth
                                    id="profit_trigger_value"
                                    label="Profit"
                                    name="profit_trigger_value"
                                    type="number"
                                    size="small"
                                    value={pnlProfitValue}
                                    onChange={(e) => setPnlProfitValue(e.target.value)}
                                />
                                <TextField
                                    sx={{
                                        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                            display: "none",
                                        },
                                        "& input[type=number]": {
                                            MozAppearance: "textfield",
                                        },
                                    }}
                                    margin="dense"
                                    // required
                                    fullWidth
                                    id="loss_trigger_value"
                                    label="Loss"
                                    name="loss_trigger_value"
                                    type="number"
                                    size="small"
                                    value={pnlLossValue}
                                    onChange={(e) => setPnlLossValue(e.target.value)}
                                />
                            </>

                        )}
                        <TextField
                            margin="dense"
                            required
                            fullWidth
                            id="password"
                            label="Password"
                            name="password"
                            type="password"
                            size="small"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'end' }}>
                            <Button onClick={handleCancel} type="button" sx={{
                                mt: 3, color: '#307c9d', border: '1px solid #307c9d',
                                '&:hover': {
                                    backgroundColor: 'transparent', // Change background color on hover
                                    color: '#509ebf',
                                    border: '1px solid #509ebf'
                                },
                            }}>
                                Cancel
                            </Button>
                            <Button type="submit" sx={{ mt: 3, backgroundColor: '#307c9d', color: '#fff' }}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Modal>
    );
}
