import React, { useEffect, useState } from "react";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AllCharts from "../components/AllCharts";
import AdminDateSelection from "../components/admin/AdminDateSelection";
import axios from "axios";
import PnlHeaderTable from "./PnlHeaderTable";
import PnlMainTable from "./PnlMainTable";
import { Skeleton } from "@mui/material";

const Admin = () => {
  const [showGraphs, setShowGraphs] = React.useState(false);
  const [headerTableData, setHeaderTableData] = React.useState();
  const [mainTableData, setMainTableData] = useState();
  const [subTableData, setSubTableData] = useState();
  const [dateSelected, setDateSelected] = React.useState("today");
  const [isLoading, setIsLoading] = React.useState(true);

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const handleClick = () => {
    setShowGraphs(true);
  };
  const HeaderTableHeaders = [
    { id: '', label: 'Cumulative Data', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '354px', fontSize: '12px' ,whiteSpace:'no-wrap', } },
    { id: 'NetPnl', label: 'Net P&L', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalProfit', label: 'Total Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalLoss', label: 'Total Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitDays', label: 'Profit Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossDays', label: 'Loss Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgProfit', label: 'Avg Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgLoss', label: 'Avg Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ROM', label: 'ROM', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxDrawdown', label: 'Max Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxIntradayDrawdown', label: 'Max Day Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'RiskRewardRatio', label: 'Reward/Risk', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitTriggers', label: 'Profit Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossTriggers', label: 'Loss Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'QtyTraded', label: 'Qty Traded', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
  ];

  const MainTableHeaders = [
    { id: 'Rank', label: 'Rank', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'DealerID', label: 'ID', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'Name', label: 'Name', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'NetPnl', label: 'Net P&L', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalProfit', label: 'Total Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalLoss', label: 'Total Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitDays', label: 'Profit Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'} },
    { id: 'LossDays', label: 'Loss Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgProfit', label: 'Avg Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgLoss', label: 'Avg Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ROM', label: 'ROM', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxDrawdown', label: 'Max Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxIntradayDrawdown', label: 'Max Day Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'RiskRewardRatio', label: 'Reward/Risk', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitTriggers', label: 'Profit Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossTriggers', label: 'Loss Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'QtyTraded', label: 'Qty Traded', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
  ];

  const SubTableHeaders = [
    { id: '', label: 'Rank', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: '', label: 'ID', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: "Time", label: 'Name', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'NetPnl', label: 'Net P&L', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalProfit', label: 'Total Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'TotalLoss', label: 'Total Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitDays', label: 'Profit Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'} },
    { id: 'LossDays', label: 'Loss Days', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgProfit', label: 'Avg Profit', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'AvgLoss', label: 'Avg Loss', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ROM', label: 'ROM', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'} },
    { id: 'MaxDrawdown', label: 'Max Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'MaxIntradayDrawdown', label: 'Max Day Dr', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'RiskRewardRatio', label: 'Reward/Risk', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'ProfitTriggers', label: 'Profit Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'LossTriggers', label: 'Loss Hits', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
    { id: 'QtyTraded', label: 'Qty Traded', style: { pl: 0.5, pr:0.5, pt: 0.5, pb: 0.5, width: '100px', fontSize: '12px' ,whiteSpace:'no-wrap'}  },
  ];

  const fetchData = () => {
    setIsLoading(true);
    if (dateSelected) {
      axios
        .post(api_server_url + `/backoffice/pnltable/all`, {
          range: dateSelected,
          year: "2024",
        })
        .then((res) => {
          // console.log(res.data);
          const months = res.data;
          setHeaderTableData(res.data[0]);
          setMainTableData(res.data.slice(1));
          const data = months?.map((item) => item.Sub);
          // console.log(data);
          setSubTableData(data.slice(1));
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
  }, [dateSelected]);

  return (
    <>
      {!showGraphs ? (
        <div className="flex flex-col gap-2 border-[1px] border-[#307C9D] p-2 border-opacity-50 rounded-md relative">
          <h1 className="text-center items-center text-[14px] absolute left-1/2 transform -translate-x-1/2">
            Admin
          </h1>
          <div className="flex items-center">
            <AdminDateSelection
              dateSelected={dateSelected}
              setDateSelected={setDateSelected}
            />
            <div className="flex items-center gap-3 ml-auto">
              <button
                className="bg-[#307C9D1F] p-1 rounded-sm"
                onClick={handleClick}
              >
                <ShowChartIcon />
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-5">
            {isLoading ? (
              <Skeleton
                variant="wave"
                width="100%"
                height={200}
                style={{ borderRadius: 5 }}
              />
            ) : (
              <>
                {headerTableData && (
                  <PnlHeaderTable
                    headers={HeaderTableHeaders}
                    data={headerTableData}
                  />
                )}
                {mainTableData && (
                  <PnlMainTable
                    headers={MainTableHeaders}
                    data={mainTableData}
                    subtableData={subTableData}
                    subheaders={SubTableHeaders}
                  />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <AllCharts
          setShowGraphs={setShowGraphs}
          dateSelected={dateSelected}
          setDateSelected = {setDateSelected}
          year="2024"
          // requestData={{ range: dateSelected, year: "2024" }}
        />
      )}
    </>
  );
};

export default Admin;
