import React from 'react';
import { MaterialReactTable, createMRTColumnHelper, useMaterialReactTable, } from 'material-react-table';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { Box, Button, Dialog, DialogActions, DialogContent, TextField, DialogContentText, Paper, DialogTitle, Skeleton, Table, TableCell, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import io from "socket.io-client";
import { throttle } from 'lodash';
import axios from 'axios';
import { toast } from 'sonner';
import DownloadingOutlinedIcon from "@mui/icons-material/DownloadingOutlined";


const columnHelper = createMRTColumnHelper();

const DealersLivePositions = () => {
    const [data, setData] = React.useState([]);
    const [tableData, setTableData] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [sorting, setSorting] = React.useState([]);
    const rowVirtualizerInstanceRef = React.useRef(null);
    const [isSquareOffDialogOpen, setIsSquareOffDialogOpen] = React.useState(false);
    const [selectedRowForSquareOff, setSelectedRowForSquareOff] = React.useState(null);
    const [deletePassword, setDeletePassword] = React.useState('');
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [isAllSquareOffDialogOpen, setIsAllSquareOffDiagloOpen] = React.useState(false)
    const api_server_url = process.env.REACT_APP_API_SERVER_URL;
    const socket_server_url = process.env.REACT_APP_SOCKET_SERVER_URL;
    const confirm_password = process.env.REACT_APP_PASSWORD

    const throttledSetData = React.useCallback(throttle((newData) => {
        setData(newData);
    }), []);

    const openSquareOffDialog = (rowData) => {
        setSelectedRowForSquareOff(rowData);
        setIsSquareOffDialogOpen(true);
    };

    const openAllSquareoffDialog = (rowData) => {
        setIsAllSquareOffDiagloOpen(true)
    }

    // const handleDealerSquareOff = (rowData) => {
    //     if (deletePassword !== confirm_password) {
    //         alert('Incorrect password');
    //         return;
    //     }
    //     if (!selectedRowForSquareOff) {
    //         console.error('no row selected for squareoff')
    //         return
    //     };
    //     const sqdata = { order: selectedRowForSquareOff }; // Square off single row
    //     console.log(sqdata)
    //     axios.put(api_server_url + '/dealer/squareoff', sqdata, {
    //         withCredentials: true,
    //     }).then(response => {
    //         toast.success(`Squared off successfully`);
    //         setIsSquareOffDialogOpen(false);
    //         setDeletePassword('');
    //     }).catch(error => {
    //         setIsSquareOffDialogOpen(false);
    //         toast.error('Square off unsuccessful. Try again later.');
    //         console.error('Error fetching data:', error);
    //         setDeletePassword('');
    //     });
    // };

    const handleSelection = (row) => {
        const isSelected = selectedRows?.some(selectedRow => selectedRow?.id === row.id);
        let newSelected = [];

        if (!isSelected) {
            // If the row is not already selected, add it to the list
            newSelected = [...selectedRows, row];
        } else {
            // If the row is already selected, remove it from the list
            newSelected = selectedRows?.filter(selectedRow => selectedRow?.id !== row.id);
        }

        setSelectedRows(newSelected);
        console.log(newSelected);
    }
    // const finalData = selectedRows?.map((val) => val?.original || 'Nhi Hoga')
    // console.log(finalData);


    const handleDealerSquareOff = (rowData) => {
        if (deletePassword !== confirm_password) {
            alert('Incorrect password');
            return;
        }
        if (!selectedRowForSquareOff) {
            console.error('no row selected for squareoff')
            return
        };

        // if (selectedRowForSquareOff) {
        let sqdata = { order: [selectedRowForSquareOff] }; // Square off single row
        console.log(sqdata)
        // } else {
        //     console.error('No rows selected for square off');
        //     return;
        // }
        axios.put(api_server_url + '/dealer/squareoff', sqdata, {
            withCredentials: true,
        }).then(response => {
            console.log(response.data)
            toast.success(`Squared off successfully`);
            setIsSquareOffDialogOpen(false);
            setDeletePassword('');
            setSelectedRowForSquareOff(null);
        }).catch(error => {
            setIsSquareOffDialogOpen(false);
            toast.error('Square off unsuccessful. Try again later.');
            console.error('Error fetching data:', error);
            setDeletePassword('');
            setSelectedRowForSquareOff(null);
        });
    };

    const handleDealerAllSquareOff = (rowData) => {
        if (deletePassword !== confirm_password) {
            alert('Incorrect password');
            return;
        }
        let sqdata = { order: selectedRows?.map((val) => val?.original || []) }; // Square off selected rows
        console.log(sqdata)
        axios.put(api_server_url + '/dealer/squareoff', sqdata, {
            withCredentials: true,
        }).then(response => {
            console.log(response.data)
            toast.success(`Squared off successfully`);
            setIsAllSquareOffDiagloOpen(false);
            setDeletePassword('');
            setSelectedRows([])
        }).catch(error => {
            setIsAllSquareOffDiagloOpen(false);
            toast.error('Square off unsuccessful. Try again later.');
            console.error('Error fetching data:', error);
            setDeletePassword('');
            setSelectedRows([])
        });
    };

    React.useEffect(() => {
        const socket = io(socket_server_url + "/testLivePositions");

        socket.on('connect', () => console.log('Connected to server'));
        socket.on("disconnect", () => console.log("Disconnected from server"));

        socket.on("test-livepositions", (newData) => {
            if (newData) {
                // console.log(newData);
                throttledSetData(newData);
                setLoading(false);
            }
            else {
                setLoading(false)
            }
        });

        return () => {
            socket.disconnect();
            throttledSetData.cancel();
        };
    }, [socket_server_url, throttledSetData]);

    React.useEffect(() => {
        if (typeof window !== 'undefined') {
            setTableData(data);
        }
    }, [data]);

    React.useEffect(() => {
        try {
            rowVirtualizerInstanceRef.current?.scrollToIndex?.(0);
        } catch (error) {
            console.error(error);
        }
    }, [sorting]);

    const columns = [
        columnHelper.accessor('dealer_id', {
            header: 'ID', size: 140, muiTableHeadCellProps: { align: 'center' }, muiTableBodyCellProps: { align: 'center' }
        }),
        columnHelper.accessor('dealer_name', {
            header: 'Name',
            size: 140,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('symbol_name', {
            header: 'Symbol',
            size: 250,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('underlying', {
            header: 'Underlying',
            size: 165,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('order_side', {
            header: 'Order Side',
            size: 160,
            Cell: ({ row }) => (
                <Box align='center' style={{ color: row.original.order_side === 'Long' ? '#25d8f4' : '#ff7c52', }}>
                    {row.original.order_side}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('total', {
            header: 'QTY',
            size: 220,
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.total}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        // columnHelper.accessor('realised_day', {
        //     header: 'Realised Day',
        //     size: 220,
        //     Cell: ({ row }) => (
        //         <Box style={{ color: row.original.realised_day > 0 ? '#97ff95' : '#ff6565' }}>
        //             {row.original.realised_day && row.original.realised_day.toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
        //         </Box>
        //     ),
        //     muiTableHeadCellProps: {
        //         align: 'center',
        //     },
        //     muiTableBodyCellProps: {
        //         align: 'center',
        //     },
        //     filterVariant: 'range',
        //     filterFn: 'betweenInclusive',
        // }),
        // columnHelper.accessor('unrealised_day', {
        //     header: 'Unrealised Day',
        //     size: 220,
        //     Cell: ({ row }) => (
        //         <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1, color: row.original.unrealised_day > 0 ? '#97ff95' : '#ff6565' }}>
        //             {row.original.unrealised_day && row.original.unrealised_day.toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
        //         </Box>
        //     ),
        //     muiTableHeadCellProps: {
        //         align: 'center',
        //     },
        //     muiTableBodyCellProps: {
        //         align: 'center',
        //     },
        //     filterVariant: 'range',
        //     filterFn: 'betweenInclusive',
        // }),
        columnHelper.accessor('realised', {
            header: 'Realised',
            size: 220,
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1, color: row?.original?.realised > 0 ? '#97ff95' : '#ff6565' }}>
                    {row?.original?.realised && row.original?.realised?.toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('unrealised', {
            header: 'Unrealised',
            size: 220,
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1, color: row.original.unrealised > 0 ? '#97ff95' : '#ff6565' }}>
                    {row.original.unrealised && row.original.unrealised.toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('net_pnl', {
            header: 'Actual MTM',
            Cell: ({ row }) => (
                <Box style={{ color: row.original.net_pnl > 0 ? '#97FF95' : '#FF6565', }} sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.net_pnl && row.original.net_pnl.toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            size: 220,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        // columnHelper.accessor('day_pnl', {
        //     header: 'Terminal P&L',
        //     size: 220,
        //     muiTableHeadCellProps: {
        //         align: 'center',
        //     },
        //     Cell: ({ row }) => (
        //         <Box style={{ color: row.original.day_pnl > 0 ? '#97FF95' : '#FF6565', }} sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
        //             {row.original.day_pnl && row.original.day_pnl.toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
        //         </Box>
        //     ),
        //     muiTableBodyCellProps: {
        //         align: 'center',
        //     },
        //     filterVariant: 'range',
        //     filterFn: 'betweenInclusive',
        // }),

        columnHelper.accessor('avg_price', {
            header: 'Avg Price',
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.avg_price && parseFloat(row.original.avg_price).toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            size: 220,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('last_entry_price', {
            header: 'Last Entry Price',
            size: 220,
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.last_entry_price && parseFloat(row.original.last_entry_price).toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('bid', {
            header: 'bid',
            size: 220,
            muiTableHeadCellProps: {
                align: 'center',
            },
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.bid && parseFloat(row.original.bid).toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('ask', {
            header: 'Ask',
            size: 220,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.ask && parseFloat(row.original.ask).toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('ltp', {
            header: 'Ltp',
            size: 220,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.ltp && parseFloat(row.original.ltp).toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('buy_avg_price', {
            header: 'Buy Avg Price',
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.buy_avg_price && parseFloat(row.original.buy_avg_price).toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            size: 220,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('sell_avg_price', {
            header: 'Sell Avg Price',
            size: 220,
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0, display: 'flex', gap: 1 }}>
                    {row.original.sell_avg_price && parseFloat(row.original.sell_avg_price).toLocaleString('en-IN', { style: 'currency', currency: 'INR'})}
                </Box>
            ),
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            filterVariant: 'range',
            filterFn: 'betweenInclusive',
        }),
        columnHelper.accessor('last_order_type', {
            header: 'Last Order Type',
            size: 170,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),
        columnHelper.accessor('expiry', {
            header: 'Expiry',
            size: 170,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
        }),

        columnHelper.accessor('squareoff', {
            id: 'squareoff',
            header: 'Square Off',
            size: 120,
            muiTableHeadCellProps: {
                align: 'center',
            },
            muiTableBodyCellProps: {
                align: 'center',
            },
            Cell: ({ row }) => (
                <Box sx={{ padding: 0, margin: 0.5, display: 'flex', gap: 1, maxWidth: '120px' }}>
                    <Button
                        sx={{ backgroundColor: '#1d1d1d', color: '#ff7373', border: '1px solid #ff7373', py: 0.3, px: 1, fontSize: '10px' }}
                        onClick={() => openSquareOffDialog(row.original)}
                    >
                        Soff
                    </Button>
                </Box >
            ),
            enableSorting: false,
            enableColumnFilter: false,
        }),
    ];

    const handleExportRows = (rows) => {
        try {
            if (!Array.isArray(rows) || rows.length === 0) {
                throw new Error("No rows to export.");
            }
            toast.success('Downloaded Successfully')
            const doc = new jsPDF('landscape', 'pt', 'a4'); // Use landscape orientation and A4 size
            // Process the table headers
            const tableHeaders = columns.map((c) => c.header || '');

            const tableData = rows.map((row) => {
                return columns.map((column) => {
                    const cellValue = row.original ? row.original[column.id] : '';
                    return cellValue !== null && cellValue !== undefined ? cellValue.toString() : '';
                });
            });

            // Calculate column widths
            const pageWidth = doc.internal.pageSize.getWidth();
            const columnCount = columns.length;
            const defaultColumnWidth = pageWidth / columnCount;

            // Set up column styles
            let columnStyles = {};
            columns.forEach((column, index) => {
                columnStyles[index] = { cellWidth: defaultColumnWidth };
            });

            autoTable(doc, {
                theme: 'grid',
                head: [tableHeaders],
                body: tableData,
                styles: {
                    overflow: 'linebreak', // Ensures content doesn't overflow
                    cellWidth: 'wrap',
                    fontSize: 8, // Smaller font size to fit more content
                },
                columnStyles: columnStyles,
                startY: 10,
                margin: { top: 10, right: 10, bottom: 10, left: 10 },
                didDrawPage: function (data) {
                    doc.text('Page ' + doc.internal.getNumberOfPages(), pageWidth - 20, doc.internal.pageSize.height - 10);
                },
            });

            const csvContent = [tableHeaders.join(',')];
            tableData.forEach((row) => {
                csvContent.push(row.join(','));
            });
            const csvBlob = new Blob([csvContent.join('\n')], { type: 'text/csv' });
            const csvUrl = URL.createObjectURL(csvBlob);
            const csvLink = document.createElement('a');
            csvLink.href = csvUrl;
            csvLink.setAttribute('download', 'Dealerwise.csv');
            document.body.appendChild(csvLink);
            csvLink.click();
            document.body.removeChild(csvLink);

            doc.save('Dealerwise.pdf');
        } catch (error) {
            toast.error('Donwload Failed')
            console.error("Error while generating PDF: ", error);
            alert("An error occurred while generating the PDF: " + error.message);
        }
    };

    const table = useMaterialReactTable({
        columns,
        data,
        getRowId: (row) => row.id,
        muiTableBodyRowProps: ({ row }) => ({
            //implement row selection click events manually
            onClick: () => { handleSelection(row) },
            hover: false,
            selected: selectedRows?.some(selectedRow => selectedRow?.id === row.id),
            sx: {
                cursor: 'pointer',
                backgroundColor: selectedRows?.some(selectedRow => selectedRow?.id === row.id) ? '#fff' : 'inherit', // Set background color to white if row is selected
                p: 0,
            },
        }),
        onRowSelectionChange: setSelectedRows,
        state: { selectedRows, loading, sorting },
        enableRowVirtualization: true,
        // enableClickToCopy: true,
        enablePagination: false,
        enableBottomToolbar: false,
        enableStickyHeader: true,
        enableDensityToggle: false,
        columnResizeMode: 'onChange',
        // enableColumnPinning: true,
        // enableColumnResizing: true,
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                //   fontStyle: 'italic',
                fontWeight: '800',
                backgroundColor: '#021b26',
                fontSize: '15px',
                paddingY: 1.5
            },
        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid rgba(48, 124, 157, 0.7)',
                paddingY: 0
            },
        },
        // enableColumnVirtualization: true,
        muiTableContainerProps: {
            sx: { maxHeight: '66vh', minWidth: 'full' },
            className:'alarm-scrollbar'
        },
        onSortingChange: setSorting,
        rowVirtualizerInstanceRef,
        enableGlobalFilterModes: true,
        enableColumnActions: false,
        initialState: {
            density: 'compact',
            columnPinning: { left: ['dealer_id', 'dealer_name', 'symbol_name', 'total', "id", "position"] },
        },
        rowVirtualizerOptions: { overscan: 5 }, renderTopToolbarCustomActions: ({ table }) => (
            <div className='flex gap-4'>
                <Button
                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                    onClick={() =>
                        handleExportRows(table.getPrePaginationRowModel().rows)
                    }
                    style={{ py: 0.5, px: 0, marginBottom: '6px', backgroundColor: '#1d1d1d7d', color: '#3b9599', border: '1px solid #3b95995D', fontSize: "11px" }}
                    startIcon={<DownloadingOutlinedIcon />}
                >
                    Download
                </Button>
                {selectedRows?.length >= 2 && (
                    <Button
                        onClick={(row) => openAllSquareoffDialog(row.original)}
                        sx={{ backgroundColor: '#1d1d1d', color: '#ff7373', border: '1px solid #ff7373', margin: 0 }}
                    >
                        Square Off
                    </Button>
                )}
            </div>
        ),
    });

    const skeletonRows = [...Array(10)].map((_, index) => (
        <TableRow key={index}>
            {columns.map((column, colIndex) => (
                <TableCell key={colIndex}>
                    <Skeleton animation="wave" height={30} />
                </TableCell>
            ))}
        </TableRow>
    ));

    React.useEffect(() => {
        const rootSocket = io(socket_server_url + "/");

        rootSocket.on('connect', () => console.log('Connected to root socket server'));
        rootSocket.on("disconnect", () => console.log("Disconnected from root socket server"));

        rootSocket.emit("livepositions", "Test Update from frontend");

        return () => {
            rootSocket.disconnect();
        };
    }, [socket_server_url]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // height: '75vh',
                    overflow: 'hidden',
                    // margin: '20px',
                    
                }}
                className='MUITable-scrollbar border border-[#307C9D] border-opacity-50 rounded-md'
            >
                {loading ? (
                    <TableContainer component={Paper} style={{ maxHeight: '450px', overflow: 'auto' }} className='alarm-scrollbar'>
                        <Table sx={{ minWidth: 600 }} aria-label="skeleton table">
                            <TableHead sx={{ backgroundColor: '#021B26' }}>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell key={column.id} style={{ ...column.style }}>
                                            <Skeleton animation="wave" height={30} />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody sx={{ backgroundColor: '#021B26' }}>{skeletonRows}</TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Box sx={{ padding: "0px", margin: '0' }}>
                        <MaterialReactTable table={table} />
                    </Box>
                )}
            </Box>
            <Dialog
                open={isSquareOffDialogOpen}
                onClose={() => setIsSquareOffDialogOpen(false)}
                aria-labelledby="square-off-dialog-title"
                aria-describedby="square-off-dialog-description"
                sx={{ '& .MuiDialog-paper': { backgroundColor: '#011F2C' } }} // Example background color
            >
                <DialogTitle id="square-off-dialog-title">{"Confirm Square Off"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="square-off-dialog-description" sx={{ color: 'white' }}>
                        Are you sure you want to square off this dealer?
                    </DialogContentText>
                    <TextField
                        type="password"
                        value={deletePassword}
                        onChange={(e) => setDeletePassword(e.target.value)}
                        fullWidth
                        autoFocus
                        size='small'
                        margin="normal"
                        label="Password"
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsSquareOffDialogOpen(false)}>Cancel</Button>
                    <Button onClick={handleDealerSquareOff} color="primary" autoFocus>
                        Square Off
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={isAllSquareOffDialogOpen}
                onClose={() => setIsAllSquareOffDiagloOpen(false)}
                aria-labelledby="square-off-dialog-title"
                aria-describedby="square-off-dialog-description"
                sx={{ '& .MuiDialog-paper': { backgroundColor: '#011F2C' } }} // Example background color
            >
                <DialogTitle id="square-off-dialog-title">{"Confirm Square Off"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="square-off-dialog-description" sx={{ color: 'white' }}>
                        Are you sure you want to square off this dealer?
                    </DialogContentText>
                    <TextField
                        type="password"
                        value={deletePassword}
                        onChange={(e) => setDeletePassword(e.target.value)}
                        fullWidth
                        autoFocus
                        size='small'
                        margin="normal"
                        label="Password"
                        variant="outlined"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsAllSquareOffDiagloOpen(false)}>Cancel</Button>
                    <Button onClick={handleDealerAllSquareOff} color="primary" autoFocus>
                        Square Off
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

};

export default DealersLivePositions;