import React, { useEffect, useState } from "react";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import PieChart from "./PieChart";
import ScatterChart from "../Pnl/Charts/ScatterChart";
import axios from "axios";
import { Skeleton } from "@mui/material";
import PnlDaysBarHorizontal from "../Pnl/Charts/PnlDaysBarHorizontal";
import NetPnlBars from "../Pnl/Charts/NetPnlBars";
import TodayBarNLine from "../Pnl/Charts/TodayBarNLine";
import TodayPnlLine from "../Pnl/Charts/TodayPnlLine";
import PnlDrawdownBars from "../Pnl/Charts/PnlDrawdownBars";
import AdminDateSelection from "./admin/AdminDateSelection";

const pnlWeekPieColors = [
  "#792A10",
  "#A54323",
  "#BF6445",
  "#E18465",
  "#F3B29C",
  "#FFDED3",
];

const PnlSegmentColors = [
  "#10406C",
  "#194F80",
  "#2E699E",
  "#5B94C8",
  "#8FBEE7",
];

const AllCharts = ({
  setShowGraphs,
  dateSelected,
  setDateSelected,
  year
}) => {
  // console.log("request data here", requestData);
  // console.log(dateSelected)
  const api_server_url = process.env.REACT_APP_API_SERVER_URL;
  const [pnlWeekPie, setPnlWeekPie] = useState([]);
  const [pnlSegment, setPnlSegment] = useState([]);
  const [riskReward, setRiskReward] = useState([]);
  const [pnlDaysBar, setPnlDaysBar] = useState([]);
  const [pnlBarNLine, setPnlBarNLine] = useState([]);
  const [pnlMaxDrBar, setPnlMaxDrBar] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setShowGraphs(false);
  };

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      axios
        .post(api_server_url + `/backoffice/pnlchart/all`, { range: dateSelected, year: year })
        .then((res) => {
          // console.log(res.data);
          // console.log(res.data.PnlBarNLine);
          const {
            PnlBarNLine,
            PnlSegment,
            RiskReward,
            PnlDaysBar,
            PnlWeekPie,
            PnlMaxDrBar,
          } = res.data;
          setPnlWeekPie(PnlWeekPie);
          setPnlSegment(PnlSegment);
          setRiskReward(RiskReward);
          setPnlDaysBar(PnlDaysBar);
          setPnlBarNLine(PnlBarNLine);
          setPnlMaxDrBar(PnlMaxDrBar);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setIsLoading(false);
        });
    };

    fetchData();
  }, [api_server_url, dateSelected, year]);

  return (
    <div className="flex flex-col gap-2 h-[78vh] rounded-lg p-4">
      <div className="self-start flex items-center gap-4">
        <button onClick={handleClick} className="text-[#8AC3DB] text-sm">
          <ArrowBackIosRoundedIcon fontSize="small" />
          Back
        </button>
        <AdminDateSelection
          dateSelected={dateSelected}
          setDateSelected={setDateSelected}
        />
      </div>

      {dateSelected === "today" ? (
        <div className="flex flex-col w-full gap-3">
          <div className="w-full flex gap-2">
            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[50%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height={200}
                />
              ) : riskReward?.length > 0 ? (
                <TodayBarNLine />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>

            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[50%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 5 }}
                />
              ) : pnlSegment?.length > 0 ? (
                <PieChart
                  originalData={pnlSegment}
                  colors={PnlSegmentColors}
                  name="Segment Wise P&L"
                />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>
          </div>
          <div className="w-full flex gap-2">
            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[50%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height={200}
                />
              ) : pnlSegment?.length > 0 ? (
                <TodayPnlLine />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>
            <div className="border border-[#307C9D] border-opacity-50 flex items-center justify-center rounded-lg p-4 h-[35vh] w-[50%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 5 }}
                />
              ) : riskReward?.length > 0 ? (
                <ScatterChart
                  riskReward={riskReward}
                  name="Risk to Reward Ratio"
                />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-2">
          <div className="flex gap-2">
            <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 5 }}
                />
              ) : pnlBarNLine?.length > 0 ? (
                <NetPnlBars data={pnlBarNLine} />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>

            <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 5 }}
                />
              ) : pnlSegment?.length > 0 ? (
                <PieChart
                  originalData={pnlSegment}
                  colors={PnlSegmentColors}
                  name="Segment Wise P&L"
                />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>

            <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 5 }}
                />
              ) : pnlDaysBar?.length > 0 ? (
                <PnlDaysBarHorizontal data={pnlDaysBar} name="P&L Days" />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>
          </div>

          <div className="flex gap-2">
            {dateSelected === "this_month" ? (
              <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
                {isLoading ? (
                  <Skeleton
                    animtion="wave"
                    variant="rounded"
                    width="100%"
                    height="100%"
                    style={{ borderRadius: 5 }}
                  />
                ) : pnlMaxDrBar?.length > 0 ? (
                  <PnlDrawdownBars data={pnlMaxDrBar} />
                ) : (
                  <p className="text-center">Data not available</p>
                )}
              </div>
            ) : (
              <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
                {isLoading ? (
                  <Skeleton
                    animtion="wave"
                    variant="rounded"
                    width="100%"
                    height="100%"
                    style={{ borderRadius: 5 }}
                  />
                ) : pnlMaxDrBar?.length > 0 ? (
                  <NetPnlBars data={pnlMaxDrBar} />
                ) : (
                  <p className="text-center">Data not available</p>
                )}
              </div>
            )}

            <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 5 }}
                />
              ) : pnlWeekPie?.length > 0 ? (
                <PieChart
                  originalData={pnlWeekPie}
                  colors={pnlWeekPieColors}
                  name="Day Wise P&L"
                />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>

            <div className="border border-[#307C9D] border-opacity-50 rounded-lg p-4 h-[35vh] w-[33%]">
              {isLoading ? (
                <Skeleton
                  animtion="wave"
                  variant="rounded"
                  width="100%"
                  height="100%"
                  style={{ borderRadius: 5 }}
                />
              ) : riskReward?.length > 0 ? (
                <ScatterChart
                  riskReward={riskReward}
                  name="Risk to Reward Ratio"
                />
              ) : (
                <p className="text-center">Data not available</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllCharts;
