import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@material-tailwind/react';
import Button from '@mui/material/Button';
import dayjs from 'dayjs'; // Import dayjs library
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

// Define custom theme styling
const customTheme = createTheme({
    components: {
        MuiDateCalendar: {
            styleOverrides: {
                root: {
                    color: '#bbdefb',
                    borderRadius: 20,
                    borderWidth: 19,
                    borderColor: '#2196f3',
                    border: '19px solid',
                    backgroundColor: '#011620',
                }
            }
        }
    }
});

export default function SelectedDateBetween({ setRangeType, startDate: initialStartDate, endDate: initialEndDate, setStartDate, setEndDate }) {
    const [startDate, setInternalStartDate] = React.useState(dayjs(initialStartDate)); // Initialize startDate as a dayjs object
    const [endDate, setInternalEndDate] = React.useState(dayjs(initialEndDate));
    // const [checked, setChecked] = React.useState(false);
    const handleSetDate = (rangeType) => {
        if (startDate && endDate) {
            const formattedStartDate = formatDate(startDate);
            const formattedEndDate = formatDate(endDate);
            setStartDate(formattedStartDate);
            setEndDate(formattedEndDate);
            setRangeType(rangeType);
            // setChecked(prev => !prev)
            // console.log("Start Date:", formattedStartDate);
            // console.log("End Date:", formattedEndDate);
        } else {
            console.error('Please select both start and end dates.');
        }
    };

    const formatDate = (date) => {
        if (date) {
            return date.format('YYYY-MM-DD'); // Use format function of dayjs to format the date
        } else {
            return null;
        }
    };

    return (
        <ThemeProvider theme={customTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer sx={{ px: 1, py: 0 }} style={{ display: 'flex', alignItems: 'center', gap: 3 }} components={['DatePicker', 'DatePicker']}>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-[14px] font-semibold'>From</h1>
                        <DesktopDatePicker
                            slotProps={{
                                input: {
                                    sx: {
                                        paddingY: '4px', // Adjust padding vertically
                                        paddingX: '12px', // Adjust padding horizontally
                                    },
                                },
                                layout: {
                                    sx: {
                                        '.MuiDateCalendar-root': {
                                            color: '#bbdefb',
                                            borderRadius: 2,
                                            borderWidth: 1,
                                            borderColor: '#fff',
                                            border: '1px solid',
                                            backgroundColor: '#011620',
                                            px: 1,
                                            py: 0,
                                            fontSize: '12px',
                                        },
                                    },
                                },
                            }}
                            openTo="year"
                            views={['year', 'month', 'day']}
                            value={startDate || null} // Use controlled value or null if undefined
                            onChange={value => setInternalStartDate(value)}
                        />
                    </div>
                    <div className='flex items-center gap-2'>
                        <h1 className='text-[14px] font-semibold'>to</h1>
                        <DesktopDatePicker
                            slotProps={{
                                input: {
                                    sx: {
                                        paddingY: '4px', // Adjust padding vertically
                                        paddingX: '12px', // Adjust padding horizontally
                                    },
                                },
                                layout: {
                                    sx: {
                                        '.MuiDateCalendar-root': {
                                            color: '#bbdefb',
                                            borderRadius: 2,
                                            borderWidth: 1,
                                            borderColor: '#2196f3',
                                            border: '1px solid',
                                            backgroundColor: '#011620',
                                            px: 1,
                                            py: 0,
                                            fontSize: '12px',
                                        },
                                    },
                                },
                            }}
                            openTo="year"
                            views={['year', 'month', 'day']}
                            value={endDate || null} // Use controlled value or null if undefined
                            onChange={value => setInternalEndDate(value)}
                        />
                    </div>
                    <Button onClick={() => handleSetDate("calendar_day")} style={{ fontSize: '11px', fontWeight: 600, backgroundColor: '#1B4152', py: 0, px: 1, color: '#fff' }}>Set Date</Button>
                    <Button onClick={() => handleSetDate("week_day")} style={{ fontSize: '11px', fontWeight: 600, backgroundColor: 'transparent', py: 0, px: 1, color: '#fff', border: '1px solid #1B4152' }}>Day Wise</Button>
                    {/* <div className='flex items-center justify-center'>
                        <FormControlLabel
                            control={<Checkbox
                                checked={checked}
                                onChange={() => handleSetDate("week_day")}
                                style={{ color: 'white' }}
                                inputProps={{ 'aria-label': 'day-wise checkbox' }}
                            />}
                            label="Day Wise"
                            style={{ color: 'white', fontSize: '7px', fontWeight: 600 }}
                        />
                    </div> */}


                </DemoContainer>
            </LocalizationProvider>
        </ThemeProvider >
    );
}

// 👍🧮  if this above code is not working then use below code once i'm using above code because when i use the below code and select the date got 400 error..... but after set Date got the right output

// import * as React from 'react';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DesktopDatePicker } from '@mui/x-date-pickers';
// import { createTheme } from '@mui/material/styles';
// import { ThemeProvider } from '@material-tailwind/react';
// import Button from '@mui/material/Button';

// // Define custom theme styling
// const customTheme = createTheme({
//     components: {
//         MuiDateCalendar: {
//             styleOverrides: {
//                 root: {
//                     color: '#bbdefb',
//                     borderRadius: 20,
//                     borderWidth: 19,
//                     borderColor: '#2196f3',
//                     border: '19px solid',
//                     backgroundColor: '#011620',
//                 }
//             }
//         }
//     }
// });

// export default function SelectedDateBetween({ startDate, endDate, setStartDate, setEndDate }) {
//     // const [startDate, setInternalStartDate] = React.useState(initialStartDate);
//     // const [endDate, setInternalEndDate] = React.useState(initialEndDate);

//     const handleSetDate = () => {
//         if (startDate && endDate) {
//             const formattedStartDate = formatDate(startDate);
//             const formattedEndDate = formatDate(endDate);
//             setStartDate(formattedStartDate);
//             setEndDate(formattedEndDate);
//             console.log("Start Date:", formattedStartDate);
//             console.log("End Date:", formattedEndDate);
//         } else {
//             // console.error('Please select both start and end dates.');
//         }
//     }

//     const formatDate = (date) => {
//         if (date) {
//             const year = date.$y;
//             const month = date.$M + 1; // Adding 1 because month is zero-based
//             const day = date.$D;

//             // Ensure month and day have leading zeros if necessary
//             const formattedMonth = month < 10 ? `0${month}` : `${month}`; // Ensure month is a string
//             const formattedDay = day < 10 ? `0${day}` : `${day}`; // Ensure day is a string

//             return `${year}-${formattedMonth}-${formattedDay}`;
//         } else {
//             return null;
//         }
//     }

//     return (
//         <ThemeProvider theme={customTheme}>
//             <LocalizationProvider sx={{ py: 0.5, px: 1 }} dateAdapter={AdapterDayjs}>
//                 <DemoContainer sx={{ px: 1, py: 0.5 }} style={{ display: 'flex', alignItems: 'center', gap: 3 }} components={['DatePicker', 'DatePicker']}>
//                     <div className='flex items-center gap-2'>
//                         <h1>From</h1>
//                         <DesktopDatePicker
//                             slotProps={{
//                                 input: {
//                                     sx: {
//                                         paddingY: '4px', // Adjust padding vertically
//                                         paddingX: '12px', // Adjust padding horizontally
//                                     },
//                                 },
//                                 layout: {
//                                     sx: {
//                                         '.MuiDateCalendar-root': {
//                                             color: '#bbdefb',
//                                             borderRadius: 2,
//                                             borderWidth: 1,
//                                             borderColor: '#2196f3',
//                                             border: '1px solid',
//                                             backgroundColor: '#0d47a1',
//                                             px: 1,
//                                             py: 0,
//                                             fontSize: '12px',
//                                         },
//                                     },
//                                 },
//                             }}
//                             openTo="year"
//                             views={['year', 'month', 'day']}
//                             value={startDate || null} // Use controlled value or null if undefined
//                             onChange={value => setStartDate(value)}
//                         />
//                     </div>
//                     <div className='flex items-center gap-2'>
//                         <h1>to</h1>
//                         <DesktopDatePicker
//                             slotProps={{
//                                 input: {
//                                     sx: {
//                                         paddingY: '4px', // Adjust padding vertically
//                                         paddingX: '12px', // Adjust padding horizontally
//                                     },
//                                 },
//                                 layout: {
//                                     sx: {
//                                         '.MuiDateCalendar-root': {
//                                             color: '#bbdefb',
//                                             borderRadius: 2,
//                                             borderWidth: 1,
//                                             borderColor: '#2196f3',
//                                             border: '1px solid',
//                                             backgroundColor: '#0d47a1',
//                                             px: 1,
//                                             py: 0,
//                                             fontSize: '12px',
//                                         },
//                                     },
//                                 },
//                             }}
//                             openTo="year"
//                             views={['year', 'month', 'day']}
//                             value={endDate || null} // Use controlled value or null if undefined
//                             onChange={value => setEndDate(value)}
//                         />
//                     </div>
//                     <Button variant="contained" onClick={handleSetDate} style={{ color: 'black', fontSize: '12px' }}>Set Date</Button>
//                 </DemoContainer>
//             </LocalizationProvider>
//         </ThemeProvider >
//     );
// }

