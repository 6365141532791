import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Area,
  AreaChart,
  Brush,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CircularProgress } from "@mui/material";

const MarginLineChart = () => {
  const [dealer, setDealer] = useState([]);
  const [liveData, setLiveDataInternal] = useState([]);
  const [dealerId, setDealerID] = useState("admin");
  const [dealerName, setDealerName] = useState("admin");
  const [highest, setHighest] = useState();
  const [lowest, setLowest] = useState();
  const [current, setCurrent] = useState();

  const [dataAvailable, setDataAvailable] = useState(true);

  const api_server_url = process.env.REACT_APP_API_SERVER_URL;

  const handleDealer = (event) => {
    const selectedDealer = event.target.value;
    setDealerID(event.target.value);
    setDealerName(event.target.value);
  };

  useEffect(() => {
    const fetchData = () => {
      axios.get(api_server_url + `/dealer/marginchart`).then((res) => {
        // console.log("set dealer", res.data);
        const dealerName = res.data;
        const dealerNameFinal = dealerName.map((dealer) => ({
          value: dealer.id,
          label: dealer.name,
        }));
        setDealer(dealerNameFinal);
        axios
          .post(api_server_url + `/dealer/marginchart`, {
            dealer_id: "admin",
            days: 1,
          })
          .then((res) => {
            // console.log(res.data);
            setLiveDataInternal(res.data.data);
            setHighest(res.data.highest);
            setLowest(res.data.lowest);
            setCurrent(res.data.current);
            setDataAvailable(false);
          })
          .catch((error) => {
            console.error(
              "Error fetching data for the selected dealer:",
              error
            );
          });
      });
    };
    fetchData();
    const intervalId = setInterval(fetchData, 60000);
    return () => clearInterval(intervalId);
  }, [api_server_url]);

  if (!dataAvailable) {
    <p className="flex items-center justify-center h-full text-sm text-gray-500">
      <CircularProgress />
    </p>;
  }

  return (
    <div
      style={{
        width: "100%",
        height: "95%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ResponsiveContainer>
        {liveData.length > 0 ? (
          <AreaChart data={liveData} isAnimationActive={false}>
            <defs>
              <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="#3b9599" stopOpacity="0.4" />
                <stop offset="100%" stopColor="#3b9599" stopOpacity="0" />
              </linearGradient>
            </defs>

            <XAxis
              dataKey="time"
              tick={{
                fontSize: "10px",
                fill: "#828282",
                angle: -18,
                textAnchor: "end",
              }}
            />
            <YAxis
              dataKey="usedMargin"
              tick={{ fontSize: "10px", fill: "#828282" }}
            />

            <Area
              type="monotone"
              dataKey="usedMargin"
              stroke="#3b9599"
              fill="url(#gradient)" // Apply the gradient fill
            />

            <Brush height={11} stroke="black" fill="slategray" />

            <Tooltip
              content={(dataItem) => {
                if (dataItem.payload && dataItem.payload.length > 0) {
                  const timeValue = dataItem.payload[0].payload.time;
                  const value = dataItem?.payload[0]?.payload?.usedMargin;

                  const formattedValue = value?.toLocaleString("en-IN");
                  return (
                    <div
                      style={{
                        background: "rgba(1, 13, 18, 0.95)",
                        color: "white",
                        borderRadius: "5px",
                        padding: "8px",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Margin: {formattedValue}
                      </p>
                      <p style={{ fontWeight: "bold", marginBottom: "5px" }}>
                        Time: {timeValue}
                      </p>
                    </div>
                  );
                }
                return null;
              }}
            />
          </AreaChart>
        ) : (
          <div className="flex items-center justify-center h-full">
            <p>Data not available.</p>
          </div>
        )}
      </ResponsiveContainer>

      <div className="flex gap-10 items-center justify-center">
        <p className="text-[12px]">Current Margin : {current}</p>
        <p className="text-[12px]">Highest Margin : {highest}</p>
        <p className="text-[12px]">Lowest Margin : {lowest}</p>
      </div>
    </div>
    // {/* <div>
    //   <h3 className="text-center text-sm">Margin Line Chart</h3>
    // </div> */}
    // </div>
  );
};

export default MarginLineChart;
