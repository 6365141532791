import React from "react";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceLine,
  ResponsiveContainer,
} from "recharts";

const NetPnlBars = ({
  data,
  barMaxDataKeys = ["BarMax", 
  // "AvgProfit"
],
  barPnlDataKeys = ["BarPnl", "dayPnl"],
  barMinDataKeys = ["BarMin", "Drawdown"],
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart barGap={-12} data={data} stackOffset="none">
        <XAxis
          dataKey='Time'
          tick={{ fontSize: "11px", fill: "white" }}
          padding={{ left: 50, right: 50 }}
          xAxisId={0}
        />
        <XAxis
          dataKey={Object.keys(data[0])[0]}
          tick={{ fontSize: "11px", fill: "white" }}
          padding={{ left: 50, right: 50 }}
          xAxisId={1}
          hide
        />
        <YAxis tick={{ fontSize: "11px", fill: "white" }} />
        <Tooltip
          contentStyle={{
            background: "rgba(1, 13, 18, 0.95)",
            color: "white",
            borderRadius: "5px",
            border:"none",
            padding: "8px",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        />
        <ReferenceLine y={0} stroke="#ccc" />

        {barMinDataKeys?.map((dataKey, index) => (
          <Bar
            key={`bar-min-${index}`}
            dataKey={dataKey}
            fill="#F23A3A4D"
            barSize={12}
            xAxisId={0}
            radius={[4, 4, 0, 0]}
          />
        ))}

        {barMaxDataKeys?.map((dataKey, index) => (
          <Bar
            key={`bar-max-${index}`}
            dataKey={dataKey}
            fill="#15513C"
            barSize={12}
            xAxisId={0}
            radius={[4, 4, 0, 0]}
          />
        ))}

        {barPnlDataKeys?.map((dataKey, index) => (
          <Bar
            key={`bar-pnl-${index}`}
            dataKey={dataKey}
            fill="#44C662"
            barSize={12}
            xAxisId={1}
            radius={[4, 4, 0, 0]}
          >
            {data?.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={entry.BarPnl > 0 ? "#44C662" : "#F23A3A"}
              />
            ))}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default NetPnlBars;
