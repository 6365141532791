import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Tooltip } from '@mui/material';

const Row = React.memo((props) => {
    const { row, headers } = props;
    return (
        <TableRow
            key={row?.DealerID}
            sx={{
                '&:hover': {
                    backgroundColor: '#1B4152',
                },
                '& > .MuiTableCell-root': {
                    p: 0.4,
                    border: '1px solid #307C9D',
                },
                backgroundColor: '#021B26'
            }}
        >
            {headers?.map((header, idx) => (
                <TableCell key={header.id} component="th" scope="row" align='center' style={{
                    borderLeft: idx === 0 ? '1px solid #307C9D' : 'transparent', fontSize: '11px',
                    cursor: 'pointer', borderBottom: '1px solid #307C9D', borderRight: '1px solid #307C9D', padding: '5px'
                }}>
                    {
                        row[header.id] && (header.id === 'AvgMargin' || header.id === 'SigmaCost')
                            ? row[header.id].toLocaleString('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 0, maximumFractionDigits: 0 })
                            : (typeof row[header.id] === 'number' ? row[header.id].toFixed(2) : row[header.id])
                    }
                </TableCell>
            ))}
        </TableRow>
    );
});

Row.propTypes = {
    row: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
};

const HeaderTable = ({ headers, data }) => {
    // If data is an array, wrap it in an object
    const rowData = Array.isArray(data) ? { row: data } : data;
    
    return (
        <Box className='MUITable-scrollbar'>
            <TableContainer component={Paper} style={{ maxHeight: '30vh', overflow: 'auto' }}>
                <Table sx={{ minWidth: 600 }} stickyHeader aria-label="sticky table">
                    <TableHead sx={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableRow>
                            {headers?.map((header) => (
                                <TableCell
                                    key={header.id}
                                    align='center'
                                    sx={{ ...header.style, fontSize: '12px', borderRight: '1px solid #307C9D', borderTop: '1px solid #307C9D', backgroundColor: '#001017', fontWeight: 800 }}
                                >
                                    <Tooltip title={header.label} placement='bottom' arrow>
                                        <span>{header.label}</span>
                                    </Tooltip>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody sx={{ backgroundColor: '#021B26', fontSize: '12px' }}>
                        <Row row={rowData} headers={headers} />
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

HeaderTable.propTypes = {
    headers: PropTypes.array.isRequired,
    // Change the prop type to accept both object and array
    data: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]).isRequired,
};

export default HeaderTable;