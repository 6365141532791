import React from "react";
import { Link } from 'react-router-dom'

const NotFoundPage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-[80vh]">
      <img
        alt='Your browser does not support the video tag.'
        src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExbXU3d3J6OTVoam4xNmIyMDN6bHA0Z3o0bTR2dTllamdpajU0MnhxeCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/b2GbsZ92eoBcm3ycrN/giphy.gif"
        className="w-64 h-auto mb-8 mix-blend-overlay"
        style={{ mixBlendMode: "normal" }}
      />
      <h1 className="text-white text-4xl font-bold mb-4">404 Not Found</h1>
      <p className="text-white text-lg">The page you are looking for does not exist.</p>

      <button className="border border-[#3b9599] text-[#fff] bg-[#3b9599] px-4 py-2 mt-5 rounded-lg"><Link to='/'>Go To Home Page</Link></button>
    </div>
  );
};

export default NotFoundPage;
